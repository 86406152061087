import React from "react";
import {
  Text,
  View,
  TouchableOpacity,
  Dimensions,
  useWindowDimensions,
} from "react-native";
import StyleSheet from "react-native-media-query";

// Utils
import { getFormattedPrice } from "../../../utils/productMethods";

// Constants
import { family } from "../../../constants/theme";
import i18n from "../../../i18n/i18n";

const TicketStepOne = ({ onPress, data }) => {
  const { name, event_location, amount, city } = data;
  const height = useWindowDimensions().height;
  return (
    <View style={[styles.container, { height: height - height * 0.3 }]}>
      <View style={styles.amountContainer}>
        <Text
          style={styles.amount}
          dataSet={{ media: ids.amount }}
        >{`${i18n.t("tickets.from")} ${getFormattedPrice(amount)}`}</Text>
      </View>
      <View style={styles.textContainer} dataSet={{ media: ids.textContainer }}>
        <Text style={styles.heroTitle} dataSet={{ media: ids.heroTitle }}>
          {name}
        </Text>
      </View>
      <View
        style={[styles.textContainer, { marginBottom: 10 }]}
        dataSet={{ media: ids.textContainer }}
      >
        <Text
          style={styles.heroDescription}
          dataSet={{ media: ids.heroDescription }}
        >
          {city}
        </Text>
      </View>
      <View style={styles.textContainer} dataSet={{ media: ids.textContainer }}>
        <Text
          style={[styles.heroDescription, { fontSize: 18 }]}
          dataSet={{ media: ids.heroDescription }}
        >
          {event_location}
        </Text>
      </View>
      <TouchableOpacity
        style={styles.button}
        dataSet={{ media: ids.button }}
        onPress={onPress}
      >
        <Text
          style={[styles.heroDescription, { color: "black" }]}
          dataSet={{ media: ids.heroDescription }}
        >
          {i18n.t("tickets.buy")}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default TicketStepOne;

const { ids, styles } = StyleSheet.create({
  container: {
    flexDirection: "column",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    width: 200,
    height: 50,
    paddingTop: 5,
    borderRadius: 40,
    backgroundColor: "white",
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    "@media (max-width: 500px)": {
      width: 120,
      height: 30,
    },
  },
  textContainer: {
    width: "80%",
    marginBottom: 50,
    alignItems: "center",
    "@media (max-width: 500px)": {
      marginBottom: 20,
      width: "100%",
    },
  },
  heroTitle: {
    fontSize: 48,
    color: "white",
    fontFamily: family.normal,
    textAlign: "center",
    "@media (max-width: 500px)": {
      fontSize: 24,
    },
  },
  heroDescription: {
    fontSize: 20,
    color: "white",
    fontFamily: family.normal,
    textAlign: "center",
    "@media (max-width: 500px)": {
      fontSize: 18,
    },
  },
  amountContainer: {
    paddingBottom: 40,
    alignItems: "center",
  },
  amount: {
    fontSize: 24,
    color: "white",
    fontFamily: family.normal,
    textAlign: "center",
    "@media (max-width: 500px)": {
      fontSize: 12,
    },
  },
});
