import React, { useEffect } from "react";
import { Platform } from "react-native";

// Custom import
const HTMLView = Platform.OS !== "web" && require("react-native-htmlview");

// TODO: erase usage of this component
const HtmlNative = ({ value, style, styleSheet, id = 1, link, script }) => {
  useEffect(() => {
    let valueToInsert = value + `<style>${style}</style>`;
    if (script) {
      valueToInsert += `<link>${link}</link>`;
    }
    if (Platform.OS === "web") {
      document.getElementById(`web-${id}`).innerHTML = valueToInsert;
      window.__be = window.__be || {};
      window.__be.id = "5b3a47b4b30a36000769d821";
      (function () {
        var be = document.createElement("script");
        be.type = "text/javascript";
        be.async = true;
        be.src =
          ("https:" == document.location.protocol ? "https://" : "http://") +
          script;
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(be, s);
      })();
    }
  }, [value]);

  return Platform.OS === "web" ? (
    <div id={`web-${id}`} />
  ) : (
    <HTMLView.default value={value} stylesheet={styleSheet} />
  );
};

export default HtmlNative;
