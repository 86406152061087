import React, { useState, useEffect } from "react";
import { View, Text, StyleSheet } from "react-native";
import { connect } from "react-redux";

// Components
import AccordionListItem from "../../../../../components/atoms/AccordionListItem";
import Divider from "../../../../../components/atoms/Divider";
import TextInput from "../../../../../components/atoms/TextInput";
import CustomText from "../../../../../components/atoms/CustomText";
import Button from "./Button";
import Title from "./Title";

// Constants
import { family } from "../../../../../constants/theme";

// Logic
import fetchDiscount from "./functions/fetchDiscount";
import handleDiscount from "./functions/handleDiscount";

// Redux
import { setDiscount } from "../../../../../redux/cart/cartActions";

const DiscountSection = ({
  customStyle,
  discount,
  userGid,
  orders,
  totalAmount,
  setDiscount,
}) => {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [discountResponse, setDiscountResponse] = useState(null);
  const [validValue, setValidValue] = useState(false);
  const [discountName, setDiscountName] = useState();

  const { accentColor, textColor } = customStyle;

  useEffect(() => {
    if (discount?.id_discount) {
      setValidValue(true);
    } else {
      setValidValue(false);
    }
  }, [discount]);

  useEffect(() => {
    setError();
  }, [value]);

  useEffect(() => {
    if (discountResponse) {
      handleDiscount({ discountData: discountResponse, cartData, methods });
    }
  }, [orders]);

  const cartData = {
    orders,
    totalAmount,
    value,
    discount,
  };

  const methods = {
    setDiscount,
    setError,
    setDiscountName,
  };

  const buttonHandler = async () => {
    try {
      setLoading(true);
      const response = await fetchDiscount(userGid, value);
      setDiscountResponse(response);
      handleDiscount({ discountData: response, cartData, methods });
    } catch (error) {
      console.log("[error] getting discount: ", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <View>
      <AccordionListItem
        title={
          <Title
            customStyle={customStyle}
            validValue={validValue}
            discountName={discountName}
          />
        }
        hasIcon={false}
        backgroundColor="transparent"
        accordionSwitch={validValue}
      >
        <View style={styles.container}>
          <Text style={[styles.text, { color: textColor }]}>
            Introduce el código de descuento
          </Text>
          <View style={styles.inputContainer}>
            <TextInput
              value={value}
              onChange={setValue}
              placeholder="Escribe aquí tu código"
              isDiscount={true}
            />
            <Divider width={8} />
            <Button
              customStyle={customStyle}
              onPress={buttonHandler}
              loading={loading}
            />
          </View>
          <View style={styles.errorWrapper}>
            {discount.id_discount !== null ? (
              <CustomText color={accentColor} text={`¡Descuento aplicado!`} />
            ) : (
              error && <CustomText color="red" text={error} />
            )}
          </View>
        </View>
      </AccordionListItem>
    </View>
  );
};

const mapStateToProps = (state) => {
  return {
    discount: state.cart.discount.discount,
    userGid: state.user.user.gid,
    orders: state.cart.orders,
  };
};

const mapDispatchToProps = {
  setDiscount,
};

const styles = StyleSheet.create({
  container: {
    paddingLeft: 18,
    flexDirection: "column",
    width: "100%",
  },
  errorWrapper: {
    height: 15,
    marginVertical: 5,
    width: "100%",
  },
  inputContainer: {
    width: "100%",
    justifiItems: "stretch",
    marginTop: 15,
    flexDirection: "row",
  },
  text: {
    fontFamily: family.normal,
    fontSize: 14,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DiscountSection);
