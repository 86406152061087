import React from "react";
import StyleSheet from "react-native-media-query";
import { Text, View } from "react-native";
import { Ionicons, Entypo } from "@expo/vector-icons";

// Components
import Divider from "../../atoms/Divider";
import TicketDateContainer from "../components/TicketDateContainer";
import TicketDescription from "../components/TicketDescription";

// Constants
import { family } from "../../../constants/theme";
import i18n from "../../../i18n/i18n";

const TicketExtendedInfo = ({ data }) => {
  const { end_date, start_date, long_description, city, location, min_age } =
    data;
  return (
    <View style={styles.container}>
      <View style={styles.content} dataSet={{ media: ids.content }}>
        <Text style={styles.title}>{i18n.t("tickets.info")}</Text>
        <Divider height={5} />
        <Divider width="100%" height={1} color={"white"} />
        <Divider height={15} />
        {min_age && (
          <Text style={styles.city}>
             {i18n.t("tickets.min_age")} {min_age}
          </Text>
        )}
        <View style={styles.directionContainer}>
          {city !== null && (
            <View style={styles.locationContainer}>
              <Entypo name="map" size={18} color="white" />
              <Divider width={5} />
              <Text style={styles.city}>{city}</Text>
            </View>
          )}
          {location !== null && (
            <View style={styles.locationContainer}>
              <Ionicons name="location" size={18} color="white" />
              <Divider width={5} />
              <Text style={styles.city}>{location}</Text>
            </View>
          )}
        </View>
        <View style={styles.infoContainer}>
          <TicketDescription data={long_description} />
        </View>
        {/* <TicketDateContainer start={start_date} end={end_date} /> */}
      </View>
    </View>
  );
};

export default TicketExtendedInfo;

const { ids, styles } = StyleSheet.create({
  container: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 30,
  },
  content: {
    width: "100%",
    maxWidth: 1440,
    paddingHorizontal: 50,
    "@media (max-width: 500px)": {
      paddingHorizontal: 20,
    },
  },
  title: {
    fontFamily: family.bold,
    fontSize: 18,
    color: "white",
  },
  infoContainer: {
    width: "100%",
    marginBottom: 10,
  },
  directionContainer: {
    flexDirection: "column",
    width: "100%",
    marginTop: 20,
  },
  city: {
    fontSize: 14,
    fontFamily: family.normal,
    color: "white",
  },
  locationContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 5,
  },
});
