import React from "react";
import { Text, View, TouchableOpacity } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import StyleSheet from "react-native-media-query";

// Constants
import { family } from "../../../constants/theme";

const TicketBack = ({ text, navigation, backAction, shopId }) => {
  const clickHandler = () => {
    if (backAction !== null) {
      backAction();
    } else {
      navigation.navigate("Tickets", { shopId: shopId });
    }
  };
  return (
    <TouchableOpacity
      style={styles.container}
      dataSet={{ media: ids.container }}
      onPress={clickHandler}
    >
      <View style={styles.wrapper} dataSet={{ media: ids.wrapper }}>
        <View style={styles.line}>
          <MaterialCommunityIcons
            name={"menu-left"}
            size={20}
            color={"white"}
            marginRight={15}
          />
          <Text style={styles.text} dataSet={{ media: ids.text }}>
            {text}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default TicketBack;

const { ids, styles } = StyleSheet.create({
  container: {
    position: "absolute",
    width: "100%",
    top: 100,
    zIndex: 10,
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 500px)": {
      top: 60,
    },
  },
  wrapper: {
    width: "100%",
    maxWidth: 1440,
    paddingHorizontal: 40,
    "@media (max-width: 500px)": {
      paddingHorizontal: 16,
    },
    "@media (max-width: 300px)": {
      paddingHorizontal: 6,
    },
  },
  line: { flexDirection: "row", alignItems: "center" },
  text: {
    fontFamily: family.bold,
    fontSize: 18,
    color: "white",
    "@media (max-width: 500px)": {
      fontSize: 12,
    },
  },
});
