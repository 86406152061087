import * as geolib from "geolib";

export const getDistance = ({ latA, lngA, latB, lngB }) => {
  const distance = geolib.getPreciseDistance(
    {
      latitude: latA,
      longitude: lngA,
    },
    {
      latitude: latB,
      longitude: lngB,
    }
  );
  return distance;
};

export const getDistances = ({ array, ref }) => {
  let distances = [];
  array.forEach((element) => {
    let distance = getDistance({
      latA: parseFloat(element.lat),
      lngA: parseFloat(element.lng),
      latB: ref.lat,
      lngB: ref.lng,
    });
    distances.push(distance);
  });
  return distances;
};

export const sortLiveElements = ({ array, ref }) => {
  const newArray = array.sort((a, b) => {
    const distanceA = getDistance({
      latA: parseFloat(a.lat),
      lngA: parseFloat(a.lng),
      latB: ref.lat,
      lngB: ref.lng,
    });
    const distanceB = getDistance({
      latA: parseFloat(b.lat),
      lngA: parseFloat(b.lng),
      latB: ref.lat,
      lngB: ref.lng,
    });
    
    if (a.schedule?.opened === 1 || a.schedule?.scheduled === 1) {
      if (distanceA > distanceB) {
        return 1;
      } else {
        return -1;
      }
    }
    return 1
  });
  return newArray;
};
