import React, { useState, useEffect } from "react";
import { View, Text, useWindowDimensions } from "react-native";
import { connect } from "react-redux";
import StyleSheet from "react-native-media-query";
import {
  getAuth,
  signInWithEmailAndPassword,
  browserLocalPersistence,
  setPersistence,
} from "firebase/auth";

// Components
import TextInput from "../../../atoms/TextInput";
import CustomButton from "../../../atoms/CustomButton";
import CustomError from "../../../atoms/CustomError";
import Hero from "../components/Hero";
import Divider from "../../../atoms/Divider";
import MediaSection from "./MediaSection";
import SectionDivider from "../components/SectionDivider";
import TouchableText from "../../../atoms/TouchableText";
import TermsText from "../components/TermsText";

// Constants
import { colors, family } from "../../../../constants/theme";

// Config
import { app } from "../../../../../config";

// Utils
import { parseFireBaseErrors } from "../../../../utils/errors/parseFirebaseErrors";
import { validateMail } from "../../../../utils/arrayMethods";
import { getLocalData } from "../../../../utils/miscelanusUtils";

// Redux actions
import { signInFirebase } from "../../../../redux/user/userActions";
import { firebaseApp } from "../../../../services/firebase";

const LoginSection = ({ sectionProps, signInFirebase }) => {
  const { setStep, step, onAuth, customStyle } = sectionProps;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [client, setClient] = useState();
  const [error, setError] = useState("");

  const width = useWindowDimensions().width;
  const height = useWindowDimensions().height;

  const tocuhableTextStyle = {
    fontFamily: family.bold,
    color: customStyle.textColor,
    fontSize: width > 340 && height > 700 ? 14 : 10,
  };

  const DIVIDER_HEIGHT = width > 340 || height > 700 ? 14 : 8;

  useEffect(() => {
    setEmail("");
    setError("");
  }, [step]);

  useEffect(() => {
    setError("");
  }, [email, password]);

  const validEmail = validateMail(email);
  const validPassword = password !== "";

  const activeButton = validEmail && validPassword;

  const handleSignIn = async () => {
    const auth = getAuth(firebaseApp);
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log('userCredential',userCredential)
        signInFirebase(userCredential.user.accessToken).then((response) => {
          setError();
          if (response?.status === "success") {
            setPassword("");
            setEmail("");
            if (onAuth) {
              onAuth();
            }
          } else if (response?.status === "fail") {
            setError(response.data);
          } else {
            setError("No se pudo establecer conexión con el servidor");
          }
        });
      })
      .catch((error) => {
        const errorMessage = parseFireBaseErrors(error.code);
        setError(errorMessage);
      });
  };

  const getClient = async () => {
    const currentClient = await getLocalData("client");
    setClient(currentClient);
  };

  useEffect(() => {
    getClient();
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.sectionContainer}>
        <Hero
          secondaryTitle={"Inicia sesión"}
          title={"Inicia sesión"}
          subtitle={"¡Hola de nuevo! Tu nuevo pedido te está esperando"}
          customStyle={customStyle}
        />
        <Divider height={DIVIDER_HEIGHT} />
        {app !== "tickets" && (
          <MediaSection
            setError={setError}
            onAuth={onAuth}
            customStyle={customStyle}
            sectionProps={sectionProps}
          />
        )}
      </View>
      {app !== "tickets" && <SectionDivider />}
      <View style={[styles.sectionContainer, { justifyContent: "flex-start" }]}>
        <TextInput placeholder="Email" value={email} onChange={setEmail} />
        <Divider height={DIVIDER_HEIGHT} />
        <TextInput
          marginBottom={DIVIDER_HEIGHT}
          placeholder="Contraseña"
          value={password}
          onChange={setPassword}
          secure
        />

        {client !== "gmp" && (
          <View style={styles.touchableContainer}>
            <TouchableText
              customStyle={tocuhableTextStyle}
              text="¿Has olvidado tu contraseña?"
              onPress={() => setStep(4)}
              underlineColor="transparent"
            />
          </View>
        )}
        <Divider height={DIVIDER_HEIGHT / 2} />
        <View style={styles.touchableContainer}>
          <Text
            style={[styles.text, { color: customStyle?.textColor }]}
            dataSet={{ media: ids.text }}
          >
            ¿Aún no tienes cuenta?{" "}
          </Text>
          <TouchableText
            customStyle={tocuhableTextStyle}
            text="¡Regístrate!"
            onPress={() => setStep(1)}
            underlineColor="transparent"
          />
        </View>
        <Divider height={DIVIDER_HEIGHT / 2} />
        <CustomError error={error} color={customStyle.errorColor} />
        <Divider height={DIVIDER_HEIGHT / 2} />
        <TermsText setStep={setStep} from="login" customStyle={customStyle} />
      </View>
      <Divider height={DIVIDER_HEIGHT} />
      <View
        style={styles.buttonContainer}
        dataSet={{ media: ids.buttonContainer }}
      >
        <CustomButton
          active={activeButton}
          title="Continuar"
          onPress={handleSignIn}
          textColor={customStyle.buttonTextColor}
          color={customStyle.backgroundColor}
        />
      </View>
    </View>
  );
};

const mapDispatchToProps = {
  signInFirebase,
};

export default connect(null, mapDispatchToProps)(LoginSection);

const { ids, styles } = StyleSheet.create({
  container: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  terms: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 20,
  },
  termButton: {
    marginLeft: 5,
    width: "100%",
  },
  underline: {
    textDecorationLine: "underline",
    textDecorationStyle: "solid",
    textDecorationColor: "black",
  },
  termsText: {
    color: "black",
    fontFamily: family.normal,
    fontSize: 12,
  },
  buttonContainer: {
    width: "100%",
    height: 70,
    "@media (max-height: 700px) or (max-width: 340px)": {
      height: 32,
    },
  },
  sectionContainer: {
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  touchableContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  text: {
    fontFamily: family.normal,
    color: colors.brown,
    fontSize: 14,
    "@media (max-height: 700px) or (max-width: 340px)": {
      fontSize: 10,
    },
  },
  termTextContainer: {
    width: "100%",
  },
});
