import React from "react";
import { StyleSheet, View, Platform, ImageBackground } from "react-native";

// Constants
import { colors } from "../../constants/theme";

const Screen = ({
  children,
  screenBackground,
  screenColor,
  size = "phone",
  ...props
}) => {
  return (
    <View
      style={[
        styles.screen,
        screenColor && { backgroundColor: screenColor },
        Platform.OS === "web" && { alignItems: "center" },
      ]}
    >
      {screenBackground ? (
        <ImageBackground
          style={[styles.content, { ...props }]}
          source={{ uri: screenBackground }}
          imageStyle={{ resizeMode: "cover" }}
        >
          {children}
        </ImageBackground>
      ) : (
        <View style={[styles.content, { ...props }]}>{children}</View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  screen: {
    width: "100%",
    height: "100%",
    backgroundColor: colors.white,
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    width: "100%",
    flex: 1,
    maxWidth: 400,
  },
});

export default Screen;
