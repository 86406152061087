import React from "react";
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import * as Linking from "expo-linking";

// Components
import Divider from "../../components/atoms/Divider";
import Screen from "../../components/atoms/Screen";

// Constants
import { colors, family } from "../../constants/theme";
import { Platform } from "react-native";

const UpdateScreen = () => {
  const subtitle =
    "Para disfrutar de todas las mejoras y\nnuevas características, por favor\n actualiza la aplicación.";

  const title = "¡Actualización requerida!";
  const buttonHandler = () => {
    Linking.openURL(
      Platform.OS === "ios"
        ? "https://apps.apple.com/es/app/gopick-pide-y-paga-en-recintos/id1628179463"
        : "https://play.google.com/store/apps/details?id=com.gopick.user&gl=ES"
    );
  };
  return (
    <Screen>
      <View style={styles.container}>
        <Image
          style={styles.image}
          source={require("../../assets/update.png")}
        />
        <Divider height={30} />
        <Text style={styles.title}>{title}</Text>
        <Divider height={10} />
        <Text style={styles.text}>{subtitle}</Text>
        <Divider height={30} />
        <TouchableOpacity
          style={styles.buttonContainer}
          onPress={buttonHandler}
        >
          <Text style={styles.buttonTitle}>{"Actualizar ahora"}</Text>
        </TouchableOpacity>
      </View>
    </Screen>
  );
};

export default UpdateScreen;

const styles = StyleSheet.create({
  screen: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize: 20,
    color: colors.brown,
    fontFamily: family.bold,
  },
  text: {
    fontSize: 16,
    color: colors.brown,
    fontFamily: family.normal,
    textAlign: "center",
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  buttonContainer: {
    minHeight: 40,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.lightPrimary,
    borderRadius: 50,
    paddingHorizontal: 15,
  },
  buttonTitle: {
    fontFamily: family.bold,
    fontSize: 18,
    color: colors.white,
  },
  image: {
    resizeMode: "contain",
    width: 100,
    height: 100,
  },
});
