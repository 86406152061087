import React, { useEffect, useContext } from "react";
import { StyleSheet, View, ScrollView } from "react-native";
import { connect } from "react-redux";

// Components
import CustomModal from "../../../../../components/atoms/CustomModal";
import ModalBackButton from "../../../../../components/molecules/ModalBackButton";
import AuthContainer from "../../../../../components/organisms/auth/AuthContainer";

// Hooks
import useForceRender from "../../../../../hooks/useForceRender";
import { AuthContext } from "../../../../../contexts/AuthContext";

const UserUpModal = ({
  visible,
  navigation,
  canBeClosed = false,
  setModal,
}) => {
  const { user: firebaseUser } = useContext(AuthContext);
  const [forceRender] = useForceRender();

  useEffect(() => {
    if (firebaseUser) {
      setModal();
    }
  }, [firebaseUser]);

  const onAuth = () => {
    if (firebaseUser) {
      setModal();
      forceRender();
    }
  };
  return (
    <CustomModal
      modalVisible={visible}
      handleModal={() => {
        canBeClosed === true ? setModal() : {};
      }}
    >
      <View style={styles.container}>
        <ScrollView style={styles.scroll} showsVerticalScrollIndicator={false}>
          <View style={styles.backButton}>
            <ModalBackButton
              action={() => {
                navigation.goBack();
              }}
            />
          </View>
          <AuthContainer navigation={navigation} onAuth={onAuth} />
        </ScrollView>
      </View>
    </CustomModal>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "auto",
    width: "100%",
    maxHeight: 620,
    justifyContent: "center",
    alignItems: "center",
  },
  backButton: {
    width: "100%",
  },
  scroll: {
    width: "100%",
  },
});

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(UserUpModal);
