import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  getIdToken,
} from "firebase/auth";

import {
  getReactNativePersistence,
  initializeAuth,
} from "firebase/auth/react-native";

import { firebaseConfig } from "../../firebase-config";
import AsyncStorage from "@react-native-async-storage/async-storage";

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = initializeAuth(app, {
  persistence: getReactNativePersistence(AsyncStorage),
});


export const signOutFirebase = async () => {
  try {
    await signOut(auth);
    return true;
  } catch (error) {
    console.log("[FIREBASE ERROR] signOutFirebase");
    console.error(error);
    return false;
  }
};

export const signIn = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;
    return true;
  } catch (error) {
    return { error: error.message };
  }
};

export const signUp = async (email, password) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;
    // TODO: create user in DB
    return true;
  } catch (error) {
    return { error: error.message };
  }
};

export const getToken = async (user) => {
  try {
    const idToken = await getIdToken(user, true);
    return idToken;
  } catch (error) {
    return { error: error.message };
  }
};

export const firebaseApp = app;
