const espMonths = [
  "Ene",
  "Feb",
  "Mar",
  "Abr",
  "May",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Oct",
  "Nov",
  "Dic",
];

export const timestampToDate = (timestamp) => {
  let unix_timestamp = timestamp;
  var date = new Date(unix_timestamp * 1000);

  var year = String(date.getFullYear());
  var month = espMonths[date.getMonth()];
  var day = date.getDate();

  return day + " " + month + ". " + year;
};

export const timestampToTime = (timestamp) => {
  let unix_timestamp = timestamp;
  var date = new Date(unix_timestamp * 1000);
  var hours = date.getHours();
  var minutes = "0" + date.getMinutes();

  var formattedTime = hours + ":" + minutes.substr(-2);

  return formattedTime;
};

export const unixToHHMM = (unixDate) => {
  const date = new Date(unixDate * 1000);

  const hours = date.getHours();
  const minutes = date.getMinutes();

  const formattedDate = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;

  return formattedDate;
};

export const parseHoraAMinutos = (hora) => {
  const partesHora = hora.split(":");
  return parseInt(partesHora[0]) * 60 + parseInt(partesHora[1]);
};

export const getFormattedDate = (unixDate) => {
  const date = new Date(unixDate * 1000); // Se multiplica por 1000 porque Unix mide en segundos y JavaScript en milisegundos
  const day = date.getDate().toString().padStart(2, "0"); // Obtiene el día y lo rellena con un cero a la izquierda si es necesario
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Obtiene el mes (se le suma 1 porque los meses en JavaScript empiezan en 0) y lo rellena con un cero a la izquierda si es necesario
  const hours = date.getHours().toString().padStart(2, "0"); // Obtiene las horas y las rellena con un cero a la izquierda si es necesario
  const minutes = date.getMinutes().toString().padStart(2, "0"); // Obtiene los minutos y los rellena con un cero a la izquierda si es necesario
  return `${day}/${month} ${hours}:${minutes}`; // Retorna la fecha en el formato dd/mm hh:mm
};
