import React from "react";
import { AntDesign } from "@expo/vector-icons";
import { LinearGradient } from "expo-linear-gradient";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  ImageBackground,
} from "react-native";

// Components
import Status from "./Status";

// Constants
import { colors, family } from "../../../../constants/theme";

const Header = ({ image, navigation, status, name }) => {
  const backHandler = () => {
    navigation.goBack();
  };
  return (
    <ImageBackground style={styles.image} source={{ uri: image }}>
      <TouchableOpacity style={styles.backButton} onPress={backHandler}>
        <AntDesign name="arrowleft" size={24} color={colors.brown} />
      </TouchableOpacity>
      <View style={styles.containerShopName}>
        <Text style={styles.shopName}>{name}</Text>
      </View>
      <Status status={status} />
      <LinearGradient
        colors={["transparent", "rgba(0,0,0,1)"]}
        style={styles.gradient}
        start={{ x: 0, y: 0 }}
        end={{ x: 0, y: 1 }}
      />
    </ImageBackground>
  );
};

export default Header;

const styles = StyleSheet.create({
  image: {
    width: "100%",
    maxWidth: 440,
    height: 180,
    resizeMode: "cover",
  },
  containerShopName: {
    zIndex: 2,
    flex: 1,
    justifyContent: "flex-end",
  },
  shopName: {
    color: "white",
    fontSize: 24,
    fontFamily: family.bold,
    textAlign: "center",
  },
  gradient: {
    width: "100%",
    maxWidth: 440,
    height: 180,
    resizeMode: "cover",
    position: "absolute",
  },
  backButton: {
    height: 40,
    width: 40,
    borderRadius: 20,
    backgroundColor: colors.white,
    justifyContent: "center",
    alignItems: "center",
    zIndex: 3,
    top: 40,
    left: 20,
    position: "absolute",
  },
});
