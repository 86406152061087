import React from "react";
import StyleSheet from "react-native-media-query";
import { TouchableOpacity } from "react-native";
import { Text, View } from "react-native";
import { colors, family, RADIUS } from "../../../../../../constants/theme";

const ScheduleItem = ({ onPress, selected, customStyle, text }) => {
  const { backgroundColor, primaryColor, textColor } = customStyle;

  const defaultStyle = [
    styles.container,
    { backgroundColor: backgroundColor, borderColor: textColor },
  ];
  const selectedStyle = [
    styles.container,
    { backgroundColor: primaryColor, borderColor: primaryColor },
  ];
  const defaultTextStyle = [styles.text, { color: textColor }];
  const selectedTextStyle = [styles.text, { color: backgroundColor }];
  return (
    <TouchableOpacity
      onPress={onPress}
      style={selected ? selectedStyle : defaultStyle}
      dataSet={{ media: ids.container }}
    >
      <Text
        style={selected ? selectedTextStyle : defaultTextStyle}
        dataSet={{ media: ids.text }}
      >
        {text}
      </Text>
    </TouchableOpacity>
  );
};

export default ScheduleItem;

const { ids, styles } = StyleSheet.create({
  container: {
    paddingVertical: 2,
    paddingHorizontal: 4,
    borderRadius: RADIUS-2,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    marginRight: 12,
    "@media (max-width:370px)": {
      paddingVertical: 2,
      paddingHorizontal: 2,
      marginRight: 6,
    },
  },
  text: {
    fontFamily: family.normal,
    fontSize: 10,
    "@media (max-width:370px)": {
      fontSize: 8,
    },
  },
});
