import React from "react";
import { TextInput } from "react-native";
import StyleSheet from "react-native-media-query";

// Constants
import { colors, size, family, RADIUS } from "../../constants/theme";

const Input = ({
  onChange,
  placeholder,
  value ="",
  secure = false,
  multiline = false,
  viewOnly,
  isDiscount = false,
  ...props
}) => (
  <TextInput
    style={[
      styles.input,
      multiline === true && { flex: 1, paddingTop: 10 },
      // TODO: captura different TextFields so to know which style to use, isDiscount created for discountFildOnly
      isDiscount === true && { flex: 1, width: undefined },
      props,
    ]}
    onChangeText={onChange}
    placeholderTextColor={colors.darkGrey}
    value={value}
    editable={viewOnly ? false : true}
    placeholder={placeholder}
    secureTextEntry={secure}
    autoCapitalize="none"
    multiline={multiline}
    {...props}
    dataSet={{ media: ids.input }}
  />
);

const { ids, styles } = StyleSheet.create({
  input: {
    width: "100%",
    padding: 10,
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    borderRadius: RADIUS,
    borderWidth: 1,
    borderColor: colors.grey,
    fontSize: 16,
    fontFamily: family.normal,
    color: colors.brown,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    "@media (max-height: 700px) or (max-width: 340px)": {
      fontSize: 12,
      padding: 6,
    },
  },
});

export default Input;
