import React from "react";
import {
  StyleSheet,
  Text,
  Image,
  View,
  useWindowDimensions,
  TouchableOpacity,
} from "react-native";

// Components
import ServiceLine from "./ServiceLine";
import StatusLine from "./StatusLine";
import StatusTag from "./StatusTag";
import Title from "./Title";

// Componentes
import { colors, family } from "../../../constants/theme";

// Utils
import { getFormattedPrice } from "../../../utils/productMethods";
import { unixToHHMM } from "../../../utils/dateMethods";

const PastOrderItem = ({ data, navigation }) => {
  const width = useWindowDimensions().width;
  const { status, amount, shop, service, location, deliveryDate, orderNumber } =
    data;

  const clickHandler = () => {
    navigation.navigate("PastOrderDetailScreen", data);
  };

  return (
    <TouchableOpacity style={styles.container} onPress={clickHandler}>
      {shop?.image && width > 320 && (
        <Image style={styles.image} source={{ uri: shop?.image }} />
      )}
      <View style={styles.content}>
        <View style={styles.row}>
          <View style={{ flex: 1 }}>
            <Title title={shop?.name} />
          </View>
          <View style={{ alignItems: "flex-end" }}>
            <Title title={orderNumber} color={colors.primaryColor} />
          </View>
        </View>
        <View style={styles.row}>
          <ServiceLine service={service?.key} location={location} />
        </View>
        <View style={styles.row}>
          <StatusLine
            status={status?.key}
            scheduled={unixToHHMM(deliveryDate)}
          />
        </View>
        <View style={styles.row}>
          <StatusTag status={status?.key} />
          <Text style={styles.price}>{getFormattedPrice(amount)}</Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default PastOrderItem;

const styles = StyleSheet.create({
  container: {
    marginVertical: 10,
    maxWidth: 400,
    height: 130,
    marginHorizontal: 18,
    backgroundColor: "white",
    borderRadius: 10,
    marginBottom: 15,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    flexDirection: "row",
  },
  image: {
    width: 120,
    height: "100%",
    resizeMode: "cover",
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  content: {
    padding: 12,
    flex: 1,
    justifyContent: "space-between",
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  price: {
    fontFamily: family.normal,
    fontSize: 14,
    color: colors.brown,
  },
});
