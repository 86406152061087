import React from "react";
import { View, Text } from "react-native";
import StyleSheet from "react-native-media-query";

// Config
import { app } from "../../../config";

// Constants
import { colors, family } from "../../constants/theme";

const CustomError = ({ error, color = colors.primaryColor }) => {
  const visible =
    error !== "" && error !== null && error !== undefined ? true : false;

  return (
    <View style={styles.errorContainer} dataSet={{ media: ids.errorContainer }}>
      {app === "tickets" ? (
        <>
          {visible === true && (
            <View style={styles.ticketingContainer}>
              <Text
                style={[styles.text, { color: "white" }]}
                dataSet={{ media: ids.text }}
              >
                {error}
              </Text>
            </View>
          )}
        </>
      ) : (
        visible === true && (
          <Text
            style={[styles.text, { color: color }]}
            dataSet={{ media: ids.text }}
            numberOfLines={3}
          >
            {error}
          </Text>
        )
      )}
    </View>
  );
};

export default CustomError;

const { ids, styles } = StyleSheet.create({
  errorContainer: {
    width: "100%",
    "@media (max-width: 320px)": {
      height: 20,
    },
  },
  ticketingContainer: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 15,
    paddingRight: 15,
    backgroundColor: "#EB3A3A",
    borderRadius: 4,
    "@media (max-width: 320px)": {
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 2,
      paddingBottom: 2,
    },
  },
  text: {
    fontFamily: family.normal,
    fontSize: 14,
    "@media (max-width: 320px)": {
      fontSize: 8,
    },
  },
});
