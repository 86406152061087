import React from "react";
import { StyleSheet, Text, View, TouchableOpacity } from "react-native";
import { FontAwesome } from "@expo/vector-icons";

// Constants
import { colors, size, family } from "../../constants/theme";

const ModalBackButton = ({ action, color }) => {
  return (
    <TouchableOpacity style={styles.backContainer} onPress={action}>
      <View style={{ marginRight: 5 }}>
        <FontAwesome
          name="chevron-left"
          size={14}
          color={color || colors.primaryColor}
        />
      </View>
      <Text style={[styles.backText, color && { color: color }]}>Volver</Text>
    </TouchableOpacity>
  );
};

export default ModalBackButton;

const styles = StyleSheet.create({
  backContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 8,
  },
  backText: {
    fontFamily: family.bold,
    fontSize: size.small,
    color: colors.primaryColor,
  },
});
