const anyEmptyQuestion = (answerArray) => {
  let result = false;
  let ticketToSelect = null;
  answerArray?.map((el) => {
    el.questions.map((question) => {
      if (question.response === "" && question.required === 1) {
        if (ticketToSelect === null) {
          ticketToSelect = el.gid;
        }
        result = true;
      }
    });
  });
  return ticketToSelect;
};

export default anyEmptyQuestion