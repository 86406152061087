import React from "react";
import { Text, View } from "react-native";
import StyleSheet from "react-native-media-query";
import { family } from "../../../../constants/theme";
import { getFormattedPrice } from "../../../../utils/productMethods";
import i18n from "../../../../i18n/i18n";

const CommissionLine = ({ commission }) => {
  return (
    <View style={[styles.line, { marginBottom: 20 }]}>
      <View style={styles.qtyContainer}></View>
      <View style={styles.content}>
        <Text style={styles.text} dataSet={{ media: ids.text }}>
          {i18n.t("tickets.admin_fee")}
        </Text>
        <Text style={styles.text} dataSet={{ media: ids.text }}>
          {getFormattedPrice(commission)}
        </Text>
      </View>
    </View>
  );
};

export default CommissionLine;

const { ids, styles } = StyleSheet.create({
  line: {
    flexDirection: "row",
    width: "100%",
  },
  content: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 30,
  },
  qtyContainer: {
    width: 30,
  },
  text: {
    fontFamily: family.normal,
    fontSize: 14,
    color: "white",
    "@media (max-width: 700px)": {
      fontSize: 10,
    },
  },
});
