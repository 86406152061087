import { v4 as uuidv4 } from "uuid";
import {
  getSingleProductCost,
  menuEqual,
  productEqual,
} from "../../utils/productMethods";
import {
  ADD_SHOP,
  ADD_SIMPLE_PRODUCT,
  RESET_CART,
  ADD_PRODUCT,
  DECREMENT_PRODUCT,
  DECREMENT_MENU_PRODUCT,
  INCREMENT_MENU_PRODUCT,
  INCREMENT_PRODUCT,
  ADD_FORMED_MENU,
  REMOVE_FORMED_MENU,
  RESET_FORMED_MENU,
  SET_SERVICE,
  SET_DISCOUNT,
  ADD_MENU_VARIANTS,
  SET_LOCATION,
  SET_DATE,
  SET_COMMENT,
  SET_PAYMENT_METHOD,
  PURGUE_CART,
  SET_CARD,
  SET_TOTAL_TO_PAY,
  SET_ORDER_TYPE,
} from "./cartTypes";
import {
  getCommissionArray,
  getScheduleArray,
  getShopById,
  hasLaterOrders,
} from "./cartFunctions";

const initialState = {
  orders: [],
  location: { gid_location: null, location: null },
  comment: "",
  payment_method: "card",
  card: null,
  orderType: "ASAP",
  delivery_address: null,
  discount: {
    discount: { amount: 0, name: "Descuento", id_discount: null },
    discountLines: [],
  },
  services: [],
  totalToPay: null,
  currentMenu: {
    gid: null,
    quantity: null,
    amount: null,
    name: null,
    plates: [],
    variants: [],
  },
};

// TODO: ensure that only orders with order_lines are included

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SHOP: {
      const auxOrderState = [...state.orders];
      const { shop, detailShop, eventData, orderType } = action.payload;
      const today = new Date(Date.now());
      const [aux, shopIndex] = getShopById(auxOrderState, shop.gid);
      
      if (shopIndex === -1) {
        auxOrderState.push({
          date: today,
          delivery: orderType || "ASAP",
          id_shop: detailShop.gid || shop.id,
          hasLaterOrders: hasLaterOrders(shop),
          id_event: detailShop.fk_event,
          platform_gid: eventData?.platform_gid || 1,
          registration: detailShop.registration,
          shop_name: detailShop.name,
          custom_design: detailShop.custom_design || null,
          only_scheduled:
            shop.schedule.opened === 0 && shop.schedule.scheduled === 1
              ? true
              : false,
          alerts: detailShop.alerts || [],
          divide_orders_point_sale: detailShop.divide_orders_point_sale || null,
          order_lines: [],
          just_menu: detailShop.just_menu || 0,
          pick_up_time: detailShop.pick_up_time || 0,
          pick_up_date: detailShop.pick_up_date || 0,
          order_lines_service: [],
          max_gopick_commission: detailShop?.max_gopick_commission || null,
          wait_time: shop?.wait_time || null,
          payment_methods: detailShop.payment_methods || [],
          order_lines_commission: getCommissionArray(detailShop),
          order_lines_discount: [],
          opened: shop.schedule.opened || 0,
          schedules:
            detailShop.pick_up_time === 1
              ? getScheduleArray(detailShop?.schedule_slots)
              : [],
          time_ranges: detailShop.time_ranges || null,
        });
      }
      return { ...state, orders: auxOrderState };
    }
    case ADD_SIMPLE_PRODUCT: {
      const auxOrderState = [...state.orders];
      const data = action.payload;
      const [auxShop, shopIndex] = getShopById(auxOrderState, data.fk_shop);

      const product = { ...data, internal_id: uuidv4(), quantity: 1 };
      auxOrderState[shopIndex].order_lines.push(product);

      return {
        ...state,
        orders: auxOrderState,
      };
    }
    case ADD_PRODUCT: {
      const {
        gid,
        name,
        only_legal_age,
        not_pay_service,
        not_pay_commission,
        increment,
        id_point_sale,
        amount,
        type,
        shop,
        quantity,
        ingredients,
        variants,
        menuData,
        exclusive_order,
        family,
      } = action.payload;
      let currentShop;
      let currentShopIndex = -1;
      const auxOrderState = [...state.orders];
      let auxMenuState = state.currentMenu;

      let productRawData;
      shop.products.map((category) => {
        category.products.forEach((product) => {
          const realGid = parseInt(gid.split(",")[0]);
          if (product.gid === realGid) {
            productRawData = product;
          }
        });
      });

      const auxProduct = {
        gid: gid,
        type: type || "individual",
        id_shop: shop.gid || shop.id_shop,
        only_legal_age: only_legal_age || null,
        not_pay_service: not_pay_service || null,
        not_pay_commission: not_pay_commission || null,
        increment: increment || null,
        id_point_sale: id_point_sale || null,
        exclusive_order: exclusive_order || null,
        quantity: quantity || 1,
        amount: amount,
        totalAmount: getSingleProductCost(amount, variants),
        name: name,
        stock: productRawData?.stock || null,
        ingredients: ingredients || [],
        variants: variants || [],
        family: family || null,
        internal_id: uuidv4(),
      };

      auxOrderState.map((orderShop, index) => {
        if (
          orderShop.id_shop == shop.gid ||
          orderShop.id_shop === shop.id_shop
        ) {
          currentShop = orderShop;
          currentShopIndex = index;
        }
      });

      if (menuData) {
        let currentPlateTindex = -1;
        var auxProductMenu = {
          id_menu_type_plate: menuData.typePlate,
          ...auxProduct,
        };

        auxMenuState.plates.map((plate, index) => {
          if (productEqual(plate, auxProductMenu) === true) {
            currentPlateTindex = index;
          }
        });
        if (auxMenuState.gid === null) {
          auxMenuState = {
            gid: menuData.gid,
            internal_id: uuidv4(),
            quantity: 1,
            type: "individual",
            id_point_sale: menuData.id_point_sale,
            amount: menuData.amount,
            name: menuData.name,
            only_legal_age: menuData.only_legal_age || null,
            not_pay_service: menuData.not_pay_service || null,
            not_pay_commission: menuData.not_pay_commission || null,
            family: menuData.family || null,
            exclusive_order: menuData.exclusive_order || null,
            plates: [auxProductMenu],
            variants: state.currentMenu.variants,
            totalAmount: getSingleProductCost(
              menuData.amount,
              state.currentMenu.variants,
              state.currentMenu.plates
            ),
          };
        } else {
          if (currentPlateTindex !== -1) {
            auxMenuState.plates[currentPlateTindex].quantity += 1;
          } else {
            auxMenuState.plates.push(auxProductMenu);
            auxMenuState.totalAmount = getSingleProductCost(
              menuData.amount,
              state.currentMenu.variants,
              state.currentMenu.plates
            );
          }
        }
      } else {
        let currentProductIndex = -1;
        currentShop?.order_lines.map((line, index) => {
          if (productEqual(line, auxProduct) === true) {
            currentProductIndex = index;
          }
        });
        if (currentProductIndex === -1) {
          auxOrderState[currentShopIndex].order_lines.push(auxProduct);
        } else {
          if (!quantity) {
            auxOrderState[currentShopIndex].order_lines[currentProductIndex]
              .quantity++;
          } else {
            auxOrderState[currentShopIndex].order_lines[
              currentProductIndex
            ].quantity += quantity;
          }
        }
      }

      return {
        ...state,
        orders: auxOrderState,
        currentMenu: auxMenuState,
      };
    }
    case INCREMENT_PRODUCT: {
      const auxOrderState = [...state.orders];
      const internal_id = action.payload;

      state.orders.map((shop, shopIndex) => {
        shop?.order_lines.map((line, index) => {
          if (internal_id === line.internal_id) {
            auxOrderState[shopIndex].order_lines[index].quantity++;
          }
        });
      });

      return { ...state, orders: auxOrderState };
    }
    case DECREMENT_PRODUCT: {
      const auxOrderState = [...state.orders];
      const internal_id = action.payload;

      state.orders.map((shop, shopIndex) => {
        shop?.order_lines.map((line, index) => {
          if (internal_id === line.internal_id) {
            const quantity =
              auxOrderState[shopIndex].order_lines[index].quantity;
            if (quantity === 1) {
              auxOrderState[shopIndex].order_lines.splice(index, 1);

              if (auxOrderState[shopIndex].order_lines.length === 0) {
                auxOrderState[shopIndex].order_lines = [];
              }
            } else {
              if (quantity > 1) {
                auxOrderState[shopIndex].order_lines[index].quantity =
                  quantity - 1;
              }
            }
            //se hace una copia de la tienda para que el render detecte el cambio.
            auxOrderState[shopIndex] = JSON.parse(
              JSON.stringify(auxOrderState[shopIndex])
            );
          }
        });
      });

      return { ...state, orders: auxOrderState };
    }
    case DECREMENT_MENU_PRODUCT: {
      let auxCurrentPlates = [...state.currentMenu.plates];
      const productInternalID = action.payload;

      let index = auxCurrentPlates.findIndex(
        (el) => el.internal_id === productInternalID
      );
      if (index !== -1) {
        const quantity = auxCurrentPlates[index].quantity;
        if (quantity === 1) {
          auxCurrentPlates.splice(index, 1);
        } else {
          if (quantity > 1) {
            auxCurrentPlates[index].quantity = quantity - 1;
          }
        }
      }

      //se hace una copia de la tienda para que el render detecte el cambio.
      auxCurrentPlates = JSON.parse(JSON.stringify(auxCurrentPlates));

      return {
        ...state,
        currentMenu: { ...state.currentMenu, plates: auxCurrentPlates },
      };
    }
    case INCREMENT_MENU_PRODUCT: {
      const auxCurrentPlates = [...state.currentMenu.plates];
      const internal_id = action.payload;

      auxCurrentPlates.map((plate, plateIndex) => {
        if (internal_id === plate.internal_id) {
          auxCurrentPlates[plateIndex].quantity++;
        }
      });

      return {
        ...state,
        currentMenu: { ...state.currentMenu, plates: auxCurrentPlates },
      };
    }
    case ADD_FORMED_MENU: {
      const shopId = action.payload.shopId;
      const menu = action.payload.menu;
      let auxMenuState = state.currentMenu;
      const auxOrderState = [...state.orders];
      const menuHashId = uuidv4();
      let menuIndex = -1;

      const [auxShop, shopIndex] = getShopById(auxOrderState, shopId);

      //Existe la tienda, buscamos el index del menu
      if (shopIndex !== -1) {
        auxOrderState[shopIndex].order_lines.map((line, pIndex) => {
          if (auxMenuState.gid !== null) {
            if (line.gid === auxMenuState.gid) {
              if (menuEqual(line, auxMenuState)) {
                menuIndex = pIndex;
              }
            }
          } else {
            if (line.gid === menu.gid) {
              if (menuEqual(line, menu)) {
                menuIndex = pIndex;
              }
            }
          }
        });
      }

      if (menuIndex !== -1) {
        auxOrderState[shopIndex].order_lines[menuIndex].quantity += 1;
      } else {
        auxOrderState[shopIndex].order_lines.push({
          ...auxMenuState,
          hashId: menuHashId,
        });
      }

      let AuxAuxState = {
        ...state,
        orders: auxOrderState,
        currentMenu: {
          gid: null,
          quantity: null,
          amount: null,
          name: null,
          plates: [],
        },
      };
      return AuxAuxState;
    }
    case RESET_FORMED_MENU: {
      const auxOrderState = [...state.orders];
      let AuxAuxState = {
        ...state,
        orders: auxOrderState,
        currentMenu: {
          gid: null,
          quantity: null,
          amount: null,
          name: null,
          plates: [],
        },
      };

      return AuxAuxState;
    }
    case REMOVE_FORMED_MENU: {
      const currentMenu = action.payload.menu;
      const shopId = action.payload.shopId;

      let menuIndex = -1;

      const auxOrderState = [...state.orders];

      const [auxShop, shopIndex] = getShopById(auxOrderState, shopId);

      if (shopIndex !== -1) {
        auxOrderState[shopIndex].order_lines.map((line, pIndex) => {
          if (line?.hashId === currentMenu.hashId) {
            menuIndex = pIndex;
          }
        });
      }

      if (menuIndex !== -1) {
        const quantity =
          auxOrderState[shopIndex].order_lines[menuIndex].quantity;
        if (quantity === 1) {
          auxOrderState[shopIndex].order_lines.splice(menuIndex, 1);

          if (auxOrderState[shopIndex].order_lines.length === 0) {
            auxOrderState[shopIndex].order_lines = [];
          }
        } else {
          if (quantity > 1) {
            auxOrderState[shopIndex].order_lines[menuIndex].quantity =
              quantity - 1;
          }
        }
      }
      return { ...state, orders: auxOrderState };
    }
    case ADD_MENU_VARIANTS: {
      const { variants } = action.payload;
      const auxMenuState = state.currentMenu;
      auxMenuState.variants = variants;
      return { ...state, currentMenu: auxMenuState };
    }

    case SET_SERVICE: {
      const { service, shop, location, gid_location } = action.payload;
      let auxService = state.services;

      let auxIndex = -1;
      auxService.map((el, index) => {
        if (el.shopId === shop.gid) {
          auxIndex = index;
        }
      });
      if (auxIndex === -1) {
        auxService.push({
          ...service,
          shopId: shop.gid,
          shopName: shop.name,
          location: location,
          gid_location: gid_location,
        });
      } else {
        auxService[auxIndex] = {
          ...service,
          shopId: shop.gid,
          shopName: shop.name,
          location: location,
          gid_location: gid_location,
        };
      }
      return {
        ...state,
        services: auxService,
      };
    }
    case SET_DISCOUNT: {
      const { discount, discountLines } = action.payload;
      return {
        ...state,
        discount: { discount: discount, discountLines: discountLines },
      };
    }
    case SET_LOCATION: {
      const { location, gid_location } = action.payload;

      return {
        ...state,
        location: { gid_location: gid_location, location: location },
      };
    }
    case SET_DATE: {
      const auxOrders = [...state.orders];
      const today = new Date(Date.now());
      const { date } = action.payload;

      if (date === null) {
        auxOrders.forEach((shop) => {
          shop.date = today;
          shop.delivery = "ASAP";
        });
      } else {
        const hour = date.split(":")[0];
        const minute = date.split(":")[1];
        const auxDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          hour,
          minute
        );
        auxOrders.forEach((shop) => {
          shop.date = auxDate;
          shop.delivery = date;
        });
      }
      return { ...state, orders: auxOrders };
    }

    case SET_COMMENT: {
      return { ...state, comment: action.payload };
    }

    case SET_PAYMENT_METHOD: {
      return { ...state, payment_method: action.payload.paymentMethod };
    }

    case RESET_CART: {
      return {
        orders: [],
        location: { gid_location: null, location: null },
        comment: "",
        payment_method: "card",
        card: null,
        orderType: "ASAP",
        delivery_address: null,
        discount: {
          discount: { amount: 0, name: "Descuento", id_discount: null },
          discountLines: [],
        },
        services: [],
        totalToPay: null,
        currentMenu: {
          gid: null,
          quantity: null,
          amount: null,
          name: null,
          plates: [],
          variants: [],
        },
      };
    }
    case SET_TOTAL_TO_PAY: {
      return { ...state, totalToPay: action.payload };
    }
    case SET_CARD: {
      const card = action.payload;
      return { ...state, card: card };
    }

    case SET_ORDER_TYPE: {
      const type = action.payload;
      const auxOrders = [...state.orders];

      auxOrders.forEach((shop) => {
        shop.orderType = type;
      });
      return { ...state, orders: auxOrders, orderType: type };
    }
    case SET_TOTAL_TO_PAY: {
      return { ...state, totalToPay: action.payload };
    }

    case PURGUE_CART: {
      let auxStateOrders = [...state.orders];
      let auxStateServices = [...state.services];

      state.orders.map((order, index) => {
        if (order.order_lines.length === 0) {
          state.services.map((service, serviceIndex) => {
            if (service.shopId === order.id_shop) {
              auxStateServices.splice(serviceIndex, 1);
            }
          });
          auxStateOrders.splice(index, 1);
        }
      });
      return { ...state, orders: auxStateOrders, services: auxStateServices };
    }
    default:
      return state;
  }
};

export default cartReducer;
