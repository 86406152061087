import {
  FETCH_ORDERS,
  UPDATE_ORDER,
  FETCH_LATER_ORDERS,
  FETCH_COMPLETED_ORDERS,
} from "./ordersTypes";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  orders: [],
  laterOrders: [],
  completedOrders:[]
};

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORDERS: {
      const orders = action.payload;
      return { ...state, orders: orders };
    }
    case FETCH_LATER_ORDERS: {
      const orders = action.payload;
      return { ...state, laterOrders: orders };
    }
    case FETCH_COMPLETED_ORDERS: {
      const orders = action.payload;
      return { ...state, completedOrders: orders };
    }
    case UPDATE_ORDER: {
      const auxOrders = [...state.orders];
      const { gid, status } = action.payload;
      auxOrders.map((order) => {
        if (order.gid === gid) {
          order.status = { key: status, gid: uuidv4() };
        }
      });

      return { ...state, orders: auxOrders };
    }
    default:
      return state;
  }
};

export default ordersReducer;
