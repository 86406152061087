import React from "react";
import { StyleSheet, Text, TouchableOpacity } from "react-native";
import { colors, family } from "../../constants/theme";

const TouchableText = ({
  onPress,
  text,
  customStyle,
  underlineColor = colors.brown,
}) => {
  return (
    <Text
      onPress={onPress}
      style={[
        styles.underline,
        styles.default,
        customStyle && { ...customStyle },
        { textDecorationColor: underlineColor },
      ]}
    >
      {text}
    </Text>
  );
};

export default TouchableText;

const styles = StyleSheet.create({
  default: {
    color: colors.brown,
    fontFamily: family.normal,
    fontSize: 12,
  },
  underline: {
    textDecorationLine: "underline",
    textDecorationStyle: "solid",
  },
});
