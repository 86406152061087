import { Platform } from "react-native";
import { getLocalData } from "../../utils/miscelanusUtils";
import { purgueFinalOrder } from "../../utils/orderMethods";
import { formattedOrder } from "../../utils/productOrderMethods";

const getFinalOrder = async ({ cart, user }) => {
  const auxCart = cart;
  let order = formattedOrder(auxCart, user, auxCart.discount.discountLines);

  // this should be being taken via redux
  const metaData = await getLocalData("metaData");

  order = {
    ...order,
    metaData: { ...metaData },
    channel: Platform.OS,
  };

  if (auxCart.amountToPay === 0) {
    order = { ...order, payment_method: "cash" };
    order = purgueFinalOrder(order);
  }
  return order;
};

export default getFinalOrder;
