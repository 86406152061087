import React, { useState, useEffect, useCallback } from "react";
import StyleSheet from "react-native-media-query";
import { useFocusEffect } from "@react-navigation/native";
import { View, Text } from "react-native";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";

// Components
import BuyTicket from "../../components/tickets/containers/BuyTicket";
import Divider from "../../components/atoms/Divider";
import NoContentTickets from "../../components/tickets/components/NoContentTickets";
import TicketExtendedInfo from "../../components/tickets/containers/TicketExtendedInfo";
import TicketHero from "../../components/tickets/containers/TicketHero";
import TicketLegalModal from "../../components/tickets/modals/TicketLegalModal";
import TicketLogInModal from "../../components/tickets/modals/TicketLogInModal";
import TicketOrder from "../../components/tickets/components/TicketOrder";
import TicketQuestionsModal from "../../components/tickets/modals/TicketQuestionsModal";
import TicketScreen from "../../components/tickets/components/TicketScreen";
import TicketStepOne from "../../components/tickets/containers/TicketStepOne";
import TicketExtraProducts from "../../components/tickets/components/TicketExtraProducts";
// Hooks
import useForceRender from "../../hooks/useForceRender";

// Redux
import {
  fetchTicketDetails,
  fetchShopTickets,
  setProductLinesData,
  setShopCheckoutData,
  setTicketDiscount,
} from "../../redux/tickets/ticketsActions";

// Utils
import { getLocalData } from "../../utils/miscelanusUtils";
import {
  getTotalTicketDiscountPromo,
  ticketProductEqual,
} from "../../utils/productMethods";

// Constants
import { family } from "../../constants/theme";
import i18n from "../../i18n/i18n";
import TicketDiscountModal from "../../components/tickets/modals/TicketDiscountModal";
import { discountAvailableTickets } from "../../utils/orderMethods";
import getCommissionByElement from "../../components/tickets/methods/getCommissionByElement";

const TicketDetailScreen = ({
  navigation,
  route,
  fetchTicketDetails,
  fetchShopTickets,
  setTicketDiscount,
  setProductLinesData,
  setShopCheckoutData,
  productLines,
  discountLines,
}) => {
  const { ticketId } = route.params;
  const [step, setStep] = useState(0);
  const [modal, setModal] = useState();
  const [answerArray, setAnswerArray] = useState([]);
  const [discount, setDiscount] = useState({
    amount: 0,
    name: "Descuento",
    id_discount: null,
  });
  const [discountError, setDiscountError] = useState();
  const [promotion, setPromotion] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [availableCommissions, setAvailableCommissions] = useState([]);
  const [commission, setCommission] = useState(0);

  const [filled, setFilled] = useState(false);
  const [ticketData, setTicketData] = useState(null);
  const [shop, setShop] = useState(null);
  const [loading, setLoading] = useState(true);
  const [ticketLines, setTicketLines] = useState([]);

  // new ticket structure
  const [ticketArray, setTicketArray] = useState([]);
  const [productArray, setProductArray] = useState([]);
  const [noContent, setNoContent] = useState(false);
  const [userData, setUserData] = useState();
  const [forceRender] = useForceRender();

  const getCommissionPrice = (ticketLines, productLines, commissions) => {
    let result = 0;

    ticketLines.map((el) => {
      result += getCommissionByElement(el, commissions, el.quantity);
    });

    productLines.map((el) => {
      result += getCommissionByElement(el, commissions, el.quantity);
    });

    discountLines.map((discount) => {
      ticketLines.map((el) => {
        if (
          discount.gid === el.id_ticket &&
          discount.amount + el.amount === 0
        ) {
          const amount = getCommissionByElement(el, commissions);
          result -= amount;
        }
      });
      productLines.map((el) => {
        if (discount.gid === el.gid && discount.amount + el.amount === 0) {
          result -= getCommissionByElement(el, commissions);
        }
      });
    });
    if (result > shop?.max_gopick_commission) {
      result = shop?.max_gopick_commission;
    }
    setCommission(result);
    return result;
  };

  const addTicket = (ticket) => {
    let auxTicketLines = [...ticketLines];
    let exists = false;
    ticketLines.map((el, index) => {
      if (el.id_ticket === ticket.gid) {
        exists = true;
        auxTicketLines[index].quantity += 1;
      }
    });
    if (exists === false) {
      auxTicketLines.push({
        name: ticket.name,
        fk_shop: ticket.fk_shop,
        payService: ticket?.not_pay_commission === 1 ? 1 : 0,
        quantity: 1,
        amount: ticket.amount,
        id_ticket: ticket.gid,
        questions: ticket.questions,
      });
    }
    setTicketLines(auxTicketLines);
  };

  const removeTicket = (ticket) => {
    let auxTicketLines = [...ticketLines];
    ticketLines.map((el, index) => {
      if (el.id_ticket === ticket.gid) {
        if (el.quantity > 1) {
          auxTicketLines[index].quantity -= 1;
        } else if (el.quantity === 1) {
          auxTicketLines.splice(index, 1);
        }
      }
    });
    setTicketLines(auxTicketLines);
  };
  const addProduct = ({ data, variants = [], ingredients = [], qty = 1 }) => {
    let auxProductLines = [...productLines];
    let exists = false;
    let price = data.amount;
    variants.map((group) =>
      group.options.map((el) => {
        price += el.increment;
      })
    );
    let auxProduct = {
      name: data.name,
      gid: data.gid,
      type:
        data?.variants?.length > 0 || data?.ingredients?.length > 0
          ? "complex"
          : "simple",
      payService: data.not_pay_service === 1 ? 1 : 0,
      exclusive_order: data.exclusive_order || 0,
      amount: price,
      quantity: qty || 1,
      id_point_sale: data.fk_point_sale || null,
      id_product: data.gid,
      variants: [],
      fk_shop: shop?.gid,
      ingredients: [],
      hash: data?.hash || uuidv4(),
    };
    auxProduct = {
      ...auxProduct,
      commission: getCommissionByElement(auxProduct, availableCommissions, qty),
    };

    if (ingredients.length > 0) {
      auxProduct.ingredients = ingredients;
    }
    if (variants.length > 0) {
      let vars = [];
      variants.map((group) => {
        let aux = [];
        group.options.map((el) => {
          aux.push({
            gid: el.gid,
            name: el.name,
            quantity: el.qty,
          });
        });
        vars.push({
          id_group_variants: group.gid,
          name: group.name,
          options: aux,
        });
        aux = [];
      });
      auxProduct.variants = vars;
    }

    productLines.map((el, index) => {
      if (
        el?.hash === auxProduct?.hash ||
        ticketProductEqual(el, auxProduct) === true
      ) {
        const finalQty = auxProductLines[index].quantity + qty;
        exists = true;
        const finalCommission = getCommissionByElement(
          auxProductLines[index],
          availableCommissions,
          finalQty
        );
        auxProductLines[index].quantity += qty;
        auxProductLines[index].commission = finalCommission;
      }
    });
    if (exists === false) {
      auxProductLines.push(auxProduct);
    }
    setProductLinesData(auxProductLines);
  };

  const removeProduct = (product) => {
    let auxProductLines = [...productLines];
    if (product.hash) {
      productLines.map((el, index) => {
        if (el.hash === product.hash) {
          if (el.quantity > 1) {
            auxProductLines[index].quantity -= 1;
          } else if (el.quantity === 1) {
            auxProductLines.splice(index, 1);
          }
        }
      });
    } else {
      productLines.map((el, index) => {
        if (el.gid === product.gid) {
          if (el.quantity > 1) {
            auxProductLines[index].quantity -= 1;
          } else if (el.quantity === 1) {
            auxProductLines.splice(index, 1);
          }
        }
      });
    }
    setProductLinesData(auxProductLines);
  };

  const getTotalAmount = () => {
    let amount = 0;

    ticketLines?.map((el) => {
      amount += el.amount * el.quantity;
    });
    productLines?.map((el) => {
      amount += el.amount * el.quantity;
    });
    if (amount - discount.amount <= 0) {
      return 0;
    } else {
      amount +=
        getCommissionPrice(ticketLines, productLines, availableCommissions) -
        discount.amount;
    }

    return amount;
  };

  const shopDataSetter = (res) => {
    if (res?.tickets.length > 0 || res?.products.length > 0) {
      setTicketArray(res?.tickets);
      setProductArray(res?.products);
      setTicketData(res);
      fetchShopTickets(res.fk_shop).then((shopData) => {
        if (shopData) {
          setShop(shopData);
          shopData?.tickets?.map((category) => {
            category.tickets.map((ticket) => {
              if (ticket.gid.toString() === ticketId.toString()) {
                setAvailableCommissions([
                  {
                    amount: shopData?.gopick_commission,
                    type: shopData?.type_gopick_commission,
                  },
                  {
                    amount: shopData?.waiter_commission,
                    type: shopData?.type_waiter_commission,
                  },
                ]);
                forceRender();
              }
            });
          });
        }
      });
    } else {
      setNoContent(true);
    }
    setFilled(true);
    setLoading(false);
  };

  useFocusEffect(
    useCallback(() => {
      setLoading(true);
      setDiscount({
        amount: 0,
        name: "Descuento",
        id_discount: null,
      });
      setTicketDiscount([]);
      setStep(0);

      const getShopData = async () => {
        const data = await fetchTicketDetails(ticketId);
        shopDataSetter(data);
      };

      try {
        getShopData();
      } catch (err) {
        setFilled(false);
        setLoading(false);
      }
    }, [])
  );

  useEffect(() => {
    setTotalPrice(getTotalAmount());
    questionSetterHandler();
  }, [ticketLines, productLines, discountLines, discount]);

  useEffect(() => {
    setTicketLines([]);
  }, [step]);

  useEffect(() => {
    if (promotion !== false) {
      const { available, data } = discountAvailableTickets({
        ticketLines: ticketLines,
        productLines: productLines,
        tickets: promotion?.tickets,
        shops: promotion?.shops,
        products: promotion?.products,
        events: promotion?.events,
      });
      if (available === true) {
        const { auxDiscount, discount_lines } = getTotalTicketDiscountPromo({
          type: promotion?.type,
          discount: promotion?.discount,
          max: promotion?.max_discount,
          min: promotion?.from_amount,
          numProducts: promotion?.apply_max_num_product,
          gid: promotion?.gid,
          data: data,
          totalPrice: totalPrice,
        });
        setTicketDiscount(discount_lines);
        setDiscount({
          amount: auxDiscount,
          name: "Descuento",
          id_discount: promotion?.gid,
        });
        setDiscountError();
        setModal();
      } else {
        setDiscount({
          amount: 0,
          name: "Descuento",
          id_discount: null,
        });
        if (promotion?.gid) {
          setDiscountError(i18n.t("tickets.code_useless"));
        }
      }
    } else {
      if (promotion?.gid) {
        setDiscountError(i18n.t("tickets.code_invalid"));
      }
      setDiscount({
        amount: 0,
        name: "Descuento",
        id_discount: null,
      });
    }
  }, [promotion, ticketLines, productLines]);

  useEffect(() => {
    const shopCheckoutData = {
      fk_event: shop?.fk_event,
      gid: shop?.gid,
      pixel_code: shop?.pixel_code,
      id_pixel_code: shop?.id_pixel_code,
      image: ticketData?.image_header,
      maxCommission: shop?.max_gopick_commission,
    };
    if (shop?.gid) {
      setShopCheckoutData(shopCheckoutData);
    }
  }, [shop]);

  const questionSetterHandler = () => {
    let auxQArray = [];
    ticketLines.map((ticket) => {
      if (ticket?.questions !== null) {
        if (ticket?.questions?.length > 0) {
          for (let i = 0; i < ticket.quantity; i++) {
            let aux = [];
            ticket.questions.map((question) => {
              aux.push({
                question: question.question.question,
                response: question.type === "select" ? question.options[0] : "",
                ...question,
              });
            });

            auxQArray.push({
              gid: uuidv4(),
              id_ticket: ticket.id_ticket,
              questionId: i,
              name: i > 0 ? ticket.name + " (" + (i + 1) + ")" : ticket.name,
              questions: aux,
            });
            aux = [];
          }
        }
      }
    });
    setAnswerArray(auxQArray);
  };

  const onLogin = async () => {
    setModal();
  };

  return (
    <TicketScreen navigation={navigation} shopId={shop?.gid}>
      {loading === true ? (
        <NoContentTickets loading />
      ) : noContent === true ? (
        <NoContentTickets text={i18n.t("tickets.ticket_not_found")} />
      ) : (
        <>
          <TicketHero
            backText={shop?.name || i18n.t("tickets.back")}
            shopId={shop?.gid}
            navigation={navigation}
            backAction={step === 1 ? () => setStep(0) : null}
            image={ticketData?.image_header}
          >
            {step === 0 && (
              <TicketStepOne
                data={ticketData}
                onPress={() => {
                  setStep(1);
                }}
              />
            )}
            {step === 1 && (
              <View style={styles.content} dataSet={{ media: ids.content }}>
                <View
                  style={styles.buyContainer}
                  dataSet={{ media: ids.buyContainer }}
                >
                  <View
                    style={styles.buyWrapper}
                    dataSet={{ media: ids.buyWrapper }}
                  >
                    {ticketArray.length === 0 && (
                      <Text style={styles.title}>
                        {i18n.t("tickets.no_content")}
                      </Text>
                    )}

                    {ticketArray.length === 0 && productArray.length === 0 ? (
                      <Text style={styles.title}>
                        {i18n.t("tickets.no_content")}
                      </Text>
                    ) : (
                      <>
                        <BuyTicket
                          onAdd={addTicket}
                          onRemove={removeTicket}
                          data={ticketArray}
                        />
                        <Divider width={20} height={20} />
                        <TicketOrder
                          ticketLines={ticketLines}
                          commission={commission}
                          productLines={productLines}
                          totalPrice={totalPrice}
                          answerArray={answerArray}
                          availableCommissions={availableCommissions}
                          discount={discount}
                          setModal={setModal}
                          navigation={navigation}
                          hasQuestions={answerArray?.length > 0}
                          setAnswerArray={setAnswerArray}
                        />
                      </>
                    )}
                  </View>
                </View>
                {productArray.length > 0 && (
                  <View style={styles.productWrapper}>
                    <TicketExtraProducts
                      data={productArray}
                      productLines={productLines}
                      addProduct={addProduct}
                      removeProduct={removeProduct}
                    />
                  </View>
                )}
              </View>
            )}
          </TicketHero>
          {step === 0 && (
            <View style={styles.infoContainer}>
              <TicketExtendedInfo data={ticketData} />
            </View>
          )}
          <TicketLogInModal
            setModal={setModal}
            visible={modal === "LogIn"}
            action={onLogin}
            navigation={navigation}
          />
          <TicketLegalModal
            visible={modal === "Legal"}
            legalAge={ticketData?.min_age}
            setModal={setModal}
            navigation={navigation}
            hasQuestions={answerArray?.length > 0}
          />
          <TicketDiscountModal
            visible={modal === "Promotion"}
            setModal={setModal}
            setPromotion={setPromotion}
            discountError={discountError}
            setDiscountError={setDiscountError}
          />
        </>
      )}
    </TicketScreen>
  );
};

const { ids, styles } = StyleSheet.create({
  buyContainer: {
    flex: 1,
    paddingBottom: 50,
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontFamily: family.bold,
    fontSize: 18,
    color: "white",
  },
  buyWrapper: {
    width: "100%",
    maxWidth: 700,
    flexDirection: "row",
    "@media (max-width: 700px)": {
      flexDirection: "column",
      justifyContent: "flex-end",
      marginTop: 75,
    },
  },
  content: {
    flex: 1,
    width: "100%",
    "@media (max-width: 700px)": {
      width: "100%",
    },
  },
  productWrapper: {
    paddingBottom: 50,
    justifyContent: "center",
    alignItems: "center",
  },
  infoContainer: {
    backgroundColor: "black",
    width: "100%",
    marginBottom: 100,
  },
});

const mapStateToProps = (state) => {
  return {
    productLines: state.tickets.productLinesData || [],
    discountLines: state.tickets.ticketDiscountLines || [],
  };
};

const mapDispatchToProps = {
  fetchTicketDetails,
  fetchShopTickets,
  setProductLinesData,
  setTicketDiscount,
  setShopCheckoutData,
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketDetailScreen);
