import React from "react";
import { Text, useWindowDimensions, View, StyleSheet } from "react-native";

// Constants
import { colors, family } from "../../../../constants/theme";

// Components
import TouchableText from "../../../atoms/TouchableText";

const TermsText = ({ setStep, from, customStyle }) => {
  const width = useWindowDimensions().width;
  const height = useWindowDimensions().height;

  const textStyle = {
    fontFamily: family.normal,
    color: customStyle?.textColor || colors.brown,
    fontSize: width > 340 && height > 700 ? 14 : 10,
  };

  const touchableTermText = {
    fontFamily: family.normal,
    color: colors.secondary,
    fontSize: width > 340 && height > 700 ? 14 : 10,
  };

  return (
    <View style={styles.termTextContainer}>
      <Text style={{ lineHeight: width > 340 || height > 700 ? 20 : 10 }}>
        <Text style={textStyle}>
          Al {from === "login" ? "iniciar sesión" : "registrarte"} estás
          aceptando los{" "}
        </Text>
        <TouchableText
          customStyle={touchableTermText}
          text="términos y condiciones"
          onPress={() => setStep(2)}
          underlineColor="transparent"
        />
        <Text style={textStyle}> y la </Text>
        <TouchableText
          customStyle={touchableTermText}
          text="política de privacidad"
          onPress={() => setStep(3)}
          underlineColor="transparent"
        />
        <Text style={textStyle}> de Gopick </Text>
      </Text>
    </View>
  );
};

export default TermsText;

const styles = StyleSheet.create({
  termTextContainer: {
    width: "100%",
  },
});
