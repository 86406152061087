import React, { useState } from "react";
import { View } from "react-native";
import { connect } from "react-redux";
import StyleSheet from "react-native-media-query";

// Components
import Divider from "../../../../../components/atoms/Divider";
import CustomAlert from "../../../../../components/molecules/CustomAlert";
import LocationsModal from "../../../../../components/organisms/modals/LocationsModal";
import { toProductScreen } from "../../../../../navigation/redirections/toProductScreen";
import {
  fetchShopById,
  addShop,
  setOrderType,
  setService,
} from "../../../../../redux";
import LiveTag from "./LiveTag";
import LiveTag2 from "./LiveTag2";
import Title from "./Title";
import Wrapper from "./Wrapper";

const LiveItem = ({
  data,
  fetchShopById,
  addShop,
  setService,
  clickable,
  itemWidth,
  setOrderType,
  navigation,
}) => {
  const [modal, setModal] = useState();
  const [alertData, setAlertData] = useState();
  const [optionsSel, setOptionsSel] = useState([]);

  const { image, name, schedule } = data;
  const abierto = schedule.opened || schedule?.scheduled;

  const shopHandler = async (service, loc, shopData, eventData, orderType) => {
    fetchShopById({
      id: shopData?.gid,
      locId: loc?.gid_location,
      service: service,
    }).then((res) => {
      if (res) {
        toProductScreen({
          navigation: navigation,
          data: shopData,
          res: res,
          action: () => {
            addShop(shopData, res, eventData, orderType);
            setOrderType(orderType);
          },
        });
      } else {
        setAlertData({
          title: `Tienda no encontrada`,
        });
        setModal("Alert");
      }
    });
  };
  const clickHandler = () => {
    if (data.shops.length === 1) {
      const currentShop = data.shops[0];
      const noService = currentShop?.services.filter(
        (el) => el.key === "no_service"
      );
      const isPrecompras = noService.length > 0;
      let orderType = "ASAP";
      if (isPrecompras) {
        orderType = "Later";
      }
      if (
        (!isPrecompras && currentShop.schedule.opened === 1) ||
        (isPrecompras && currentShop.schedule.opened !== 1)
      ) {
        if (
          currentShop.services.length === 1 &&
          currentShop.services[0].levels_locations === "not_show"
        ) {
          setService({
            service: currentShop.services[0],
            shop: currentShop,
            location: "",
            gid_location: null,
          });
          shopHandler(
            currentShop.services[0].key,
            "",
            currentShop,
            data,
            orderType
          );
        } else {
          if (isPrecompras) {
            setService({
              service: noService[0],
              shop: currentShop,
              location: "",
              gid_location: null,
            });
            shopHandler(
              currentShop.services[0].key,
              "",
              currentShop,
              data,
              orderType
            ).then(() => setLoading(false));
          } else {
            setModal("Locations");
          }
        }
      } else {
        setAlertData({
          title: `${data.name} no cuenta con puntos de venta abiertos`,
        });
        setModal("Alert");
      }
    } else {
      navigation.navigate("EventsStack", {
        screen: "ShopList",
        params: {
          shopList: data.shops.sort((a, b) => {
            if (a.schedule.opened === 1) {
              return -1;
            } else {
              return 1;
            }
          }),
          name: data.name,
          style: data.custom_design || null,
          eventData: data,
          eventId: data?.gid,
        },
      });
    }
  };

  return (
    <Wrapper
      opened={abierto}
      clickable={clickable}
      image={image}
      itemWidth={itemWidth}
      onPress={clickable ? clickHandler : () => {}}
    >
      <View style={styles.content} dataSet={{ media: ids.content }}>
        <View style={styles.tagContainer}>
          <LiveTag2 />
        </View>
        <View style={styles.infoContainer}>
          <Title title={name} />
          <Divider height={25} />
          <LiveTag />
        </View>
      </View>
      <LocationsModal
        services={data.shops[0].services}
        setModal={setModal}
        visible={modal === "Locations"}
        data={data.shops[0]}
        shopInfo={data.shops[0]}
        onNavigate={shopHandler}
        eventId={data.gid}
        optionsSel={optionsSel}
        setOptionsSel={setOptionsSel}
        navigation={navigation}
      />
      <CustomAlert
        setModalVisible={setModal}
        modalVisible={modal === "Alert"}
        alertData={alertData}
      />
    </Wrapper>
  );
};

const mapDispatchToProps = {
  addShop,
  setOrderType,
  setService,
  fetchShopById,
};

export default connect(null, mapDispatchToProps)(LiveItem);

const { ids, styles } = StyleSheet.create({
  content: {
    width: "100%",
    height: "100%",
    padding: 32,
    justifyContent: "space-between",
    "@media (max-height: 700px) or (max-width: 340px)": {
      padding: 24,
    },
  },
  infoContainer: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  tagContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
});
