import React, { useEffect } from "react";
import { Platform } from "react-native";
import { View } from "react-native";

const PixelFacebook = ({ amount, id, currency = "EUR" }) => {
  const pixel = `<!-- Facebook Pixel Code -->
  <script async defer>
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window,document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '${id}');
  fbq('track', 'PageView')
  fbq('track', 'Purchase', {value: ${amount/100}, currency: '${currency}'});
  </script>
  <noscript>
  <img height="1" width="1"
  src="https://www.facebook.com/tr?id=${id}&ev=PageView
  &noscript=1"/>
  </noscript>
  <!-- End Facebook Pixel Code -->`;

  useEffect(() => {
    if (id && amount && Platform.OS === "web") {
      var script = document.createElement("facebook");
      script.type = "text/javascript";
      script.innerHTML = pixel;
      script.async = true;
      const element = document.getElementById("pixel")
      element.appendChild(script)
    }
  }, [id, amount]);

  return <div id="pixel"></div>;
};

export default PixelFacebook;
