import React from "react";
import { View, LogBox, StyleSheet, Text, ScrollView } from "react-native";
import { FontAwesome } from "@expo/vector-icons";
import { v4 as uuidv4 } from "uuid";

// Components
import CustomModal from "../../../../../components/atoms/CustomModal";
import CustomButton from "../../../../../components/atoms/CustomButton";
import HtmlNative from "../../../../../components/atoms/HtmlNative";

// Constants
import { colors, family, size } from "../../../../../constants/theme";
import Divider from "../../../../../components/atoms/Divider";

LogBox.ignoreLogs(["react-native-styled-text"]);

const DeleteProductModal = ({
  visible,
  setModal,
  customStyle,
  shopName,
  productName,
  onAccept,
}) => {
  const { backgroundColor, textColor, primaryColor } = customStyle;
  const id = uuidv4();

  const normalStyle = [styles.text, { color: textColor }];
  const boldStyle = [normalStyle, { fontFamily: family.bold }];
  return (
    <CustomModal
      modalVisible={visible}
      handleModal={() => setModal()}
      canClose={true}
    >
      <View style={styles.titleContainer}>
        <Text style={[styles.title, { color: primaryColor }]}>
          Quitar producto
        </Text>
      </View>
      <View style={styles.content}>
        <Text>
          <Text style={normalStyle}>¿Quieres quitar </Text>
          <Text style={boldStyle}>{productName}</Text>
          <Text style={normalStyle}> de </Text>
          <Text style={boldStyle}>{shopName}</Text>
          <Text style={normalStyle}> del pedido? </Text>
        </Text>
      </View>
      <View style={styles.buttonContainer}>
        <CustomButton
          active
          onPress={onAccept}
          title="Aceptar"
          marginRight={5}
          color={primaryColor}
          textColor={backgroundColor}
        />
        <CustomButton
          active
          onPress={() => setModal()}
          title="Cancelar"
          color={backgroundColor}
          textColor={primaryColor}
        />
      </View>
    </CustomModal>
  );
};

export default DeleteProductModal;

const styles = StyleSheet.create({
  text: {
    fontFamily: family.normal,
    color: colors.brown,
    fontSize: size.small,
  },
  title: {
    fontFamily: family.bold,
    color: colors.brown,
    fontSize: 14,
  },
  content: {
    width: "100%",
  },
  titleContainer: {
    width: "100%",
    justifyContent: "flex-start",
    marginBottom: 20,
  },
  buttonContainer: {
    width: "100%",
    flexDirection: "row",
    marginTop: 20,
  },
});
