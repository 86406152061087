import React from "react";
import { Text, View, Platform } from "react-native";
import { family } from "../../../constants/theme";
import StyleSheet from "react-native-media-query";
import { Linking } from "react-native";
import Link from "../../atoms/Link";
import i18n from "../../../i18n/i18n";
import { version } from "../../../../config";
import Divider from "../../atoms/Divider";

const openURL = (url) => {
  Linking.openURL(url).catch((err) => console.error("An error occurred", err));
};

const TicketFooter = ({ navigation }) => {
  return (
    <View style={styles.container}>
      <View style={styles.wrapper} dataSet={{ media: ids.wrapper }}>
        <View style={{flexDirection: 'row'}}>
          <Link
            name={`${i18n.t("tickets.privacy")}. `}
            onPress={() => navigation.navigate("TicketPrivacyScreen")}
          />
          <Link
            name={i18n.t("tickets.terms")}
            onPress={() => navigation.navigate("TicketTermsScreen")}
          />
        </View>
        <Divider height={5}/>
        <Text style={styles.link}> -{version}-</Text>
        {Platform.OS === "web" ? (
          <noscript>
            <iframe
              src="https://www.googletagmanager.com/ns.html?id=GTM-N77RQ5T"
              height="0"
              width="0"
              style="display:none;visibility:hidden"
            ></iframe>
          </noscript>
        ) : null}
      </View>
    </View>
  );
};

export default TicketFooter;

const { ids, styles } = StyleSheet.create({
  container: {
    backgroundColor: "black",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    width: "100%",
    borderWidth: 1,
    borderColor: "red",
    maxWidth: 1440,
    paddingVertical: 50,
    flexDirection: "row",
    paddingHorizontal: 40,
    "@media (max-width: 400px)": {
      paddingHorizontal: 20,
      flexDirection: "column",
    },
  },
  iconWrapper: {
    height: 75,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  link: {
    fontFamily: family.normal,
    fontSize: 12,
    color: "white",
  },
  infoContainer: {
    flexDirection: "column",
    height: 75,
    alignItems: "center",
    justifyContent: "space-between",
  },
  column: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "@media (max-width: 400px)": {
      flexDirection: "row",
    },
  },
  section: {
    height: 75,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  sectionTitle: {
    fontFamily: family.bold,
    fontSize: 12,
    color: "white",
  },
  infoContainer: {
    height: 75,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  infoText: {
    fontFamily: family.normal,
    fontSize: 12,
    color: "white",
    textAlign: "center",
  },
  wrapper: {
    paddingTop: 30,
    width: "100%",
    flexWrap: "wrap",
    paddingHorizontal: 40,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 65,
    flexDirection: "column",
    "@media (max-width: 600px)": {
      flexDirection: "column",
      paddingHorizontal: 20,
    },
  },
});
