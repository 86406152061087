import React from "react";
import { Entypo } from "@expo/vector-icons";
import {
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from "react-native";
import StyleSheet from "react-native-media-query";

// Components
import Divider from "../../../../components/atoms/Divider";

// Constants
import { colors, family } from "../../../../constants/theme";

const ToEventsButton = ({ navigation }) => {
  const { width, height } = useWindowDimensions();
  const smallScreen = height < 700 || width < 340;

  const clickHandler = () => {
    navigation.navigate("EventTypesScreen");
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity style={styles.content} onPress={clickHandler}>
        <Entypo
          name="compass"
          size={24}
          size={smallScreen ? 12 : 16}
          color={colors.secondary}
        />
        <Divider width={7} />
        <Text style={styles.text} dataSet={{ media: ids.text }}>
          Descubrir más eventos
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default ToEventsButton;

const { ids, styles } = StyleSheet.create({
  container: {
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    height: 48,
  },
  content: {
    flexDirection: "row",
    alignItems: "center",
  },
  text: {
    color: colors.secondary,
    fontFamily: family.bold,
    fontSize: 16,
    "@media (max-height: 700px) or (max-width: 340px)": {
      fontSize: 14,
    },
  },
});
