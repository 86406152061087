import { filterByHavingOrderLines } from "../../../../utils/orderMethods";

export const canASAP = (shops) => {
  let result = false;

  shops.filter(filterByHavingOrderLines).map((shop) => {
    if (shop.opened === 1) {
      result = true;
    }
  });

  if (shops.filter(filterByHavingOrderLines).length > 1) {
    result = true;
  }
  return result;
};
