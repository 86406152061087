import React from "react";
import { StyleSheet, Text, View } from "react-native";

// Components
import PastVariantsText from "../PastVariantsText";

// Constants
import { colors, family } from "../../../../../constants/theme";

const MenuSelection = ({ data = [] }) => {
  if (data.length === 0) {
    return <View></View>;
  }
  return (
    <View>
      {data.map((el) => {
        return (
          <Text key={el.gid} style={styles.text}>
            {"- " + el.name}
            {el?.orderLineMenuGroups.length > 0 && (
              <Text>
                <Text style={styles.text}>: </Text>
                <PastVariantsText variants={el?.orderLineMenuGroups} />
              </Text>
            )}
          </Text>
        );
      })}
    </View>
  );
};

export default MenuSelection;

const styles = StyleSheet.create({
  text: {
    fontFamily: family.normal,
    fontSize: 12,
    color: colors.brown,
  },
});
