import { baseVersion } from "../../../../../config";
import { URLbase } from "../../../../constants/urls";

export const fetchPointSalesInfo = async (shopId, token) => {
  try {
    const response = await fetch(`${URLbase}/v2/point-sale?shopId=${shopId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
         
      },
      method: "GET",
    });

    const resData = await response.json();

    if (resData.status === "success") {
      return { status: "success", data: resData.data };
    } else {
      return {
        status: "fail",
        data: resData.message,
      };
    }
  } catch (error) {
    return { status: "fail", data: error.message };
  }
};
