import React, { useState, useEffect } from "react";
import { Text, useWindowDimensions, View } from "react-native";
import { connect } from "react-redux";
import StyleSheet from "react-native-media-query";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

// Components
import MediaSection from "./MediaSection";
import TextInput from "../../../atoms/TextInput";
import CustomButton from "../../../atoms/CustomButton";
import CustomError from "../../../atoms/CustomError";
import Divider from "../../../atoms/Divider";
import CustomCheckBox from "../../../atoms/CustomCheckBox";
import TouchableText from "../../../atoms/TouchableText";
import WarningModal from "../../modals/WarningModal";
import SectionDivider from "../components/SectionDivider";
import Hero from "../components/Hero";
import TermsText from "../components/TermsText";

// Constants
import { colors, family } from "../../../../constants/theme";

// Config
import { app } from "../../../../../config";

// Utils
import { validateMail, validatePassword } from "../../../../utils/arrayMethods";
import { parseFireBaseErrors } from "../../../../utils/errors/parseFirebaseErrors";

// Redux
import { editUser, signInFirebase } from "../../../../redux/user/userActions";

import { firebaseApp } from "../../../../services/firebase";

const RegisterSection = ({ sectionProps, editUser, signInFirebase }) => {
  const { setStep, step, customStyle, onAuth } = sectionProps;
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [email2, setEmail2] = useState("");
  const [password, setPassword] = useState("");
  const [communications, setCommunications] = useState(false);
  const [error, setError] = useState("");
  const [modal, setModal] = useState({ type: null, message: "" });

  const width = useWindowDimensions().width;
  const height = useWindowDimensions().height;

  const DIVIDER_HEIGHT = width > 340 || height > 700 ? 14 : 8;

  useEffect(() => {
    setEmail("");
    setError("");
    setPassword("");
    setName("");
    setLastName("");
  }, [step]);

  useEffect(() => {
    setError("");
  }, [email, email2, password]);

  const tocuhableTextStyle = {
    fontFamily: family.bold,
    color: customStyle?.textColor || colors.brown,
    fontSize: width > 340 || height > 700 ? 14 : 10,
  };

  const validEmail = validateMail(email);
  const validEmail2 = validateMail(email2);

  const buttonActive = validEmail && validEmail2 && password !== "";

  const signUpProcess = async () => {
    const auth = getAuth(firebaseApp);
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        signInFirebase(userCredential.user.accessToken).then(async(response) => {
          if (response.status === "success") {
            const editResponse = await editUser({
              token: userCredential.user.accessToken,
              name: `${name} ${lastName}`,
            });
            if (editResponse.status === "success") {
              setError("");
              if (onAuth) {
                onAuth();
              }
            } else {
              setError(response?.data);
            }
          } else {
            setError(response?.data);
          }
        });
      })
      .catch((error) => {
        const errorMessage = parseFireBaseErrors(error.code);
        setError(errorMessage);
      });
  };

  const handleSignUp = async () => {
    const isPasswordValid = validatePassword(password);

    if (app === "tickets" && name.length == 0) {
      setModal({
        type: "Warning",
        message: "El nombre es obligatorio",
      });
    } else if (app === "tickets" && lastName.length == 0) {
      setModal({
        type: "Warning",
        message: "El lastName es obligatorio",
      });
    } else if (validEmail === false || validEmail2 === false) {
      setModal({
        type: "Warning",
        message: "Comprueba que el email es correcto",
      });
    } else if (email !== email2) {
      setModal({
        type: "Warning",
        message: "Los emails introducidos no son iguales",
      });
    } else if (isPasswordValid !== true) {
      setModal({
        type: "Warning",
        message: isPasswordValid,
      });
    } else {
      signUpProcess();
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.sectionContainer}>
        <Hero
          secondaryTitle={"Regístrate"}
          title={"Disfruta sin esperas"}
          subtitle={
            "Regístrate y disfruta sin hacer cola de tu comida favorita"
          }
          customStyle={customStyle}
        />
        <Divider height={DIVIDER_HEIGHT} />
        {app !== "tickets" && (
          <MediaSection
            setError={setError}
            onAuth={onAuth}
            customStyle={customStyle}
            sectionProps={sectionProps}
          />
        )}
      </View>
      {app !== "tickets" && <SectionDivider />}
      <View style={[styles.sectionContainer, { justifyContent: "flex-start" }]}>
        {app === "tickets" && (
          <View style={{ width: "100%" }}>
            <TextInput
              placeholder="Nombre"
              value={name}
              onChange={setName}
            />
            <Divider height={DIVIDER_HEIGHT} />
            <TextInput
              placeholder="Apellido"
              value={lastName}
              onChange={setLastName}
            />
            <Divider height={DIVIDER_HEIGHT} />
          </View>
        )}
        <TextInput placeholder="Email" value={email} onChange={setEmail} />
        <Divider height={DIVIDER_HEIGHT} />
        <TextInput
          placeholder="Repite tu email"
          value={email2}
          onChange={setEmail2}
        />
        <Divider height={DIVIDER_HEIGHT} />
        <TextInput
          marginBottom={DIVIDER_HEIGHT}
          placeholder="Contraseña"
          value={password}
          onChange={setPassword}
          secure
        />
        <View style={styles.checkboxContainer}>
          <CustomCheckBox
            bold={false}
            label={"Quiero enterarme de nuevos eventos, ofertas y promociones"}
            onPress={setCommunications}
            value={communications}
            textColor={customStyle?.textColor}
          />
        </View>
        <Divider height={DIVIDER_HEIGHT} />
        <View style={styles.touchableContainer}>
          <Text
            style={[styles.text, { color: customStyle?.textColor }]}
            dataSet={{ media: ids.text }}
          >
            ¿Ya tienes cuenta?{" "}
          </Text>
          <TouchableText
            customStyle={tocuhableTextStyle}
            text="Inicia sesión!"
            onPress={() => setStep(0)}
            underlineColor="transparent"
          />
        </View>
        <Divider height={DIVIDER_HEIGHT / 2} />
        <CustomError error={error} color={customStyle.errorColor} />
        <Divider height={DIVIDER_HEIGHT / 2} />
        <TermsText
          setStep={setStep}
          from="register"
          customStyle={customStyle}
        />
      </View>
      <Divider height={DIVIDER_HEIGHT} />
      <View
        style={styles.buttonContainer}
        dataSet={{ media: ids.buttonContainer }}
      >
        <CustomButton
          active={buttonActive}
          title="Continuar"
          onPress={handleSignUp}
          textColor={customStyle.buttonTextColor}
          color={customStyle.backgroundColor}
        />
      </View>
      <WarningModal
        visible={modal?.type === "Warning"}
        setModal={setModal}
        text={modal?.message}
      />
    </View>
  );
};

const mapDispatchToProps = {
  editUser,
  signInFirebase,
};

const mapStateToProps = (state) => {
  return { user: state.user.user };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterSection);

const { ids, styles } = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  terms: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 20,
  },
  backButton: {
    marginTop: 4,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  termButton: {
    marginLeft: 5,
    width: "100%",
  },
  underline: {
    textDecorationLine: "underline",
    textDecorationStyle: "solid",
  },
  termsText: {
    fontFamily: family.normal,
    fontSize: 12,
  },
  buttonContainer: {
    width: "100%",
    height: 70,
    "@media (max-height: 700px) or (max-width: 340px)": {
      height: 32,
    },
  },
  sectionContainer: {
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  touchableContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  text: {
    fontFamily: family.normal,
    color: colors.brown,
    fontSize: 14,
    "@media (max-height: 700px) or (max-width: 340px)": {
      fontSize: 10,
    },
  },
  checkboxContainer: {
    width: "100%",
  },
});
