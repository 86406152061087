import { baseVersion } from "../../../../../config";
import { URlManagement } from "../../../../constants/urls";

export const getServices = async (eventId, shopId) => {
  try {
    const url = `${URlManagement}/event/get/${eventId}`;
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Connection: "keep-alive",
         
      },
      method: "GET",
    });

    const resData = await response.json();

    const shopList = resData.data.shops;
    const result = shopList.filter((shop) => shop.gid === shopId)[0]?.services;
    return result?.length > 0 ? result : [];
  } catch (error) {
    console.log("error getServices ", error.message);
    return [];
  }
};
