import React, { useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import { FontAwesome } from "@expo/vector-icons";

// Components
import CustomModal from "../../atoms/CustomModal";
import { Carousel, Pagination } from "../../atoms/Carousel";

// Constants
import { colors, size, family, RADIUS } from "../../../constants/theme";

const LiveModal = ({ modalVisible, handleModal, data, handlePlaceClick }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleBuyButton = () => {
    handleModal(false);
    handlePlaceClick(data[activeIndex]);
  };

  return (
    <CustomModal
      modalVisible={modalVisible}
      handleModal={() => handleModal(false)}
      size="window"
      canClose
      closeColor={colors.primaryColor}
    >
      <Carousel data={data} setIndex={setActiveIndex} />
      <Pagination data={data} activeIndex={activeIndex} />
      <TouchableOpacity style={styles.confirmButton} onPress={handleBuyButton}>
        <Text style={styles.confirmText}>Pedir</Text>
      </TouchableOpacity>
    </CustomModal>
  );
};

export default LiveModal;

const styles = StyleSheet.create({
  closeButton: {
    position: "absolute",
    top: 10,
    right: 10,
    zIndex: 2,
  },
  confirmButton: {
    width: "80%",
    height: 46,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.primaryColor,
    borderRadius: RADIUS,
    position: "absolute",
    bottom: 10,
  },
  confirmText: {
    color: "white",
    fontFamily: family.bold,
    fontSize: size.big,
  },
});
