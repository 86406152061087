import getCommissionByElement from "./getCommissionByElement";
import getQuestions from "./getQuestions";

const getFinalTicketLines = (ticketLines, answerArray, availableCommissions) => {
  let lines = [];

  ticketLines.map((ticket) => {
    for (let i = 0; i < ticket.quantity; i++) {
      lines.push({
        name: ticket.name,
        quantity: 1,
        amount: ticket.amount,
        id_ticket: ticket.id_ticket,
        questions: getQuestions(ticket.id_ticket, i, answerArray),
        commission: getCommissionByElement(ticket, availableCommissions),
      });
    }
  });

  return lines
};

export default getFinalTicketLines