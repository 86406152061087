import React from "react";
import { Text } from "react-native";

// Components
import Screen from "../../components/atoms/Screen";

const ProfileOpinionScreen = () => {
  return (
    <Screen>
      <Text>Danos tu opinión</Text>
    </Screen>
  );
};

export default ProfileOpinionScreen;
