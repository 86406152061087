import React from "react";
import { StyleSheet, View } from "react-native";

// Components
import TicketFooter from "./TicketFooter";
import TicketsHeader from "./TicketsHeader";
import WhatsappIcon from "./WhatsappIcon";

const TicketScreen = ({ navigation, shopId, hideActions, children }) => (
  <View style={styles.screen}>
    <TicketsHeader
      navigation={navigation}
      shopId={shopId}
      hideActions={hideActions}
    />
    {children}
    <TicketFooter navigation={navigation} />
    <WhatsappIcon />
  </View>
);

export default TicketScreen;

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    width: "100%",
    backgroundColor: "black",
    backgroundColor: "black",
  },
});
