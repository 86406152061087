import React from "react";
import { StyleSheet, View, Text } from "react-native";

// Components
import ProgressBar from "./ProgressBar";

// Constants
import { family } from "../../../../constants/theme";

// Methods
import { getStatusText } from "./methods/getStatusText";

// Utils
import { stages } from "./stages";

const StatusBar = ({ status }) => {
  const renderStages = (status) => {
    if (status) {
      const currentStages = stages[status];
      return currentStages.map((stage, index) => (
        <ProgressBar key={index} {...stage} />
      ));
    }
  };
  const statusText = getStatusText(status);
  if (statusText) {
    return (
      <View style={styles.statusWrapper}>
        <Text style={styles.text}>{statusText}</Text>
      </View>
    );
  }
  return <View style={styles.container}>{renderStages(status)}</View>;
};

export default StatusBar;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    textAlign: "center",
    zIndex: 2,
    height: 60,
  },
  statusWrapper: {
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2,
    height: 60,
  },
  text: {
    color: "white",
    fontSize: 16,
    fontFamily: family.normal,
    textAlign: "center",
  },
});
