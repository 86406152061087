import { URlManagement } from "../../constants/urls";
import {
  FETCH_LIVE_EVENTS,
  FETCH_EVENT_TYPES,
  SORT_LIVE_ITEMS,
  FETCH_ALL_EVENTS,
} from "./eventsTypes";
import * as Localization from "expo-localization";
import { baseVersion } from "../../../config";

const timezone = Localization.timezone;

export const fetchLiveEvents = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${URlManagement}/event/getLive?timezone=${timezone}`,
        {
          headers: {
            "Content-Type": "application/json",
            Connection: "keep-alive",
             
          },
          method: "GET",
        }
      );

      const resData = await response.json();

      dispatch({
        type: FETCH_LIVE_EVENTS,
        payload: resData.status === "success" ? resData.data : [],
      });
    } catch (err) {
      console.log("error fetching live events: ", err);
    }
  };
};

export const sortLiveItems = (location) => {
  return async (dispatch) => {
    const sortBy = location?.lat ? "location" : "schedule";
    dispatch({
      type: SORT_LIVE_ITEMS,
      payload: {
        sortBy: sortBy,
        location: location,
      },
    });
  };
};

export const fetchEventTypes = () => {
  return async (dispatch) => {
    try {
      const url = `${URlManagement}/event/type/getAll?timezone=${timezone}`;
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Connection: "keep-alive",
           
        },
        method: "GET",
      });

      const resData = await response.json();

      dispatch({
        type: FETCH_EVENT_TYPES,
        payload: resData,
      });
    } catch (err) {
      console.log("error fetching event types: ", err);
    }
  };
};

export const fetchAllEvents = () => {
  return async (dispatch) => {
    try {
      const url = `${URlManagement}/event/getAll`;
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Connection: "keep-alive",
           
        },
        method: "GET",
      });

      const resData = await response.json();

      dispatch({
        type: FETCH_ALL_EVENTS,
        payload: resData.message === "success" ? resData.data : [],
      });
    } catch (err) {
      console.log("error fetching event types: ", err);
    }
  };
};
