import React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { AntDesign } from "@expo/vector-icons";

// Constants
import { colors } from "../../../../constants/theme";

const BackButton = ({ navigation }) => {
  const clickHandler = () => {
    navigation.goBack();
  };
  return (
    <TouchableOpacity style={styles.backButton} onPress={clickHandler}>
      <AntDesign name="arrowleft" size={24} color={colors.brown} />
    </TouchableOpacity>
  );
};

export default BackButton;

const styles = StyleSheet.create({
  backButton: {
    height: 40,
    width: 40,
    borderRadius: 20,
    backgroundColor: colors.white,
    justifyContent: "center",
    alignItems: "center",
    zIndex: 3,
    top: 40,
    left: 20,
    position: "absolute",
  },
});