import React from "react";

import { StatusBar } from "react-native";

import { isLight } from "../../utils/colorMethods";

const CustomStatusBar = ({ color = "#FFFFFF" }) => {
  let colorScheme;
  if(!isLight(color)){
    colorScheme = "light"
  }else{
    colorScheme="dark"
  }

  return (
    <StatusBar
      backgroundColor={color}
      barStyle={colorScheme === "light" ? "light-content" : "dark-content"}
    />
  );
};

export default CustomStatusBar;
