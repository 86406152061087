import React from "react";
import { StyleSheet, Text, View } from "react-native";

// Components
import Price from "../../../components/ProductItem/Price";

// Constants
import { colors, family, size } from "../../../../../../constants/theme";

const ProductLine = ({ product, customStyle }) => {
  const { name } = product;
  const { textColor } = customStyle;
  return (
    <View style={styles.container}>
      <View style={styles.nameColumn}>
        <Text style={[styles.text, { color: textColor }]}>{name}</Text>
      </View>
      <Price data={product} color={textColor} />
    </View>
  );
};

export default ProductLine;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    marginVertical: 5,
    paddingHorizontal: 18,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  text: {
    fontFamily: family.normal,
    color: colors.brown,
    fontSize: size.small,
  },
  qtyColumn: {
    width: 30,
    justifyContent: "center",
    alignItems: "flex-start",
  },
  nameColumn: {
    flex: 1,
    justifyContent: "flex-start",
    paddingRight: 8,
    flexDirection: "column",
  },
});
