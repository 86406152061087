import React from "react";
import { StyleSheet, View } from "react-native";

// Components
import CustomButton from "../../atoms/CustomButton";
import CustomModal from "../../atoms/CustomModal";
import CustomText from "../../atoms/CustomText";

const TicketWarningModal = ({ visible, setModal, text }) => {
  return (
    <CustomModal
      modalVisible={visible}
      handleModal={() => setModal(false)}
      color={"rgba(0,0,0,0.9)"}
    >
      <View style={styles.inputWrapper}>
        <CustomText
          textAlign="center"
          fontSize={18}
          color="white"
          bold
          text={text}
        />
        <View style={{ height: 60, width: "100%" }}>
          <CustomButton
            color={"white"}
            textColor={"black"}
            title="Aceptar"
            marginTop={20}
            active
            onPress={() => setModal()}
          />
        </View>
      </View>
    </CustomModal>
  );
};

export default TicketWarningModal;

const styles = StyleSheet.create({
  inputWrapper: {
    width: "100%",
  },
});
