import { URlManagement } from "../../../../constants/urls";

export const fetchEventsOfType = async (id) => {
  try {
    const response = await fetch(`${URlManagement}/event/getOfType/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Connection: "keep-alive",
      },
      method: "GET",
    });

    const resData = await response.json();
    if (resData.status === "success") {
      return { status: "success", data: resData.data };
    } else {
      return { status: "fail", message: resData.message };
    }
  } catch (err) {
    console.log("error fetching places:", err);
    return { status: "fail", message: err.message };
  }
};
