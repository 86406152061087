import React from "react";
import { connect } from "react-redux";
import Divider from "../../../../components/atoms/Divider";

// Components
import Section from "../components/Section";
import ShopItem from "../components/ShopItem";

const ProductsSection = ({ orders, customStyle }) => {
  return (
    <Section customStyle={customStyle}>
      {orders.map(
        (shop) =>
          shop.order_lines.length > 0 && (
            <>
            <ShopItem
              key={shop.id_shop}
              shop={shop}
              customStyle={customStyle}
            />
            <Divider height={20}/>
            </>
          )
      )}
    </Section>
  );
};

const mapStateToProps = (state) => {
  return {
    orders: state.cart.orders,
  };
};

export default connect(mapStateToProps)(ProductsSection);
