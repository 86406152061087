import React from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";

// Components
import TextInput from "../../../../components/atoms/TextInput";
import CustomButton from "../../../../components/atoms/CustomButton";
import CustomModal from "../../../../components/atoms/CustomModal";

// Redux
import { setComment } from "../../../../redux/laterCart/laterCartActions";

const CommentModal = ({ visible, setModal, comment, setComment }) => {
  return (
    <CustomModal modalVisible={visible} handleModal={() => setModal()}>
      <View style={styles.inputWrapper}>
        <TextInput
          multiline
          value={comment}
          onChange={setComment}
          placeholder="Escribe aquí tu comentario"
        />
        <CustomButton
          marginTop={24}
          title="Aceptar"
          active
          onPress={() => {
            setModal();
          }}
        />
      </View>
    </CustomModal>
  );
};

const mapDispatchToProps = {
  setComment,
};

const mapStateToProps = (state) => {
  return {
    comment: state.laterOrders.comment,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentModal);

const styles = StyleSheet.create({
  inputWrapper: {
    width: "100%",
    height: 300,
  },
});
