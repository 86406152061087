import { discountAvailableProducts } from "../../../../../../utils/orderMethods";
import { getTotalTicketDiscountPromo } from "../../../../../../utils/productMethods";

const handleDiscount = ({ discountData, cartData, methods }) => {
  const { orders, totalAmount, value, discount } = cartData;
  const { setDiscount, setError, setDiscountName } = methods;

  const resetDiscount = (printError) => {
    setDiscountName();
    setDiscount({
      discount: {
        amount: 0,
        name: "Descuento",
        id_discount: null,
      },
      discountLines: [],
    });
    if (printError) {
      setError("Código no válido");
    }
  };

  if (discountData) {
    const { available, data } = discountAvailableProducts({
      orders: orders,
      discountData: discountData,
    });

    if (available === true) {
      const { auxDiscount, discount_lines } = getTotalTicketDiscountPromo({
        ...discountData,
        data: data,
        totalPrice: totalAmount,
      });
      if (discount.id_discount === discountData?.gid) {
        setDiscount({
          discount: {
            amount: auxDiscount,
            name: discount.name,
            id_discount: discountData?.gid,
          },
          discountLines: discount_lines,
        });
      } else {
        setDiscount({
          discount: {
            amount: auxDiscount,
            name: "Descuento " + value,
            id_discount: discountData?.gid,
          },
          discountLines: discount_lines,
        });
        setDiscountName(value);
      }

      setError();
    } else {
      setError("Descuento no disponible con la selección actual");
      resetDiscount(false);
    }
  } else {
    resetDiscount(true);
  }
};

export default handleDiscount;
