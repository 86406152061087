import React, { useState } from "react";
import { StyleSheet, TouchableOpacity, View, Text } from "react-native";
import { connect } from "react-redux";
import { AntDesign } from "@expo/vector-icons";

// Components
import CustomAlert from "../molecules/CustomAlert";

// Constants
import { colors, family } from "../../constants/theme";

// Utils
import { getAlerts, hasOrders, numOfProducts } from "../../utils/orderMethods";

const CartIcon = ({ navigation, orders }) => {
  const [modal, setModal] = useState();
  const [alertData, setAlertData] = useState();

  const clickHandler = () => {
    const alerts = getAlerts(orders, "finish_store");
    const action = () => {
      setModal();
      navigation.navigate("ConfirmOrderScreen", {
        params: {
          data: "data",
        },
      });
    };
    if (alerts !== false) {
      setAlertData({
        title: alerts.title,
        message: alerts.message,
        action: action,
      });
      setModal("Alert");
    } else {
      action();
    }
  };

  const products = numOfProducts(orders);
  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={clickHandler}>
        {hasOrders(orders) ? (
          <View style={styles.hasOrderWrapper}>
            <Text style={styles.text}>{products}</Text>
            <AntDesign name="shoppingcart" size={22} color={colors.white} />
          </View>
        ) : (
          <View style={styles.noOrderWrapper}>
            <AntDesign name="shoppingcart" size={22} color={colors.darkGrey} />
          </View>
        )}
      </TouchableOpacity>
      <CustomAlert
        setModalVisible={setModal}
        modalVisible={modal === "Alert"}
        alertData={alertData}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  hasOrderWrapper: {
    minWidth: 64,
    height: 32,
    borderRadius: 30,
    justifyContent: "space-evenly",
    paddingHorizontal: 5,
    alignItems: "center",
    backgroundColor: colors.secondary,
    flexDirection: "row",
  },
  noOrderWrapper: {
    width: 34,
    height: 34,
    borderRadius: 18,
    borderWidth: 1,
    borderColor: colors.darkGrey,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.white,
  },
  text: {
    color: colors.white,
    fontSize: 18,
    fontFamily: family.bold,
  },
});

const mapStateToProps = (state) => {
  return {
    orders: state.cart.orders,
  };
};

export default connect(mapStateToProps)(CartIcon);
