import { baseVersion } from "../../../../../config";
import { URLbase } from "../../../../constants/urls";

export const postLaterOrder = async (object, token) => {
  try {
    const url = `${URLbase}/v2/order/consumption-later`;
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Connection: "keep-alive",
        Authorization: `Bearer ${token}`,
         
      },
      body: JSON.stringify(object),
      method: "POST",
    });

    const resData = await response.json();

    if (resData.status === "success") {
      return { status: "success", data: null };
    } else {
      return { status: "fail", data: resData.message };
    }
  } catch (error) {
    return { status: "fail", data: error.message };
  }
};
