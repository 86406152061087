import React, { useState, useEffect } from "react";
import { Text, Image, View, Platform } from "react-native";
import StyleSheet from "react-native-media-query";
import * as Device from "expo-device";
import * as Linking from "expo-linking";

// Components
import Divider from "../atoms/Divider";
import Button from "./Button";

// Constants
import { colors, family } from "../../constants/theme";

// Utils
import { getLocalData, storeLocalData } from "../../utils/miscelanusUtils";

const DownloadBanner = () => {
  const [visible, setVisible] = useState(true);
  const title = "Descubre más ofetas en la app";
  const subtitle =
    "Descarga la app para descubrir nuevos eventos\ny acceder a promociones exclusivas.";

  const appHandler = async () => {
    setVisible(false);
    storeLocalData("bannerShown", true);
  };

  const downloadHandler = async () => {
    storeLocalData("bannerShown", true);
    setVisible(false);
    Linking.openURL(
      Device.manufacturer === "Apple"
        ? "https://apps.apple.com/es/app/gopick-pide-y-paga-en-recintos/id1628179463"
        : "https://play.google.com/store/apps/details?id=com.gopick.user&gl=ES"
    );
  };

  const getData = async () => {
    const bannerShown = await getLocalData("bannerShown");
    setVisible(!bannerShown);
  };

  useEffect(() => {
    getData();
  }, []);

  if (Platform.OS === "web" && visible) {
    return (
      <View style={styles.container}>
        <Image
          style={styles.image}
          dataSet={{ media: ids.image }}
          source={require("../../assets/appIcon.png")}
        />
        <Divider height={10} />
        <Text style={styles.title} dataSet={{ media: ids.title }}>
          {title}
        </Text>
        <Divider height={10} />
        <Text style={styles.subtitle}>{subtitle}</Text>
        <Divider height={10} />
        <Button colored text="Continuar en la app" onPress={downloadHandler} />
        <Divider height={10} />
        <Button text="Continuar en la web" onPress={appHandler} />
      </View>
    );
  }
};

export default DownloadBanner;

const { ids, styles } = StyleSheet.create({
  container: {
    maxWidth: 400,
    width: "100%",
    backgroundColor: colors.white,
    borderColor: "#BCBCBC",
    borderWidth: 1,
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
    position: "absolute",
    bottom: 0,
    zIndex: 20,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 18,
    "@media (max-height: 700px) or (max-width: 340px)": {
      paddingVertical: 12,
    },
  },
  image: {
    width: 80,
    height: 80,
    borderRadius: 10,
    resizeMode: "cover",
    "@media (max-height: 700px) or (max-width: 340px)": {
      width: 60,
      height: 60,
    },
  },
  title: {
    fontFamily: family.bold,
    fontSize: 20,
    color: colors.brown,
    textAlign: "center",
    "@media (max-height: 700px) or (max-width: 340px)": {
      fontSize: 14,
    },
  },
  subtitle: {
    fontFamily: family.normal,
    fontSize: 12,
    color: colors.brown,
    textAlign: "center",
  },
});
