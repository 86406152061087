import {
  SET_CURRENT_AMOUNT,
  SET_PRODUCT_LINES,
  SET_TICKET_LINES,
  SET_SHOP_CHECKOUT_DATA,
  SET_TICKET_DISCOUNT,
  SET_TICKET_PAYMENT_METHOD,
  SET_CURRENT_CARD,
  FETCH_SHOP_DETAILS,
  FETCH_TICKET_DETAILS,
  RESET_TICKET_CART,
} from "./ticketsTypes";
import { URlManagement } from "../../constants/urls";
import { baseVersion } from "../../../config";

export const fetchShopTickets = (id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${URlManagement}/shop/get/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Connection: "keep-alive",
           
        },
        method: "GET",
      });

      const resData = await response.json();

      const data = resData?.message === "success" ? resData?.data[0] : [];

      dispatch({
        type: FETCH_SHOP_DETAILS,
        payload: data,
      });

      return data;
    } catch (err) {
      console.log("error fetching shop data: ", err);
      dispatch({
        type: FETCH_SHOP_DETAILS,
        payload: [],
      });
    }
  };
};

export const fetchTicketDetails = (id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${URlManagement}/ticketData/getData/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Connection: "keep-alive",
             
          },
          method: "GET",
        }
      );

      const resData = await response.json();
      const data =
        resData.message === "success"
          ? resData.data
          : { tickets: [], products: [] };

      dispatch({
        type: FETCH_TICKET_DETAILS,
        payload: data,
      });
      return data;
    } catch (err) {
      dispatch({
        type: FETCH_TICKET_DETAILS,
        payload: [],
      });
    }
  };
};

export const setCurrentAmount = (value) => {
  return (dispatch) => {
    dispatch({
      type: SET_CURRENT_AMOUNT,
      payload: value,
    });
  };
};

export const setProductLinesData = (value) => {
  return (dispatch) => {
    dispatch({
      type: SET_PRODUCT_LINES,
      payload: value,
    });
  };
};

export const setTicketLinesData = (value) => {
  return (dispatch) => {
    dispatch({
      type: SET_TICKET_LINES,
      payload: value,
    });
  };
};

export const setShopCheckoutData = (value) => {
  return (dispatch) => {
    dispatch({
      type: SET_SHOP_CHECKOUT_DATA,
      payload: value,
    });
  };
};

export const setTicketDiscount = (value) => {
  return (dispatch) => {
    dispatch({
      type: SET_TICKET_DISCOUNT,
      payload: value,
    });
  };
};

export const setTicketPaymentMethod = (value) => {
  return (dispatch) => {
    dispatch({
      type: SET_TICKET_PAYMENT_METHOD,
      payload: value,
    });
  };
};

export const setCurrentCard = (value) => {
  return (dispatch) => {
    dispatch({
      type: SET_CURRENT_CARD,
      payload: value,
    });
  };
};

export const setTicketQuestions = (value) => {
  return (dispatch) => {
    dispatch({
      type: SET_CURRENT_CARD,
      payload: value,
    });
  };
};

export const resetTicketCart = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_TICKET_CART,
    });
  };
};
