import React from "react";
import { View, LogBox, StyleSheet, Text, ScrollView } from "react-native";
import { FontAwesome } from "@expo/vector-icons";
import { v4 as uuidv4 } from "uuid";

// Components
import CustomModal from "../../../../../components/atoms/CustomModal";
import CustomButton from "../../../../../components/atoms/CustomButton";
import HtmlNative from "../../../../../components/atoms/HtmlNative";

// Constants
import { colors, family, size } from "../../../../../constants/theme";

LogBox.ignoreLogs(["react-native-styled-text"]);

const CustomAlert = ({ visible, setModal, alertData, customStyle }) => {
  const id = uuidv4();
  const { primaryColor, backgroundColor, textColor } = customStyle;
  return (
    <CustomModal modalVisible={visible} handleModal={() => setModal()}>
      <View>
        <FontAwesome name="bell" size={48} color={textColor} />
      </View>
      {alertData?.title && (
        <View style={{ marginVertical: 20 }}>
          <Text style={[styles.title, { color: textColor }]}>
            {alertData.title}
          </Text>
        </View>
      )}
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ width: "100%", height: "auto", maxHeight: 300 }}
      >
        {alertData?.message && (
          <HtmlNative
            id={id}
            value={alertData.message}
            style={`p{color: ${textColor}; font-family: 'Raleway', sans-serif}`}
          />
        )}
        {alertData?.subtitle && (
          <View style={{ marginVertical: 20 }}>
            <Text style={[styles.text, { color: textColor }]}>
              {alertData.subtitle}
            </Text>
          </View>
        )}
      </ScrollView>
      <View style={{ width: "100%", flexDirection: "row", marginTop: 10 }}>
        {alertData?.actions ? (
          alertData.actions?.map((action) => (
            <CustomButton
              active
              key={action.title}
              onPress={() => {
                action.onPress();
                if (alertData?.action) {
                  alertData.action();
                }
                if (!alertData?.disableVisible) {
                  setModal();
                }
              }}
              title={action.title}
              marginHorizontal={5}
              color={primaryColor}
              textColor={backgroundColor}
            />
          ))
        ) : (
          <CustomButton
            active
            onPress={() => {
              if (!alertData?.disableVisible) {
                setModal();
              }
              if (alertData?.action) {
                alertData.action();
              }
            }}
            title="Aceptar"
            color={primaryColor}
            textColor={backgroundColor}
          />
        )}
      </View>
    </CustomModal>
  );
};

export default CustomAlert;

const styles = StyleSheet.create({
  text: {
    fontFamily: family.normal,
    color: colors.brown,
    fontSize: size.small,
    textAlign: "center",
  },
  title: {
    fontFamily: family.bold,
    textAlign: "center",
    color: colors.brown,
    fontSize: size.normal,
  },
});
