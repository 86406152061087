import React, {
  useMemo,
  useEffect,
  useState,
  useCallback,
  useContext,
} from "react";

import { StyleSheet, ScrollView, useWindowDimensions } from "react-native";
import { View } from "react-native";
import { connect } from "react-redux";

// Components
import SectionTitle from "../components/SectionTitle";
import Divider from "../../../../components/atoms/Divider";

// Constants
import { colors } from "../../../../constants/theme";

// Sections
import CommentSection from "../sections/CommentSection";
import DiscountSection from "../sections/DiscountSection";
import ProductsSection from "../sections/ProductsSection";
import ResumeSection from "../sections/ResumeSection";
import ScheduleSection from "../sections/ScheduleSection";
import ConfirmButton from "../components/ConfirmButton";
import CustomAlert from "../modals/CustomAlert";
import UserUpModal from "../modals/UserUpModal";

// Utils
import { getTotalPrice, islaterOrder } from "../../../../utils/productMethods";
import { getAlerts } from "../../../../utils/orderMethods";
import { darken } from "../../../../utils/colorMethods";

// Redux
import { setTotalToPay } from "../../../../redux/cart/cartActions";

import { AuthContext } from "../../../../contexts/AuthContext";

const CheckoutContainer = ({ cart, navigation, setScreenColor }) => {
  const { user: firebaseUser } = useContext(AuthContext);
  const [modal, setModal] = useState();
  const [modalData, setModalData] = useState();
  const [alertsShown, setAlertsShown] = useState(false);
  const [customStyle, setCustomStyle] = useState({
    primaryColor: colors.primaryColor,
    secondaryColor: colors.white,
    textColor: colors.brown,
    backgroundColor: colors.white,
    darkPrimary: colors.grey,
    darkSecondary: colors.lightenGrey,
    accentColor: colors.secondary,
  });

  const height = useWindowDimensions().height;
  const totalAmount = useMemo(() => getTotalPrice(cart), [cart]);

  useEffect(() => {
    setTotalToPay(totalAmount);
  }, [totalAmount]);

  const setShopStyle = () => {
    setScreenColor(colors.white);
    if (cart.orders.length === 1) {
      if (cart.orders[0].custom_design !== null) {
        const design = cart.orders[0].custom_design;
        const customHeaderStyle = {
          color: design.custom_color_header,
          textColor: design.custom_color_letter_header,
          customIcon: design.custom_logo,
          iconsColor: design.custom_color_icons_header,
        };

        setScreenColor(design.custom_color_1 || colors.white);
        setCustomStyle({
          primaryColor: design.custom_color_2 || colors.primaryColor,
          secondaryColor: design.custom_color_letter_2 || colors.white,
          textColor: design.custom_color_letter_1 || colors.brown,
          backgroundColor: design.custom_color_letter_2 || colors.white,
          darkPrimary: colors.grey,
          darkSecondary: design.custom_color_1
            ? darken(design.custom_color_1)
            : colors.lightenGrey,
          accentColor: colors.secondary,
        });
        navigation.setOptions({ style: customHeaderStyle });
      }
    }
  };

  useEffect(() => {
    setShopStyle();
  }, [cart.orders]);

  useEffect(() => {
    if (!firebaseUser) {
      setModal("UserUp");
    } else if (alertsShown === false) {
      const alerts = getAlerts(cart.orders, "checkout");
      setAlertsShown(true);
      if (alerts !== false) {
        setModalData({
          title: alerts.title,
          message: alerts.message,
          action: () => setModal(),
        });
        setModal("Alert");
      }
    }
  }, [cart.orders, firebaseUser]);

  return (
    <>
      <ScrollView
        style={[styles.container, { height: height - 140 }]}
        showsVerticalScrollIndicator={false}
      >
        <SectionTitle title={"Detalles del pedido"} />
        <ProductsSection customStyle={customStyle} />
        <SectionTitle title={"Detalles de entrega"} />
        <ScheduleSection customStyle={customStyle} />
        <Divider height={20} />
        <SectionTitle title={"Resumen del pedido"} />
        {!islaterOrder(cart.orders) && (
          <CommentSection customStyle={customStyle} />
        )}
        <DiscountSection customStyle={customStyle} totalAmount={totalAmount} />
        <ResumeSection customStyle={customStyle} totalAmount={totalAmount} />
        {/* TODO: evaluate if it has sense to have a 200px divider*/}
        <View style={{ height: 200 }} />
      </ScrollView>
      <ConfirmButton
        customStyle={customStyle}
        totalAmount={totalAmount}
        navigation={navigation}
      />
      <CustomAlert
        setModal={setModal}
        visible={modal === "Alert"}
        alertData={modalData}
        customStyle={customStyle}
      />
      <UserUpModal
        navigation={navigation}
        visible={modal === "UserUp"}
        setModal={setModal}
        customStyle={customStyle}
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    paddingHorizontal: 18,
    paddingTop: 18,
  },
});

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
  };
};

export default connect(mapStateToProps)(CheckoutContainer);
