import React from "react";
import { StyleSheet, Text, View, ActivityIndicator } from "react-native";
import { family, size } from "../../../constants/theme";

const NoContentTickets = ({ text, loading }) => {
  return (
    <View style={styles.container}>
      {loading ? (
        <ActivityIndicator color="white" size="small" />
      ) : (
        <Text style={styles.text}>{text}</Text>
      )}
    </View>
  );
};

export default NoContentTickets;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    fontFamily: family.bold,
    color: "white",
    fontSize: size.big,
  },
});
