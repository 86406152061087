import { useState } from "react";

const useForceRender = () => {
  const [render, setRender] = useState(0);

  const forceRender = () => {
    const first = 1;
    const second = 2;
    setRender(first);
    if (render === 1) {
      setRender(second);
    }
  };

  return [forceRender];
};

export default useForceRender;