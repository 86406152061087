import * as React from "react";
import { getAuth, OAuthProvider, signInWithPopup } from "firebase/auth";
import { connect } from "react-redux";

// Components
import MediaButton from "../components/MediaButton";

// Methods
import { onSignIn } from "../methods/onSignIn";

// Redux
import { signInFirebase } from "../../../../redux/user/userActions";

// Services
import { firebaseApp } from "../../../../services/firebase";

const IosAuthButton = ({
  sectionProps,
  signInFirebase,
  onError = () => {},
}) => {
  const { onAuth } = sectionProps;
  const buttonHandler = () => {
    const auth = getAuth(firebaseApp);
    const provider = new OAuthProvider("apple.com");
    signInWithPopup(auth, provider)
      .then((userCredential) => {
        signInFirebase(userCredential.user.accessToken).then((response) =>
          onSignIn(response, onAuth, onError)
        );
      })
      .catch((error) => {
        if (onError) {
          onError(`Algo salió mal. ${error.message}`);
        }
        console.log("Error signing with google", error);
      });
  };

  return <MediaButton onPress={buttonHandler} icon="apple" />;
};

const mapDispatchToProps = {
  signInFirebase,
};

export default connect(null, mapDispatchToProps)(IosAuthButton);
