import React, { useState, useEffect } from "react";
import { StyleSheet, View } from "react-native";

// Components
import EmptyScreen from "../../../components/molecules/EmptyScreen";
import ManagedList from "../../../components/molecules/ManagedList";
import Screen from "../../../components/atoms/Screen";
import Header from "./components/Header";
import Footer from "./components/Footer";

// Utils
import { getShopAlert } from "../../../utils/orderMethods";
import {
  getCategoryList,
  productSectionList,
} from "../../../utils/productMethods";
import CustomAlert from "../../../components/molecules/CustomAlert";

const ShopScreen = ({ route, navigation, orders }) => {
  const { shopData, shopIdUrl, extraShopData } = route.params;

  const [productList, setProductList] = useState([]);
  const [catList, setCatList] = useState([]);
  const [modal, setModal] = useState();
  const [alertData, setAlertData] = useState({});

  const customStyle = {};
  // Custom styles DEPRECATED
  //  const custom_logo = shopData?.custom_design?.custom_logo || null;
  //  const custom_color_header =
  //    shopData?.custom_design?.custom_color_header || colors.white;
  //  const custom_color_letter_header =
  //    shopData?.custom_design?.custom_color_letter_header || colors.primaryColor;
  //  const custom_color_2 =
  //    shopData?.custom_design?.custom_color_2 || colors.primaryColor;
  //  const custom_color_letter_2 =
  //    shopData?.custom_design?.custom_color_letter_2 || colors.white;

  //  const customHeaderStyle = {
  //    mode: "white",
  //    color: custom_color_header,
  //    textColor: custom_color_letter_header,
  //    customIcon: custom_logo,
  //    iconsColor: colors.brown,
  //  };

  useEffect(() => {
    if (shopIdUrl) {
      if (active === 0) {
        Alert.alert("Establecimiento cerrado");
        navigation.goBack();
      } else {
        setModal("Locations");
      }
    }
    if (shopData?.products) {
      setProductList(productSectionList(shopData?.products));
      setCatList(getCategoryList(shopData?.products));

      let alerts = getShopAlert(shopData, "store");
      if (alerts !== false) {
        setAlertData({
          title: alerts.title,
          message: alerts.message,
          action: () => setModal(),
        });
        setModal("Alert");
      }
    }
  }, []);

  const footerHandler = () => {
    let alerts = getShopAlert(shopData, "finish_store");
    const action = () => {
      setModal();
      navigation.navigate("ConfirmOrderScreen");
    };
    if (alerts !== false) {
      setAlertData({
        title: alerts.title,
        message: alerts.message,
        action: action,
      });
      setModal("Alert");
    } else {
      action();
    }
  };

  if (!shopData?.products) {
    return <EmptyScreen id="products" title="No hay productos disponibles" />;
  }


  return (
    <Screen paddingHorizontal={0}>
      <View style={styles.container}>
        <Header
          name={shopData.name}
          description={shopData.description}
          image={shopData.image_header}
          navigation={navigation}
          schedules={extraShopData?.schedules}
        />
        <ManagedList
          productList={productList}
          catList={catList}
          navigation={navigation}
          shopData={shopData}
          extraShopData={extraShopData}
        />
      </View>
      <Footer action={footerHandler} customStyle={customStyle} />
      <CustomAlert
        setModalVisible={setModal}
        modalVisible={modal === "Alert"}
        alertData={alertData}
      />
    </Screen>
  );
};

export default ShopScreen;

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
});
