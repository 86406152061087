const hasGroup = (id, array) => {
  if (array) {
    const avaiable = array.filter((group) => group.gid === id);
    if (avaiable.length > 0) {
      return true;
    }
  }
  return false;
};

export const getVariantGroupIndex = (id, array) => {
  const index = array.findIndex((el) => el.gid === id);
  return index;
};

export const getVariantVariantIndex = (id, array) => {
  const index = array.findIndex((el) => el.gid === id);
  return index;
};

const hasVariant = (id, array) => {
  if (array) {
    const avaiable = array.filter((variant) => variant.gid === id);
    if (avaiable.length > 0) {
      return true;
    }
  }
  return false;
};

export const variantsRadioHandler = (variante, op, variants, setVariants) => {
  let auxVariants = [...variants];
  if (hasGroup(variante.gid, variants) === true) {
    const groupIndex = getVariantGroupIndex(variante.gid, variants);
    if (hasVariant(op.gid, variants[groupIndex].options)) {
    } else {
      auxVariants[groupIndex].options = [
        {
          name: op.name,
          gid: op.gid,
          increment: op.increment || 0,
          qty: 1,
        },
      ];
    }
  } else {
    auxVariants.push({
      gid: variante.gid,
      name: variante.name,
      options: [
        {
          name: op.name,
          gid: op.gid,
          increment: op.increment || 0,
          qty: 1,
        },
      ],
    });
  }
  setVariants(auxVariants);
  return variants;
};

export const variantsCheckHandler = (variante, op, variants, setVariants) => {
  let auxVariants = [...variants];
  const max = variante?.max_select;
  const actualQty = getVariantsQuantityByGroup(variants, variante.gid);
  if (hasGroup(variante.gid, variants) === true) {
    const groupIndex = getVariantGroupIndex(variante.gid, variants);
    if (hasVariant(op.gid, variants[groupIndex].options)) {
      const variantsIndex = getVariantVariantIndex(
        op.gid,
        variants[groupIndex].options
      );
      auxVariants[groupIndex].options.splice(variantsIndex, 1);

      if (auxVariants[groupIndex].options.length === 0) {
        auxVariants.splice(groupIndex, 1);
      }
    } else {
      if (!max || max > actualQty) {
        variants[groupIndex].options.push({
          name: op.name,
          gid: op.gid,
          increment: op.increment || 0,
          qty: 1,
        });
      }
    }
  } else {
    if (!max || max > actualQty) {
      auxVariants.push({
        gid: variante.gid,
        name: variante.name,
        options: [
          {
            name: op.name,
            gid: op.gid,
            increment: op.increment || 0,
            qty: 1,
          },
        ],
      });
    }
  }
  setVariants(auxVariants);
  return variants;
};

const getVariantsQuantity = (variantList) => {
  let qty = 0;
  variantList.map((variant) => {
    variant.options.map((op) => {
      qty += op.qty;
    });
  });
  return qty;
};

const getVariantsQuantityByGroup = (variantList, gid) => {
  let qty = 0;
  variantList.map((variant) => {
    if (variant.gid === gid) {
      variant.options.map((op) => {
        qty += op.qty;
      });
    }
  });
  return qty;
};
export const handleQtyVariant = (
  op,
  option,
  variante,
  variants,
  setVariants
) => {
  let auxVariants = [...variants];
  const max = variante?.max_select;
  const groupIndex = getVariantGroupIndex(variante.gid, variants);
  const variantIndex = getVariantVariantIndex(
    op.gid,
    variants[groupIndex].options
  );

  const qty = getVariantsQuantityByGroup(variants, variante.gid);

  if (option === "add") {
    if (max) {
      if (qty < max) {
        auxVariants[groupIndex].options[variantIndex].qty++;
      } else {
        return false;
      }
    } else {
      auxVariants[groupIndex].options[variantIndex].qty++;
    }
  } else {
    if (option === "remove") {
      if (auxVariants[groupIndex].options[variantIndex].qty > 1) {
        auxVariants[groupIndex].options[variantIndex].qty--;
      } else if (auxVariants[groupIndex].options[variantIndex].qty === 1) {
        auxVariants[groupIndex].options.splice(variantIndex, 1);
      }
    }
  }
  setVariants(auxVariants);
  return true;
};

export const variantsEqual = (first, second) => {
  let result = true;
  if ((first && !second) || (second && !first)) {
    result = false;
    return result;
  } else if (!first && !second) {
    result = true;
    return result;
  } else {
    if (first.length !== second.length) {
      result = false;
      return result;
    } else {
      const firstGroup = first.map((group) => group.gid);
      const secondGroup = second.map((group) => group.gid);
      if (firstGroup.sort().join(",") !== secondGroup.sort().join(",")) {
        result = false;
        return result;
      }
    }

    first.forEach((group) => {
      const firstGroup = group.options.map((variant) => ({
        gid: variant.gid,
        qty: variant.qty,
      }));
      second.forEach((auxGroup) => {
        if (group.gid === auxGroup.gid) {
          if (group.options.length === auxGroup.options.length) {
            const secondGroup = auxGroup.options.map((variant) => ({
              gid: variant.gid,
              qty: variant.qty,
            }));
            if (
              JSON.stringify(firstGroup.sort((a, b) => a.gid > b.gid)) !==
              JSON.stringify(secondGroup.sort((a, b) => a.gid > b.gid))
            ) {
              result = false;
              return;
            }
          } else {
            result = false;
            return;
          }
        }
      });
    });
    return result;
  }
};

export const getVariantsText = (
  variants,
  showTitles = true,
  separator = "comma"
) => {
  let texts = [];
  for (let i = 0; i < variants.length; i++) {
    if (showTitles) {
      texts.push({
        content: `${variants[i].name}: `,
        type: "bold",
      });
    }
    const qty = variants[i].options.length;
    for (let j = 0; j < variants[i].options.length; j++) {
      if (j < qty && j !== 0) {
        texts.push({ content: ", ", type: "normal" });
      }
      texts.push({
        content: `${variants[i].options[j].name}`,
        type: "normal",
      });
      if (variants[i].options[j].qty > 1) {
        texts.push({
          content: ` x${variants[i].options[j].qty}`,
          type: "normal",
        });
      }
    }
    if (separator === "comma") {
      texts.push({
        content: `, `,
        type: "bold",
      });
    } else {
      texts.push({
        content: `\n`,
        type: "bold",
      });
    }
  }
  texts.pop();
  return texts;
};

export const getPastOrderVariantText = (variants = [], showTitles = true) => {
  let texts = [];
  for (let i = 0; i < variants.length; i++) {
    if (showTitles) {
      texts.push({
        content: `${variants[i].name}: `,
        type: "bold",
      });
    }

    const lineVariants =
      variants[i]?.orderLineGroupVariants ||
      variants[i]?.orderLineMenuGroupVariants;
    const qty = lineVariants?.length;
    for (let j = 0; j < lineVariants.length; j++) {
      if (j < qty && j !== 0) {
        texts.push({ content: ", ", type: "normal" });
      }
      texts.push({
        content: `${lineVariants[j].name}`,
        type: "normal",
      });
      if (lineVariants[j].quantity > 1) {
        texts.push({
          content: ` x${lineVariants[j].quantity}`,
          type: "normal",
        });
      }
    }
    texts.push({
      content: `, `,
      type: "bold",
    });
  }
  texts.pop();

  return texts;
};
