import React from "react";
import { StyleSheet, Text, View } from "react-native";

// Constants
import { colors, family } from "../../../constants/theme";

const TitleRow = ({ name, orderNumber, openTime }) => {
  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <Text style={styles.title}>{name}</Text>
        {orderNumber && (
          <View style={styles.numberWrapper}>
            <Text style={styles.orderNumber}>{orderNumber}</Text>
          </View>
        )}
      </View>
      <View style={styles.row}>
        {openTime && (
          <Text style={styles.openTime}>{`Abierto hasta las ${openTime}`}</Text>
        )}
      </View>
    </View>
  );
};

export default TitleRow;

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    width: "100%",
  },
  row: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontFamily: family.bold,
    fontSize: 20,
    color: colors.brown,
  },
  orderNumber: {
    fontFamily: family.bold,
    fontSize: 24,
    color: colors.primaryColor,
  },
  openTime: {
    fontFamily: family.normal,
    fontSize: 14,
    color: colors.brown,
  },
  numberWrapper: {
    width: 100,
    alignItems: "flex-end",
  },
});
