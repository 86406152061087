function getOpeningTime(data) {
  const today = new Date();
  const currentDay = today.getDay();
  const currentHour = today.getHours();
  const currentMinute = today.getMinutes();

  const matchingEntries = (data || []).filter((entry) =>
    entry.weekdays.includes(currentDay)
  );

  if (matchingEntries.length === 0) {
    return "Cerrado";
  }

  const openingHours = matchingEntries.map((entry) => {
    const startHour = parseInt(entry.start_time.slice(0, 2));
    const startMinute = parseInt(entry.start_time.slice(3, 5));
    const endHour = parseInt(entry.end_time.slice(0, 2));
    const endMinute = parseInt(entry.end_time.slice(3, 5));

    let openingHour = startHour;
    let openingMinute = startMinute;
    let closingHour = endHour;
    let closingMinute = endMinute;

    if (
      endHour < startHour ||
      (endHour === startHour && endMinute < startMinute)
    ) {
      closingHour += 24;
    }

    if (
      currentHour < startHour ||
      (currentHour === startHour && currentMinute < startMinute)
    ) {
      openingHour = startHour;
      openingMinute = startMinute;
      closingHour = endHour;
      closingMinute = endMinute;
    } else if (
      currentHour > endHour ||
      (currentHour === endHour && currentMinute >= endMinute)
    ) {
      openingHour = startHour;
      openingMinute = startMinute;
      closingHour = endHour + 24;
      closingMinute = endMinute;
    } else {
      openingHour = startHour;
      openingMinute = startMinute;
      closingHour = endHour;
      closingMinute = endMinute;
    }

    return `${formatTime(openingHour)}:${formatTime(
      openingMinute
    )} - ${formatTime(closingHour)}:${formatTime(closingMinute)}`;
  });

  return openingHours.join(" / ");
}

function formatTime(time) {
  return time < 10 ? `0${time}` : time;
}

export default getOpeningTime;
