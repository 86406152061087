import React from "react";
import Svg, { Path } from "react-native-svg";

const ProfileIcon = (props) => {
	return (
		<Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
			<Path d="M27.5 29.1c-.6 0-1-.4-1-1 0-6.3-5.2-11.5-11.5-11.5S3.5 21.8 3.5 28.1c0 .6-.4 1-1 1s-1-.4-1-1c0-7.4 6.1-13.5 13.5-13.5s13.5 6.1 13.5 13.5c0 .6-.4 1-1 1zM15 14.3A6.7 6.7 0 1115 .9a6.7 6.7 0 010 13.4zm0-11.4c-2.6 0-4.7 2.1-4.7 4.7s2.1 4.7 4.7 4.7 4.7-2.1 4.7-4.7-2.1-4.7-4.7-4.7z" />
		</Svg>
	);
};

export default ProfileIcon;
