import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { SimpleLineIcons } from "@expo/vector-icons";
import { colors, family } from "../../../constants/theme";
import Divider from "../../atoms/Divider";

const ServiceLine = ({ service, location }) => {
  return (
    <View style={styles.container}>
      <View>
        <Divider height={2} />
        {service === "pick_up" ? (
          <>
            <MaterialCommunityIcons
              name="shopping-outline"
              size={12}
              color={colors.brown}
            />
          </>
        ) : (
          <SimpleLineIcons name="direction" size={12} color={colors.brown} />
        )}
      </View>

      <Divider width={5} />
      <Text style={styles.text}>
        {service === "pick_up" ? "Para recoger" : location}
      </Text>
    </View>
  );
};

export default ServiceLine;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
    paddingRight: 16,
  },
  text: {
    fontFamily: family.normal,
    fontSize: 12,
    color: colors.brown,
  },
});
