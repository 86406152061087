import React from "react";
import { StyleSheet, View, Platform } from "react-native";
import { connect } from "react-redux";

// Components
import CustomButton from "../../../components/atoms/CustomButton";
import Animation from "../../../components/atoms/Animation";
import Title from "../../../components/atoms/Title";

// Redux Actions
import { resetCart } from "../../../redux/cart/cartActions";

// Constants
import { size, colors, family } from "../../../constants/theme";

// Assets
import loaded from "../../../assets/animations/loaded.json";

const PaymentSuccess = ({ navigation }) => {
  const handleOut = () => {
    navigation.popToTop();
    navigation.navigate("OrdersStack", { screen: "OrdersHomeScreen" });
  };

  return (
    <View style={styles.container}>
      <View style={styles.wrapper}>
        <View style={styles.content}>
          <Animation
            animation={loaded}
            name="loaded"
            loop={false}
            style={{ width: 400, height: 400, marginBottom: 50 }}
          />
          <Title
            color={colors.primaryColor}
            multiline
            title={"¡Pedido realizado correctamente!"}
          />
        </View>
        <CustomButton
          width="100%"
          active
          onPress={handleOut}
          title={`Aceptar`}
          alignSelf="center"
        />
      </View>
    </View>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};
const mapDispatchToProps = {
  resetCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess);

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: 18,
    backgroundColor: colors.white,
  },
  wrapper: {
    width: "100%",
    height: "100%",
    maxWidth: Platform.OS === "web" ? 400 : null,
  },
  content: {
    paddingTop: 80,
    width: "100%",
    alignItems: "center",
    flex: 1,
    justifyContent: "space-around",
    backgroundColor: colors.white,
  },
  closeButton: {
    position: "absolute",
    top: Platform.OS === "web" ? 0 : 10,
    right: 0,
    zIndex: 2,
  },
  errorWrapper: {
    height: 30,
    paddingTop: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize: size.bigger,
    color: colors.primaryColor,
    fontFamily: family.bold,
    textAlign: "center",
  },
  text: {
    fontSize: size.medium,
    color: colors.brown,
    fontFamily: family.bold,
    textAlign: "center",
  },
});
