import React from "react";
import Svg, { Path } from "react-native-svg";

const MailIcon = (props) => {
  return (
    <Svg
      viewBox="0 0 511.996 511.996"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path d="M511.996 127.512v-8.079c0-30.419-24.748-55.166-55.166-55.166H55.166C24.747 64.267 0 89.014 0 119.433v273.133c0 30.417 24.747 55.163 55.166 55.163h401.667c30.417 0 55.163-24.746 55.163-55.163V127.571v-.059zM55.166 94.267H456.83c13.876 0 25.166 11.289 25.166 25.166v.007L281.1 253.37c-13.372 8.915-36.83 8.914-50.2.001L30 119.439v-.007c0-13.876 11.289-25.165 25.166-25.165zm401.667 323.462H55.166C41.289 417.729 30 406.441 30 392.566V155.494l184.259 122.838c11.704 7.803 26.719 11.703 41.74 11.703 15.017 0 30.039-3.901 41.742-11.703l184.256-122.838v237.072c-.001 13.875-11.289 25.163-25.164 25.163z" />
    </Svg>
  );
};

export default MailIcon;
