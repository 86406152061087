import React, { useEffect, useContext } from "react";
import { StyleSheet, ActivityIndicator, View } from "react-native";
import { Platform, Text } from "react-native";
import * as Location from "expo-location";
import { connect } from "react-redux";

// Redux Actions
import { fetchLocation, fetchLiveEvents, fetchEventTypes } from "../../redux";
import { fetchAllEvents } from "../../redux/events/eventsActions";
import { fetchAllShops } from "../../redux/shops/shopActions";
import { fetchPastOrders } from "../../redux/orders/ordersActions";

// Components
import Screen from "../../components/atoms/Screen";
import CustomIcon from "../../components/atoms/CustomIcon";
import CustomStatusBar from "../../components/atoms/CustomStatusBar";

// Constants
import { colors, family, size } from "../../constants/theme";
import { googleApiKey } from "../../constants/urls";

// Utils
import { registerForPushNotificationsAsync } from "../../utils/notificationsMethods";

import { AuthContext } from "../../contexts/AuthContext";

// Config
import { baseVersion, environment, version } from "../../../config";
import {
  compareVersions,
  fetchVersion,
  getLocalData,
} from "../../utils/miscelanusUtils";

const StartupScreen = ({
  fetchLocation,
  fetchLiveEvents,
  fetchEventTypes,
  fetchAllEvents,
  fetchAllShops,
  fetchPastOrders,
  userId,
  location,
  navigation,
}) => {
  const { user: firebaseUser } = useContext(AuthContext);

  useEffect(() => {
    navigation.setParams({ title: "Gopick" });
  }, []);

  const fetchInfo = async () => {
    if (!firebaseUser) return;
    const token = "";
    try {
      token = await firebaseUser.getIdToken();
    } catch (error) {
      console.log(error.message);
    }

    await fetchPastOrders(userId, token);

    if (location.lat === null) {
      await fetchLocation();
    }
    await fetchAllEvents();
    await fetchAllShops();
    await fetchEventTypes();
    await fetchLiveEvents(location);
  };

  const tryLogin = async () => {
    const onboardingDone = await getLocalData("loaded");
    Location.setGoogleApiKey(googleApiKey);

    if (onboardingDone !== true) {
      navigation.navigate("Onboarding");
    } else {
      if (Platform.OS !== "web") {
        registerForPushNotificationsAsync();
      }

      fetchInfo().then(() => {
        navigation.navigate("App");
      });
    }
  };

  const versionControl = async () => {
    if (Platform.OS !== "web") {
      const response = await fetchVersion();
      if (response.status === "success") {
        if (compareVersions(response.value, baseVersion) === 1) {
          navigation.navigate("UpdateScreen");
          return false;
        }
      }
    }
    return true;
  };

  useEffect(() => {
    versionControl().then((response) => {
      if (response) {
        tryLogin();
      }
    });
  }, []);

  return (
    <Screen>
      <CustomStatusBar color={colors.white} />
      <View style={styles.container}>
        <CustomIcon
          name="logo"
          width={200}
          height={70}
          color={colors.primaryColor}
        />
        <ActivityIndicator
          size="large"
          color={colors.primaryColor}
          style={{ marginTop: 50 }}
        />
      </View>
      {environment === "dev" && (
        <View style={styles.textContainer}>
          <Text style={styles.text}>Entorno de desarrollo</Text>
          <Text style={styles.text}>{version}</Text>
        </View>
      )}
    </Screen>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  textContainer: {
    width: "100%",
    paddingBottom: 30,
    alignItems: "center",
  },
  text: {
    fontFamily: family.normal,
    color: colors.darkGrey,
    fontSize: size.small,
  },
});

const mapDispatchToProps = {
  fetchLocation,
  fetchLiveEvents,
  fetchEventTypes,
  fetchAllEvents,
  fetchAllShops,
  fetchPastOrders,
};

const mapStateToProps = (state) => {
  return {
    location: state.user.location,
    userId: state.user.user.gid,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StartupScreen);
