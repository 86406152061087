import { v4 as uuidv4 } from "uuid";

function getParentsArray(array) {
  let events = [];
  array?.forEach((el) => {
    if (events.filter((aux) => aux.gid === el.event.gid).length === 0) {
      // push on array available events if they dont exist
      events.push({ ...el.event, shops: [] });
    }
  });
  return events;
}

function fillOrders(shops, shopId, orders, parentOrder) {
  shops.forEach((shop) => {
    if (shop.gid === shopId) {
      orders.map((order) => {
        shop.orders.push({
          ...order,
          parentOrder: parentOrder,
          internalId: uuidv4(),
        });
      });
    }
  });
  return shops;
}

function fillWithShops(events, data) {
  let auxEvents = events;
  data.map((element) => {
    auxEvents.forEach((event, ev_index) => {
      if (event.gid === element.event.gid) {
        if (
          event.shops.filter((shop) => shop.gid === element.shop.gid).length !==
          0
        ) {
          // shop exists exist. We add products with fillOrders.
          auxEvents[ev_index].shops = fillOrders(
            event.shops,
            element.shop.gid,
            element.orderLines,
            element.gid
          );
        } else {
          // shop doesnt exists, we push it with all its info
          event.shops.push({
            ...element.shop,
            orders: element.orderLines.map((od) => {
              return {
                ...od,
                parentOrder: element.gid,
                internalId: uuidv4(),
              };
            }),
          });
        }
      }
    });
  });

  return auxEvents;
}

// export const purgueArray = (array) => {
//   array.forEach((event, eventIndex) => {
//     event.shops.forEach((shop, shopIndex) => {
//       shop.orders.forEach((order, odIndex) => {
//         if (order.quantityAvailable === 0) {
//           shop.orders.splice(odIndex, 1);
//         }
//       });
//       if (shop.orders.length === 0) {
//         event.shops.splice(shopIndex, 1);
//       }
//     });
//     if (event.shops.length === 0) {
//       array.splice(eventIndex, 1);
//     }
//   });
//   return array;
// };

export const generateLaterArray = (data) => {
  const parentArray = getParentsArray(data);
  const filledArray = fillWithShops(parentArray, data);
  return filledArray;
};
