import React from "react";
import { ActivityIndicator } from "react-native";
import { StyleSheet, Text, TouchableOpacity } from "react-native";

// Constants
import { colors, family } from "../../../../../constants/theme";

const Button = ({ onPress, customStyle, loading }) => {
  const { accentColor, secondaryColor } = customStyle;
  return (
    <TouchableOpacity
      onPress={onPress}
      style={[styles.button, { backgroundColor: accentColor }]}
    >
      {loading === false ? (
        <Text style={[styles.text, { color: secondaryColor }]}>Aplicar</Text>
      ) : (
        <ActivityIndicator color={secondaryColor} size="small" />
      )}
    </TouchableOpacity>
  );
};

export default Button;

const styles = StyleSheet.create({
  button: {
    borderRadius: 50,
    justifyContent: "center",
    alignItems: "center",
    width: 100,
    paddingHorizontal: 18,
  },
  text: {
    fontFamily: family.bold,
    fontSize: 16,
  },
});
