import React from "react";
import { StyleSheet, Text, View } from "react-native";

// Components
import CustomCheckBox from "../../../atoms/CustomCheckBox";

// Hooks
import useForceRender from "../../../../hooks/useForceRender";
import { family, size } from "../../../../constants/theme";

const Ingredients = ({ data, setIngredients, ingredientsList }) => {
  const [forceRender] = useForceRender();
  const handleIngredients = (el) => {
    if (ingredientsList.includes(el)) {
      setIngredients(ingredientsList.filter((ing) => ing !== el));
    } else {
      
      setIngredients([...ingredientsList, el]);
    }
    forceRender();
  };
  return (
    <View style={styles.varsContainer}>
      <Text style={[styles.title, { marginBottom: 5, color: "white" }]}>
        Ingredientes
      </Text>
      {data.map((ig) => (
        <CustomCheckBox
          key={ig}
          textColor={"white"}
          color="black"
          label={ig}
          value={!ingredientsList.includes(ig)}
          onPress={() => handleIngredients(ig)}
        />
      ))}
    </View>
  );
};

export default Ingredients;

const styles = StyleSheet.create({
  varsContainer: {
    backgroundColor: "black",
    paddingHorizontal: 18,
    paddingVertical: 20,
  },
  title: {
    fontSize: size.small,
    fontFamily: family.bold,
  },
});
