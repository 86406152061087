import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";

// Constants
import { colors, family } from "../../../../constants/theme";

const ShopServiceLine = ({ service, location }) => {
  return (
    <View>
      {service === 2 ? (
        <Text style={styles.text}>
          Tu pedido se entregará en{" "}
          <Text style={{ fontFamily: family.bold }}>{location}</Text>
        </Text>
      ) : service === 1 ? (
        <Text style={styles.text}>Pedido para recoger</Text>
      ) : (
        <Text style={styles.text}></Text>
      )}
    </View>
  );
};

const mapStateToProps = (state) => {
  return {
    service: state.laterOrders.serviceTypeId,
    location: state.laterOrders.location,
  };
};
export default connect(mapStateToProps)(ShopServiceLine);

const styles = StyleSheet.create({
  text: {
    fontFamily: family.normal,
    fontSize: 12,
    textAlign: "left",
    color: colors.brown,
  },
  column: {
    marginBottom: 5,
  },
});
