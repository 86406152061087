const needAnyLocation = (services) => {
  let response = false;
  services.map((service) => {
    if (service.key === "my_site") {
      if (
        (service.location === "" ||
          service.location === null ||
          service.location === undefined) &&
        service.levels_locations !== "not_show"
      ) {
        response = `Debes seleccionar ${
          service.key === "delivery" ? "dirección" : "localización"
        } en la tienda ${service.shopName}`;
      }
    }
  });
  return response;
};

export default needAnyLocation;
