import React from "react";
import { StyleSheet, View } from "react-native";
import { colors, family } from "../../../constants/theme";
import Tag from "./Tag";

export const StatusTag = ({ status }) => {
  switch (status) {
    case "scheduled":
      return <View></View>;
    case "waiting":
      return <Tag text={"Recibido"} textColor={colors.brown} bgColor={colors.blue}/>;
    case "processing":
      return <Tag text={"Preparando"} textColor={colors.brown} bgColor={colors.orange}/>;
    case "await_pickup":
      return <Tag text={"Listo"} textColor={colors.brown} bgColor={colors.lightGreen2}/>;
    case "await_shipment":
      return <Tag text={"Preparando"} textColor={colors.brown} bgColor={colors.orange}/>;
    case "parcially_delivered":
      return <Tag text={"Preparando"} textColor={colors.brown} bgColor={colors.orange}/>;
    case "shipped":
      return <Tag text={"Enviado"} textColor={colors.brown} bgColor={colors.lightGreen2}/>;
    case "completed":
      return <Tag text={"Completado"} textColor={colors.brown} bgColor={colors.lightGreen2}/>;
  }
};

export default StatusTag;

const styles = StyleSheet.create({
  lineStyle: {
    flexDirection: "row",
    alignItems: "center",
  },
  textStyle: {
    fontFamily: family.normal,
    fontSize: 13,
    color: colors.brown,
  },
});
