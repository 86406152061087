import React, { useMemo } from "react";
import { StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";

// Constants
import { family } from "../../../../../../constants/theme";

// Utils
import { getShopServiceCost } from "../../../../../../utils/orderMethods";
import { getFormattedPrice } from "../../../../../../utils/productMethods";

const ServiceLine = ({ orders, totalAmount, services, customStyle }) => {
  const { textColor } = customStyle;
  const textStyle = [styles.text, { color: textColor }];

  const servicesCost = () => {
    let amount = 0;
    orders.map((shop) => {
      amount += getShopServiceCost(services, shop);
    });

    return amount > 0 ? getFormattedPrice(amount) : "GRATIS";
  };

  const service = useMemo(
    () => servicesCost(),
    [orders, services, totalAmount]
  );

  return (
    <View style={styles.container}>
      <Text style={textStyle}>Servicio</Text>
      <Text style={textStyle}>{service}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    justifyContent: "space-between",
    marginBottom: 5,
    flexDirection: "row",
  },
  text: {
    fontFamily: family.normal,
    fontSize: 12,
  },
});

const mapStateToProps = (state) => {
  return {
    orders: state.cart.orders,
    services: state.cart.services,
  };
};
export default connect(mapStateToProps)(ServiceLine);
