import React from "react";
import {
  StyleSheet,
  Text,
  View,
  ImageBackground,
  Platform,
} from "react-native";

const SnapCarousel =
  Platform.OS !== "web" && require("react-native-snap-carousel");

// Constants
import { size, family, width, colors, RADIUS } from "../../constants/theme";

export const Carousel = ({ data, setIndex }) => {
  const _renderItem = ({ item, index }) => {
    return (
      <ImageBackground
        source={{
          uri: data[index].popup_image,
        }}
        resizeMode="cover"
        imageStyle={{ borderRadius: RADIUS, opacity: 1 }}
        style={styles.background}
      >
        <View style={styles.container}>
        </View>
      </ImageBackground>
    );
  };

  return (
    <SnapCarousel.default
      data={data}
      renderItem={_renderItem}
      sliderWidth={width * 0.9}
      itemWidth={width * 0.9}
      onSnapToItem={(index) => setIndex(index)}
      style={{ backgroundColor: "rgba(0,0,0,0)" }}
    />
  );
};

export const Pagination = ({ data, activeIndex }) => {
  return (
    <SnapCarousel.Pagination
      dotsLength={data.length}
      activeDotIndex={activeIndex}
      containerStyle={{ position: "absolute", bottom: 40 }}
      dotStyle={{
        width: 10,
        height: 10,
        borderRadius: 5,
        backgroundColor: colors.brown,
        marginHorizontal: -5,
      }}
      inactiveDotStyle={{
        backgroundColor: colors.brown,
      }}
      inactiveDotOpacity={0.6}
      inactiveDotScale={0.6}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    maxWidth: width * 0.9,
    height: 500,
    padding: 10,
    paddingTop: 100,
    justifyContent: "space-between",
    borderRadius: RADIUS,
  },
  textWrapper: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontFamily: family.bold,
    fontSize: size.bigger,
    textAlign: "center",
  },
  background: {
    width: "100%",
    borderRadius: RADIUS,
  },
});
