import React from "react";
import { ScrollView } from "react-native";

// Components
import Divider from "../../../../components/atoms/Divider";
import EventItem from "./EventItem";

const EventList = ({ navigation, data }) => {
  return (
    <ScrollView style={{ height: 1 }} showsVerticalScrollIndicator={false}>
      {data.map((item) => (
        <>
          <Divider height={12} />
          <EventItem key={item.gid} data={item} navigation={navigation} />
        </>
      ))}
      <Divider height={160} />
    </ScrollView>
  );
};

export default EventList;
