import { baseVersion } from "../../../../../config";
import { URlManagement } from "../../../../constants/urls";

export const getLocalizaciones = async (eventId, shopId) => {
  try {
    const url = `${URlManagement}/location/getAllAvailableOfEvent/${eventId}&${shopId}&my_site`;
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Connection: "keep-alive",
         
      },
      method: "GET",
    });
    const resData = await response.json();

    if (resData.status === "success") {
      return { status: "success", data: resData.data };
    } else {
      return { status: "success", data: [] };
    }
  } catch (error) {
    console.log("error getLocalizaciones", error.message);
    return { status: "error", data: [] };
  }
};
