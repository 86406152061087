import React from "react";
import { StyleSheet, View } from "react-native";

// Components
import Divider from "../../../components/atoms/Divider";
import TitleRow from "./TitleRow";
import ExtraLine from "./Products/ExtraLine";

// Methods
import { getPastProductsTotal } from "./methods/getPastProductsTotal";
import { getPastTicketsTotal } from "./methods/getPastTicketsTotal";

// Utils
import { getFormattedPrice } from "../../../utils/productMethods";

const ResumeSection = ({ data }) => {
  const {
    orderLineCommissions,
    orderLineServices,
    orderLineDiscounts,
    orderLines,
    orderLineTickets,
    amount,
  } = data;

  const getTotal = (lines) => {
    const total = lines?.reduce(
      (accumulator, item) => accumulator + Math.abs(item.amount),
      0
    );
    return total === 0 ? "GRATIS" : getFormattedPrice(total);
  };

  const subtotal = getFormattedPrice(
    getPastProductsTotal(orderLines) + getPastTicketsTotal(orderLineTickets)
  );
  const commission = getTotal(orderLineCommissions);
  const service = getTotal(orderLineServices);
  const discount = getTotal(orderLineDiscounts);

  return (
    <View style={styles.container}>
      <TitleRow name={"Resumen"} />
      <ExtraLine name={"Subtotal"} amount={subtotal} big />
      <ExtraLine name={"Gastos de gestión"} amount={commission} />
      <ExtraLine name={"Servicio"} amount={service} />
      {discount !== "GRATIS" && (
        <ExtraLine name={"Descuento"} amount={"-" + discount} />
      )}
      <Divider height={10} />
      <ExtraLine
        name={"Total"}
        amount={getFormattedPrice(amount)}
        big
        subtitle="(Impuestos incluidos)"
      />
    </View>
  );
};

export default ResumeSection;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "column",
    marginBottom: 8,
  },
});
