import React from "react";
import { StyleSheet, View, Image } from "react-native";
import { Octicons } from "@expo/vector-icons";

// Assets
const whiteIcon = require("../../../assets/gopick-logos/hand-white.png");
const redIcon = require("../../../assets/gopick-logos/hand-colored.png");

// Constants
import { colors } from "../../../constants/theme";

const Icon = ({ index, active }) => {
  const color = active ? colors.primaryColor : colors.brown;
  const icon = active ? whiteIcon : redIcon;

  switch (index) {
    case 0:
      return <Octicons name="home" size={30} color={color} />;
    case 1:
      return (
        <View style={[styles.orders, !active && styles.ordersActive]}>
          <Image style={styles.image} source={icon} />
        </View>
      );
    case 2:
      return <Octicons name="person" size={30} color={color} />;
  }
};

export default Icon;

const styles = StyleSheet.create({
  orders: {
    width: 70,
    height: 70,
    borderRadius: 35,
    backgroundColor: colors.primaryColor,
    position: "absolute",
    bottom: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  ordersActive: {
    backgroundColor: "white",
    borderWidth: 1,
    borderColor: colors.primaryColor,
  },
  image: {
    width: 45,
    height: 45,
    resizeMode: "contain",
  },
});
