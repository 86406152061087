import React from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";

// Components
import CustomButton from "../../../../../components/atoms/CustomButton";
import TextInput from "../../../../../components/atoms/TextInput";
import CustomModal from "../../../../../components/atoms/CustomModal";

// Redux
import { setComment } from "../../../../../redux/cart/cartActions";

const CommentModal = ({
  visible,
  setModal,
  customStyle,
  comment,
  setComment,
}) => {
  const { backgroundColor, primaryColor } = customStyle;
  return (
    <CustomModal modalVisible={visible} handleModal={() => setModal()}>
      <View style={styles.inputWrapper}>
        <TextInput
          multiline
          value={comment}
          onChange={setComment}
          placeholder="Escribe aquí tu comentario"
        />
        <CustomButton
          marginTop={24}
          title="Aceptar"
          active
          onPress={() => {
            setModal();
          }}
          color={primaryColor}
          textColor={backgroundColor}
        />
      </View>
    </CustomModal>
  );
};

const mapDispatchToProps = {
  setComment,
};

const mapStateToProps = (state) => {
  return {
    comment: state.cart.comment,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentModal);

const styles = StyleSheet.create({
  inputWrapper: {
    width: "100%",
    height: 300,
  },
});
