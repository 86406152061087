import React, { useCallback, useEffect, useState } from "react";
import { StyleSheet, ScrollView, View, Text } from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import { connect } from "react-redux";

// Redux Actions
import { logOut } from "../../redux/user/userActions";
import { setCurrentScreen } from "../../redux";

// Components
import CustomButton from "../../components/atoms/CustomButton";
import TextButton from "../../components/atoms/TextButton";
import Screen from "../../components/atoms/Screen";
import CustomStatusBar from "../../components/atoms/CustomStatusBar";

// Constants
import { colors, family, size } from "../../constants/theme";

// Utils
import { getLocalData } from "../../utils/miscelanusUtils";

// Config
import { version } from "../../../config";
import Divider from "../../components/atoms/Divider";

const ProfileHomeScreen = ({ navigation, setCurrentScreen, logOut }) => {
  const [client, setClient] = useState(null);

  const getClient = async () => {
    const currentClient = await getLocalData("client");
    setClient(currentClient);
  };

  useEffect(() => {
    getClient();
  }, []);

  const buttonHandler = (page) => {
    navigation.navigate("ProfileStack", {
      screen: page,
    });
  };

  const logOutHandler = async () => {
    logOut();
    navigation.navigate("ProfileStack", { screen: "AuthHomeScreen" });
  };

  useFocusEffect(
    useCallback(() => {
      setCurrentScreen(3);
    }, [])
  );

  return (
    <Screen>
      <CustomStatusBar color={colors.white} />
      <View style={styles.container}>
        <View style={styles.buttonsContainer}>
          <TextButton
            icon={"user"}
            big
            title={"Mis Datos"}
            onPress={() => buttonHandler("ProfilePersonalDataScreen")}
            buttonColor={colors.white}
          />
          <TextButton
            icon={"list"}
            big
            title={"Historial de Pedidos"}
            onPress={() => buttonHandler("CompletedOrdersScreen")}
            buttonColor={colors.white}
          />
          <TextButton
            icon={"balance-scale"}
            big
            title={"Términos y condiciones"}
            onPress={() => buttonHandler("ProfileTermsAdviceScreen")}
            buttonColor={colors.white}
          />
          <TextButton
            icon={"lock"}
            big
            title={"Política de privacidad"}
            onPress={() => buttonHandler("ProfilePrivacyAdviceScreen")}
            buttonColor={colors.white}
          />
          <TextButton
            icon={"eye"}
            big
            title={"Aviso legal"}
            onPress={() => buttonHandler("ProfileLegalAdviceScreen")}
            buttonColor={colors.white}
          />
        </View>

        <View style={styles.logoutContainer}>
          <CustomButton title="Cerrar sesión" active onPress={logOutHandler} />
          <Divider height={20} />
          <View style={styles.textContainer}>
            <Text style={styles.text}>{version}</Text>
          </View>
        </View>
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    paddingBottom: 10,
    paddingHorizontal: 18,
  },
  textContainer: {
    width: "100%",
    alignItems: "center",
  },
  text: {
    fontFamily: family.normal,
    color: colors.darkGrey,
    fontSize: size.small,
  },
  buttonsContainer: {
    flex: 1,
  },
  logoutContainer: {
    height: 100,
  },
});

const mapDispatchToProps = {
  logOut,
  setCurrentScreen,
};

export default connect(null, mapDispatchToProps)(ProfileHomeScreen);
