import React, { useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import { Feather } from "@expo/vector-icons";
import { TouchableOpacity } from "react-native";

// Components
import RemoveProduct from "../../../../components/organisms/products/ProductItem/components/RemoveProduct";
import ActionButton from "../../../../components/organisms/products/ProductItem/components/ActionButton";

// Constants
import { colors, family } from "../../../../constants/theme";

// Redux
import { deleteProduct } from "../../../../redux/laterCart/laterCartActions";
import { incrementProduct } from "../../../../redux/laterCart/laterCartActions";
import useForceRender from "../../../../hooks/useForceRender";
import DeleteProductModal from "../modals/DeleteProductModal";

const ProductItem = ({ data, shop, incrementProduct, deleteProduct }) => {
  const [modal, setModal] = useState();
  const [forceRender] = useForceRender();
  const {
    productName: name,
    qtyAvailable: quantityAvailable,
    internalId,
    quantity,
  } = data;

  const onAdd = () => {
    if (quantity < quantityAvailable) {
      incrementProduct({ internalId: internalId, shopId: shop.shopId });
      forceRender();
    }
  };

  const onRemove = () => {
    if (quantity === 1) {
      setModal("DeleteProduct");
    } else {
      deleteProduct({ shopId: shop.shopId, internalId: internalId });
    }
    forceRender();
  };

  const acceptDeleteHandler = () => {
    deleteProduct({ shopId: shop.shopId, internalId: internalId });
    setModal()
    forceRender();
  };

  return (
    <TouchableOpacity style={styles.container} onPress={onAdd}>
      <RemoveProduct
        showActions={true}
        quantity={quantity || 0}
        onPress={onRemove}
      />
      <View style={styles.info}>
        <Text style={styles.title}>{name}</Text>
      </View>
      {quantityAvailable - quantity > 0 && (
        <View style={styles.actionContainer}>
          <Text style={[styles.title, { fontSize: 16 }]}>
            {quantityAvailable - quantity}
          </Text>
          <ActionButton color={colors.lightenGreen} onPress={onAdd}>
            <Feather name="plus" size={20} color={colors.lightGreen} />
          </ActionButton>
        </View>
      )}
      <DeleteProductModal
        visible={modal === "DeleteProduct"}
        setModal={setModal}
        productName={name}
        onAccept={acceptDeleteHandler}
      />
    </TouchableOpacity>
  );
};

const mapDispatchToProps = {
  incrementProduct,
  deleteProduct,
};

export default connect(null, mapDispatchToProps)(ProductItem);

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "row",
    height: 55,
  },
  info: {
    flexDirection: "column",
    flex: 1,
    paddingHorizontal: 18,
  },
  title: {
    fontFamily: family.bold,
    fontSize: 12,
    color: colors.brown,
  },
  actionContainer: {
    flexDirection: "column",
    width: 40,
    justifyContent: "space-between",
    alignItems: "center",
  },
});
