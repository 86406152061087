import React from "react";
import { StyleSheet, Text, View } from "react-native";

// Components
import IngredientsRemoved from "./DetailLines/IngredientsRemoved";
import VariantsText from "./DetailLines/VariantsText";
import MenuSelection from "./DetailLines/MenuSelection";

// Constants
import { colors, family, RADIUS } from "../../../../constants/theme";

// Utils
import { getFormattedPrice } from "../../../../utils/productMethods";
import { getPastProductPrice } from "../../../../utils/productMethods";

const Product = ({ data }) => {
  const {
    name,
    quantity,
    orderLineGroups,
    ingredientsRemoved,
    orderLineMenus,
  } = data;

  const price = getFormattedPrice(getPastProductPrice(data));

  return (
    <View style={styles.container}>
      <View style={styles.qtyColumn}>
        <Text style={styles.text}>{(quantity || 1) + "x"}</Text>
      </View>
      <View style={styles.detailsColumn}>
        <Text numberOfLines={2} style={styles.text}>
          {name}
        </Text>
        <IngredientsRemoved data={ingredientsRemoved} />
        <VariantsText data={orderLineGroups} />
        <MenuSelection data={orderLineMenus} />
      </View>
      <View style={styles.priceColumn}>
        <Text style={styles.text}>{price}</Text>
      </View>
    </View>
  );
};

export default Product;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "row",
    borderRadius: RADIUS,
    marginBottom: 8,
  },
  qtyColumn: {
    width: 35,
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingRight: 8,
  },
  priceColumn: {
    justifyContent: "flex-start",
  },
  detailsColumn: {
    flex: 1,
    justifyContent: "flex-start",
    paddingRight: 8,
  },
  text: {
    fontFamily: family.normal,
    fontSize: 14,
    color: colors.brown,
  },
});
