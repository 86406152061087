import React from "react";
import { Feather } from "@expo/vector-icons";
import { StyleSheet, Text, View, Image } from "react-native";

// Components
import Divider from "../../../../components/atoms/Divider";

// Constants
import { colors, family } from "../../../../constants/theme";

const Header = ({ data }) => {
  const { schedule, name, image } = data;

  return (
    <View style={styles.container}>
      <Image style={styles.image} source={{ uri: image }} />
      <View style={styles.content}>
        <Text style={styles.title}>{name}</Text>
        {schedule && (
          <>
            <Divider height={10} />
            <View style={styles.row}>
              <Feather name="clock" size={14} color={colors.white} />
              <Divider width={5} />
              <Text style={styles.text}>{schedule}</Text>
            </View>
          </>
        )}
      </View>
    </View>
  );
};

export default Header;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: 180,
    flexDirection: "row",
  },
  image: {
    resizeMode: "cover",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
  },
  content: {
    backgroundColor: "rgba(0,0,0,0.45)",
    flex: 1,
    height: "100%",
    paddingVertical: 12,
    paddingHorizontal: 30,
    justifyContent: "flex-end",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontFamily: family.bold,
    color: colors.white,
    fontSize: 28,
  },
  text: {
    fontFamily: family.normal,
    color: colors.white,
    fontSize: 14,
  },
});
