import React from "react";
import StyleSheet from "react-native-media-query";
import { View, ImageBackground } from "react-native";
import { LinearGradient } from "expo-linear-gradient";

// Components
import TicketBack from "../components/TicketBack";

const TicketHero = ({
  navigation,
  children,
  backAction,
  backText,
  shopId,
  image,
  ...props
}) => {
  return (
    <View style={[styles.heroContainer, { ...props }]}>
      <ImageBackground
        style={styles.heroImage}
        source={
          image ? { uri: image } : require("../../../assets/concertbyn.webp")
        }
      >
        <View style={styles.opacity}>
          {backText && (
            <TicketBack
              backAction={backAction}
              shopId={shopId}
              text={backText}
              navigation={navigation}
            />
          )}
          <View style={styles.heroContent} dataSet={{ media: ids.heroContent }}>
            {children}
          </View>
          <LinearGradient
            colors={["transparent", "rgba(0,0,0,1)"]}
            style={styles.gradient}
            start={{ x: 0, y: 0 }}
            end={{ x: 0, y: 1 }}
          />
        </View>
      </ImageBackground>
    </View>
  );
};

export default TicketHero;

const { ids, styles } = StyleSheet.create({
  heroContainer: {
    width: "100%",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    backgroundColor: "black",
    minHeight: 520,
  },
  gradient: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "50%",
    zIndex: 3,
  },
  heroContent: {
    width: "100%",
    height: "100%",
    paddingTop: 150,
    maxWidth: 1440,
    paddingHorizontal: 40,
    justifyContent: "center",
    alignItems: "center",
    zIndex: 4,
    "@media (max-width: 500px)": {
      paddingHorizontal: 20,
      paddingTop: 100,
    },
  },
  heroImage: {
    width: "100%",
    height: "100%",
    resizeMode: "cover",
    justifyContent: "center",
    alignItems: "center",
  },
  opacity: {
    width: "100%",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.3)",
  },
});
