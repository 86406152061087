import React, { useState } from "react";
import { View, StyleSheet, Platform, useWindowDimensions } from "react-native";
import Carousel from "react-native-reanimated-carousel";

// Components
import LiveItem from "./LiveItem";

const LiveList = ({ list, ...props }) => {
  const { height, width } = useWindowDimensions();
  const [clickable, setClickable] = useState(true);

  const itemHeight = height - (Platform.OS === "web" ? 80 : 110);
  const smallScreen = height < 700 || width < 340;
  let itemWidth = itemHeight * 0.68;

  if (itemWidth > width) {
    itemWidth = width - 12;
  }

  const baseOptions = {
    vertical: false,
    width: itemWidth,
    height: "100%",
  };

  return (
    <View style={styles.container}>
      <Carousel
        {...baseOptions}
        style={{
          width: width,
          height: height - 130,
          justifyContent: "center",
        }}
        loop
        onScrollBegin={() => setClickable(false)}
        onScrollEnd={() => setClickable(true)}
        overscrollEnabled={true}
        pagingEnabled={true}
        snapEnabled={true}
        autoPlay={false}
        mode="parallax"
        modeConfig={{
          parallaxScrollingScale: smallScreen ? 0.83 : 0.87,
          parallaxScrollingOffset: 50,
        }}
        data={list}
        renderItem={({ item }) => (
          <LiveItem
            data={item}
            key={item?.gid}
            clickable={clickable}
            itemWidth={itemWidth}
            {...props}
          />
        )}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    flex: 1,
    alignItems: "center",
  },
});

export default LiveList;
