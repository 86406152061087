import React, { useState, useRef, useEffect, useContext } from "react";
import { ActivityIndicator, StyleSheet, View, ScrollView } from "react-native";
import { connect } from "react-redux";
import { Platform } from "react-native";
import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";

// Constants
import { colors } from "../../constants/theme";

// Methods
import getAdyenSession from "../functions/getAdyenSession";
import { resetTicketCart } from "../../redux";

// Utils
import { getFormattedOrderTickets } from "../../utils/orderMethods";
import { paymentConfig } from "../paymentConfig";
import { environment } from "../../../config";

import { AuthContext } from "../../contexts/AuthContext";

const AdyenTicketingPayment = ({
  navigation,
  productLines,
  ticketLines,
  user,
  shopCheckoutData,
  discountLines,
  amount,
  visible,
  resetTicketCart,
  modalHandler,
  setWarningText,
}) => {
  const { user: firebaseUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const paymentContainer = useRef(null);

  async function comprobarJwt() {
    const token = await firebaseUser?.getIdToken();
    const response = await fetchPastOrders(user?.gid, token);
    if (response?.code == 401 || response?.code == 403) {
      logOut();
      return false;
    }
    return true;
  }

  const initiateCheckout = async (amount) => {
    try {
      if (comprobarJwt()) {
        setLoading(true);
        const tData = {
          ticket_lines: ticketLines,
          discount: discountLines,
          product_lines: productLines,
        };
        const order = getFormattedOrderTickets(tData, shopCheckoutData, user);
        const token = await firebaseUser.getIdToken(true);

        const response = await getAdyenSession(token, {
          ...order,
          channel: Platform.OS,
          returnUrl:
            environment === "dev"
              ? "https://userdev.gopick-app.com"
              : "https://tickets.gopick-app.com",
        });

        if (response?.status === "success") {
          const session = response.data.session;
          const { config } = paymentConfig(amount, session);
          const configToSend = {
            ...config,
            session: session,
            clientKey: config.clientKey,
            onPaymentCompleted: (response, _component) => {
              if (response?.resultCode === "Authorised") {
                navigation.navigate("TicketsPaymentSuccess");
                resetTicketCart();
              }
              if (response?.resultCode === "Refused") {
                navigation.navigate("/TicketsPaymentError", {
                  error: error.message,
                });
              }
              modalHandler();
            },
            onError: (error, _component) => {
              modalHandler();
              setWarningText(
                "Pago rechazado, comprueba que los datos introducidos son correctos"
              );
            },
            onDisable: () => {},
          };
          const createCheckout = async () => {
            const checkout = await AdyenCheckout({
              ...configToSend,
            });
            if (paymentContainer.current) {
              checkout
                .create("dropin", {
                  showPaymentMethods: true,
                  showStoredPaymentMethods: true,
                  showRemovePaymentMethodButton: true,
                  instantPaymentTypes: ["applepay", "googlepay"],
                  onReady: () => {
                    setLoading(false);
                  },
                  onDisableStoredPaymentMethod: async (
                    storedPaymentMethodId,
                    resolve,
                    reject
                  ) => {
                    const response = await disableMethod(
                      session?.shopperReference,
                      storedPaymentMethodId
                    );
                    if (response === "success") {
                      resolve();
                    } else {
                      reject();
                    }
                  },
                })
                .mount(paymentContainer.current);
            }
          };
          createCheckout();
        }
      }
    } catch (error) {
      // setWarningText(error.message);
      // TODO
      console.log("error", error);
      setError(error.message);
      // modalHandler()
    }
  };

  useEffect(() => {
    if (visible) initiateCheckout(amount);
  }, [visible, amount]);

  return (
    <ScrollView style={styles.container}>
      <div id="payment-page">
        <div className="container">
          <div className="payment-container">
            <View style={styles.wrapper}>
              {loading && (
                <ActivityIndicator color={colors.primaryColor} size={"small"} />
              )}
              <div ref={paymentContainer} className="payment"></div>
            </View>
          </div>
        </div>
      </div>
    </ScrollView>
  );
};

const mapStateToProps = (state) => {
  return {
    productLines: state.tickets.productLinesData,
    ticketLines: state.tickets.ticketLinesData,
    shopCheckoutData: state.tickets.shopCheckoutData,
    discountLines: state.tickets.ticketDiscountLines || [],
    user: state.user.user,
  };
};

const mapDispatchToProps = {
  resetTicketCart,
};

const styles = StyleSheet.create({
  wrapper: {
    minHeight: 220,
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    width: "100%",
    height: "auto",
    maxHeight: 400,
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdyenTicketingPayment);
