import React, { useState } from "react";
import { ScrollView } from "react-native";
import { StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";

// Components
import ScheduleItem from "../components/ScheduleItem";
import Divider from "../../../../../../components/atoms/Divider";

// Constants
import { family } from "../../../../../../constants/theme";

// Redux
import { setDate } from "../../../../../../redux/cart/cartActions";
import { setOrderType } from "../../../../../../redux/cart/cartActions";

const SchedulesContainer = ({ customStyle, shop, setDate, setOrderType }) => {
  const [selectedItem, setSelectedItem] = useState(shop.delivery);
  const { textColor } = customStyle;

  const setScheduleHandler = (date) => {
    if (date) {
      setDate(date);
      setSelectedItem(date);
      setOrderType("Scheduled");
    } else {
      setDate(null);
    }
  };
  
  return (
    <ScrollView style={styles.container}>
      {shop?.schedules.map((list) => {
        const title = list?.list[0]?.substring(0, 2);
        return (
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Text style={[styles.title, { color: textColor }]}>
              {title.length > 1 ? title : "0" + title}:
            </Text>
            <Divider width={8} />
            <View
              style={[styles.listContainer, { borderLeftColor: textColor }]}
            >
              <ScrollView
                key={list.id}
                horizontal
                style={[styles.horizontalScroll]}
                showsHorizontalScrollIndicator={false}
              >
                {list.list.map((item) => (
                  <ScheduleItem
                    key={item}
                    text={item}
                    onPress={() => setScheduleHandler(item)}
                    selected={selectedItem === item}
                    customStyle={customStyle}
                  />
                ))}
                <Divider width={50} />
              </ScrollView>
            </View>
          </View>
        );
      })}
    </ScrollView>
  );
};

const mapDispatchToProps = {
  setDate,
  setOrderType,
};

export default connect(null, mapDispatchToProps)(SchedulesContainer);

const styles = StyleSheet.create({
  container: {
    maxHeight: 300,
    width: "100%",
  },
  listContainer: {
    borderLeftWidth: 1,
    paddingLeft: 10,
    width: "100%",
  },
  horizontalScroll: {
    width: "100%",
    marginVertical: 2,
  },
  title: {
    fontFamily: family.normal,
    fontSize: 10,
  },
});
