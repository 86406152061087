// React && Libraries
import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { FontAwesome } from "@expo/vector-icons";

// Constants
import { colors, size, family, RADIUS } from "../../constants/theme";

const TextButton = ({
  onPress,
  icon,
  big = true,
  title,
  subtitle,
  selected = false,
  buttonColor=colors.white,
  textColor=colors.brown,
  ...props
}) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={[
        styles.dateButton,
        {backgroundColor: buttonColor},
        selected && { backgroundColor: colors.grey },
        { ...props },
      ]}
    >
      <View style={{ width: 30, alignItems: "center" }}>
        <FontAwesome name={icon} size={big ? 22 : 18} color={textColor} />
      </View>
      <View style={styles.textContainer}>
        <Text
          style={[
            styles.dateButtonTitle,
            {color:textColor},
            big && { fontSize: size.medium, fontFamily: family.normal },
          ]}
        >
          {title}
        </Text>
        {subtitle && (
          <Text
            style={[
              styles.dateButtonTitle,
              {color:textColor},
              { marginTop: 4, fontFamily: family.normal },
            ]}
          >
            {subtitle}
          </Text>
        )}
      </View>
    </TouchableOpacity>
  );
};

export default TextButton;

const styles = StyleSheet.create({
  dateButton: {
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    marginVertical: 10,
    padding: 8,
    borderRadius: RADIUS,
  },
  textContainer: {
    width: "100%",
    justifyContent: "flex-start",
  },
  dateButtonTitle: {
    fontSize: size.small,
    fontFamily: family.bold,
    width: "100%",
    marginLeft: 12,
  },
});
