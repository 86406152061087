import { baseVersion } from "../../config";
export const commonHeaders = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Connection: "keep-alive",
    "X-Client-Version": baseVersion,
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
};
