import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { colors, family } from "../../../constants/theme";

const PastOrderProductLines = ({ lines }) => {
  return (
    <View>
      {lines?.slice(0, 2).map((item)=>
        <Text style={styles.item} key={item.gid}>
          {item.quantity + "x "}
          {item.name || item.product.name}
        </Text>
      )}
    </View>
  );
};

export default PastOrderProductLines;

const styles = StyleSheet.create({
  item: {
    fontSize: 12,
    color: colors.darkGrey,
    fontFamily: family.normal,
  },
});
