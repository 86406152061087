import React, { useEffect, useState } from "react";
import {
  ScrollView,
  useWindowDimensions,
  StyleSheet,
  View,
} from "react-native";
import { connect } from "react-redux";

// Components
import Screen from "../../components/atoms/Screen";
import HtmlNative from "../../components/atoms/HtmlNative";

// Redux Actions
import { fetchTextualLegal } from "../../redux/config/configActions";
import { family } from "../../constants/theme";
import { Platform } from "react-native";

const ProfileLegalAdviceScreen = ({ legal, fetchTextualLegal }) => {
  const [loading, setLoading] = useState(false);
  const height = useWindowDimensions().height;

  useEffect(() => {
    setLoading(true);
    fetchTextualLegal().then(() => setLoading(false));
  }, []);

  return (
    <Screen>
      <View style={{ height: "100%", width: "100%" }}>
        <ScrollView
          contentContainerStyle={
            Platform.OS === "web"
              ? {
                  height: height - 70,
                }
              : { height: "auto" }
          }
          style={{ marginBottom: 10, paddingHorizontal: 18 }}
          showsVerticalScrollIndicator={false}
        >
          <HtmlNative
            value={
              loading
                ? "<p>Cargando...</p>"
                : legal === null
                ? "<p>Nada que mostrar</p>"
                : legal
            }
            style={
              "p{color: #424242; font-family: 'Raleway', sans-serif, font-size: 14, line-height:15}"
            }
            styleSheet={styles.descriptionText}
          />
        </ScrollView>
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  descriptionText: {
    fontFamily: family.normal,
    fontSize: 12,
    lineHeight: 15,
    height: "auto",
  },
});

const mapStateToProps = (state) => {
  return { legal: state.config.textualPages.legal };
};

const mapDispatchToProps = {
  fetchTextualLegal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileLegalAdviceScreen);
