import React from "react";
import StyleSheet from "react-native-media-query";
import { ImageBackground, TouchableOpacity, Text, View } from "react-native";

// Constants
import { family, size } from "../../../constants/theme";

// Utils
import { getFormattedPrice } from "../../../utils/productMethods";
import i18n from "../../../i18n/i18n";

const TicketItem = ({ data, onPress }) => {
  const { gid, active, image, amount, name, short_description } = data;
  return (
    <TouchableOpacity
      style={styles.container}
      dataSet={{ media: ids.container }}
      onPress={() => onPress(gid)}
    >
      <ImageBackground
        style={styles.image}
        source={image ? { uri: image } : require("../../../assets/ticket.jpeg")}
        imageStyle={{ borderRadius: 10 }}
      >
        <View style={styles.amountWrapper}>
          <Text style={styles.text}>
            {i18n.t("tickets.from") + " " + getFormattedPrice(amount)}
          </Text>
        </View>
        <View style={styles.infoWrapper}>
          <View style={styles.textWrapper}>
            <Text style={styles.title}>{name}</Text>
          </View>
          <View style={styles.textWrapper}>
            <Text style={styles.text} numberOfLines={2}>
              {short_description}
            </Text>
          </View>
        </View>
      </ImageBackground>
    </TouchableOpacity>
  );
};

export default TicketItem;

const { ids, styles } = StyleSheet.create({
  container: {
    width: 200,
    height: 200,
    borderRadius: 10,
    marginVertical: 30,
    marginRight: 30,
    backgroundColor: "rgba(0,0,0,0.7)",
    "@media (max-width: 480px)": {
      marginRight: 0,
    },
  },
  amountWrapper: {
    maxWidth: 140,
    height: 30,
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    top: 10,
    left: 50,
    backgroundColor: "rgba(0,0,0,0.7)",
    borderRadius: 10,
    paddingTop: 4,
  },
  text: {
    fontFamily: family.normal,
    fontSize: size.tiny,
    color: "white",
  },
  textWrapper: {
    width: "100%",
    paddingHorizontal: 10,
    justifyContent: "center",
    alignItems: "flex-start",
    marginVertical: 2,
  },
  title: {
    fontFamily: family.bold,
    fontSize: size.small,
    color: "white",
  },
  info: {
    fontFamily: family.normal,
    fontSize: size.small,
    color: "white",
    textAlign: "center",
  },
  image: {
    width: "100%",
    height: "100%",
    resizeMode: "cover",
    position: "absolute",
  },
  infoWrapper: {
    width: 200,
    position: "relative",
    paddingVertical: 15,
    justifyContent: "space-between",
    top: 70,
    height: 100,
    backgroundColor: "rgba(0,0,0,0.7)",
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },

  container2: {
    width: "100%",
    height: 100,
    borderRadius: 10,
    marginVertical: 30,
    marginRight: 30,
    backgroundColor: "rgba(0,0,0,0.7)",
  },
  opac: {
    backgroundColor: "rgba(0,0,0,0.7)",
    width: "100%",
    height: "100%",
    flexDirection: "row",
  },
  amountWrapper2: {
    width: 70,
    height: "100%",
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
  },
  infoWrapper2: {
    flex: 1,
    paddingVertical: 15,
    justifyContent: "space-between",
    height: "100%",
  },
});
