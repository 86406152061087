export const onSignIn = ({ data, onAuth, onError = () => {} }) => {
  onError();
  if (data?.status === "success") {
    if (onAuth) {
      onAuth();
    }
  } else if (data?.status === "fail") {
    onError(data?.data);
  } else {
    onError("No se pudo establecer conexión con el servidor");
  }
};
