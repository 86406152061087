import React from "react";
import { Text, View } from "react-native";
import StyleSheet from "react-native-media-query";

// Contants
import { colors, family } from "../../../../../constants/theme";

const PrecomprasTag = () => {
  return (
    <View style={styles.tag} dataSet={{ media: ids.tag }}>
      <Text style={styles.text} dataSet={{ media: ids.text }}>
        Preventas
      </Text>
    </View>
  );
};

export default PrecomprasTag;

const { ids, styles } = StyleSheet.create({
  tag: {
    borderRadius: 8,
    backgroundColor: colors.lightGreen,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    flexShrink: 1,
    flexGrow: 0,
    paddingVertical: 3,
    paddingHorizontal: 6,
    "@media (max-height: 700px) or (max-width: 340px)": {
      borderRadius: 6,
    },
  },
  text: {
    fontFamily: family.normal,
    fontSize: 15,
    color: colors.white,
    "@media (max-height: 700px) or (max-width: 340px)": {
      fontSize: 10,
    },
  },
});
