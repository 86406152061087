import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { StyleSheet, View } from "react-native";

// Components
import ScheduleButton from "../../../live/ConfirmOrderScreen/sections/ScheduleSection/ScheduleButton";
import Divider from "../../../../components/atoms/Divider";
import ScheduleModal from "../modals/ScheduleModal";

// Methods
import { fetchScheduleInfo } from "../methods/fetchScheduleInfo";
import { getShopSchedules } from "../methods/getShopSchedules";

// Redux
import { setDate } from "../../../../redux/laterCart/laterCartActions";
import { setOrderType } from "../../../../redux/laterCart/laterCartActions";
import { ActivityIndicator } from "react-native";
import { colors } from "../../../../constants/theme";

const ScheduleSection = ({
  orders,
  setOrderType,
  orderType,
  serviceTypeId,
  locationId,
  date,
  setDate,
}) => {
  const [modal, setModal] = useState();
  const [loading, setLoading] = useState(false);
  const [schedules, setSchedules] = useState();
  const [canSchedule, setCanSchedule] = useState(false);

  const setUpSchedules = async () => {
    setLoading(true);
    try {
      const response = await fetchScheduleInfo({
        id: orders[0].shopId,
        service: serviceTypeId,
        locId: locationId,
      });

      const { available, slots } = response;
      setSchedules(slots);
      setCanSchedule(available);
    } catch (error) {
      console.log("error getting schedules");
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  useEffect(() => {
    setUpSchedules();
    if (!orderType) {
      setOrderType("ASAP");
      setDate(null);
    }
  }, []);

  const asapHandler = () => {
    setOrderType("ASAP");
    setDate(null);
  };

  const scheduleHandler = () => {
    setModal("Schedule");
  };

  return (
    <>
      <View style={styles.container}>
        {loading ? (
          <ActivityIndicator size="small" color={colors.secondary} />
        ) : (
          <View style={styles.row}>
            <ScheduleButton
              title={"Lo antes posible"}
              active={orderType === "ASAP"}
              onPress={asapHandler}
            />
            {canSchedule && <Divider width={10} />}
            {canSchedule && (
              <ScheduleButton
                title={"Programar pedido"}
                subtitle={getShopSchedules(date)}
                active={orderType === "Scheduled"}
                onPress={scheduleHandler}
              />
            )}
          </View>
        )}
      </View>
      <ScheduleModal
        visible={modal === "Schedule"}
        setModal={setModal}
        slots={schedules}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    orderType: state.laterOrders.orderType,
    orders: state.laterOrders.orders,
    serviceTypeId: state.laterOrders.serviceTypeId,
    locationId: state.laterOrders.locationId,
    date: state.laterOrders.date,
  };
};
const mapDispatchToProps = {
  setOrderType,
  setDate,
};

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    width:'100%'
  },
  container: {
    height: 60,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    paddingHorizontal: 18,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleSection);
