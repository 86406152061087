import React from "react";
import { TouchableOpacity, useWindowDimensions } from "react-native";
import StyleSheet from "react-native-media-query";

// Components
import EventImage from "./EventImage";

const Wrapper = ({ onPress, image, children }) => {
  const width = useWindowDimensions().width;
  return (
    <TouchableOpacity
      style={[styles.wrapper, { width: width > 400 ? 368 : width - 32 }]}
      onPress={onPress}
      dataSet={{ media: ids.wrapper }}
    >
      <EventImage image={image} />
      {children}
    </TouchableOpacity>
  );
};

export default Wrapper;

const {ids, styles} = StyleSheet.create({
  wrapper: {
    height: 190,
    borderRadius: 15,
    "@media (max-height: 700px) or (max-width: 340px)": {
      height: 150,
      borderRadius: 12,
    },
  },
});
