import React from "react";
import { Image, View, StyleSheet } from "react-native";

const ShopImage = (props) => {
  return (
    <>
      <Image
        style={styles.image}
        source={
          props?.image
            ? { uri: props.image }
            : require("../../../../../assets/placeholderItem.png")
        }
      />
      <View style={styles.opacity} />
    </>
  );
};

const styles = StyleSheet.create({
  image: {
    position: "absolute",
    width: "100%",
    height: "100%",
    resizeMode: "cover",
    borderRadius: 15,
    justifyContent: "center",
    alignItems: "center",
  },
  opacity: {
    borderRadius: 15,
    position: "absolute",
    width: "100%",
    top: 0,
    left: 0,
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.2)",
  },
});

export default ShopImage;
