import { Dimensions } from "react-native";

export const colors = {
  primaryColor: "#F75353",
  lightPrimary: "#F65353",
  lightenPrimary: "#ffc5cc",
  secondary: "#009682",
  darkPrimary: "#610000",
  secondary: "#009682",
  accentColor: "#FFB740",
  grey: "#C4C4C4",
  lightenGrey: "#eeeeee",
  darkGrey: "#7E8389",
  white: "#FFFFFF",
  brown: "#424242",
  green: "#009683",
  lightGreen: "#4AA81F",
  lightGreen2: "#A2E483",
  lightenGreen: "#D2F3C2",
  yellow: "#FFD37A",
  lightRed: "#FFD1D1",
  blue: "#C7DDFF",
  orange: "#FFD683",
  red: "#C61414"
};


export const size = {
  tiny: 12,
  small: 14,
  medium: 16,
  normal: 18,
  big: 20,
  bigger: 28,
  huge: 32,
};

export const family = {
  bold: "nunito-bold",
  normal: "nunito-book",
  light: "nunito-light",
};

export const width = Dimensions.get("window").width;
export const height = Dimensions.get("window").height;

export const RADIUS = 8;
