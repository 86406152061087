export const getStatusText = (status) => {
  switch (status) {
    case "completed":
      return "Completado";
    case "cancelled":
      return "Cancelado";
    case "reimbursed_pending":
      return "Reembolso pendiente";
    case "reimbursed":
      return "Reembolsado";
    case "failed_reimbursement":
      return "Reembolso fallido";
    default:
      null;
  }
};
