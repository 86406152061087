import React, { useEffect } from "react";
import { Platform } from "react-native";

const lottie = Platform.OS === "web" && require("lottie-web");

const AnimationWeb = ({
  animation,
  name,
  style,
  loop = true,
  autoPlay = true,
  onLoading = () => {},
}) => {
  useEffect(() => {
    if (Platform.OS === "web") {
      onLoading();
      lottie.loadAnimation({
        container: document.querySelector(`#${name}`),
        animationData: animation,
        loop: loop,
        autoplay: autoPlay,
      });
    }
  }, [name]);

  return <div id={`${name}`} style={style} />;
};

export default AnimationWeb;
