export const stages = {
  scheduled: [
    { title: "Recibido" },
    { title: "Preparando", big: true },
    { title: "Listo" },
  ],
  waiting: [
    { title: "Recibido", current: true },
    { title: "Preparando", big: true },
    { title: "Listo" },
  ],
  processing: [
    { title: "Recibido", active: true },
    { title: "Preparando", current: true, big: true },
    { title: "Listo" },
  ],
  await_shipment: [
    { title: "Recibido", active: true },
    { title: "Preparando", current: true, big: true },
    { title: "Listo" },
  ],
  parcially_delivered: [
    { title: "Recibido", active: true },
    { title: "Preparando", current: true, big: true },
    { title: "Listo" },
  ],
  await_pickup: [
    { title: "Recibido", active: true },
    { title: "Preparando", active: true, big: true },
    { title: "Listo", current: true },
  ],
  shipped: [
    { title: "Recibido", active: true },
    { title: "Preparando", active: true, big: true },
    { title: "Enviado", current: true },
  ],
};
