import React, { useState, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { ScrollView } from "react-native";

// Redux Actions
import { addProduct } from "../../../redux";

// Components
import Screen from "../../../components/atoms/Screen";
import Variants from "../../../components/organisms/products/VariablesTab/Variants";
import Ingredients from "../../../components/organisms/products/VariablesTab/Ingredients";
import WarningModal from "../../../components/organisms/modals/WarningModal";
import Description from "./components/Description";
import Allergens from "./components/Allergens";
import Header from "./components/Header";
import Footer from "./components/Footer";

// Utils
import { getSingleProductCost } from "../../../utils/productMethods";

// Methods
import { getCustomStyle } from "./methods/getCustomStyle";
import { hasVariants } from "./methods/hasVariants";

const MealVariablesScreen = ({ navigation, route, addProduct }) => {
  const {
    data,
    onPress,
    setSelections,
    shopData,
    menuData,
    justMenu,
    currentQuantity,
  } = route.params;

  const {
    long_description,
    name,
    allergens,
    image,
    variants,
    ingredients,
    only_legal_age,
    not_pay_commission,
    increment,
    not_pay_service,
    fk_point_sale,
    exclusive_order,
    product_type,
    amount,
    gid,
    ingredients_group_name,
    stock,
  } = data;

  const { customStyle, customHeaderStyle } = getCustomStyle(shopData);
  const [ingredientsList, setIngredients] = useState([]);
  const [variantsList, setVariants] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [modal, setModal] = useState();

  useEffect(() => {
    navigation.setOptions({ style: customHeaderStyle });
  }, []);

  const addProductHandler = () => {
    addProduct({
      shop: shopData,
      family: data.family,
      fk_point_sale: fk_point_sale,
      gid: gid,
      only_legal_age: only_legal_age,
      not_pay_commission: not_pay_commission,
      increment: increment,
      not_pay_service: not_pay_service,
      exclusive_order: exclusive_order,
      type: product_type,
      name: name,
      amount: amount,
      quantity: quantity,
      variants: variantsList,
      ingredients: ingredientsList,
      menuData: menuData,
    });
  };

  const confirmButtonHandler = () => {
    if (shopData?.just_menu !== 1) {
      if (
        stock === null ||
        stock === undefined ||
        currentQuantity + quantity <= stock
      ) {
        addProductHandler();
        navigation.pop();
        if (onPress) {
          setSelections((prev) => prev + 1);
          onPress();
        }
      } else {
        setModal("Max");
      }
    } else {
      navigation.pop();
    }
  };

  const handleAdd = () => {
    if (currentQuantity + quantity < stock || stock === null) {
      setQuantity(quantity + 1);
    } else {
      setModal("Max");
    }
  };

  const handleRemove = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  return (
    <Screen>
      <View style={styles.container}>
        <Header image={image} navigation={navigation} title={name} />
        <View style={styles.scrollWrapper}>
          <ScrollView
            showsVerticalScrollIndicator={false}
            style={styles.scroll}
          >
            <Allergens allergens={allergens} />
            <Description
              description={long_description}
              customStyle={customStyle}
            />

            {hasVariants(variants) && (
              <Variants
                data={variants}
                setVariants={setVariants}
                variantsList={variantsList}
                color={customStyle.custom_color_2}
                textColor={customStyle.custom_color_letter_2_actions}
                actionColor={customStyle.custom_color_letter_2}
              />
            )}
            {ingredients?.length > 0 && (
              <Ingredients
                title={ingredients_group_name}
                data={ingredients}
                setIngredients={setIngredients}
                ingredientsList={ingredientsList}
                color={customStyle.custom_color_letter_2_actions}
              />
            )}
            <View style={{ height: 150 }}></View>
          </ScrollView>
          {!justMenu && (
            <Footer
              onAdd={handleAdd}
              onRemove={handleRemove}
              qty={quantity || 0}
              increment={0}
              amount={getSingleProductCost(amount, variantsList)}
              confirmButtonHandler={confirmButtonHandler}
              showActions={menuData ? false : true}
              actionBackgroundColor={customStyle.custom_color_2}
              backgroundColor={customStyle.custom_color_1}
              textColor={customStyle.custom_color_letter_2}
              textActionColor={customStyle.custom_color_letter_2_actions}
            />
          )}
        </View>
      </View>
      <WarningModal
        visible={modal === "Max"}
        setModal={setModal}
        text="Se ha alcanzado el máximo de existencias"
      />
    </Screen>
  );
};

const mapStateToProps = (state) => {
  return {
    orders: state.cart.orders,
  };
};

const mapDispatchToProps = {
  addProduct,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MealVariablesScreen);

const styles = StyleSheet.create({
  container: {
    height: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
  scroll: {
    width: "100%",
    paddingTop: 12,
    paddingBottom: 20,
    paddingHorizontal: 18,
    height: 1,
  },
  image: {
    height: 180,
    width: "100%",
    resizeMode: "cover",
  },
  scrollWrapper: {
    flex: 1,
    width: "100%",
  },
});
