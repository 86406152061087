import React, { useState, useContext } from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";

// Components
import AdyenPayment from "../../../../../adyen/components/AdyenPayment";
import PaymentModal from "../../modals/PaymentModal";
import CustomAlert from "../../modals/CustomAlert";
import UserUpModal from "../../modals/UserUpModal";

// Utils
import { getAlerts, getLegalAge } from "../../../../../utils/orderMethods";

// Methods
import needAnythingLeft from "../../methods/needAnythingLeft";

// Redux actions
import { setTotalToPay } from "../../../../../redux/cart/cartActions";

import { AuthContext } from "../../../../../contexts/AuthContext";

const ConfirmButton = ({
  totalAmount,
  customStyle,
  cart,
  navigation,
  setTotalToPay,
}) => {
  const { user: firebaseUser } = useContext(AuthContext);
  const [modal, setModal] = useState();
  const [alertData, setAlertData] = useState();
  const { primaryColor, backgroundColor } = customStyle;

  const buttonHandler = async () => {
    const alerts = getAlerts(cart.orders, "finish_checkout");
    let hasNeededLog;

    const finishProcess = () => {
      setTotalToPay(totalAmount);
      const anyNeed = needAnythingLeft(cart);
      if (!firebaseUser && hasNeededLog !== true) {
        setModal("Alert");
        setAlertData({
          disableVisible: true,
          title:
            "En este momento necesitas iniciar sesión o registrarte para continuar",
          actions: [
            {
              onPress: () => setModal(),
              title: "Cancelar",
            },
            {
              onPress: () => setModal("ForcedLogin"),
              title: "Accede",
            },
          ],
        });
      } else {
        if (anyNeed) {
          setModal("Alert");
          setAlertData({
            title: anyNeed,
          });
        } else {
          setModal("AdyenModal");
        }
      }
    };

    const checkForAlerts = () => {
      if (alerts !== false) {
        setAlertData({
          title: alerts.title,
          message: alerts.message,
          action: finishProcess,
          disableVisible: true,
        });
        setModal("Alert");
      } else {
        finishProcess();
      }
    };

    if (getLegalAge(cart.orders)) {
      setAlertData({
        title: "Cuentas con productos para mayores de edad",
        subtitle: "¿Eres mayor de edad?",
        actions: [
          { title: "Sí", onPress: checkForAlerts },
          { title: "No", onPress: () => setModal() },
        ],
        disableVisible: true,
      });
      setModal("Alert");
    } else {
      // Proceed to attempt payment
      checkForAlerts();
    }
  };

  return (
    <>
      <View style={styles.confirmWrapper}>
        <AdyenPayment
          navigation={navigation}
          color={primaryColor}
          textColor={backgroundColor}
          amount={totalAmount}
          buttonHandler={buttonHandler}
          active
        />
      </View>
      <PaymentModal
        visible={modal === "AdyenModal"}
        setModal={setModal}
        amount={totalAmount}
        color={primaryColor}
        textColor={backgroundColor}
        navigation={navigation}
        totalToPay={cart.totalToPay}
      />
      <CustomAlert
        visible={modal === "Alert"}
        setModal={setModal}
        alertData={alertData}
        customStyle={customStyle}
      />
      <UserUpModal
        canBeClosed={modal === "ForcedLogin"}
        navigation={navigation}
        visible={modal === "ForcedLogin"}
        setModal={setModal}
        color={primaryColor}
        textColor={backgroundColor}
      />
    </>
  );
};

const styles = StyleSheet.create({
  confirmWrapper: {
    width: "100%",
    height: 66,
    paddingHorizontal: 18,
  },
});

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
  };
};

const mapDispatchToProps = {
  setTotalToPay,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmButton);
