import React, { useState } from "react";
import { StyleSheet, Text } from "react-native";
import { TouchableOpacity } from "react-native";
import { colors, family } from "../../../../constants/theme";

const Button = ({ onClick, title }) => {
  const [loading, setLoading] = useState(false);
  const buttonHandler = () => {
    try {
      setLoading(true);
      onClick();
    } catch (error) {
      console.log("ButtonHandler error", error.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <TouchableOpacity style={styles.container} onPress={buttonHandler}>
      <Text style={styles.title}>{title}</Text>
    </TouchableOpacity>
  );
};

export default Button;

const styles = StyleSheet.create({
  container: {
    minHeight: 40,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.lightPrimary,
    borderRadius: 50,
    paddingHorizontal: 15,
  },
  title: {
    fontFamily: family.bold,
    fontSize: 18,
    color: colors.white,
  },
});
