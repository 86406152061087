import React, { useState } from "react";
import { connect } from "react-redux";
import {
  TouchableOpacity,
  View,
  Text,
  StyleSheet,
  useWindowDimensions,
} from "react-native";
import { FontAwesome5 } from "@expo/vector-icons";

// Components
import Divider from "../../../../components/atoms/Divider";
import CommentModal from "../modals/CommentModal";

// Constants
import { colors, family } from "../../../../constants/theme";

const CommentSection = ({ customStyle, comment }) => {
  const width = useWindowDimensions().width;
  const [modal, setModal] = useState();

  const getCommentSubtitle = () => {
    return comment !== "" ? [comment] : [];
  };

  return (
    <>
      <View style={styles.container}>
        <TouchableOpacity
          style={styles.textContainer}
          onPress={() => setModal("Comment")}
        >
          <FontAwesome5
            name="comment-dots"
            size={width > 320 ? 16 : 12}
            color={customStyle?.accentColor || colors.secondary}
          />
          <Divider width={10} />
          <Text
            style={[
              styles.title,
              { color: customStyle?.accentColor || colors.secondary },
            ]}
          >
            ¿Algún comentario?
          </Text>
        </TouchableOpacity>
        {getCommentSubtitle().length > 0 && (
          <>
            <Divider height={2} />
            {getCommentSubtitle().map((text) => (
              <Text
                numberOfLines={1}
                style={[
                  styles.subtitle,
                  { color: customStyle?.textColor || colors.brown },
                ]}
              >
                {text}
              </Text>
            ))}
          </>
        )}
      </View>
      <CommentModal
        visible={modal === "Comment"}
        setModal={setModal}
        customStyle={customStyle}
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: 12,
    paddingHorizontal: 18,
    flexDirection: "column",
  },
  textContainer: {
    flexDirection: "row",
  },
  title: {
    fontFamily: family.bold,
    fontSize: 14,
  },
  subtitle: {
    marginBottom: 2,
    fontFamily: family.normal,
    fontSize: 12,
  },
});

const mapStateToProps = (state) => {
  return {
    comment: state.cart.comment,
  };
};
export default connect(mapStateToProps)(CommentSection);
