import React, { useEffect, useState } from "react";
import StyleSheet from "react-native-media-query";
import { Text, View, FlatList } from "react-native";
import { connect } from "react-redux";

// Components
import TicketItem from "../../components/tickets/components/TicketItem";
import TicketScreen from "../../components/tickets/components/TicketScreen";
import ShopHero from "../../components/tickets/containers/ShopHero";
import NoContentTickets from "../../components/tickets/components/NoContentTickets";

// Constants
import { family, size, width } from "../../constants/theme";

// Hooks
import useForceRender from "../../hooks/useForceRender";

// Redux
import { fetchShopTickets, setShopCheckoutData } from "../../redux";

const TicketsShopScreen = ({
  shopDetails,
  navigation,
  route,
  setShopCheckoutData,
  fetchShopTickets,
}) => {
  const [forceRender] = useForceRender();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const ticketClickHandler = (ticket) => {
    navigation.navigate("TicketDetailScreen", { ticketId: ticket.gid });
  };

  const columns = () => {
    if (width > 1440) {
      return 5;
    } else {
      return Math.floor((width - 100) / 220);
    }
  };
  const numColumns = columns();

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const id = route.params?.shopId;
        if (id) {
          await fetchShopTickets(id);
        }
      } catch (err) {
        console.log("error fetching ticket shop data:", err);
        setError("Algo no ha ido como debería");
      } finally {
        setLoading(false);
      }
    };
    getData();
    forceRender();
  }, []);

  useEffect(() => {
    if (shopDetails) {
      if (shopDetails?.gid) {
        setShopCheckoutData(shopDetails);
      }
      if (
        shopDetails?.pixel_code !== null &&
        shopDetails?.pixel_code !== undefined
      ) {
        var script = document.createElement("x");
        script.type = "text/javascript";
        script.innerHTML = shopDetails?.pixel_code;

        document.getElementsByTagName("head")[0].appendChild(script);
      }
    }
  }, [shopDetails]);

  return (
    <TicketScreen navigation={navigation} shopId={shopDetails?.gid}>
      <ShopHero
        title={shopDetails?.name}
        loading={loading}
        subtitle={shopDetails?.description}
        logo={shopDetails?.custom_design?.custom_logo}
        image={shopDetails?.image}
        imageHeader={shopDetails?.image_header}
      />
      <View style={styles.scrollWrapper}>
        {loading ? (
          <NoContentTickets loading />
        ) : error ? (
          <NoContentTickets text={error} />
        ) : shopDetails?.length === 0 ? (
          <NoContentTickets text={""} />
        ) : (
          <View style={styles.content} dataSet={{ media: ids.content }}>
            {shopDetails?.tickets.map((category) => {
              return (
                <View key={category.gid}>
                  {shopDetails.tickets.length > 1 && (
                    <View style={styles.textWrapper}>
                      <Text style={styles.categoryText}>{category.name}</Text>
                    </View>
                  )}

                  <FlatList
                    dataSet={{ media: ids.listStyle }}
                    data={category.tickets}
                    keyExtractor={(item) => item.gid}
                    renderItem={(item) => (
                      <TicketItem
                        data={item.item}
                        onPress={() => ticketClickHandler(item.item)}
                      />
                    )}
                    numColumns={numColumns}
                    style={styles.listStyle}
                    showsVerticalScrollIndicator={false}
                  />
                </View>
              );
            })}
          </View>
        )}
      </View>
    </TicketScreen>
  );
};

const { ids, styles } = StyleSheet.create({
  categoryText: {
    fontFamily: family.bold,
    color: "white",
    fontSize: size.big,
  },
  listStyle: {
    width: "100%",
    "@media (max-width: 480px)": {
      alignItems: "center",
      justifyContent: "center",
    },
  },
  scrollWrapper: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "black",
    paddingBottom: 20,
    paddingTop: 50,
  },
  content: {
    width: "100%",
    maxWidth: 1400,
    justifyContent: "center",
    paddingHorizontal: 50,
    "@media (max-width: 500px)": {
      paddingHorizontal: 20,
      // alignItems: "center",
    },
  },
});

const mapDispatchToProps = {
  fetchShopTickets,
  setShopCheckoutData,
};
const mapStateToProps = (state) => {
  return {
    shopDetails: state.tickets.shopDetails,
    id: state.tickets.ticketShop,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketsShopScreen);
