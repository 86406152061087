import React from "react";
import StyleSheet from "react-native-media-query";
import { View } from "react-native";

// Components
import NothingSelected from "./NothingSelected";
import OrderContainer from "./OrderContainer";

const TicketOrder = ({ ...props }) => {
  const { ticketLines, productLines } = props;
  const hasSelected = ticketLines?.length > 0 || productLines?.length > 0;

  return (
    <View
      style={[
        styles.container,
        ticketLines.length > 0 && { justifyContent: "flex-start" },
      ]}
      dataSet={{ media: ids.container }}
    >
      {hasSelected ? (
        <OrderContainer orderData={props} />
      ) : (
        <NothingSelected />
      )}
    </View>
  );
};

export default TicketOrder;

const { ids, styles } = StyleSheet.create({
  container: {
    width: "50%",
    flexDirection: "column",
    "@media (max-width: 700px)": {
      width: "100%",
    },
  },
});
