import * as Location from "expo-location";
import { Platform } from "react-native";
import { Alert } from "react-native";

export const handlePermissions = async () => {
  if (Platform.OS === "web") {
    return true;
  } else {
    const { status } = await Location.requestForegroundPermissionsAsync();
    const { locationServicesEnabled } = await Location.getProviderStatusAsync();

    if (status === "granted" && locationServicesEnabled === true) {
      return true;
    } else if (status !== "granted") {
      Alert.alert(
        "Algo salió mal",
        `No se cuenta con permisos de localización, status: ${status}`
      );
      return false;
    } else if (locationServicesEnabled !== true) {
      Alert.alert("Algo salió mal", "Activa la localización de tu dispositivo");
      return false;
    }
    return false;
  }
};

export const getGeoCode = async (coords) => {
  const { latitude, longitude } = coords;

  const address = await Location.reverseGeocodeAsync({
    latitude,
    longitude,
  });

  return address
    ? `${address[0].street || address[0].name}, ${
        address[0].city || address[0].subregion
      }`
    : "";
};
