import React from "react";
import { Text } from "react-native";

import { colors, size, family } from "../../constants/theme";

const Title = ({ big, light, multiline, color = colors.darkGrey, title, ...props }) => {
  return (
    <Text
      style={{
        fontFamily: light ? family.normal : family.bold,
        fontSize: big ? size.big : size.normal,
        textAlign: 'center',
        color: color,
      }}
      {...props}
      numberOfLines={multiline ? null : 1}
    >
      {title}
    </Text>
  );
};

export default Title;
