import React from "react";
import { StyleSheet, View } from "react-native";

// Components
import IngredientsText from "../../../components/ProductItem/IngredientsText";
import PlatesText from "../../../components/ProductItem/PlatesText";
import VariantsText from "../../../components/ProductItem/VariantsText";

// Constants
import { colors, RADIUS } from "../../../../../../constants/theme";

const ProductDetails = ({ product, customStyle }) => {
  return (
    <View style={styles.detailsColumn}>
      <IngredientsText data={product?.ingredients} customStyle={customStyle} />
      <VariantsText data={product?.variants} customStyle={customStyle} />
      <PlatesText data={product?.plates} customStyle={customStyle} />
    </View>
  );
};

export default ProductDetails;

const styles = StyleSheet.create({
  detailsColumn: {
    paddingHorizontal: 18,
    paddingVertical: 10,
    width: "100%",
    justifyContent: "flex-start",
    flexDirection: "column",
    borderRadius: RADIUS,
    backgroundColor: colors.lightenGrey,
  },
});
