import { Platform } from "react-native";

import { handlePermissions } from "../../../utils/locationMethods";
import {
  cargarNotificacionesWeb,
  registerForPushNotificationsAsync,
} from "../../../utils/notificationsMethods";

const steps = [
  {
    asset: 1,
    title: `Pide y paga desde la app tu comida y bebida favoritas`,
    subtitle: "#NoHagasCola",
    name: "food",
    action: () => {},
  },
  {
    asset: 2,
    title: `Encuentra la oferta gastronómica cercana a ti`,
    action: Platform.OS !== "web" ? handlePermissions : () => {},
    subtitle: "¡Activa la localización!",
  },
  {
    asset: 3,
    title: `Ten activas las notificaciones para recibir tu comida`,
    subtitle: "¡Activa las notificaciones!",
    action: registerForPushNotificationsAsync,
  },
];
export default steps;
