import React from "react";
import { StyleSheet, View, Text } from "react-native";

// Components
import Screen from "../../../components/atoms/Screen";
import Header from "./Header";

// Constants
import { family } from "../../../constants/theme";

const ErrorScreen = ({ navigation, error, image, name }) => {
  return (
    <Screen hasFooter header={"hidden"} hideActions>
      <Header image={image} navigation={navigation} name={name} />
      <View style={styles.container}>
        <Text style={styles.error}>{error}</Text>
      </View>
    </Screen>
  );
};

export default ErrorScreen;

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  error: {
    fontFamily: family.normal,
    fontSize: 14,
    textAlign: "center",
  },
});
