import React from "react";
import Svg, { Path } from "react-native-svg";

const LiveIcon = (props) => {
	return (
		<Svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 30 30"
			fill="black"
			{...props}
		>
			<Path d="M15 18.3c-1.8 0-3.3-1.5-3.3-3.3s1.5-3.3 3.3-3.3 3.3 1.5 3.3 3.3-1.5 3.3-3.3 3.3zm0-4.6c-.7 0-1.3.6-1.3 1.3s.6 1.3 1.3 1.3 1.3-.6 1.3-1.3-.6-1.3-1.3-1.3zM10.3 19.5c-.4 0-.7-.2-.9-.5-.7-1.3-1-2.6-1-4.1s.4-2.8 1-4.1c.2-.5.8-.6 1.3-.4.5.2.7.7.4 1.2-.5 1-.8 2.1-.8 3.3s.3 2.3.8 3.3c.2.5 0 1-.5 1.2 0 .1-.2.1-.3.1z" />
			<Path d="M7.4 21.8c-.4 0-.7-.2-.9-.5-1-2-1.5-4.1-1.5-6.3s.5-4.3 1.6-6.2c.2-.5.8-.6 1.3-.5.5.2.6.8.4 1.3C7.4 11.2 7 13 7 15c0 1.9.5 3.8 1.4 5.4.2.5 0 1-.5 1.2-.2.1-.3.2-.5.2z" />
			<Path d="M4.6 24c-.4 0-.7-.2-.9-.5C2.3 20.9 1.6 18 1.6 15s.7-6 2.1-8.5c.2-.5.8-.6 1.3-.4.5.2.7.7.5 1.2-1.3 2.3-2 5-2 7.7s.7 5.3 1.9 7.7c.2.5 0 1-.5 1.2 0 0-.2.1-.3.1zM19.8 19.6c-.1 0-.3 0-.4-.1-.5-.2-.7-.7-.5-1.2.5-1 .8-2.1.8-3.3s-.3-2.3-.8-3.3c-.2-.5 0-1 .5-1.2.5-.2 1.1 0 1.3.5.7 1.3 1 2.6 1 4.1s-.4 2.8-1 4.1c-.2.2-.5.4-.9.4zM22.7 21.8c-.1 0-.3 0-.4-.1-.5-.2-.7-.7-.5-1.2.9-1.6 1.4-3.5 1.4-5.4s-.5-3.8-1.3-5.4c-.2-.5 0-1 .5-1.2.5-.2 1.1 0 1.3.5 1 1.9 1.6 4 1.6 6.2s-.5 4.4-1.6 6.2c-.3.2-.7.4-1 .4z" />
			<Path d="M25.5 24c-.1 0-.3 0-.4-.1-.5-.2-.7-.7-.5-1.2 1.3-2.3 1.9-5 1.9-7.7s-.7-5.3-1.9-7.7c-.2-.5 0-1 .5-1.2.5-.2 1.1 0 1.3.5 1.4 2.5 2.1 5.4 2.1 8.5 0 3-.7 5.9-2.1 8.4-.2.3-.5.5-.9.5z" />
		</Svg>
	);
};

export default LiveIcon;
