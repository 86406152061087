import React, { useState, useRef, useEffect } from "react";
import { View, StyleSheet, Animated, Easing } from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import { TouchableOpacity } from "react-native";

const AccordionListItem = ({
  title,
  children,
  backgroundColor = "white",
  opened=false,
  hasIcon = true,
  hasPadding = true,
  accordionSwitch,
  setListener=()=>{}
}) => {
  const [open, setOpen] = useState(false);
  const animatedController = useRef(new Animated.Value(0)).current;
  const [bodySectionHeight, setBodySectionHeight] = useState(0);

  let bodyHeight = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: [0, bodySectionHeight],
  });
  let arrowAngle = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: ["0rad", `${Math.PI}rad`],
  });

  useEffect(() => {
    if (open === true && accordionSwitch === true) {
      toggleListItem();
    }
  }, [accordionSwitch]);

  useEffect(() => {
    Animated.timing(animatedController, {
      duration: 300,
      toValue: 0,
      easing: Easing.bezier(0.4, 0.0, 0.2, 1),
      useNativeDriver: false,
    }).start();
    setOpen(false);
    setListener(false)
  }, [opened]);

  const toggleListItem = () => {
    if (open) {
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 0,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
        useNativeDriver: false,
      }).start();
    } else {
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 1,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
        useNativeDriver: false,
      }).start();
    }
    setOpen(!open);
    setListener(!open)
  };

  return (
    <View style={{ width: "100%" }}>
      <TouchableOpacity onPress={() => toggleListItem()}>
        <View
          style={[styles.titleContainer, { backgroundColor: backgroundColor, padding: hasPadding ? 10: 0 }]}
        >
          <View style={{ width: "100%" }}>{title}</View>
          {hasIcon === true && (
            <Animated.View
              style={{ right: 20, transform: [{ rotateZ: arrowAngle }] }}
            >
              <MaterialIcons
                name="keyboard-arrow-down"
                size={20}
                color="black"
              />
            </Animated.View>
          )}
        </View>
      </TouchableOpacity>
      <Animated.View style={[styles.bodyBackground, { height: bodyHeight }]}>
        <View
          style={styles.bodyContainer}
          onLayout={(event) =>
            setBodySectionHeight(event.nativeEvent.layout.height)
          }
        >
          {children}
        </View>
      </Animated.View>
    </View>
  );
};
export default AccordionListItem;

const styles = StyleSheet.create({
  bodyBackground: {
    overflow: "hidden",
    width: "100%",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    backgroundColor: "white",
  },
  bodyContainer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
});
