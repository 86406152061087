import React from "react";
import { StyleSheet, Text, View } from "react-native";

// Components
import ScheduleSlotsContainer from "../components/ScheduleSlotsContainer";
import CustomModal from "../../../../components/atoms/CustomModal";

// Constants
import { colors, family } from "../../../../constants/theme";

const ScheduleModal = ({ visible, setModal, slots }) => {
  return (
    <CustomModal modalVisible={visible} handleModal={setModal} canClose={true}>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>¿Cuándo quieres tu pedido?</Text>
      </View>
      <ScheduleSlotsContainer slots={slots} />
    </CustomModal>
  );
};

export default ScheduleModal;

const styles = StyleSheet.create({
  titleContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: 20,
  },
  title: {
    fontFamily: family.bold,
    color: colors.brown,
    fontSize: 16,
  },
});
