import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { colors, family } from "../../../constants/theme";

const Tag = ({ textColor = colors.white, bgColor = colors.green, text }) => {
  return (
    <View style={[styles.tag, { backgroundColor: bgColor }]}>
      <Text style={[styles.text, { color: textColor }]}>{text}</Text>
    </View>
  );
};

export default Tag;

const styles = StyleSheet.create({
  tag: {
    borderRadius: 5,
    paddingHorizontal: 5,
    paddingVertical: 2,
    minWidth: 80,
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    fontFamily: family.normal,
    fontSize: 13,
  },
});
