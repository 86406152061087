import React from "react";
import { View } from "react-native";

// Components
import Product from "./Products/Product";

const DetailsSection = ({ data }) => {
  return (
    <View>
      {data?.orderLines?.map((item) => {
        return <Product data={item} key={"product" + item.gid} />;
      })}
      {data?.orderLineTickets?.map((item) => {
        return (
          <Product
            data={{ ...item, quantity: 1 }}
            key={"ticket" + item.gid}
            ticket
          />
        );
      })}
    </View>
  );
};

export default DetailsSection;
