import React, { useMemo } from "react";
import { StyleSheet, Text, View } from "react-native";
import { family } from "../../../../../../constants/theme";
import {
  getFormattedPrice,
  shopAmount,
} from "../../../../../../utils/productMethods";

const ShopLine = ({ shop, customStyle }) => {
  const { textColor } = customStyle;

  // const amount = useMemo(() => shopAmount(shop), [shop]);
  const amount = shopAmount(shop)
  const textStyle = [styles.text, { color: textColor }];
  
  return amount > 0 ? (
    <View style={styles.container}>
      <Text style={textStyle}>{shop.shop_name}</Text>
      <Text style={textStyle}>{getFormattedPrice(amount)}</Text>
    </View>
  ) : (
    <View></View>
  );
};

export default ShopLine;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    justifyContent: "space-between",
    marginBottom: 5,
    flexDirection: "row",
  },
  text: {
    fontFamily: family.normal,
    fontSize: 12,
  },
});
