function getHeaderSTyle(shop) {
  const design = shop.custom_design;
  const customHeaderStyle = {
    color: design.custom_color_header,
    textColor: design.custom_color_letter_header,
    customIcon: design.custom_logo,
    iconsColor: design.custom_color_icons_header,
  };
  return customHeaderStyle;
}

export const isShopOpened = (shop) => {
  const noService = shop?.services.filter((el) => el.key === "no_service");
  const isPrecompras = noService.length > 0;
  const abierto =
    (!isPrecompras &&
      (shop?.schedule?.opened === 1 || shop?.schedule?.scheduled === 1)) ||
    (isPrecompras && shop?.schedule?.opened !== 1)
      ? true
      : false;

  return abierto;
};
