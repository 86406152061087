import React from "react";
import Svg, { Path, Circle } from "react-native-svg";

const EmptyOrder = (props) => {
  return (
    <Svg height={256} width={256} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M283.611 159.362h-55.222V12.995a5.494 5.494 0 015.494-5.494h44.234a5.494 5.494 0 015.494 5.494z"
        fill="#017297"
      />
      <Path d="M228.389 139.361h55.222v33.878h-55.222z" fill="#025f80" />
      <Path
        d="M223 489.089a20.472 20.472 0 0118.726-12.2H256L90.334 463.083v36.314a5.102 5.102 0 005.102 5.102h117.433a5.1 5.1 0 004.667-3.041zM288.714 489.871l4.478 11.393a5.102 5.102 0 004.749 3.236h118.623a5.102 5.102 0 005.102-5.102v-36.314L256 476.889h13.662a20.47 20.47 0 0119.052 12.982z"
        fill="#017297"
      />
      <Path
        d="M256 200.778L35.111 214.584l39.528 250.341a14.174 14.174 0 0014.001 11.964h334.72a14.174 14.174 0 0014.001-11.964l39.528-250.341zm-124.25 248.5h-27.611v-96.639h27.611zm0-124.25h-27.611V214.584h27.611zm55.222 124.25h-27.611v-55.222h27.611zm0-179.472h-27.611v-55.222h27.611zm55.223 179.472h-27.611v-55.222h27.611zm0-179.472h-27.611v-55.222h27.611zm55.222 179.472h-27.611v-55.222h27.611zm0-179.472h-27.611v-55.222h27.611zm55.222 179.472h-27.611v-55.222h27.611zm0-179.472h-27.611v-55.222h27.611zm55.222 179.472H380.25v-96.639h27.611zm0-124.25H380.25V214.584h27.611z"
        fill="#e94444"
      />
      <Path
        d="M434.151 211.912l-26.29 166.503v70.863H380.25v-8.34c-12.138 10.014-27.663 15.951-44.401 15.951H73.37l1.269 8.037A14.174 14.174 0 0088.64 476.89h334.72a14.174 14.174 0 0014.001-11.964l39.528-250.341z"
        fill="#d83131"
      />
      <Path
        d="M499.327 214.584H12.673a5.172 5.172 0 01-5.172-5.172v-44.877a5.172 5.172 0 015.172-5.172h486.655a5.172 5.172 0 015.172 5.172v44.877a5.173 5.173 0 01-5.173 5.172z"
        fill="#ee6161"
      />
      <Path d="M186.972 297.417h138.055v69.028H186.972z" fill="#017297" />
      <Path d="M499.327 151.861h-51.629a7.5 7.5 0 000 15H497v40.221H15.001v-40.221h397.696a7.5 7.5 0 000-15H291.112V12.995c0-7.166-5.83-12.995-12.995-12.995h-44.234c-7.165 0-12.994 5.829-12.994 12.995v20.457a7.5 7.5 0 0015 0V15.001h12.61v136.861h-12.61V68.453a7.5 7.5 0 00-15 0v83.409H12.673C5.685 151.861 0 157.546 0 164.534v44.878c0 6.988 5.685 12.672 12.672 12.672h16.03L67.23 466.095c1.361 8.616 7.565 15.312 15.603 17.517v15.785c0 6.949 5.654 12.603 12.603 12.603h117.433c4.988 0 9.513-2.948 11.528-7.511l5.465-12.37a12.978 12.978 0 0111.865-7.731h27.935a12.897 12.897 0 0112.072 8.227l4.479 11.393A12.53 12.53 0 00297.941 512h118.623c6.949 0 12.603-5.654 12.603-12.603v-15.786c8.038-2.204 14.242-8.9 15.603-17.517l38.528-244.011h16.03c6.988 0 12.673-5.685 12.673-12.672v-44.878c-.001-6.987-5.686-12.672-12.674-12.672zM263.5 15.001h12.611v136.861H263.5zm124.25 207.083h12.61v95.444h-12.61zm-55.222 0h12.611v40.221h-12.611zm-55.222 0h12.61v40.221h-12.61zm-55.222 0h12.611v40.221h-12.611zm-55.222 0h12.61v40.221h-12.61zm-55.223 0h12.61v95.444h-12.61zM216.14 486.059L211.306 497H97.834v-12.611h119.11a27.592 27.592 0 00-.804 1.67zM299.575 497l-3.881-9.872a28.648 28.648 0 00-1.246-2.738h119.718V497zm130.378-33.245a6.643 6.643 0 01-6.593 5.633H88.64a6.643 6.643 0 01-6.593-5.633L43.889 222.084h52.75v102.944a7.5 7.5 0 007.5 7.5h27.611a7.5 7.5 0 007.5-7.5V222.084h12.611v47.722a7.5 7.5 0 007.5 7.5h27.611a7.5 7.5 0 007.5-7.5v-47.722h12.61v47.722a7.5 7.5 0 007.5 7.5h27.611a7.5 7.5 0 007.5-7.5v-47.722h12.61v47.722a7.5 7.5 0 007.5 7.5h27.611a7.5 7.5 0 007.5-7.5v-47.722h12.61v47.722a7.5 7.5 0 007.5 7.5h27.611a7.5 7.5 0 007.5-7.5v-47.722h12.61v102.944a7.5 7.5 0 007.5 7.5h27.611a7.5 7.5 0 007.5-7.5V222.084h52.75z" />
      <Path d="M131.75 345.138h-27.611a7.5 7.5 0 00-7.5 7.5v22.35a7.5 7.5 0 0015 0v-14.85h12.61v81.639h-12.61V409.99a7.5 7.5 0 00-15 0v39.288a7.5 7.5 0 007.5 7.5h27.611a7.5 7.5 0 007.5-7.5v-96.64a7.5 7.5 0 00-7.5-7.5zM186.972 386.555h-27.611a7.5 7.5 0 00-7.5 7.5v55.223a7.5 7.5 0 007.5 7.5h27.611a7.5 7.5 0 007.5-7.5v-55.223a7.499 7.499 0 00-7.5-7.5zm-7.5 55.223h-12.61v-40.222h12.61zM242.195 386.555h-27.611a7.5 7.5 0 00-7.5 7.5v55.223a7.5 7.5 0 007.5 7.5h27.611a7.5 7.5 0 007.5-7.5v-55.223a7.5 7.5 0 00-7.5-7.5zm-7.501 55.223h-12.611v-40.222h12.611zM407.861 345.138H380.25a7.5 7.5 0 00-7.5 7.5v96.64a7.5 7.5 0 007.5 7.5h27.611a7.5 7.5 0 007.5-7.5v-96.64a7.5 7.5 0 00-7.5-7.5zm-7.5 96.64h-12.61v-81.639h12.61zM352.639 386.555h-27.611a7.5 7.5 0 00-7.5 7.5v55.223a7.5 7.5 0 007.5 7.5h27.611a7.5 7.5 0 007.5-7.5v-55.223a7.5 7.5 0 00-7.5-7.5zm-7.5 55.223h-12.611v-40.222h12.611zM297.417 386.555h-27.611a7.5 7.5 0 00-7.5 7.5v55.223a7.5 7.5 0 007.5 7.5h27.611a7.5 7.5 0 007.5-7.5v-55.223a7.5 7.5 0 00-7.5-7.5zm-7.501 55.223h-12.61v-40.222h12.61zM332.528 366.445v-69.028a7.5 7.5 0 00-7.5-7.5H186.972a7.5 7.5 0 00-7.5 7.5v69.028a7.5 7.5 0 007.5 7.5h138.055a7.5 7.5 0 007.501-7.5zm-15.001-7.5H194.473v-54.027h123.055v54.027z" />
      <Circle cx={256} cy={186.973} r={7.5} />
      <Path d="M35.111 179.472a7.5 7.5 0 000 15h193.278a7.5 7.5 0 000-15zM276.111 186.972a7.5 7.5 0 007.5 7.5h193.277a7.5 7.5 0 000-15H283.611a7.5 7.5 0 00-7.5 7.5z" />
    </Svg>
  )
}

export default EmptyOrder