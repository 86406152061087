import React, { useState } from "react";
import { connect } from "react-redux";

// Utils
import { darken } from "../../../../utils/colorMethods";

// Components
import NormalProduct from "./productTypes/NormalProduct";
import VariantsProduct from "./productTypes/VariantsProduct";
import WarningModal from "../../modals/WarningModal";
import MenuProduct from "./productTypes/MenuProduct";

// Constants
import { colors } from "../../../../constants/theme";

// Methods
import { isOnEvent } from "./methods/isOnEvent";
import { isCompatibleType } from "./methods/isCompatibleType";

//TODO
//el shop q se envia al addProduct puede ser el fk_shop del data TODO
// const shop = orders.filter((shop) => shop.id_shop === data.fk_shop)[0];

const productSelector = ({
  data,
  shopData,
  navigation,
  cart,
  orders,
  extraShopData,
}) => {
  const [modal, setModal] = useState();
  const shop = orders.filter((shop) => shop.id_shop === shopData.gid)[0];
  const orderProductList = shop?.order_lines?.filter(
    (line) => line.gid === data.gid
  );
  if (orderProductList?.length === 1) {
    var auxProduct = orderProductList[0];
  }

  // Custom Style
  const custom_color_1 = shopData?.custom_design?.custom_color_1 || "#FFFFFF";
  const custom_color_letter_1 =
    shopData?.custom_design?.custom_color_letter_1 || colors.brown;
  const custom_color_2 =
    shopData?.custom_design?.custom_color_2 || colors.lightRed;
  const custom_color_letter_1_title =
    shopData?.custom_design?.custom_color_letter_1 || colors.brown;
  const custom_color_letter_2 =
    shopData?.custom_design?.custom_color_letter_2 || colors.brown;
  const custom_color_letter_2_icon =
    shopData?.custom_design?.custom_color_letter_2 || colors.primaryColor;
  const darkenColor1 = darken(custom_color_1, 5);

  const componentProps = {
    data: data,
    shopData: shopData,
    setModal: setModal,
    orderProduct: auxProduct,
    isOnEvent: isOnEvent(orders, shopData?.fk_event),
    isCompatibleType: isCompatibleType(cart, extraShopData),
    orderProductList: orderProductList || [],
    navigation: navigation,
    customStyle: {
      color1: custom_color_1,
      letter1: custom_color_letter_1,
      color2: custom_color_2,
      letter2: custom_color_letter_2,
      title1: custom_color_letter_1_title,
      icon2: custom_color_letter_2_icon,
      darken1: darkenColor1,
    },
  };

  const isComplex = (data) => {
    let isComplex = false;
    if (
      data?.variants !== null &&
      data?.variants !== undefined &&
      data?.variants?.length > 0
    ) {
      isComplex = true;
    }

    if (
      data?.ingredients !== null &&
      data?.ingredients !== undefined &&
      data?.ingredients?.length > 0
    ) {
      isComplex = true;
    }
    if (
      data.long_description !== null &&
      data.long_description !== undefined &&
      data.long_description !== ""
    ) {
      isComplex = true;
    }
    return isComplex;
  };
  const getComponent = () => {
    switch (data?.product_type) {
      case "individual":
      case "bono": {
        if (isComplex(data)) {
          return <VariantsProduct props={componentProps} />;
        } else {
          return <NormalProduct props={componentProps} />;
        }
      }
      case "menu": {
        return <MenuProduct props={componentProps} />;
      }
    }
  };

  return (
    <>
      {getComponent()}
      <WarningModal
        visible={modal === "Max"}
        setModal={setModal}
        text="Se ha alcanzado el máximo de existencias"
      />
      <WarningModal
        visible={modal === "OutOfEvent"}
        setModal={setModal}
        text="¡Sólo se pueden escoger productos de un mismo evento! Revisa tu carrito"
      />
      <WarningModal
        visible={modal === "OutOfLater"}
        setModal={setModal}
        text={`No se puede combinar una tienda de precompras con otras tiendas.\nRevisa tu carrito`}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return { orders: state.cart.orders, cart: state.cart };
};

export default connect(mapStateToProps)(productSelector);
