import React, { useContext } from "react";
import { StyleSheet, Text, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import { family } from "../../../../constants/theme";
import i18n from "../../../../i18n/i18n";
import { AuthContext } from "../../../../contexts/AuthContext";

const DiscountButton = ({ setModal }) => {
  const { user: firebaseUser } = useContext(AuthContext);
  const promotionHandler = () => {
    if (firebaseUser) {
      setModal("Promotion");
    } else {
      setModal("LogIn");
    }
  };

  return (
    <TouchableOpacity style={styles.discountButton} onPress={promotionHandler}>
      <Text style={styles.discountText}>
        {i18n.t("tickets.apply_discount")}
      </Text>
    </TouchableOpacity>
  );
};

const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps)(DiscountButton);

const styles = StyleSheet.create({
  discountButton: {
    paddingVertical: 2,
    paddingHorizontal: 4,
    borderRadius: 10,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  discountText: {
    fontSize: 12,
    fontFamily: family.normal,
    color: "white",
  },
});
