import { baseVersion } from "../../../config";
import { URlManagement } from "../../constants/urls";

export const getShopInfo = async (gid) => {
  try {
    const url = `${URlManagement}/shop/getInfo/${gid}`;
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Connection: "keep-alive",
         
      },
      method: "GET",
    });

    const resData = await response.json();
    if (resData.message === "success") {
      return resData.data;
    }
  } catch (err) {
    console.log("error fetching event:", err);
  }
};
