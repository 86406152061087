import React, { useEffect } from "react";
import { StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import { family } from "../../../../../../constants/theme";
import { setDiscount } from "../../../../../../redux";
import { getFormattedPrice } from "../../../../../../utils/productMethods";

const DiscountLine = ({ discount, orders, user, setDiscount, customStyle }) => {
  const { textColor } = customStyle;
  const textStyle = [styles.text, { color: textColor }];

  useEffect(() => {
    setDiscount({
      discount: { amount: 0, name: "Descuento", id_discount: null },
      discountLines: [],
    });
  }, [user]);

  return discount.id_discount !== null ? (
    <View style={styles.container}>
      <Text style={textStyle}>{discount.name}</Text>
      <Text style={textStyle}>-{getFormattedPrice(discount.amount)}</Text>
    </View>
  ) : (
    <View></View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    justifyContent: "space-between",
    marginBottom: 5,
    flexDirection: "row",
  },
  text: {
    fontFamily: family.normal,
    fontSize: 12,
  },
});

const mapDispatchToProps = {
  setDiscount,
};

const mapStateToProps = (state) => {
  return {
    discount: state.cart.discount.discount,
    orders: state.cart.orders,
    user: state.user.user,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DiscountLine);
