import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import CustomButton from "../../../../components/atoms/CustomButton";

const ConfirmButton = ({ navigation, orders }) => {
  const buttonHandler = () => {
    navigation.navigate("CheckoutLaterProducts");
  };

  return (
    <View style={styles.container}>
      <CustomButton
        onPress={buttonHandler}
        title="Confirmar"
        active={orders?.length > 0}
      />
    </View>
  );
};

const mapStateToProps = (state) => {
  return {
    orders: state.laterOrders.orders,
  };
};

export default connect(mapStateToProps)(ConfirmButton);

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: 100,
    paddingHorizontal: 30
  },
});
