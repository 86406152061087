import React, { useState, useEffect } from "react";

// Components
import ShopList from "./components/ShopList";
import Screen from "../../../components/atoms/Screen";

// Hooks
import useForceRender from "../../../hooks/useForceRender";

// Utils
import { isShopOpened } from "../../../utils/shopMethods";

const EventScreen = ({ navigation, route }) => {
  const { shopList, style, eventData } = route.params;
  const [itemList, setItemList] = useState(shopList);

  const [forceRender] = useForceRender();

  // Custom Style DEPRECATED
  // const custom_logo = style?.custom_logo || null;
  // const custom_color_header = style?.custom_color_header || colors.white;
  // const custom_color_letter_header =
  //   style?.custom_color_letter_header || colors.primaryColor;
  // const custom_color_icons_header =
  //   style?.custom_color_letter_header || colors.brown;
  // const custom_background =
  //   style?.custom_background || style?.custom_color_1 || colors.white;
  // const custom_color_1 = style?.custom_color_1 || "rgba(255,255,255,0.8)";
  // const custom_color_letter_1 = style?.custom_color_letter_1 || colors.brown;

  // const customHeaderStyle = {
  //   color: custom_color_header,
  //   textColor: custom_color_letter_header,
  //   customIcon: custom_logo,
  //   iconsColor: custom_color_icons_header,
  // };

  useEffect(() => {
    // navigation.setOptions({ style: customHeaderStyle });
    const sortedList = shopList.sort((a, b) => {
      const aOpened = isShopOpened(a);
      const bOpened = isShopOpened(b);

      if (aOpened && !bOpened) {
        return -1;
      } else {
        return 1;
      }
    });
    setItemList(sortedList);
    forceRender();
  }, []);

  return (
    <Screen>
      <ShopList data={itemList} navigation={navigation} eventData={eventData} />
    </Screen>
  );
};

export default EventScreen;
