import React from "react";
import { Text } from "react-native";

import { colors, family, size } from "../../constants/theme";

const CustomText = ({
  fontSize = size.small,
  color = colors.brown,
  bold,
  text,
  ...props
}) => {
  return (
    <Text
      style={[{
        fontFamily: bold ? family.bold : family.normal,
        fontSize: fontSize,
        color: color,
      },{...props}]}
      
    >
      {text}
    </Text>
  );
};

export default CustomText;
