import React from "react";
import { TouchableOpacity } from "react-native";
import { StyleSheet, Text } from "react-native";
import { family } from "../../constants/theme";

const Link = ({ name, onPress, bold, ...rest }) => {
  return (
    <TouchableOpacity {...rest} onPress={() => onPress()}>
      <Text style={[styles.infoText, bold && { fontFamily: family.bold }]}>
        {name}
      </Text>
    </TouchableOpacity>
  );
};

export default Link;

const styles = StyleSheet.create({
  infoText: {
    fontFamily: family.normal,
    fontSize: 12,
    color: "white",
    textAlign: "center",
  },
});
