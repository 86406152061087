import React from "react";
import StyleSheet from "react-native-media-query";
import { Text } from "react-native";

// Constants
import { colors, family } from "../../../../../constants/theme";

// Components
import IngredientsText from "./IngredientsText";
import VariantsText from "./VariantsText";

const PlatesText = ({ plates = [], color, separator }) => {
  return (
    <>
      {plates.map((sel, index) => (
        <Text
          key={index}
          dataSet={{ media: ids.text }}
          style={[styles.text, { color: color }]}
        >
          {sel.quantity > 1 && sel.quantity + "x "}
          {sel.name}
          {(sel?.ingredients.length > 0 || sel?.variants.length > 0) && (
            <Text>{"\n"}</Text>
          )}
          <IngredientsText ingredients={sel?.ingredients} color={color} />
          {sel?.variants.length > 0 && (
            <VariantsText
              variants={sel?.variants}
              color={color}
              showTitles={true}
              separator={separator}
            />
          )}
        </Text>
      ))}
    </>
  );
};

export default PlatesText;

const { ids, styles } = StyleSheet.create({
  text: {
    fontSize: 12,
    fontFamily: family.normal,
    color: colors.darkGrey,
    marginTop: 5,
  },
});
