import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { family } from "../../../../constants/theme";
import i18n from "../../../../i18n/i18n";

const NothingSelected = () => {
  return (
    <View
      style={{
        backgroundColor: "rgba(0,0,0,0.8)",
        width: "100%",
        height: 100,
        borderRadius: 15,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Text style={styles.emptyTitle}>
        {i18n.t("tickets.nothing_selected")}
      </Text>
    </View>
  );
};

export default NothingSelected;

const styles = StyleSheet.create({
  emptyTitle: {
    fontFamily: family.bold,
    fontSize: 18,
    color: "white",
    textAlign: "center",
    justifyContent: "center",
  },
});
