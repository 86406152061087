import React from "react";
import { View, StyleSheet } from "react-native";

// Components
import Tab from "./Tab";
import DownloadBanner from "../../DownloadBanner";

// Methods
import { getActiveRouteState } from "../methods/getActiveRouteState";

const TabBar = ({ state, descriptors, navigation }) => {
  const activeRoute = getActiveRouteState(state);
  const hiddenTabBar =
    activeRoute?.state?.index && activeRoute?.state?.index !== 0;

  if (hiddenTabBar) {
    return <View></View>;
  }
  return (
    <View style={styles.container}>
      <View style={styles.content}>
        {state.routes.map((route, index) => (
          <Tab
            key={index}
            state={state}
            descriptors={descriptors}
            navigation={navigation}
            route={route}
            index={index}
          />
        ))}
      </View>
      <DownloadBanner />
    </View>
  );
};

export default TabBar;

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    height: 70,
    borderTopWidth: 1,
    borderTopColor: "#BCBCBC",
    position:'relative',
    backgroundColor: "white",
  },
  content: {
    width: "100%",
    height: "100%",
    maxWidth: 400,
    paddingHorizontal: 18,
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
});
