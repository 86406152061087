import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";

// Components
import ProductItem from "../ProductItem";
import ShopServiceLine from "./ShopServiceLine";
import Title from "./Title";

const ShopItem = ({ shop, services, customStyle }) => {
  const getService = () => {
    return services.filter((el) => el.shopId === shop.id_shop)[0];
  };
  const service = useMemo(() => getService(), [shop]);

  return (
    <View style={styles.container}>
      <View style={styles.titleContainer}>
        <Title shop={shop} customStyle={customStyle} />
        <ShopServiceLine service={service} customStyle={customStyle} />
      </View>
      {shop.order_lines.map((line) => (
        <View style={{ flexDirection: "column" }} key={line.internal_id}>
          <ProductItem
            product={line}
            showQty
            shopData={shop}
            customStyle={customStyle}
          />
        </View>
      ))}
    </View>
  );
};

const mapStateToProps = (state) => {
  return {
    services: state.cart.services,
    orders: state.cart.orders,
  };
};

export default connect(mapStateToProps)(ShopItem);

const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
  titleContainer: {
    width: "100%",
  },
});
