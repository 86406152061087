import { FETCH_SHOP_BY_ID, FETCH_ALL_SHOPS } from "./shopTypes";

const initialState = {
  shops: [],
  allShops:[]
};

const shopReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SHOP_BY_ID: {
      const newShop = action.payload;
      const currentShops = [...state.shops];
      let exists = false;
      if (newShop.length > 0) {
        currentShops.map((shop) => {
          if (shop.gid === newShop[0].gid) {
            exists = true;
          }
        });
        if (exists === false) {
          currentShops.push(newShop[0]);
          return { ...state, shops: currentShops };
        }
      }
      return state;
    }
    case FETCH_ALL_SHOPS:{
      return {
        ...state,
        allShops: action.payload,
      };
    }
    default:
      return state;
  }
};

export default shopReducer;
