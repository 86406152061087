import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  ScrollView,
  View,
  useWindowDimensions,
} from "react-native";
import { connect } from "react-redux";

// Components
import NoOrder from "../../live/ConfirmOrderScreen/containers/NoOrder";
import SectionTitle from "../../live/ConfirmOrderScreen/components/SectionTitle";
import ProductsSection from "./components/ProductsSection";
import ScheduleSection from "./components/ScheduleSection";
import CommentSection from "./components/CommentSection";
import ConfirmButton from "./components/ConfirmButton";
import Screen from "../../../components/atoms/Screen";

// Constants
import { colors } from "../../../constants/theme";

// Redux
import {
  setDate,
  setOrderType,
} from "../../../redux/laterCart/laterCartActions";

const CheckoutLaterProducts = ({
  orders,
  setDate,
  setOrderType,
  navigation,
}) => {
  const height = useWindowDimensions().height;

  useEffect(() => {
    if (orders.length === 0) {
      setOrderType("ASAP");
      setDate(null);
    }
  }, [orders]);

  return (
    <Screen screenColor={colors.white}>
      {orders.length > 0 ? (
        <>
          <ScrollView
            style={[styles.container, { height: height - 140 }]}
            showsVerticalScrollIndicator={false}
          >
            <SectionTitle title={"Detalles del pedido"} />
            <ProductsSection />
            <SectionTitle title={"Detalles de entrega"} />
            <ScheduleSection />
            <CommentSection />
            <View style={{ height: 200 }} />
          </ScrollView>
          <ConfirmButton navigation={navigation} />
        </>
      ) : (
        <NoOrder navigation={navigation} />
      )}
    </Screen>
  );
};

const mapStateToProps = (state) => {
  return {
    orders: state.laterOrders.orders,
  };
};

const mapDispatchToProps = {
  setOrderType,
  setDate,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckoutLaterProducts);

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    paddingHorizontal: 18,
    paddingTop: 18,
  },
});
