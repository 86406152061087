import React from "react";
import Svg, { Path } from "react-native-svg";

const ExploreIcon = (props) => {
	return (
		<Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
			<Path d="M28.5 14.9C28.4 7.5 22.4 1.5 15 1.5S1.6 7.5 1.5 14.9v.1c0 7.4 6.1 13.5 13.5 13.5S28.5 22.4 28.5 15v-.1zM16 26.4v-1.9c0-.6-.4-1-1-1s-1 .4-1 1v1.9C8.4 26 4 21.5 3.5 15.9h1.9c.6 0 1-.4 1-1s-.4-1-1-1H3.6C4.1 8.4 8.5 4 14 3.6v1.9c0 .6.4 1 1 1s1-.4 1-1V3.6c5.5.5 9.9 4.9 10.4 10.3h-1.9c-.6 0-1 .4-1 1s.4 1 1 1h1.9C26 21.5 21.6 26 16 26.4z" />
			<Path d="M21.2 7.4L12.4 12h-.1c-.1 0-.1.1-.2.1l-.2.2v.1l-4.6 8.8c-.2.4-.1.9.2 1.2.2.2.4.3.7.3.2 0 .3 0 .5-.1l8.8-4.6h.1c.1 0 .1-.1.2-.2s.1-.1.1-.2v-.1l4.6-8.8c.2-.4.1-.9-.2-1.2-.2-.2-.7-.3-1.1-.1zm-8 7.2l2.3 2.3-4.8 2.5 2.5-4.8zm3.6.8l-2.3-2.3 4.8-2.5-2.5 4.8z" />
		</Svg>
	);
};

export default ExploreIcon;
