import React from "react";
import { ActivityIndicator } from "react-native";
import { Text, TouchableOpacity } from "react-native";
import StyleSheet from "react-native-media-query";

// Constants
import { colors, size, family } from "../../constants/theme";

// Utils
import { lighten } from "../../utils/colorMethods";

const CustomButton = ({
  onPress,
  title,
  active,
  color = colors.primaryColor,
  textColor = colors.white,
  loading,
  ...rest
}) => {
  return (
    <TouchableOpacity
      style={[
        styles.confirmButton,
        { backgroundColor: color },
        active === false && { backgroundColor: lighten(color, 40) },
        { ...rest },
      ]}
      dataSet={{ media: ids.confirmButton }}
      onPress={!active ? () => {} : onPress}
      activeOpacity={!active ? 1 : 0.3}
    >
      {loading ? (
        <ActivityIndicator size="small" color={colors.white} />
      ) : (
        <Text
          dataSet={{ media: ids.confirmText }}
          style={[styles.confirmText, { color: textColor }]}
        >
          {title}
        </Text>
      )}
    </TouchableOpacity>
  );
};

export default CustomButton;

const { ids, styles } = StyleSheet.create({
  confirmButton: {
    flex: 1,
    height: 46,
    maxHeight: 46,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 60,
    paddingHorizontal: 18,
    "@media (max-height: 700px) or (max-width: 340px)": {
      height: 32,
    },
  },
  confirmText: {
    fontFamily: family.bold,
    fontSize: size.big,
    textAlign: "center",

    "@media (max-height: 700px) or (max-width: 340px)": {
      fontSize: 14,
    },
  },
});
