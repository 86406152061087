import React, { useState } from "react";
import StyleSheet from "react-native-media-query";
import { Text, View } from "react-native";
import { connect } from "react-redux";

// Components
import TouchableText from "../../../../../components/atoms/TouchableText";
import AddProduct from "../../../../../components/organisms/products/ProductItem/components/AddProduct";
import DetailsModal from "../../modals/DetailsModal";
import DeleteProductModal from "../../modals/DeleteProductModal";

// Constants
import { colors, family, size } from "../../../../../constants/theme";

// Redux
import { incrementProduct, decrementProduct } from "../../../../../redux";

// Hooks
import useForceRender from "../../../../../hooks/useForceRender";

// Utils
import { getSingleProductCost } from "../../../../../utils/productMethods";
import RemoveProductStatic from "../../../../../components/organisms/products/ProductItem/components/RemoveProductStatic";
import WarningModal from "../../../../../components/organisms/modals/WarningModal";

const ProductItem = ({
  product,
  incrementProduct,
  decrementProduct,
  customStyle,
  shopData,
}) => {
  const [modal, setModal] = useState();
  const [forceRender] = useForceRender();
  const { textColor } = customStyle;
  const {
    amount,
    variants = [],
    plates = [],
    ingredients = [],
    quantity,
    internal_id = null,
    name,
    stock,
  } = product;

  const hasExtras =
    ingredients?.length > 0 || variants?.length > 0 || plates?.length > 0
      ? true
      : false;

  const addHandler = () => {
    if (quantity < stock) {
      incrementProduct(internal_id);
      forceRender();
    } else {
      setModal("Max");
    }
  };

  const removeHandler = () => {
    if (quantity === 1) {
      setModal("DeleteProduct");
    } else {
      decrementProduct(internal_id);
    }
    forceRender();
  };

  const acceptDeleteHandler = () => {
    decrementProduct(internal_id);
  };

  const handleModal = () => {
    setModal("Details");
  };

  return (
    <View style={styles.container}>
      <RemoveProductStatic
        quantity={quantity}
        onPress={removeHandler}
        customStyle={customStyle}
        buttonSize={20}
        iconSize={16}
      />
      <View style={styles.detailsColumn}>
        <Text style={[styles.text, { color: textColor }]}>{name}</Text>
        {hasExtras === true && (
          <TouchableText
            onPress={handleModal}
            text="Ver Detalles"
            customStyle={{ marginRight: 5, fontSize: 10, color: textColor }}
          />
        )}
      </View>
      <AddProduct
        amount={getSingleProductCost(amount, variants, plates) * quantity}
        textColor={textColor}
        onAdd={addHandler}
        buttonSize={20}
        iconSize={16}
      />
      <DetailsModal
        visible={modal === "Details"}
        setModal={() => setModal()}
        customStyle={customStyle}
        shopName={shopData.shop_name}
        product={product}
      />
      <WarningModal
        visible={modal === "Max"}
        setModal={setModal}
        text="Se ha alcanzado el máximo de existencias"
      />
      <DeleteProductModal
        visible={modal === "DeleteProduct"}
        setModal={setModal}
        customStyle={customStyle}
        shopName={shopData.shop_name}
        productName={name}
        onAccept={acceptDeleteHandler}
      />
    </View>
  );
};

const mapDispatchToProps = {
  incrementProduct,
  decrementProduct,
};

export default connect(null, mapDispatchToProps)(ProductItem);

const { styles, ids } = StyleSheet.create({
  container: {
    marginVertical: 5,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flex:1
  },
  detailsColumn: {
    flex: 1,
    height: "100%",
    justifyContent: "center",
    alignItems: "flex-start",
    paddingRight: 8,
    flexDirection: "column",
  },
  text: {
    fontFamily: family.normal,
    color: colors.brown,
    fontSize: size.small,
  },
});
