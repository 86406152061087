import React, { useState, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";

// Components
import Screen from "../../../components/atoms/Screen";
import WarningModal from "../../../components/organisms/modals/WarningModal";
import DescriptionStep from "./components/DescriptionStep";
import PlateSelectorStep from "./components/PlateSelectorStep";

// Constants
import { colors } from "../../../constants/theme";

// Redux Actions
import { addFormedMenu } from "../../../redux/cart/cartActions";
import { resetFormedMenu } from "../../../redux/cart/cartActions";
import { setMenuVariants } from "../../../redux/cart/cartActions";

// Methods
import { hasVariants } from "./methods/hasVariants";
import { getCustomStyle } from "./methods/getCustomStyle";
import { getChoices } from "./methods/getChoices";

const MenuSelectorScreen = ({
  navigation,
  route,
  setMenuVariants,
  addFormedMenu,
  resetFormedMenu,
}) => {
  const { data, shopData } = route.params;
  const { long_description, variants, plates } = data;

  const choices = getChoices(plates);

  const [step, setStep] = useState(
    long_description || hasVariants(variants) === true ? 0 : 1
  );

  const [variantsList, setVariants] = useState([]);
  const [modal, setModal] = useState();

  const customStyle = getCustomStyle(shopData);

  useEffect(() => {
    navigation.setOptions({ style: customStyle.customHeaderStyle });
  }, []);

  const handleStep = () => {
    if (step <= choices.length) {
      setStep((prevState) => prevState + 1);
    } else {
      if (shopData?.just_menu !== 1) {
        addFormedMenu(shopData.gid, data);
      }
      navigation.pop();
    }
  };

  useEffect(() => {
    resetFormedMenu();
  }, []);

  useEffect(() => {
    if (step === 1) {
      setMenuVariants(variantsList, data.gid);
    }
    if (step > choices.length) {
      if (shopData?.just_menu !== 1) {
        addFormedMenu(shopData.gid, data);
      }
      navigation.pop();
    }
  }, [step]);

  const continueHandler = () => {
    setStep(1);
  };

  return (
    <Screen paddingHorizontal={0}>
      <View style={styles.wrapper}>
        <View
          style={[
            styles.productContainer,
            { backgroundColor: customStyle.custom_color_1 || colors.white },
          ]}
        >
          {step === 0 && (
            <DescriptionStep
              data={data}
              customStyle={customStyle}
              variantsList={variantsList}
              setVariants={setVariants}
              onContinue={continueHandler}
              navigation={navigation}
            />
          )}

          {step !== 0 && step <= choices.length && (
            <PlateSelectorStep
              data={data}
              choices={choices}
              setModal={setModal}
              handleStep={handleStep}
              shopData={shopData}
              navigation={navigation}
              step={step}
            />
          )}
        </View>
      </View>
      <WarningModal
        visible={modal === "Max"}
        setModal={setModal}
        text="Se ha alcanzado el máximo de existencias"
      />
    </Screen>
  );
};

const mapDispatchToProps = {
  addFormedMenu,
  setMenuVariants,
  resetFormedMenu,
};

export default connect(null, mapDispatchToProps)(MenuSelectorScreen);

const styles = StyleSheet.create({
  wrapper: {
    justifyContent: "flex-start",
    alignItems: "center",
    position: "relative",
    height: "100%",
    width: "100%",
  },
  productContainer: {
    height: "100%",
    width: "100%",
    justifyContent: "space-between",
    zIndex: 1,
  },
});
