import { baseVersion } from "../../../../../config";
import { URlManagement } from "../../../../constants/urls";
import { getScheduleArray } from "../../../../redux/cart/cartFunctions";

export const fetchScheduleInfo = async ({ id, service, locId }) => {
  try {
    const serviceAux = service === 1 ? "pick_up" : "my_site";
    const url =
      serviceAux === "my_site"
        ? `${URlManagement}/shop/get/${id.toString()}?location=${locId}&service=my_site`
        : `${URlManagement}/shop/get/${id.toString()}?service=${serviceAux}`;

    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Connection: "keep-alive",
         
      },
      method: "GET",
    });
    const resData = await response.json();
    const data = resData.message === "success" ? resData.data[0] : [];

    if (data.schedule_slots?.length > 0) {
      const slots = getScheduleArray(data.schedule_slots);
      return { available: true, slots: slots };
    } else {
      return { available: false, slots: [] };
    }
  } catch (err) {
    console.log("error fetching shop data: ", err);
    return { available: false, slots: [] };
  }
};
