import React from "react";
import { View, ScrollView, useWindowDimensions } from "react-native";

// Components
import EmptyScreen from "./EmptyScreen";
import PastOrderItem from "../../../../components/organisms/pastOrder/PastOrderItem";

const CurrentOrdersList = ({ data = [], navigation }) => {
  const height = useWindowDimensions().height;

  if (data.length === 0) {
    return <EmptyScreen navigation={navigation} />;
  }
  return (
    <ScrollView
      style={{ height: height - 120 }}
      showsVerticalScrollIndicator={false}
    >
      {data.map((order) => (
        <PastOrderItem key={order.gid} data={order} navigation={navigation} />
      ))}
      <View style={{ height: 250 }} />
    </ScrollView>
  );
};

export default CurrentOrdersList;
