import React, { useState, useEffect } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";

// Components
import TextInput from "../../../atoms/TextInput";
import CustomButton from "../../../atoms/CustomButton";
import CustomError from "../../../atoms/CustomError";
import CustomText from "../../../atoms/CustomText";
import Divider from "../../../atoms/Divider";
import Hero from "../components/Hero";

// Methods
import { lostPassword } from "../methods/lostPassword";

// Utils
import { validateMail } from "../../../../utils/arrayMethods";


const ForgotPasswordSection = ({ sectionProps }) => {
  const { step, setStep, customStyle } = sectionProps;
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSucess] = useState(false);

  useEffect(() => {
    setError("");
    setEmail("");
    setSucess(false);
  }, [step]);

  const validEmail = validateMail(email);

  const handleLostPassword = async () => {
    lostPassword(email).then((status) => {
      setError();
      if (status?.status === "success") {
        setEmail("");
        setSucess(true);
      } else if (status?.status === "fail") {
        setError(status.message);
      } else {
        setError("Algo salió mal");
      }
    });
  };

  return (
    <View style={styles.container}>
      {success === false ? (
        <>
          <Hero
            title={"¿Has olvidado la contraseña?"}
            subtitle={
              "No te preocupes. Introduce el correo electrónico vinculado a tu cuenta y te enviaremos un enlace para recuperarla."
            }
            customStyle={customStyle}
          />
          <Divider height={20} />
          <TextInput
            marginVertical={14}
            placeholder={"Email"}
            value={email}
            onChange={setEmail}
          />
          <View style={styles.buttonContainer}>
            <CustomButton
              color={customStyle.backgroundColor}
              textColor={customStyle.buttonTextColor}
              active={validEmail}
              title={"Aceptar"}
              onPress={handleLostPassword}
            />
          </View>
          <CustomError error={error} color={customStyle.errorColor} />
          <TouchableOpacity onPress={() => setStep(0)} style={{ marginTop: 4 }}>
            <CustomText color={customStyle.textColor} text={"Volver"} />
          </TouchableOpacity>
        </>
      ) : (
        <>
          <Hero
            title={"¡Hecho!"}
            subtitle={
              "Revisa el correo electrónico introducido. \nSe ha enviado un email con la nueva contraseña autogenerada"
            }
          />
          <Divider height={20} />
          <View style={styles.buttonContainer}>
            <CustomButton
              color={customStyle.backgroundColor}
              textColor={customStyle.buttonTextColor}
              active
              title={"Volver"}
              onPress={() => setStep(0)}
            />
          </View>
        </>
      )}
    </View>
  );
};


export default ForgotPasswordSection

const styles = StyleSheet.create({
  container: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonContainer: {
    width: "100%",
    height: 70,
  },
});
