import React from "react";
import StyleSheet from "react-native-media-query";
import { Text, View } from "react-native";

// Contants
import { colors, family } from "../../../../../constants/theme";

const ShortDescription = ({ description, color }) => {
  return description !== null ? (
    <Text dataSet={{ media: ids.text }} style={[styles.text, { color: color }]}>
      {description}
    </Text>
  ) : (
    <View></View>
  );
};

export default ShortDescription;

const { ids, styles } = StyleSheet.create({
  text: {
    fontSize: 12,
    fontFamily: family.normal,
    color: colors.darkGrey,
    marginTop: 5,
  },
});
