import { baseVersion } from "../../../config";
import { URlOrder } from "../../constants/urls";

const getAdyenSession = async (token, order) => {
  try {
    const response = await fetch(`${URlOrder}/pay/adyen/session`, {
      headers: {
        "Content-Type": "application/json",
        Connection: "keep-alive",
        Authorization: `Bearer ${token}`,
         
      },
      body: JSON.stringify(order),
      method: "POST",
    });

    const resData = await response.json();
    return resData;
  } catch (error) {
    console.log("[error] ", JSON.stringify(error));
    return { status: "fail", data: error.message };
  }
};

export default getAdyenSession;
