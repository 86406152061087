import React from "react";
import { Text, TouchableOpacity } from "react-native";
import StyleSheet from "react-native-media-query";

// Constants
import { colors, size, family, RADIUS } from "../../constants/theme";

const TextItem = ({ text, selected, onPress, color, textColor }) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={[
        styles.container,
        {
          backgroundColor: selected
            ? color
              ? color
              : colors.primaryColor
            : textColor
            ? textColor
            : colors.white,
        },
      ]}
      dataSet={{ media: ids.container }}
    >
      <Text
        style={[
          styles.text,
          {
            color: selected
              ? textColor
                ? textColor
                : colors.white
              : color
              ? color
              : colors.primaryColor,
          },
        ]}
        dataSet={{ media: ids.text }}
      >
        {text}
      </Text>
    </TouchableOpacity>
  );
};

export default TextItem;

const { ids, styles } = StyleSheet.create({
  container: {
    padding: 6,
    borderRadius: RADIUS,
    justifyContent: "center",
    alignItems: "center",
    marginRight: 12,
    "@media (max-width:370px)": {
      padding: 4,
      borderRadius: RADIUS - 2,
      marginRight: 6,
    },
  },
  selectedContainer: {
    backgroundColor: colors.primaryColor,
  },
  text: {
    fontFamily: family.normal,
    fontSize: 18,
    color: colors.primaryColor,
    "@media (max-width:370px)": {
      fontSize: 12,
    },
  },
  selectedText: {
    color: colors.white,
  },
});
