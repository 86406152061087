// React & Libraries
import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View, TouchableOpacity } from "react-native";

// Components
import IconButton from "../../../atoms/IconButton";
import Divider from "../../../atoms/Divider";

// Constants
import { colors, size, family } from "../../../../constants/theme";
import { getFormattedPrice } from "../../../../utils/productMethods";

const Footer = ({ data, variantsList, ingredientsList, onAdd, setModal }) => {
  const [qty, setQty] = useState(1);
  const [price, setPrice] = useState(0);

  const removeHandler = () => {
    if (qty > 0) {
      setQty(qty - 1);
    }
  };
  const addHandler = () => {
    if ((data.sold_out === 0 && qty < data.stock) || data.stock === null) {
      setQty(qty + 1);
    }
  };

  useEffect(() => {
    let increment = 0;
    variantsList.map((group) =>
      group.options.map((el) => {
        increment += el.increment;
      })
    );
    setPrice(qty * (increment + data.amount));
  }, [qty, variantsList]);

  const confirmationHandler = () => {
    if (data.sold_out === 0) {
      onAdd({
        data: data,
        variants: variantsList,
        ingredients: ingredientsList,
        qty: qty,
      });
    }
    setModal();
  };
  return (
    <View style={styles.footer}>
      <Divider width="100%" color={colors.lightenGrey} />
      <View style={styles.actionsContainer}>
        <View style={styles.actions}>
          <IconButton
            color="white"
            textColor="black"
            icon="minus"
            onPress={removeHandler}
          />
          <Text style={styles.qtyText}>{qty}</Text>
          <IconButton
            color="white"
            textColor="black"
            icon="plus"
            onPress={addHandler}
          />
        </View>
        <View style={styles.priceContainer}>
          <Text style={styles.qtyText}>{getFormattedPrice(price)}</Text>
        </View>
      </View>
      <View style={{ height: 30 }}>
        {(qty >= data.stock && data.stock !== null) && (
          <Text style={styles.maxStock}>{"Máximo stock disponible"}</Text>
        )}
      </View>
      <TouchableOpacity
        style={styles.confirmButton}
        onPress={confirmationHandler}
      >
        <Text style={styles.confirmText}>CONFIRMAR</Text>
      </TouchableOpacity>
    </View>
  );
};

export default Footer;

const styles = StyleSheet.create({
  footer: {
    width: "100%",
    borderBottomRightRadius: 15,
    borderBottomLeftRadius: 15,
    backgroundColor: "black",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 18,
    paddingBottom: 18,
    position: "relative",
    bottom: 0,
    zIndex: 10,
  },
  actionsContainer: {
    flexDirection: "row",
    marginTop: 21,
    width: "80%",
    maxWidth: 200,
    justifyContent: "space-between",
  },
  actions: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  qtyText: {
    fontSize: size.big,
    fontFamily: family.normal,
    color: "white",
  },
  maxStock: {
    fontSize: size.small,
    fontFamily: family.normal,
    color: "white",
  },
  priceContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  confirmButton: {
    width: "100%",
    height: 46,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: 10,
  },
  confirmText: {
    color: "black",
    fontFamily: family.bold,
    fontSize: size.big,
  },
});
