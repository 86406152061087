import React from "react";
import Product from "../components/Product";

import { addProduct, decrementProduct } from "../../../../../redux";
import { connect } from "react-redux";

const NormalProduct = ({ props, addProduct, decrementProduct }) => {
  const {
    data,
    shopData,
    setModal,
    orderProduct,
    customStyle,
    isCompatibleType,
    isOnEvent,
  } = props;
  //todo el shop q se envia al addProduct puede ser el fk_shop del data

  const showActions = orderProduct?.quantity ? true : false;

  const addHandler = () => {
    if (!isCompatibleType) {
      setModal("OutOfLater");
    } else if (!isOnEvent) {
      setModal("OutOfEvent");
    } else if (
      data?.stock === null ||
      data?.stock === undefined ||
      orderProduct === undefined ||
      data?.stock > 0 ||
      orderProduct?.quantity <= data?.stock
    ) {
      addProduct({
        shop: shopData,
        fk_point_sale: data.fk_point_sale,
        gid: data.gid,
        family: data.family,
        only_legal_age: data.only_legal_age,
        not_pay_service: data.not_pay_service,
        not_pay_commission: data.not_pay_commission,
        exclusive_order: data.exclusive_order,
        type: data.type,
        amount: data.amount,
        name: data.name,
        quantity: 1,
      });
    } else {
      setModal("Max");
    }
  };

  const removeHandler = () => {
    decrementProduct(orderProduct?.internal_id);
  };

  return (
    <Product
      justMenu={shopData?.just_menu !== 1 ? false : true}
      data={data}
      onPress={addHandler}
      onAction={removeHandler}
      showActions={showActions}
      quantity={orderProduct?.quantity || null}
      customStyle={customStyle}
    />
  );
};
const mapDispatchToProps = {
  addProduct,
  decrementProduct,
};

export default connect(null, mapDispatchToProps)(NormalProduct);
