import React from "react";
import { Platform, StyleSheet, useWindowDimensions, View } from "react-native";

// Components
import Divider from "../../../atoms/Divider";
import GoogleAuthButton from "../mediaButtons/GoogleAuthButton";
import IosAuthButton from "../mediaButtons/IosAuthButton";

const MediaSection = ({ setError, onAuth, sectionProps }) => {
  const width = useWindowDimensions().width;
  return (
    <View style={styles.mediaContainer}>
      {Platform.OS !== "ios" && (
        <GoogleAuthButton
          onError={setError}
          onAuth={onAuth}
          sectionProps={sectionProps}
        />
      )}
      {Platform.OS === "web" && <Divider width={width > 320 ? 20 : 10} />}
      {Platform.OS !== "android" && (
        <IosAuthButton
          onError={setError}
          onAuth={onAuth}
          sectionProps={sectionProps}
        />
      )}
    </View>
  );
};

export default MediaSection;

const styles = StyleSheet.create({
  mediaContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
