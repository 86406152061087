import { environment } from "./config";

const configProd = {
  apiKey: "AIzaSyCx7SuWrpnFLHL5U1VTZMAWfc6GKRF-lzU",
  authDomain: "gopick-user.firebaseapp.com",
  projectId: "gopick-user",
  storageBucket: "gopick-user.appspot.com",
  messagingSenderId: "999693077078",
  appId: "1:999693077078:web:d1437c99296d367201c9f7",
  measurementId: "G-NL0895JTKB",
};

const configDev = {
  apiKey: "AIzaSyCNaybEK_JuqauimTS1jQ5XPRexbtjoays",
  authDomain: "gopick-dev.firebaseapp.com",
  projectId: "gopick-dev",
};

export const firebaseConfig = environment === "prod" ? configProd : configDev;