export const toProductScreen = ({ navigation, data, res, action }) => {
  const navigate = () => {
    action(data, res);
    navigation.navigate("EventsStack", {
      screen: "ShopScreen",
      params: {
        shopData: res,
        name: res.name,
        shopId: res.gid,
        extraShopData: data,
      },
    });
  };
  navigate();
};
