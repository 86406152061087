import React, {
  useCallback,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Dimensions, StyleSheet, View } from "react-native";
import Animated, {
  Extrapolate,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from "react-native-reanimated";
import { Octicons } from "@expo/vector-icons";
import { TouchableOpacity } from "react-native";
import { colors } from "../../constants/theme";

const { height: SCREEN_HEIGHT } = Dimensions.get("window");

const MAX_TRANSLATE_Y = -SCREEN_HEIGHT + 50;

const BottomSheet = forwardRef(
  (
    {
      children,
      openPosition,
      closePosition,
      startPosition,
      onClick = () => {},
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const translateY = useSharedValue(startPosition);
    const active = useSharedValue(false);

    const scrollTo = useCallback((destination) => {
      "worklet";
      active.value = destination !== closePosition;

      translateY.value = withSpring(destination, { damping: 50 });
    }, []);

    const headerHandler = () => {
      if (active.value) {
        onClick("close");
        setIsOpen(false);
        scrollTo(closePosition);
      } else {
        onClick("open");
        setIsOpen(true);
        scrollTo(openPosition);
      }
    };

    const isActive = useCallback(() => {
      return active.value;
    }, []);

    useImperativeHandle(ref, () => ({ scrollTo, isActive }), [
      scrollTo,
      isActive,
    ]);

    const rBottomSheetStyle = useAnimatedStyle(() => {
      const borderRadius = interpolate(
        translateY.value,
        [MAX_TRANSLATE_Y + 50, MAX_TRANSLATE_Y],
        [25, 5],
        Extrapolate.CLAMP
      );

      return {
        borderRadius,
        transform: [{ translateY: translateY.value }],
      };
    });

    return (
      <Animated.View style={[styles.bottomSheetContainer, rBottomSheetStyle]}>
        <TouchableOpacity style={styles.lineContainer} onPress={headerHandler}>
          <Octicons
            name={isOpen ? "chevron-down" : "chevron-up"}
            size={24}
            color="grey"
          />
        </TouchableOpacity>
        {children}
      </Animated.View>
    );
  }
);

const styles = StyleSheet.create({
  bottomSheetContainer: {
    height: SCREEN_HEIGHT,
    width: "100%",
    borderColor: colors.grey,
    borderWidth: 1,
    backgroundColor: "white",
    position: "absolute",
    top: SCREEN_HEIGHT,
    borderRadius: 25,
  },
  lineContainer: {
    width: "100%",
    height: 30,
    zIndex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default BottomSheet;
