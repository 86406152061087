import React from "react";
import { TouchableOpacity } from "react-native";
import { View, Platform, Text } from "react-native";
import CheckBox from "react-native-check-box";
import StyleSheet from "react-native-media-query";

// Custom import
const ExpoCheckbox =
  Platform.OS === "ios"
    ? require("react-native-check-box")
    : require("expo-checkbox");

// Constants
import { colors, family, size } from "../../constants/theme";

const CustomCheckBox = ({
  value,
  color,
  label,
  subtitle,
  onPress,
  textColor,
  children,
  ...props
}) => {
  return (
    <View {...props} style={styles.container}>
      {Platform.OS !== "web" ? (
        <CheckBox
          style={styles.checkbox}
          dataSet={{ media: ids.checkbox }}
          onClick={onPress}
          isChecked={value}
          uncheckedCheckBoxColor={textColor || "black"}
          checkedCheckBoxColor={textColor || "black"}
        />
      ) : (
        <ExpoCheckbox.default
          dataSet={{ media: ids.checkbox }}
          style={styles.checkbox}
          value={value}
          onValueChange={onPress}
        />
      )}
      {label && (
        <TouchableOpacity onPress={onPress} style={styles.labelTouchable}>
          <Text style={{ paddingRight: 30 }}>
            {label && (
              <Text
                dataSet={{ media: ids.label }}
                style={[styles.label, textColor && { color: textColor }]}
              >
                {label}
              </Text>
            )}
            {subtitle && (
              <Text
                dataSet={{ media: ids.label }}
                style={[styles.label, textColor && { color: textColor }]}
              >
                {subtitle}
              </Text>
            )}
          </Text>
        </TouchableOpacity>
      )}
      {children}
    </View>
  );
};

const { ids, styles } = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 4,
  },
  checkbox: {
    height: 20,
    width: 20,
    fontSize: 12,
    alignItems: "center",
    justifyContent: "center",
    "@media (max-height: 700px) or (max-width: 340px)": {
      height: 15,
      width: 15,
      fontSize: 10,
    },
  },
  labelTouchable: {
    marginLeft: 5,
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  label: {
    fontFamily: family.bold,
    fontSize: 14,
    color: colors.brown,
    "@media (max-height: 700px) or (max-width: 340px)": {
      fontSize: 10,
    },
  },
});
export default CustomCheckBox;
