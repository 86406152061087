import React from "react";
import { StyleSheet, Text, TouchableOpacity } from "react-native";
import { family } from "../../../../constants/theme";
import i18n from "../../../../i18n/i18n";

const PayButton = ({ onPress }) => {
  return (
    <TouchableOpacity style={styles.button} onPress={onPress}>
      <Text style={styles.buttonText}>Confirmar y pagar</Text>
    </TouchableOpacity>
  );
};

export default PayButton;

const styles = StyleSheet.create({
  button: {
    width: "100%",
    height: 30,
    marginTop: 10,
    borderRadius: 40,
    backgroundColor: "white",
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  buttonText: {
    color: "black",
    fontFamily: family.bold,
    fontSize: 14,
  },
});
