import React from "react";
import { StyleSheet, Text, View } from "react-native";
import ContentLoader, { Rect } from "react-content-loader/native";

// Constants
import { colors } from "../../../../constants/theme";

const ProgressBar = ({ title, active, current, big }) => {
  const bgColor = active ? colors.green : current ? "#73D3C7" : "white";

  return (
    <View style={[styles.container, big && { width: "40%" }]}>
      <Text style={styles.title}>{title}</Text>
      <View style={[styles.progressBar, { backgroundColor: bgColor }]}>
        {current && (
          <ContentLoader
            speed={1}
            width={"100%"}
            style={{ borderRadius: 10 }}
            height={10}
            backgroundColor={bgColor}
            foregroundColor={colors.green}
          >
            <Rect width="100%" height="10" />
          </ContentLoader>
        )}
      </View>
    </View>
  );
};

export default ProgressBar;

const styles = StyleSheet.create({
  container: {
    width: "20%",
    justifyContent: "center",
    textAlign: "center",
  },
  title: {
    color: "white",
    textAlign: "center",
  },
  progressBar: {
    backgroundColor: colors.green,
    height: 10,
    borderRadius: 10,
    marginTop: 5,
    overflow: "hidden",
  },
});
