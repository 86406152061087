import { baseVersion } from "../../../config";
import { URlOrder } from "../../constants/urls";

export const disableMethod = async (
  shopperReference,
  storedPaymentMethodId
) => {
  try {
    const response = await fetch(`${URlOrder}/pay/adyen/card/disable`, {
      headers: {
        "Content-Type": "application/json",
         
      },
      body: JSON.stringify({
        shopperReference: shopperReference,
        recurringDetailReference: storedPaymentMethodId,
      }),
      method: "POST",
    });

    const resData = await response.json();
    if (resData.status === "success") {
      return "success";
    } else {
      return "fail";
    }
  } catch (error) {
    console.log("[error] ", JSON.stringify(error));
    return "fail";
  }
};
