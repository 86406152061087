import React from "react";
import { Text } from "react-native";

// Components
import Screen from "../../components/atoms/Screen";

const ProfileNotificationsScreen = () => {
  return (
    <Screen>
      <Text>Notificaciones</Text>
    </Screen>
  );
};

export default ProfileNotificationsScreen;
