// React & Libraries
import React from "react";
import { StyleSheet, Text } from "react-native";

// Constants
import { colors, size, family } from "../../constants/theme";

const ErrorMessage = ({ error }) => <Text style={styles.error}>{error}</Text>;

export default ErrorMessage;

const styles = StyleSheet.create({
  error: {
    fontFamily: family.normal,
    color: colors.primaryColor,
    fontSize: size.small,
  },
});
