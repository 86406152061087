import React from "react";
import {
  StyleSheet,
  ScrollView,
  Text,
  View,
  TouchableOpacity,
} from "react-native";
import { AntDesign } from "@expo/vector-icons";

// Components
import ModalContainer from "./TicketVariantsModal/ModalContainer";
import Description from "./TicketVariantsModal/Description";

// Constants
import { family, size } from "../../../constants/theme";

const DescriptionModal = ({ visible, setModal, data }) => {
  return (
    <ModalContainer modalVisible={visible} handleModal={() => setModal(false)}>
      <View style={styles.wrapper}>
        <TouchableOpacity style={styles.iconWrapper} onPress={() => setModal()}>
          <AntDesign name="close" size={24} color={"white"} />
        </TouchableOpacity>
        <ScrollView style={styles.container}>
          {data !== null && data !== undefined && data !== "" && (
            <Description description={data} />
          )}
        </ScrollView>
      </View>
    </ModalContainer>
  );
};
/*<Description description={data} />*/
export default DescriptionModal;

const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
  iconWrapper: {
    width: 50,
    height: 50,
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 2,
  },
  imageWrapper: {
    width: "100%",
    height: 200,
  },
  confirmButton: {
    width: "100%",
    height: 46,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: 10,
  },
  confirmText: {
    color: "black",
    fontFamily: family.bold,
    fontSize: size.big,
  },
  wrapper: {
    width: "100%",
    height: "100%",
    padding: 10,
    borderRadius: 20,
    backgroundColor: "black",
    position: "relative",
  },
});
