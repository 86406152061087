import React from "react";
import StyleSheet from "react-native-media-query";
import { Text, useWindowDimensions, View } from "react-native";

// Components
import Divider from "../../../atoms/Divider";

// Constants
import { colors, family } from "../../../../constants/theme";

const Hero = ({ title, subtitle, secondaryTitle, customStyle }) => {
  const height = useWindowDimensions().height;
  const width = useWindowDimensions().width;

  const normalSCreen = height > 700 && width > 340;

  if (normalSCreen) {
    return (
      <View style={styles.container}>
        <Text
          style={[styles.title, { color: customStyle?.textColor }]}
          dataSet={{ media: ids.title }}
        >
          {title}
        </Text>
        <Divider height={20} />
        <Text
          style={[styles.subtitle, { color: customStyle?.textColor }]}
          dataSet={{ media: ids.subtitle }}
        >
          {subtitle}
        </Text>
      </View>
    );
  } else {
    <View style={styles.container}>
      <Text
        style={[styles.title, { color: customStyle?.textColor }]}
        dataSet={{ media: ids.title }}
      >
        {secondaryTitle}
      </Text>
    </View>;
  }
};

export default Hero;

const { ids, styles } = StyleSheet.create({
  container: {
    width: "100%",
    paddingHorizontal: 10,
    alignItems: "center",
  },
  title: {
    fontFamily: family.bold,
    fontSize: 26,
    textAlign: "center",
    color: colors.brown,
    "@media (max-height: 700px) or (max-width: 340px)": {
      fontSize: 18,
    },
  },
  subtitle: {
    fontFamily: family.normal,
    fontSize: 16,
    color: colors.brown,
    textAlign: "center",
    "@media (max-height: 700px) or (max-width: 340px)": {
      fontSize: 12,
    },
  },
});
