export const getChoices = (rawChoices = []) => {
  let result = [];
  rawChoices.forEach((category) => {
    const aux = {
      title: category.name,
      gid: category.gid,
      numOfChoices: 1,
      plates: [],
    };
    category.products.map((plate) => {
      const auxItem = aux.plates.find((el) => el.gid === plate.gid);
      if (auxItem) {
        aux.numOfChoices += 1;
      } else {
        aux.plates.push(plate);
      }
    });
    result.push(aux);
  });
  return result;
};
