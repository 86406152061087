import React, { useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { family } from "../../../constants/theme";

const TicketDescription = ({ data }) => {
  useEffect(() => {
    document.getElementById("description").innerHTML = data;
  }, []);

  return (
    <View style={styles.info}>
      <div
        style={{
          fontFamily: family.normal,
          fontSize: 14,
          color: "white",
        }}
        id="description"
      />
    </View>
  );
};

export default TicketDescription;

const styles = StyleSheet.create({
  info: {
    marginTop: 10,
    flex: 1,
  },
});
