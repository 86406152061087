import React from "react";

// Icons
import LiveIcon from "../../assets/icons/LiveIcon";
import ExploreIcon from "../../assets/icons/ExploreIcon";
import OrdersIcon from "../../assets/icons/OrdersIcon";
import ProfileIcon from "../../assets/icons/ProfileIcon";
import AppleIcon from "../../assets/icons/AppleIcon";
import FoodIcon from "../../assets/icons/FoodIcon";
import DeliveryIcon from "../../assets/icons/DeliveryIcon";
import LocationIcon from "../../assets/icons/LocationIcon";
import NotificationIcon from "../../assets/icons/NotificationIcon";
import MailIcon from "../../assets/icons/MailIcon";
import LogoIcon from "../../assets/icons/LogoIcon";
import GoogleIcon from "../../assets/icons/GoogleIcon";
import BagIcon from "../../assets/icons/BagIcon";
import SearchIcon from "../../assets/icons/SearchIcon";
import PickUpIcon from "../../assets/icons/PickUpIcon";
import MyPlaceIcon from "../../assets/icons/MyPlace";
import EmptyOrder from "../../assets/icons/EmptyOrder";
import ReadyIcon from "../../assets/icons/ReadyIcon";
import EmptyScreen1 from "../../assets/icons/EmptyScreen1";
import WhiteIcon from "../../assets/icons/WhiteIcon";
import LogoTicketing from "../../assets/icons/LogoTicketing";
import LogoTicketingVertical from "../../assets/icons/LogoTicketingVertical";

const CustomIcon = ({ name, color = "black", ...rest }) => {
  switch (name) {
    case "live":
      return <LiveIcon fill={color} {...rest} />;
    case "eventos":
      return <ExploreIcon fill={color} {...rest} />;
    case "pedidos":
      return <OrdersIcon fill={color} {...rest} />;
    case "apple":
      return <AppleIcon fill={color} {...rest} />;
    case "delivery":
      return <DeliveryIcon fill={color} {...rest} />;
    case "location":
      return <LocationIcon fill={color} {...rest} />;
    case "food":
      return <FoodIcon fill={color} {...rest} />;
    case "logo":
      return <LogoIcon fill={color} {...rest} />;
    case "mail":
      return <MailIcon fill={color} {...rest} />;
    case "notifications":
      return <NotificationIcon fill={color} {...rest} />;
    case "google":
      return <GoogleIcon fill={color} {...rest} />;
    case "perfil":
      return <ProfileIcon fill={color} {...rest} />;
    case "bag":
      return <BagIcon fill={color} {...rest} />;
    case "emptyOrder":
      return <EmptyOrder fill={color} {...rest} />;
    case "search":
      return <SearchIcon stroke={color} {...rest} />;
    case "recoger":
      return <PickUpIcon {...rest} />;
    case "ready":
      return <ReadyIcon {...rest} />;
    case "whiteIcon":
      return <WhiteIcon {...rest} />;
    case "ticketing":
      return <LogoTicketing {...rest} />;
    case "en mi sitio":
      return <MyPlaceIcon {...rest} />;
    case "ticketingVertical":
      return <LogoTicketingVertical {...rest} />;
    case "empty1":
      return <EmptyScreen1 {...rest} />;
  }
};

export default CustomIcon;
