import React from "react";
import StyleSheet from "react-native-media-query";
import { Text, View } from "react-native";
import { v4 as uuidv4 } from "uuid";

// Constants
import { colors, family } from "../../../../../constants/theme";

// Utils
import { getVariantsText } from "../../../../../utils/variantsMethods";

const VariantsText = ({
  variants = [],
  color,
  showTitles = true,
  separator,
}) => {
  //todo style
  return variants.length > 0 ? (
    <Text dataSet={{ media: ids.text }} style={styles.text}>
      {getVariantsText(variants, showTitles, separator).map((text) => {
        const key = uuidv4();
        return (
          <Text
            key={key}
            dataSet={{ media: ids.text }}
            style={[
              styles.text,
              {
                color: color,
                fontFamily: text.type === "bold" ? family.bold : family.normal,
              },
            ]}
          >
            {text.content}
          </Text>
        );
      })}
    </Text>
  ) : (
    <View></View>
  );
};

export default VariantsText;

const { ids, styles } = StyleSheet.create({
  text: {
    fontFamily: family.normal,
    color: colors.brown,
    fontSize: 12,
  },
});
