import React from "react";
import { StyleSheet, Text, View } from "react-native";

// Components
import CustomCheckBox from "../../../atoms/CustomCheckBox";

// Hooks
import useForceRender from "../../../../hooks/useForceRender";
import { colors, family, size } from "../../../../constants/theme";

const Ingredients = ({
  data,
  setIngredients,
  title,
  ingredientsList,
  color,
}) => {
  const [forceRender] = useForceRender();
  const handleIngredients = (el) => {
    if (ingredientsList.includes(el)) {
      setIngredients(ingredientsList.filter((ing) => ing !== el));
    } else {
      setIngredients([...ingredientsList, el]);
    }
    forceRender();
  };
  return (
    <View style={styles.varsContainer}>
      <Text
        style={[styles.title, { marginBottom: 5 }, color && { color: color }]}
      >
        {title}
      </Text>
      {data.map((ig) => (
        <CustomCheckBox
          key={ig}
          label={ig}
          value={!ingredientsList.includes(ig)}
          onPress={() => handleIngredients(ig)}
          textColor={color}
        />
      ))}
    </View>
  );
};

export default Ingredients;

const styles = StyleSheet.create({
  varsContainer: {
    paddingVertical: 10,
  },
  title: {
    fontSize: size.small,
    color: colors.brown,
    fontFamily: family.bold,
  },
});
