import {
  FETCH_LOCATION,
  AUTH_USER,
  LOG_OUT,
  USER_DELETE,
  EDIT,
} from "./userTypes";

import { signOutFirebase } from "../../services/firebase";

const initialState = {
  location: {
    lat: null,
    lng: null,
    address: "",
  },
  user: {
    name: "",
    gid: null,
    email: null
  },
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LOCATION: {
      return {
        ...state,
        location: action.payload,
      };
    }
    case AUTH_USER: {
      const { name, gid, email } = action.payload || {};
      const newUser = {
        name: name || "",
        gid: gid || null,
        email: email || null,
      };
      return {
        ...state,
        user: { ...newUser },
      };
    }
    case USER_DELETE: {
      return {
        ...state,
        user: {
          name: "",
          gid: null,
        },
      };
    }
    case LOG_OUT: {
      // todo: logout firebase
      signOutFirebase();
      return {
        ...state,
        user: {
          name: "",
          gid: null,
        },
      };
    }

    case EDIT: {
      let auxUser = state.user;
      let properties = action.payload;
      return {
        ...state,
        user: { ...auxUser, ...properties },
      };
    }

    default:
      return state;
  }
};

export default userReducer;
