import i18n from "i18n-js";
import * as Localization from 'expo-localization';
import es from "./languages/es.json"
import pt from "./languages/pt.json"

i18n.fallbacks = true;

i18n.translations = {
  es,
  pt
};

i18n.locale = Localization.locale;

i18n.defaultLocale= 'es'

export default i18n;

