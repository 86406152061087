import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ScrollView, View, StyleSheet } from "react-native";

// Components
import EmptyScreen from "../../../components/molecules/EmptyScreen";
import EventTypeItem from "./EventTypeItem";
import Divider from "../../../components/atoms/Divider";
import LoadingScreen from "../EventListScreen/components/LoadingScreen";
import Screen from "../../../components/atoms/Screen";

// Redux Actions
import { fetchEventTypes } from "../../../redux";

const EventTypesScreen = ({ navigation, fetchEventTypes, events }) => {
  const [loading, setLoading] = useState(false);
  const getData = async () => {
    setLoading(true);
    await fetchEventTypes();
    setLoading(false);
  };

  const reloadHandler = async () => {
    await fetchEventTypes();
  };
  useEffect(() => {
    getData();
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  if (events?.length === 0) {
    return (
      <EmptyScreen
        title="No hay eventos"
        onReload={reloadHandler}
        id="explore"
      />
    );
  }

  return (
    <Screen>
      <View style={styles.content}>
        <ScrollView style={{ height: 1 }} showsVerticalScrollIndicator={false}>
          {events.map((item) => (
            <>
              <Divider height={15} />
              <EventTypeItem
                key={item.gid}
                data={item}
                navigation={navigation}
              />
            </>
          ))}
          <Divider height={100} />
        </ScrollView>
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  content: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

const mapStateToProps = (state) => {
  return {
    events: state.events.eventTypes,
  };
};

const mapDispatchToProps = {
  fetchEventTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventTypesScreen);
