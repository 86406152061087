export const addLight = function (color, amount) {
  let cc = parseInt(color, 16) + amount;
  let c = cc > 255 ? 255 : cc;
  c = c.toString(16).length > 1 ? c.toString(16) : `0${c.toString(16)}`;
  return c;
};

export const lighten = (color, amount) => {
  if (color) {
    color = color.indexOf("#") >= 0 ? color.substring(1, color.length) : color;
    amount = parseInt((255 * amount) / 100);
    return (color = `#${addLight(color.substring(0, 2), amount)}${addLight(
      color.substring(2, 4),
      amount
    )}${addLight(color.substring(4, 6), amount)}`);
  } else {
    return null;
  }
};

export const darken = (color, amount) => {
  color = color.indexOf("#") >= 0 ? color.substring(1, color.length) : color;
  amount = parseInt((255 * amount) / 100);
  return (color = `#${subtractLight(
    color.substring(0, 2),
    amount
  )}${subtractLight(color.substring(2, 4), amount)}${subtractLight(
    color.substring(4, 6),
    amount
  )}`);
};

export const subtractLight = function (color, amount) {
  if (color) {
    let cc = parseInt(color, 16) - amount;
    let c = cc < 0 ? 0 : cc;
    c = c.toString(16).length > 1 ? c.toString(16) : `0${c.toString(16)}`;
    return c;
  } else {
    return null;
  }
};

export const isLight = (color) => {
  if (color) {
    const hex = color.replace("#", "");
    const c_r = parseInt(hex.substr(0, 2), 16);
    const c_g = parseInt(hex.substr(2, 2), 16);
    const c_b = parseInt(hex.substr(4, 2), 16);
    const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
    return brightness > 155;
  } else {
    return false;
  }
};
