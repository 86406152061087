import React from "react";
import { StyleSheet, Text, View, TouchableOpacity } from "react-native";
import { AntDesign } from "@expo/vector-icons";
import { family, size } from "../../../../constants/theme";

const Header = ({ title, onPress }) => {
  return (
    <View style={styles.header}>
      <View style={styles.titleWrapper}>
        <Text style={styles.title}>{title}</Text>
      </View>
      <TouchableOpacity style={styles.iconWrapper} onPress={onPress}>
        <AntDesign name="close" size={24} color={"white"} />
      </TouchableOpacity>
    </View>
  );
};

export default Header;

const styles = StyleSheet.create({
  header: {
    width: "100%",
    height: 50,
    // position: 'sticky'
  },
  titleWrapper: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "black",
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
  },
  iconWrapper: {
    width: 50,
    height: 50,
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    right: 0,
    top: 0,
  },
  title: {
    fontFamily: family.bold,
    fontSize: size.big,
    color: "white",
  },
});
