import React from "react";
import { StyleSheet, Text, View } from "react-native";

// Components
import Divider from "../../atoms/Divider";

// Constants
import { colors, family } from "../../../constants/theme";

const ProductLines = ({ shops, maxItems }) => {
  return (
    <>
      {shops?.map((shop) => {
        const orders = maxItems
          ? shop?.orders.slice(0, maxItems)
          : shop?.orders;
        return orders?.map((item, idx) => {
          const isLastItemWithMaxItemsApplied =
            idx === orders.length - 1 && orders.length < shop?.orders.length;
          const name = item.product.name || item.name;
          if (item.quantityAvailable > 0) {
            return (
              <>
                <Text style={styles.item} key={item.gid}>
                  {item.quantityAvailable + "x "}
                  {isLastItemWithMaxItemsApplied ? name.slice(0, 15) : name}
                  {isLastItemWithMaxItemsApplied ? "..." : ""}
                </Text>
                <Divider height={5} />
              </>
            );
          }
        });
      })}
    </>
  );
};

export default ProductLines;

const styles = StyleSheet.create({
  item: {
    fontSize: 14,
    color: colors.darkGrey,
    fontFamily: family.normal,
  },
});
