import React from "react";
import { View } from "react-native";
import Svg, { Image } from "react-native-svg";
import StyleSheet from "react-native-media-query";

const LogoTicketing = (props) => {
  return (
    <View style={styles.container} dataSet={{ media: ids.container }}>
      <Svg {...props}>
        <Image {...props} href={require("../experiences.svg")} />
      </Svg>
    </View>
  );
};

const { ids, styles } = StyleSheet.create({
  container: {
    zIndex: 0,
    height: "100%",
    marginTop: 10,
    justifyContent: "center",

  },
});

export default LogoTicketing;
