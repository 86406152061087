import React from "react";
import Svg, { Path, Circle } from "react-native-svg";

const ReadyIcon = ({ ...props }) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      style={{
        enableBackground: "new 0 0 512 512",
      }}
      xmlSpace="preserve"
      {...props}
    >
      <Circle
        style={{
          fill: "#6dc180",
        }}
        cx={256}
        cy={256}
        r={256}
      />
      <Path
        style={{
          fill: "#5ca15d",
        }}
        d="M256 0v512c141.385 0 256-114.615 256-256S397.385 0 256 0z"
      />
      <Path
        style={{
          fill: "#f2f2f4",
        }}
        d="M219.429 367.932 108.606 257.108l38.788-38.788 72.035 72.033L355.463 154.32l38.788 38.788z"
      />
      <Path
        style={{
          fill: "#dfdfe1",
        }}
        d="m256 331.361 138.251-138.253-38.788-38.788L256 253.782z"
      />
    </Svg>
  );
};

export default ReadyIcon;
