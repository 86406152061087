import React from "react";
import { createAppContainer, createSwitchNavigator } from "react-navigation";
import { createStackNavigator } from "@react-navigation/stack";

// Components
import NavigationTabs from "../components/Footer";

// Screens
import StartupScreen from "../screens/onboarding/StartupScreen";
import OnboardingScreen from "../screens/onboarding/OnboardingScreen";
import UpdateScreen from "../screens/onboarding/UpdateScreen";

const Stack = createStackNavigator();

const AppNavigation = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        title: "Gopick"
      }}
    >
      <Stack.Screen name="Home" component={NavigationTabs} />
    </Stack.Navigator>
  );
};

const MainNavigation = createSwitchNavigator({
  Startup: StartupScreen,
  UpdateScreen: UpdateScreen,
  Onboarding: OnboardingScreen,
  App: AppNavigation,
});

const AppNavigator = createAppContainer(MainNavigation);

export default AppNavigator;
