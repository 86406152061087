import React from "react";
import {
  StyleSheet,
  TouchableOpacity,
  useWindowDimensions,
  Platform,
} from "react-native";
import { FontAwesome } from "@expo/vector-icons";

// Constants
import { colors } from "../../../constants/theme";

const IconButtonTickets = ({
  icon,
  size,
  onPress,
  color,
  textColor,
  ...props
}) => {
  const width = useWindowDimensions().width;

  const small = () => {
    if (Platform.OS === "web" && width < 500) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <TouchableOpacity
      style={[
        styles.container,
        { backgroundColor: color || colors.primaryColor },
        small() === true && { width: 18, height: 18 },
      ]}
      onPress={onPress}
      {...props}
    >
      <FontAwesome
        name={icon}
        size={small() === true ? 12 : 16}
        color={textColor || "white"}
      />
    </TouchableOpacity>
  );
};

export default IconButtonTickets;

const styles = StyleSheet.create({
  container: {
    width: 26,
    height: 26,
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 1,
    paddingTop: 1,
  },
});
