import { URLbase, URlOrder } from "../constants/urls";
import io from "socket.io-client";

let socket;
export const initiateSocket = async (token, path = "orders") => {
  if (token !== null && token !== undefined && token !== "") {
    socket = io(`${URLbase}/${path}`, {
      transports: ["websocket"],
      path: "/sockets",
      extraHeaders: {
        Authorization: `Bearer ${token}`,
      },
      auth: {
        authorization: `Bearer ${token}`,
      },
      reconnectionAttempts: 15,
    });

    onSocketConnect(socket);
    onSocketError(socket);
    return true;
  }
};

export const onSocketConnect = (socket) => {
  socket.on("connect", () => {
    console.log("connected");
  });
};

export const onSocketError = (socket) => {
  socket.on("connect_error", (err) => {
    console.log("socket error", err);
  });
};

export const createOrder = (orders, setData) => {
  socket.emit("orders:create", orders);
  socket.on("orders:create:result", (result) => {
    setData(result);
  });
};

/**
 *
 * @param {string} token
 * @param {*} orders
 * @param {function} setData
 */


export const ordersUpdate = (updateOrder) => {
  socket.on("orders:update:result", (result) => {
    updateOrder(result);
  });
};

const getFormattedObject = (orders, user) => {
  let object = {
    gid_user: user.gid,
    shops: [],
    products: [],
    families: [],
  };

  orders.map((shop) => {
    object.shops.push(shop.id_shop);
    shop.order_lines.map((line) => {
      const separator = line.gid.indexOf(",");
      const realGid = line.gid.slice(0, separator);
      object.products.push(parseInt(realGid));
      object.families.push(line.family);
    });
  });
  return object;
};