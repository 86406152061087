import React from "react";
import StyleSheet from "react-native-media-query";
import { Text, View } from "react-native";

// Constants
import { colors, family } from "../../../../../constants/theme";

// Utils
import { getFormattedPrice } from "../../../../../utils/productMethods";

const Price = ({ amount, color }) => {
  return amount ? (
    <View>
      <Text
        dataSet={{ media: ids.text }}
        style={[styles.text, { color: color }]}
      >
        {getFormattedPrice(amount)}
      </Text>
    </View>
  ) : (
    <View></View>
  );
};

export default Price;

const { ids, styles } = StyleSheet.create({
  text: {
    fontSize: 14,
    marginLeft: "auto",
    fontFamily: family.normal,
    color: colors.darkGrey,
    "@media (max-width: 320px)": {
      fontSize: 12,
    },
  },
});
