import React from "react";
import {
  StyleSheet,
  Text,
  useWindowDimensions,
  TouchableOpacity,
  View,
} from "react-native";
import { FontAwesome5 } from "@expo/vector-icons";

// Components
import Divider from "../../../../components/atoms/Divider";

// Constants
import { family, RADIUS } from "../../../../constants/theme";

const Section = ({ title, subtitles = [], children, customStyle, onPress }) => {
  const width = useWindowDimensions().width;
  const {  accentColor, textColor } = customStyle;
  return (
    <View style={styles.container}>
      {title && (
        <TouchableOpacity style={styles.textContainer} onPress={onPress}>
          <FontAwesome5
            name="comment-dots"
            size={width > 320 ? 16 : 12}
            color={accentColor}
          />
          <Divider width={10} />
          <Text style={[styles.title, { color: accentColor }]}>{title}</Text>
        </TouchableOpacity>
      )}
      {subtitles.length > 0 && <Divider height={2} />}
      {subtitles.map((text) => (
        <Text
          numberOfLines={1}
          style={[styles.subtitle, { color: textColor }]}
        >
          {text}
        </Text>
      ))}
      {children}
    </View>
  );
};

export default Section;

const styles = StyleSheet.create({
  container: {
    borderRadius: RADIUS,
    paddingVertical: 12,
    paddingHorizontal: 18,
    flexDirection: "column",
  },
  textContainer: {
    flexDirection: "row",
  },
  title: {
    fontFamily: family.bold,
    fontSize: 14,
  },
  subtitle: {
    marginBottom: 2,
    fontFamily: family.normal,
    fontSize: 12,
  },
});
