const daysOfWeek = [
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
  "Domingo",
];

export const getNextOpeningTime = (intervals) => {
  const now = new Date();
  const currentTimestamp = now.getTime();

  for (let i = 0; i < intervals.length; i++) {
    const fromTimestamp = new Date(intervals[i].from).getTime();
    const toTimestamp = new Date(intervals[i].to).getTime();

    if (currentTimestamp >= fromTimestamp && currentTimestamp <= toTimestamp) {
      let h = now.getHours();
      let m = now.getMinutes();
      let time = h + ":" + m;

      let today = now.toLocaleDateString("es-ES", { weekday: "long" });
      today = today[0].toUpperCase() + today.substring(1);
      const todaySchedule = intervals[i].timeTable.find(
        (day) => day.dayOfWeek === today
      );

      const nextOpenInterval = todaySchedule?.intervals.find((interval) => {
        return isBiggerFirstHour(interval.openingTime, time);
      });

      if (!nextOpenInterval) {
        const nextDay = getNextOpenDay(intervals[i].timeTable, today);
        return {
          day: nextDay,
          hour: intervals[i].timeTable.find((day) => day.dayOfWeek === nextDay)
            ?.intervals[0].openingTime,
        };
      }
      return { day: null, hour: nextOpenInterval.openingTime };
    } else {
      return { day: null, hour: null, message: "expired" };
    }
  }
};

function getNextOpenDay(schedule, currentDay) {
  const currentDayIndex = daysOfWeek.indexOf(currentDay);
  for (let i = 1; i <= 7; i++) {
    const nextDayIndex = (currentDayIndex + i) % 7;
    const nextDay = daysOfWeek[nextDayIndex];
    const nextDaySchedule = schedule.find((day) => day.dayOfWeek === nextDay);

    if (nextDaySchedule?.intervals.length > 0) {
      return nextDay;
    }
  }

  return null;
}

function isBiggerFirstHour(hora1, hora2) {
  const [horas1, minutos1] = hora1.split(":").map(Number);
  const [horas2, minutos2] = hora2.split(":").map(Number);

  if (horas1 > horas2) {
    return true;
  } else if (horas1 === horas2 && minutos1 > minutos2) {
    return true;
  } else {
    return false;
  }
}
