import React from "react";
import { StyleSheet, Text, TouchableOpacity } from "react-native";

// Constants
import { colors, family } from "../../constants/theme";

const Button = ({ colored, text, onPress }) => {
  return (
    <TouchableOpacity
      style={[
        styles.button,
        colored && { backgroundColor: colors.primaryColor },
      ]}
      onPress={onPress}
    >
      <Text style={[styles.text, colored && { color: colors.white }]}>
        {text}
      </Text>
    </TouchableOpacity>
  );
};

export default Button;

const styles = StyleSheet.create({
  button: {
    height: 34,
    width: 200,
    borderRadius: 5,
    backgroundColor: colors.white,
    borderWidth: 1,
    borderColor: colors.primaryColor,
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    fontSize: 12,
    fontFamily: family.normal,
    color: colors.primaryColor,
  },
});
