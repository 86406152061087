import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { colors, family, size } from "../../../../../constants/theme";
import { getVariantsText } from "../../../../../utils/variantsMethods";

const VariantsText = ({ data }) => {
  return data?.length > 0 ? (
    <View style={{ marginTop: 4 }}>
      <Text key={data} style={styles.descText}>
        {getVariantsText(data).map((text, index) => (
          <Text
            key={index}
            style={[
              styles.descText,
              {
                fontFamily: text.type === "bold" ? family.bold : family.normal,
              },
            ]}
          >
            {text.content}
          </Text>
        ))}
      </Text>
    </View>
  ) : (
    <View></View>
  );
};

export default VariantsText;

const styles = StyleSheet.create({
  descText: {
    lineHeight: 16,
    fontFamily: family.normal,
    fontSize: size.small,
    color: colors.brown,
  },
  text: {
    fontFamily: family.normal,
    color: colors.brown,
    fontSize: size.small,
  },
});
