import { FontAwesome5 } from "@expo/vector-icons";
import React, { useState } from "react";
import { TouchableOpacity, useWindowDimensions } from "react-native";
import { StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import Divider from "../../../../components/atoms/Divider";
import { colors, family } from "../../../../constants/theme";
import CommentModal from "../modals/CommentModal";

// Components

const CommentSection = ({ comment }) => {
  const [modal, setModal] = useState();
  const width = useWindowDimensions().width;

  const textHandler = () => {
    setModal("Comment");
  };

  return (
    <>
      <View style={styles.container}>
        <TouchableOpacity style={styles.textContainer} onPress={textHandler}>
          <FontAwesome5
            name="comment-dots"
            size={width > 320 ? 16 : 12}
            color={colors.secondary}
          />
          <Divider width={10} />
          <Text style={styles.title}>{"¿Algún comentario?"}</Text>
        </TouchableOpacity>
        {comment.length > 0 && (
          <>
            <Divider height={2} />
            <Text numberOfLines={1} style={styles.subtitle}>
              {comment}
            </Text>
          </>
        )}
      </View>
      <CommentModal
        visible={modal === "Comment"}
        setModal={setModal}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    comment: state.laterOrders.comment,
  };
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: 12,
    paddingHorizontal: 18,
    flexDirection: "column",
  },
  textContainer: {
    flexDirection: "row",
  },
  title: {
    fontFamily: family.bold,
    fontSize: 14,
    color: colors.secondary,
  },
  subtitle: {
    marginBottom: 2,
    fontFamily: family.normal,
    fontSize: 12,
    color: colors.brown,
  },
});
export default connect(mapStateToProps)(CommentSection);
