import React from "react";
import { Platform } from "react-native";
import LottieView from "lottie-react-native";
import AnimationWeb from "./AnimationWeb";

const Animation = ({
  animation,
  name,
  style,
  loop = true,
  autoPlay = true,
  onLoading = () => {},
}) => {
  return (
    <>
      {Platform.OS === "web" ? (
        <AnimationWeb
          animation={animation}
          name={name}
          style={style}
          loop={loop}
          autoPlay={autoPlay}
          onLoading={onLoading}
        />
      ) : (
        <LottieView
          autoPlay={autoPlay}
          loop={loop}
          style={style}
          source={animation}
        />
      )}
    </>
  );
};

export default Animation;
