import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import { connect } from "react-redux";

// Components
import LocationsModal from "../../../../../components/organisms/modals/LocationsModal";
import WarningModal from "../../../../../components/organisms/modals/WarningModal";
import Divider from "../../../../../components/atoms/Divider";
import Wrapper from "./Wrapper";
import Title from "./Title";
import ScheduleTag from "./ScheduleTag";
import PrecomprasTag from "./PrecomprasTag";
import TimeLine from "./TimeLine";
import Description from "./Description";
import ClosedTag from "./ClosedTag";

// Methods
import { isShopOpened } from "../../../../../utils/shopMethods";

// Redux
import { setService } from "../../../../../redux";

const ShopItem = ({ data, onPress, navigation, setService, eventId }) => {
  const { image, name, schedule, gid, description } = data;
  // TODO: descripcion?
  const [modal, setModal] = useState();
  const [selectedService, setSelectedService] = useState();
  const [localization, setLocalization] = useState([]);

  const abierto = isShopOpened(data);
  const noService = data?.services.filter((el) => el.key === "no_service");
  const isPrecompras = noService.length > 0;
  const scheduleable = schedule.scheduled === 1 && !isPrecompras;

  useEffect(() => {
    setLocalization([]);
  }, [selectedService]);

  const handleItemClick = () => {
    if (!abierto) {
      setModal("Warning");
    } else {
      if (isPrecompras) {
        onPress(noService[0].key, "", data);
        setService({
          service: noService[0],
          shop: data,
          location: "",
          gid_location: null,
        });
      } else if (
        data?.services?.length === 1 &&
        data?.services[0]?.levels_locations === "not_show"
      ) {
        setService({
          service: data.services[0],
          shop: data,
          location: "",
          gid_location: null,
        });
        onPress(data.services[0].key, "", data);
      } else {
        setModal("Locations");
      }
    }
  };

  return (
    <Wrapper opened={abierto} image={image} onPress={handleItemClick}>
      <View style={styles.content}>
        <View style={styles.tagContainer}>
          {!abierto && <ClosedTag />}
          {isPrecompras && (
            <>
              <Divider width={5} />
              <PrecomprasTag />
            </>
          )}
          {scheduleable && (
            <>
              <Divider width={5} />
              <ScheduleTag />
            </>
          )}
        </View>
        <View style={styles.infoContainer}>
          <Title title={name} />
          <Divider height={5} />
          {description && <Description description={description} />}
          {description && <Divider height={5} />}
          {abierto && <TimeLine schedules={data?.schedules} />}
        </View>
      </View>

      <LocationsModal
        navigation={navigation}
        services={data.services}
        setSelectedService={setSelectedService}
        setModal={setModal}
        visible={modal === "Locations"}
        shopInfo={{ gid: gid, name: name }}
        onNavigate={onPress}
        eventId={eventId}
        optionsSel={localization}
        setOptionsSel={setLocalization}
        scheduled={schedule.scheduled === 1 ? true : false}
      />
      <WarningModal
        setModal={setModal}
        visible={modal === "Warning"}
        text="Establecimiento cerrado"
      />
    </Wrapper>
  );
};

const styles = StyleSheet.create({
  content: {
    width: "100%",
    height: "100%",
    paddingHorizontal: 18,
    paddingVertical: 12,
    justifyContent: "space-between",
  },
  infoContainer: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  tagContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    display: "flex",
    flexWrap: "wrap",
  },
});

const mapStateToProps = (state) => {
  return { services: state.cart.services };
};

const mapDispatchToProps = {
  setService,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopItem);
