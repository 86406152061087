import { colors } from "../../../../constants/theme";

export const getCustomStyle = (shopData) => {
  const customStyle = {
    custom_color_header:
      shopData.custom_design?.custom_color_header || "#FFFFFF",
    custom_color_letter_header:
      shopData.custom_design?.custom_color_letter_header || colors.primaryColor,
    custom_color_1: shopData.custom_design?.custom_color_1 || "#FFFFFF",
    custom_color_letter_1:
      shopData.custom_design?.custom_color_letter_2 || colors.brown,
    custom_color_2:
      shopData.custom_design?.custom_color_2 || colors.primaryColor,
    custom_color_letter_2:
      shopData.custom_design?.custom_color_letter_2 || colors.white,
    custom_color_letter_2_actions:
      shopData.custom_design?.custom_color_letter_2 || colors.brown,
  };
  const customHeaderStyle = {
    mode: "white",
    color: customStyle.custom_color_header,
    textColor: customStyle.custom_color_letter_header,
  };
  return { customStyle, customHeaderStyle };
};
