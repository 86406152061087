import React, { useState } from "react";
import { View } from "react-native";
import { connect } from "react-redux";
import StyleSheet from "react-native-media-query";

// Components
import TicketHero from "../../components/tickets/containers/TicketHero";
import TicketScreen from "../../components/tickets/components/TicketScreen";
import LastStepInfo from "../../components/tickets/components/LastStepInfo";
import PixelFacebook from "../../components/molecules/PixelFacebook";

// Constants
import { storeLocalData } from "../../utils/miscelanusUtils";

// Utils
import { family } from "../../constants/theme";
import i18n from "../../i18n/i18n";

// Redux
import { resetTicketCart, setCurrentAmount } from "../../redux";

const TicketsPaymentSuccess = ({
  navigation,
  resetTicketCart,
  currentAmount,
  shopCheckoutData,
}) => {
  const [image] = useState(shopCheckoutData?.image);

  const backHandler = () => {
    storeLocalData("alreadyDone", false);
    resetTicketCart();
    navigation.navigate("Tickets", { shopId: shopCheckoutData?.gid });
  };

  return (
    <TicketScreen hideActions shopId={shopCheckoutData?.gid}>
       <TicketHero navigation={navigation} image={image}>

        <View style={styles.buyContainer} dataSet={{ media: ids.buyContainer }}>
          <LastStepInfo
            action={backHandler}
            buttonTitle={i18n.t("tickets.back")}
            textArray={[
              i18n.t("tickets.checkout.success"),
              i18n.t("tickets.checkout.email_sent"),
            ]}
          />
        </View>
        {shopCheckoutData?.id_pixel_code && (
          <PixelFacebook
            id={shopCheckoutData.id_pixel_code}
            amount={currentAmount}
          />
        )}
      </TicketHero>
    </TicketScreen>
  );
};

const mapDispatchToProps = {
  resetTicketCart,
  setCurrentAmount,
};

const mapStateToProps = (state) => {
  return {
    currentAmount: state.tickets.currentAmount,
    shopCheckoutData: state.tickets.shopCheckoutData,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TicketsPaymentSuccess);

const { ids, styles } = StyleSheet.create({
  buyContainer: {
    flexDirection: "row",
    width: "70%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
    borderRadius: 20,
    padding: 10,
    "@media (max-width: 700px)": {
      width: "80%",
    },
    "@media (max-width: 500px)": {
      width: "90%",
    },
  },
  title: {
    fontFamily: family.bold,
    fontSize: 18,
    textAlign: "center",
    color: "white",
    "@media (max-width: 700px)": {
      fontSize: 12,
    },
  },
  textContainer: {
    width: "100%",
    paddingHorizontal: 10,
    alignItems: "center",
    justifyContent: "center",
  },
});
