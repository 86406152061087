import React from "react";
import { View, Text, StyleSheet } from "react-native";

// Constants
import { colors, family } from "../../../../constants/theme";

const ExtraLine = ({ name, amount, big, subtitle }) => {
  return (
    <View style={styles.container}>
      <View style={styles.detailsColumn}>
        <Text>
          <Text
            numberOfLines={2}
            style={[
              styles.text,
              big && { fontFamily: family.bold, fontSize: 16 },
            ]}
          >
            {name}{" "}
          </Text>
          <Text style={styles.subtitle}>{subtitle}</Text>
        </Text>
      </View>
      <View style={styles.priceColumn}>
        <Text
          style={[
            styles.text,
            big && { fontFamily: family.bold, fontSize: 16 },
          ]}
        >
          {amount}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "row",
  },
  priceColumn: {
    justifyContent: "center",
  },
  detailsColumn: {
    flex: 1,
    justifyContent: "flex-start",
    paddingRight: 8,
  },
  text: {
    fontFamily: family.normal,
    fontSize: 14,
    color: colors.brown,
  },
  subtitle: {
    fontFamily: family.normal,
    fontSize: 12,
    color: colors.brown,
  },
});

export default ExtraLine;
