import React from "react";
import { View, LogBox, StyleSheet, Text } from "react-native";
import { v4 as uuidv4 } from "uuid";

// Components
import CustomModal from "../../../../components/atoms/CustomModal";
import CustomButton from "../../../../components/atoms/CustomButton";

// Constants
import { colors, family, size } from "../../../../constants/theme";

LogBox.ignoreLogs(["react-native-styled-text"]);

const DeleteProductModal = ({ visible, setModal, productName, onAccept }) => {
  return (
    <CustomModal
      modalVisible={visible}
      handleModal={() => setModal()}
      canClose={true}
    >
      <View style={styles.titleContainer}>
        <Text style={styles.title}>Quitar producto</Text>
      </View>
      <View style={styles.content}>
        <Text>
          <Text style={styles.text}>¿Quieres quitar </Text>
          <Text style={styles.textBold}>{productName}</Text>
          <Text style={styles.text}> del pedido? </Text>
        </Text>
      </View>
      <View style={styles.buttonContainer}>
        <CustomButton
          active
          onPress={onAccept}
          title="Aceptar"
          marginRight={5}
        />
        <CustomButton
          active
          onPress={() => setModal()}
          title="Cancelar"
          textColor={colors.primaryColor}
          color={colors.white}
        />
      </View>
    </CustomModal>
  );
};

export default DeleteProductModal;

const styles = StyleSheet.create({
  text: {
    fontFamily: family.normal,
    color: colors.brown,
    fontSize: size.small,
  },
  textBold: {
    fontFamily: family.normal,
    color: colors.brown,
    fontSize: size.small,
  },
  title: {
    fontFamily: family.bold,
    color: colors.primaryColor,
    fontSize: 14,
  },
  content: {
    width: "100%",
  },
  titleContainer: {
    width: "100%",
    justifyContent: "flex-start",
    marginBottom: 20,
  },
  buttonContainer: {
    width: "100%",
    flexDirection: "row",
    marginTop: 20,
  },
});
