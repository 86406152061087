import React, { useState, useContext } from "react";
import { StyleSheet, View } from "react-native";

// Components
import Divider from "../../../atoms/Divider";
import TicketWarningModal from "../../modals/TicketWarningModal";
import CommissionLine from "./CommissionLine";
import DiscountButton from "./DiscountButton";
import DiscountLine from "./DiscountLine";
import PayButton from "./PayButton";
import PaymentModal from "../../modals/PaymentModal";
import ProductLines from "./ProductLines";
import TicketLines from "./TicketLines";
import TotalLine from "./TotalLine";

// Redux & Methods
import getFinalTicketLines from "../../methods/getFinalTicketLines";
import { setTicketLinesData } from "../../../../redux";
import { connect } from "react-redux";
import anyEmptyQuestion from "../../methods/anyEmptyQuestion";
import TicketQuestionsModal from "../../modals/TicketQuestionsModal";
import { AuthContext } from "../../../../contexts/AuthContext";

const OrderContainer = ({ orderData, setTicketLinesData }) => {
  const { user: firebaseUser } = useContext(AuthContext);
  const [modalWarn, setModalWarn] = useState();
  const [warning, setWarning] = useState();
  const [orderModal, setOrderModal] = useState();

  const {
    ticketLines,
    productLines,
    commission,
    navigation,
    totalPrice,
    discount,
    answerArray,
    setModal,
    availableCommissions,
    hasQuestions,
    setAnswerArray,
  } = orderData;

  console.log('orderData',orderData)
  const createPayment = () => {
    setTicketLinesData(
      getFinalTicketLines(ticketLines, answerArray, availableCommissions)
    );
    setOrderModal("Adyen");
  };

  const confirmPayment = () => {
    if (firebaseUser) {
      if (hasQuestions && anyEmptyQuestion(answerArray)) {
        setOrderModal("Questions");
      } else {
        createPayment();
      }
    } else {
      setModal("LogIn");
    }
  };

  return (
    <View style={styles.wrapper}>
      <View style={styles.ordersContainer}>
        <TicketLines ticketLines={ticketLines} />
        <ProductLines productLines={productLines} />
        {totalPrice > 0 && <CommissionLine commission={commission} />}
        {discount.amount > 0 && <DiscountLine discount={discount} />}
        <Divider width={"100%"} height={1} color="white" />
        <Divider height={10} />
        <TotalLine totalPrice={totalPrice} commission={commission} />

        <Divider height={10} />
        {/* <DiscountButton setModal={setModal} /> */}
        <PayButton onPress={confirmPayment} />
      </View>
      <TicketWarningModal
        visible={modalWarn === "Warning"}
        setModal={setModalWarn}
        text={warning}
      />
      <PaymentModal
        navigation={navigation}
        setModal={setOrderModal}
        visible={orderModal === "Adyen"}
        setWarningText={setWarning}
      />
      <TicketQuestionsModal
        visible={orderModal === "Questions"}
        setModal={setOrderModal}
        answerArray={answerArray}
        setAnswerArray={setAnswerArray}
        onFinish={createPayment}
      />
    </View>
  );
};

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = {
  setTicketLinesData,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderContainer);

const styles = StyleSheet.create({
  wrapper: {
    width: "100%",
    justifyContent: "space-between",
    padding: 20,
    backgroundColor: "rgba(0,0,0,0.8)",
    borderRadius: 15,
  },
  ordersContainer: {
    flexDirection: "column",
  },
});
