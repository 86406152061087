import React, { useState, useEffect, useContext } from "react";
import StyleSheet from "react-native-media-query";
import { View, Platform, Text, useWindowDimensions, Image } from "react-native";
import { TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import { openURL } from "expo-linking";
import { MaterialIcons } from "@expo/vector-icons";

// Components
import Divider from "../../atoms/Divider";
import TicketLogInModal from "../modals/TicketLogInModal";

// Constants
import { family } from "../../../constants/theme";
import {
  headerTagManager1,
  headerTagManager2,
} from "../../../constants/tagManager";

// Redux
import { logOut } from "../../../redux";
import { fetchPastOrders } from "../../../redux/orders/ordersActions";

// i18N
import i18n from "../../../i18n/i18n";

import { AuthContext } from "../../../contexts/AuthContext";

const TicketsHeader = ({
  logOut,
  user,
  navigation,
  shopId,
  hideActions,
  fetchPastOrders,
  shopData,
}) => {
  const { user: firebaseUser } = useContext(AuthContext);
  const [shop, setShop] = useState(shopId);
  const width = useWindowDimensions().width;
  const [modal, setModal] = useState();
  
  useEffect(() => {
    if (Platform.OS === "web") {
      headerTagManager1;
      headerTagManager2;
    }
  }, []);

  useEffect(() => {
    if (shopId !== null && shopId !== undefined) {
      setShop(shopId);
    } else if (shopData?.gid) {
      setShop(shopData?.gid);
    }
  }, [shopId, shopData]);

  const loginHandler = () => {
    setModal("UserUp");
  };

  const modalAction = () => {
    setModal();
  };

  const logOutHandler = async () => {
    logOut();
    navigation.navigate("Tickets", { shopId: shopId });
  };

  console.log('user',user)

  return (
    <View
      style={styles.headerContainer}
      dataSet={{ media: ids.headerContainer }}
    >
      <View style={styles.headerContent} dataSet={{ media: ids.headerContent }}>
        <Image
          style={[
            styles.image,
            { width: width > 500 ? 170 : 90 },
            { height: width > 500 ? 75 : 50 },
          ]}
          source={
            shop === 433
              ? require("../../../assets/events/433.png")
              : require("../../../assets/experiences.png")
          }
        />
        {!!firebaseUser && !hideActions ? (
          <View style={styles.wrapper} dataSet={{ media: ids.wrapper }}>
            <View
              style={styles.logOutContainer}
              dataSet={{ media: ids.logOutContainer }}
            >
              <View
                style={styles.emailWrapper}
                dataSet={{ media: ids.emailWrapper }}
              >
                <Text
                  numberOfLines={1}
                  style={styles.email}
                  dataSet={{ media: ids.email }}
                >
                  {user?.email}
                </Text>
              </View>
              <Divider height={5} />
              <TouchableOpacity
                onPress={() => openURL("mailto:soporte@gopick-app.com")}
                style={styles.contactButton}
              >
                <Text style={styles.contactButtonText}>
                  {i18n.t("tickets.any_problem")}
                </Text>
              </TouchableOpacity>
            </View>
            <Divider width={width < 500 ? 0 : 10} height={5} />
            <TouchableOpacity onPress={logOutHandler}>
              <MaterialIcons
                name="logout"
                size={width < 500 ? 16 : 24}
                color="white"
              />
            </TouchableOpacity>
          </View>
        ) : (
          !hideActions && (
            <View
              style={styles.loginWrapper}
              dataSet={{ media: ids.loginWrapper }}
            >
              <TouchableOpacity
                onPress={loginHandler}
                style={styles.loginContainer}
              >
                <Text style={styles.logout} dataSet={{ media: ids.logout }}>
                  {i18n.t("tickets.sign_in")}
                </Text>
              </TouchableOpacity>
              <Divider width={width < 500 ? 0 : 10} height={5} />
              <TouchableOpacity
                onPress={() => openURL("mailto:soporte@gopick-app.com")}
                style={styles.contactButton}
                dataSet={{ media: ids.contactButton }}
              >
                <Text style={styles.contactButtonText}>
                  {i18n.t("tickets.contact")}
                </Text>
              </TouchableOpacity>
            </View>
          )
        )}
      </View>

      <TicketLogInModal
        setModal={setModal}
        visible={modal === "UserUp"}
        action={modalAction}
        navigation={navigation}
      />
    </View>
  );
};

const mapDispatchToProps = {
  logOut,
  fetchPastOrders,
};

const mapStateToProps = (state) => {
  return { user: state.user.user, shopData: state.tickets.shopCheckoutData };
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketsHeader);

const { ids, styles } = StyleSheet.create({
  headerContainer: {
    width: "100%",
    height: 75,
    flexDirection: "row",
    justifyContent: "space-between",
    "@media (max-width: 500px)": {
      height: 50,
    },
    justifyContent: "center",
    alignItems: "center",
    position: Platform.OS === "web" ? "fixed" : "relative",
    top: 0,
    zIndex: 10,
    backgroundColor: "rgba(0,0,0,0.6)",
  },
  image: {
    width: 150,
    height: 50,
    resizeMode: "contain",
  },
  headerContent: {
    flex: 1,
    height: "100%",
    maxWidth: 1440,
    flexDirection: "row",
    paddingHorizontal: 40,
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 500px)": {
      paddingHorizontal: 20,
    },
    "@media (max-width: 300px)": {
      paddingHorizontal: 10,
    },
  },
  logOutContainer: {
    height: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 500px)": {
      paddingRight: 10,
    },
    "@media (max-width: 350px)": {
      paddingRight: 0,
    },
  },
  loginContainer: {
    height: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  logoWrapper: {
    height: "100%",
  },
  emailWrapper: {
    maxWidth: 200,
    "@media (max-width: 350px)": {
      maxWidth: 120,
    },
  },
  email: {
    fontFamily: family.normal,
    fontSize: 12,
    color: "white",
    "@media (max-width: 500px)": {
      fontSize: 10,
    },
  },
  logout: {
    fontFamily: family.bold,
    fontSize: 12,
    color: "white",
    textAlign: "right",
    "@media (max-width: 600px)": {
      fontSize: 10,
    },
  },
  wrapper: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 600px)": {
      alignItems: "space-between",
      justifyContent: "space-between",
    },
  },
  loginWrapper: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 600px)": {
      flexDirection: "column",
      alignItems: "space-between",
      justifyContent: "space-between",
    },
  },
  contactButtonText: {
    fontFamily: family.normal,
    fontSize: 10,
    color: "white",
    textAlign: "right",
    color: "rgba(0,0,0,0.6)",
  },
  contactButton: {
    borderRadius: 50,
    backgroundColor: "white",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 5,
    paddingHorizontal: 10,
    "@media (max-width: 600px)": {
      paddingVertical: 2,
      paddingHorizontal: 8,
    },
  },
});
