import React from "react";
import { StyleSheet, View, ScrollView } from "react-native";
import { connect } from "react-redux";

// components
import Divider from "../../../../components/atoms/Divider";
import ShopItem from "./ShopItem";

// Utils
import { toProductScreen } from "../../../../navigation/redirections/toProductScreen";

// Redux
import { addShop, fetchShopById } from "../../../../redux";

const ShopList = ({
  navigation,
  data,
  customStyle,
  addShop,
  eventData,
  fetchShopById,
}) => {
  // customStyle is DEPRECATED, must think about it

  const shopDataHandler = async ({ id, loc, service }) => {
    const response = await fetchShopById({
      id: id,
      locId: loc?.gid_location,
      service: service,
    });
    return response;
  };

  return (
    <View style={styles.container}>
      <ScrollView style={{ height: 1 }} showsVerticalScrollIndicator={false}>
        {data.map((item) => (
          <View key={item.gid || item.id}>
            <Divider height={15} />
            <ShopItem
              navigation={navigation}
              data={item}
              // backgroundColor={custom_color_1}
              // textColor={custom_color_letter_1}
              eventId={eventData.gid}
              onPress={async (service, loc) =>
                shopDataHandler({
                  id: item.gid || item.id,
                  service: service,
                  loc: loc,
                }).then((res) => {
                  if (res) {
                    toProductScreen({
                      navigation: navigation,
                      data: item,
                      res: res,
                      action: () => {
                        addShop(item, res, eventData);
                      },
                    });
                  }
                })
              }
            />
          </View>
        ))}
        <Divider height={100} />
      </ScrollView>
    </View>
  );
};

const mapDispatchToProps = {
  fetchShopById,
  addShop,
};

export default connect(null, mapDispatchToProps)(ShopList);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});
