import React from "react";
import { Text, View } from "react-native";
import StyleSheet from "react-native-media-query";
import { family } from "../../../../constants/theme";
import {
  getFormattedPrice,
  getIngredientsText,
} from "../../../../utils/productMethods";
import { getVariantsText } from "../../../../utils/variantsMethods";

const ProductLines = ({ productLines }) => {
  return (
    <View style={styles.orders}>
      {productLines.map((data) => {
        return (
          <View key={data.hash} style={styles.product}>
            <View style={styles.line}>
              <View style={styles.qtyContainer}>
                <Text style={styles.text} dataSet={{ media: ids.text }}>
                  {data.quantity + "x"}
                </Text>
              </View>
              <View style={styles.content}>
                <Text style={styles.text} dataSet={{ media: ids.text }}>
                  {data.name}
                </Text>
                <Text style={styles.text} dataSet={{ media: ids.text }}>
                  {getFormattedPrice(data.amount * data.quantity)}
                </Text>
              </View>
            </View>
            {data?.variants?.length > 0 && (
              <Text style={styles.text} dataSet={{ media: ids.text }}>
                {getVariantsText(data.variants).map((text, index) => (
                  <Text
                    key={index}
                    dataSet={{ media: ids.text }}
                    style={[
                      styles.text,
                      {
                        fontFamily:
                          text.type === "bold" ? family.bold : family.normal,
                      },
                    ]}
                  >
                    {text.content}
                  </Text>
                ))}
              </Text>
            )}
            {data.ingredients?.length > 0 && (
              <Text style={styles.text} dataSet={{ media: ids.text }}>
                {getIngredientsText(data.ingredients).map((text, index) => (
                  <Text
                    key={index}
                    dataSet={{ media: ids.text }}
                    style={[
                      styles.text,
                      {
                        fontFamily:
                          text.type === "bold" ? family.bold : family.normal,
                      },
                    ]}
                  >
                    {text.content}
                  </Text>
                ))}
              </Text>
            )}
          </View>
        );
      })}
    </View>
  );
};

export default ProductLines;

const { ids, styles } = StyleSheet.create({
  orders: {
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  product: {
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    marginBottom: 20,
  },
  line: {
    flexDirection: "row",
    width: "100%",
  },
  content: {
    flexDirection: "row",
    justifyContent: "space-between",
    flex: 1,
    alignItems: "center",
  },
  qtyContainer: {
    width: 30,
  },
  text: {
    fontFamily: family.normal,
    fontSize: 14,
    color: "white",
    "@media (max-width: 700px)": {
      fontSize: 10,
    },
  },
});
