import {
  SET_CURRENT_AMOUNT,
  SET_PRODUCT_LINES,
  SET_TICKET_LINES,
  SET_SHOP_CHECKOUT_DATA,
  SET_TICKET_DISCOUNT,
  SET_TICKET_PAYMENT_METHOD,
  SET_CURRENT_CARD,
  RESET_TICKET_CART,
  FETCH_SHOP_DETAILS,
  FETCH_TICKET_DETAILS,
} from "./ticketsTypes";

const initialState = {
  currentAmount: null,
  productLinesData: [],
  shopTicketsData: [],
  ticketLinesData: [],
  shopCheckoutData: {
    fk_event: null,
    gid: null,
    pixel_code: null,
    id_pixel_code: null,
    maxCommission: null,
  },
  ticketDiscountLines: [],
  ticketPaymentMethod: null,
  currentCard: null,
  shopTickets: [],
  ticketDetails: [],
  shopDetails: null,
};

const ticketsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_AMOUNT: {
      const value = action.payload;
      return { ...state, currentAmount: value };
    }
    case SET_PRODUCT_LINES: {
      const value = action.payload;
      return { ...state, productLinesData: value };
    }
    case SET_TICKET_LINES: {
      const value = action.payload;
      return { ...state, ticketLinesData: value };
    }
    case SET_SHOP_CHECKOUT_DATA: {
      const value = action.payload;
      return { ...state, shopCheckoutData: value };
    }
    case SET_TICKET_DISCOUNT: {
      const value = action.payload;
      return { ...state, ticketDiscountLines: value };
    }
    case SET_TICKET_PAYMENT_METHOD: {
      const value = action.payload;
      return { ...state, ticketPaymentMethod: value };
    }
    case SET_CURRENT_CARD: {
      const value = action.payload;
      return { ...state, currentCard: value };
    }
    case FETCH_SHOP_DETAILS: {
      const value = action.payload;
      return { ...state, shopDetails: value };
    }
    case FETCH_TICKET_DETAILS: {
      const value = action.payload;
      return { ...state, ticketDetails: value };
    }
    case RESET_TICKET_CART: {
      return {
        ...state,
        productLinesData: [],
        ticketLinesData: [],
        ticketDiscountLines: [],
        shopCheckoutData: null,
        currentAmount: null,
        currentCard: null,
        paymentMethod: null,
      };
    }
    default:
      return state;
  }
};

export default ticketsReducer;
