import React from "react";
import { StyleSheet, Text, View, Image } from "react-native";
import { TouchableOpacity } from "react-native";

// Components
import Divider from "../../../../components/atoms/Divider";

// Constants
import { colors, family } from "../../../../constants/theme";
import ProductLines from "../../../../components/organisms/pastOrder/ProductLines";
import Tag from "../../../../components/organisms/pastOrder/Tag";
import { setEvent } from "../../../../redux/laterCart/laterCartActions";
import { connect } from "react-redux";

const ForLaterItem = ({ data, navigation, setEvent }) => {
  const { name = null, image = null, shops, expireAt } = data;

  const clickHandler = () => {
    setEvent(data.gid);
    navigation.navigate("MyProductsScreen", { ...data, title: name });
  };

  const fecha = new Date(expireAt);
  const dia = fecha.getDate().toString().padStart(2, "0");
  const mes = (fecha.getMonth() + 1).toString().padStart(2, "0");
  const fechaFormateada = `${dia}/${mes}`;

  return (
    <TouchableOpacity style={styles.container} onPress={clickHandler}>
      <View style={styles.infoContainer}>
        {image && <Image source={{ uri: image }} style={styles.image} />}
        <View style={styles.content}>
          <View style={styles.headerContainer}>
            <Text style={styles.title}>{name}</Text>
            {expireAt ? (
              <Tag
                text={`Caduca el ${fechaFormateada}`}
                textColor={colors.brown}
                bgColor={colors.lightRed}
              />
            ) : (
              <Tag
                text={`No caduca`}
                textColor={colors.brown}
                bgColor={colors.yellow}
              />
            )}
          </View>
          <Divider height={5} />
          <ProductLines shops={shops} maxItems={2}/>
        </View>
        <View style={styles.tagContainer}>
          <Tag text={"Pedir"} />
        </View>
      </View>
    </TouchableOpacity>
  );
};

const mapDispatchToProps = {
  setEvent,
};

export default connect(null, mapDispatchToProps)(ForLaterItem);

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    width: "100%",
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderBottomColor: colors.grey,
    borderBottomWidth: 1,
  },
  infoContainer: {
    position: "relative",
    flexDirection: "row",
    width: "100%",
  },
  image: {
    resizeMode: "cover",
    height: 80,
    width: 80,
    borderRadius: 8,
  },
  content: {
    flex: 1,
    flexDirection: "column",
    paddingLeft: 12,
    maxHeight: 80,
    overflow: "hidden",
  },
  title: {
    fontSize: 16,
    color: colors.brown,
    fontFamily: family.bold,
  },
  item: {
    fontSize: 14,
    color: colors.darkGrey,
    fontFamily: family.normal,
  },
  tagContainer: {
    position: "absolute",
    right: 0,
    bottom: 0,
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
