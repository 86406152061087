import React, { useContext } from "react";
import { Platform } from "react-native";
import { createStackNavigator } from "@react-navigation/stack";
import { connect } from "react-redux";

// Components
import BackHeader from "../../components/Header/BackHeader";
import TitleHeader from "../../components/Header/TitleHeader";


// Screens
import ProfileHomeScreen from "../../screens/profile/ProfileHomeScreen";
import CompletedOrdersScreen from "../../screens/profile/CompletedOrdersScreen";
import ProfileLegalAdviceScreen from "../../screens/profile/ProfileLegalAdviceScreen";
import ProfileNotificationsScreen from "../../screens/profile/ProfileNotificationsScreen";
import ProfileOpinionScreen from "../../screens/profile/ProfileOpinionScreen";
import ProfilePersonalDataScreen from "../../screens/profile/ProfilePersonalDataScreen";
import ProfilePrivacyAdviceScreen from "../../screens/profile/ProfilePrivacyAdviceScreen";
import ProfileTermsAdviceScreen from "../../screens/profile/ProfileTermsAdviceScreen";
import ProfileAuthScreen from "../../screens/profile/ProfileAuthScreen";
import CompletedOrderDetailScreen from "../../screens/profile/CompletedOrderDetailScreen";

import { AuthContext } from "../../contexts/AuthContext";

const Stack = createStackNavigator();

const ProfileStack = () => {
  const { user: firebaseUser } = useContext(AuthContext);
  return (
    <Stack.Navigator
      screenOptions={{
        title: "Gopick",
        animationEnabled: Platform.OS === "ios" ? true : false,
      }}
    >
      <Stack.Screen
        name="ProfileHomeScreen"
        options={{
          headerShown: !!firebaseUser,
          header: () => <TitleHeader title={"Perfil"} />,
        }}
        component={!!firebaseUser ? ProfileHomeScreen : ProfileAuthScreen}
      />

      <Stack.Screen
        name="ProfilePersonalDataScreen"
        options={{
          header: (props) => <BackHeader title={"Mis datos"} {...props} />,
        }}
        component={ProfilePersonalDataScreen}
      />

      <Stack.Screen
        name="ProfileNotificationsScreen"
        options={{
          header: (props) => <BackHeader title={"Notificaciones"} {...props} />,
        }}
        component={ProfileNotificationsScreen}
      />

      <Stack.Screen
        name="CompletedOrdersScreen"
        options={{
          header: (props) => (
            <BackHeader title={"Historial de pedidos"} {...props} />
          ),
        }}
        component={CompletedOrdersScreen}
      />

      <Stack.Screen
        name="CompletedOrderDetailScreen"
        options={{ headerShown: false }}
        component={CompletedOrderDetailScreen}
      />

      <Stack.Screen
        name="ProfileOpinionScreen"
        options={{
          header: (props) => (
            <BackHeader title={"Danos tu opinión"} {...props} />
          ),
        }}
        component={ProfileOpinionScreen}
      />

      <Stack.Screen
        name="ProfileTermsAdviceScreen"
        options={{
          header: (props) => (
            <BackHeader title={"Términos y condiciones"} {...props} />
          ),
        }}
        component={ProfileTermsAdviceScreen}
      />

      <Stack.Screen
        name="ProfilePrivacyAdviceScreen"
        options={{
          header: (props) => (
            <BackHeader title={"Política de privacidad"} {...props} />
          ),
        }}
        component={ProfilePrivacyAdviceScreen}
      />

      <Stack.Screen
        name="ProfileLegalAdviceScreen"
        options={{
          header: (props) => <BackHeader title={"Aviso legal"} {...props} />,
        }}
        component={ProfileLegalAdviceScreen}
      />
    </Stack.Navigator>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(ProfileStack);
