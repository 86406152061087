import React, { useState, useEffect, useCallback, useContext } from "react";
import { useFocusEffect } from "@react-navigation/native";
import { connect } from "react-redux";

// Components
import Screen from "../../../components/atoms/Screen";
import LaterOrdersList from "./components/LaterOrdersList";
import LoadingScreen from "./components/LoadingScreen";
import CurrentOrdersList from "./components/CurrentOrdersList";

// Context
import { AuthContext } from "../../../contexts/AuthContext";

// Hooks
import useForceRender from "../../../hooks/useForceRender";

// Redux
import { setCurrentScreen } from "../../../redux";
import { resetCart } from "../../../redux/laterCart/laterCartActions";
import {
  fetchPastOrders,
  fetchLaterOrders,
  updateOrder,
} from "../../../redux/orders/ordersActions";

// Services
import { initiateSocket, ordersUpdate } from "../../../services/orders";

const OrdersHomeScreen = ({
  navigation,
  orders,
  resetCart,
  updateOrder,
  fetchPastOrders,
  setCurrentScreen,
  user,
  laterOrders,
  fetchLaterOrders,
}) => {
  const { user: firebaseUser } = useContext(AuthContext);
  const [currentOrders, setCurrentOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [forceRender] = useForceRender();

  useFocusEffect(
    useCallback(() => {
      setCurrentScreen(2);
      getData();
      resetCart();
    }, [])
  );

  async function initiateSocketWithToken() {
    if (firebaseUser) {
      const token = await firebaseUser.getIdToken(true);
      initiateSocket(token, "orders").then(() => ordersUpdate(updateOrder));
    }
  }

  useEffect(() => {
    initiateSocketWithToken();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const token = await firebaseUser.getIdToken(true);
      await fetchPastOrders(user.gid, token);
      await fetchLaterOrders(user.gid, token);
    } catch (err) {
      console.log("error fetching past orders");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setCurrentOrders(orders);
    forceRender();
  }, [orders]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Screen>
      <CurrentOrdersList data={currentOrders} navigation={navigation} />
      <LaterOrdersList data={laterOrders} navigation={navigation} />
    </Screen>
  );
};

const mapDispatchToProps = {
  fetchPastOrders,
  updateOrder,
  setCurrentScreen,
  resetCart,
  fetchLaterOrders,
};

const mapStateToProps = (state) => {
  return {
    orders: state.orders.orders,
    laterOrders: state.orders.laterOrders,
    user: state.user.user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersHomeScreen);
