// React & Libraries
import React from "react";
import { Text, View, TouchableOpacity } from "react-native";
import StyleSheet from "react-native-media-query";

// Components
import IconButton from "../../../../../components/atoms/IconButton";
import Divider from "../../../../../components/atoms/Divider";

// Constants
import { colors, family } from "../../../../../constants/theme";

// Utils
import { getFormattedPrice } from "../../../../../utils/productMethods";

const Footer = ({
  onAdd,
  onRemove,
  qty,
  increment,
  amount,
  showActions = true,
  confirmButtonHandler,
  actionBackgroundColor,
  textColor,
  textActionColor,
}) => {
  return (
    <View dataSet={{ media: ids.footer }} style={styles.footer}>
      {showActions && (
        <View style={styles.actionsContainer}>
          <View dataSet={{ media: ids.actions }} style={styles.actions}>
            <IconButton
              color={actionBackgroundColor}
              icon="minus"
              onPress={() => onRemove()}
            />
            <View style={styles.priceContainer}>
              <Text
                dataSet={{ media: ids.qtyText }}
                style={[styles.qtyText, { color: textActionColor }]}
              >
                {qty}
              </Text>
            </View>

            <IconButton
              color={actionBackgroundColor}
              icon="plus"
              onPress={() => onAdd()}
            />
          </View>
          <Divider width={10} />
          <View style={styles.priceContainer}>
            <Text
              dataSet={{ media: ids.qtyText }}
              style={[styles.qtyText, { color: textActionColor }]}
            >
              {getFormattedPrice((qty + increment) * amount)}
            </Text>
          </View>
        </View>
      )}
      {showActions && <Divider height={10} />}
      <TouchableOpacity
        dataSet={{ media: ids.confirmButton }}
        style={[
          styles.confirmButton,
          { backgroundColor: actionBackgroundColor },
        ]}
        onPress={confirmButtonHandler}
      >
        <Text
          dataSet={{ media: ids.confirmText }}
          style={[styles.confirmText, { color: textColor }]}
        >
          {!showActions ? "Continuar" : "Añadir al carrito"}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default Footer;

const { ids, styles } = StyleSheet.create({
  footer: {
    bottom: 0,
    paddingTop: 12,
    paddingBottom: 18,
    width: "100%",
    backgroundColor: "white",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingHorizontal: 18,
    borderTopWidth: 1,
    borderTopColor: colors.grey,
    position: "absolute",
    zIndex: 10,
  },
  actionsContainer: {
    flexDirection: "row",
    width: "50%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  actions: {
    width: 100,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 320px)": {
      width: 60,
    },
  },
  qtyText: {
    fontSize: 20,
    fontFamily: family.normal,
    color: colors.darkGrey,
    "@media (max-width: 320px)": {
      fontSize: 14,
    },
  },
  priceContainer: {
    justifyContent: "center",
    alignItems: "center",
    height: 30,
  },
  confirmButton: {
    width: "100%",
    height: 46,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.primaryColor,
    borderRadius: 50,
    "@media (max-width: 320px)": {
      height: 32,
    },
  },
  confirmText: {
    color: "white",
    fontFamily: family.bold,
    fontSize: 20,
    "@media (max-width: 320px)": {
      fontSize: 14,
    },
  },
});
