import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { colors, family } from "../../../constants/theme";

const Title = ({ title, color }) => (
  <Text numberOfLines={1} style={{fontFamily: family.bold, fontSize: 16, color: color ||colors.brown }}>
    {title}
  </Text>
);
export default Title;
