import React from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";

// Components
import CommissionLine from "./lines/CommissionLine";
import DiscountLine from "./lines/DiscountLine";
import ServiceLine from "./lines/ServiceLine";
import ShopLine from "./lines/ShopLine";
import TotalLine from "./lines/TotalLine";

// Constants
import { family } from "../../../../../constants/theme";

const ResumeSection = ({ customStyle, orders, totalAmount }) => {
  return (
    <View style={styles.container}>
      <View style={styles.content}>
        {orders.map((shop) => (
          <ShopLine shop={shop} customStyle={customStyle} />
        ))}
        <DiscountLine customStyle={customStyle} />
        <ServiceLine customStyle={customStyle} totalAmount={totalAmount} />
        <CommissionLine customStyle={customStyle} totalAmount={totalAmount} />
        <TotalLine customStyle={customStyle} amount={totalAmount} />
      </View>
    </View>
  );
};

const mapStateToProps = (state) => {
  return {
    orders: state.cart.orders,
  };
};

export default connect(mapStateToProps)(ResumeSection);

const styles = StyleSheet.create({
  container: {
    backgroundColor: "transparent",
    width: "100%",
    paddingVertical: 12,
    marginBottom: 12,
    paddingHorizontal: 18,
    flexDirection: "column",
  },
  titleContainer: {
    width: "100%",
    justifyContent: "flex-start",
    marginBottom: 10,
  },
  content: {},
  title: {
    fontFamily: family.bold,
    fontSize: 14,
  },
});
