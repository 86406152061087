import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { timestampToDate, timestampToTime } from "../../../utils/dateMethods";
import { AntDesign } from "@expo/vector-icons";
import Divider from "../../atoms/Divider";
import { colors, family, size } from "../../../constants/theme";

const TicketDateContainer = ({ start, end }) => {
  const dateText = end
    ? timestampToDate(start) + " - " + timestampToDate(end)
    : timestampToDate(start);

  return (
    <View style={styles.dateContainer}>
      <View style={styles.line}>
        <AntDesign name="clockcircleo" size={14} color="white" />
        <Divider width={10} />
        <Text style={styles.date}>{timestampToTime(start)}</Text>
      </View>
      <Divider height={10} />
      <View style={styles.line}>
        <AntDesign name="calendar" size={14} color="white" />
        <Divider width={10} />
        <Text style={styles.date}>{dateText}</Text>
      </View>
    </View>
  );
};

export default TicketDateContainer;

const styles = StyleSheet.create({
  dateContainer: {
    flexDirection: "column",
  },
  line: {
    flexDirection: "row",
    alignItems: "center",
  },
  date: {
    fontFamily: family.normal,
    fontSize: 14,
    color: "white",
  },
});
