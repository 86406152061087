export const canSchedule = (shops) => {
  let result = true;

  shops?.map((shop) => {
    if (shop?.schedules?.length === 0 ) {
      result = false;
    }
  });
  return result;
};
