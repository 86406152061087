import { colors } from "../../../../constants/theme";

export const getCustomStyle = (shopData) => {
  const customStyle = {
    custom_color_1: shopData?.custom_design?.custom_color_1 || "#FFFFFF",
    custom_color_2:
      shopData.custom_design?.custom_color_2 || colors.primaryColor,
    custom_color_letter_2_actions:
      shopData.custom_design?.custom_color_letter_2 || colors.brown,
    customHeaderStyle: {
      color: shopData?.custom_design?.custom_color_header || colors.white,
      textColor:
        shopData?.custom_design?.custom_color_letter_header ||
        colors.primaryColor,
      customIcon: shopData?.custom_design?.custom_logo || null,
      iconsColor:
        shopData?.custom_design?.custom_color_letter_header || colors.brown,
    },
  };
  return customStyle;
};
