// React libraries
import React from "react";
import { View, TouchableOpacity, Text } from "react-native";
import StyleSheet from "react-native-media-query";

// Constants
import { colors, family } from "../../constants/theme";

const RadioButton = ({
  style,
  textColor,
  selected,
  label,
  onPress,
  ...props
}) => {
  return (
    <View {...props} style={styles.container}>
      <TouchableOpacity
        onPress={onPress}
        style={[styles.radio, style, { borderColor: textColor || "white" }]}
        dataSet={{ media: ids.radio }}
      >
        {selected && (
          <View
            dataSet={{ media: ids.selected }}
            style={[styles.selected, { backgroundColor: textColor || "white" }]}
          />
        )}
      </TouchableOpacity>
      {label && (
        <TouchableOpacity style={{ marginLeft: 5 }} onPress={onPress}>
          <Text
            style={[styles.label, { color: textColor || "white" }]}
            dataSet={{ media: ids.label }}
          >
            {label}
          </Text>
        </TouchableOpacity>
      )}
    </View>
  );
};

export default RadioButton;

const { ids, styles } = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 4,
  },
  radio: {
    height: 20,
    width: 20,
    borderRadius: 12,
    borderWidth: 2,
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 320px)": {
      height: 15,
      width: 15,
      borderWidth: 1,
    },
  },
  selected: {
    height: 10,
    width: 10,
    borderRadius: 6,
    "@media (max-width: 320px)": {
      height: 8,
      width: 8,
    },
  },
  label: {
    fontFamily: family.bold,
    fontSize: 14,
    textColor: colors.brown,
    "@media (max-width: 320px)": {
      fontSize: 12,
    },
  },
});
