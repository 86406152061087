import { URlManagement } from "../../../../constants/urls";

export const fetchShopById = async ({ id, service, locId }) => {
  try {
    const url =
      service === "my_site"
        ? `${URlManagement}/shop/get/${id.toString()}?location=${locId}&service=my_site`
        : `${URlManagement}/shop/get/${id.toString()}?service=${service}`;

    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Connection: "keep-alive",
      },
      method: "GET",
    });
    const resData = await response.json();

    if (resData.status === "success") {
      return { status: "success", data: resData.data[0] };
    } else {
      return { status: "fail", message: resData.message };
    }
  } catch (err) {
    console.log("error fetching places:", err);
    return { status: "fail", message: err.message };
  }
};
