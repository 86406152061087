import React from "react";
import { ImageBackground } from "react-native";
import { Platform } from "react-native";
import { TouchableOpacity } from "react-native";
import { Modal } from "react-native";
import { StyleSheet, Text, View } from "react-native";
import { colors, family, RADIUS, size } from "../../../constants/theme";
import CustomModal from "../../atoms/CustomModal";

const CloseEventModal = ({ data, setModal, modal, handlePlaceClick }) => {
  const handleBuyButton = () => {
    setModal(false);
    handlePlaceClick(data);
  };
  return (
    <CustomModal
      size="window"
      modalVisible={modal}
      handleModal={() => setModal()}
      height={500}
      canClose
      closeColor={colors.primaryColor}
    >
      <ImageBackground
        source={{
          uri: data.popup_image,
        }}
        resizeMode="cover"
        imageStyle={{ borderRadius: RADIUS }}
        style={styles.background}
      ></ImageBackground>
      <TouchableOpacity style={styles.confirmButton} onPress={handleBuyButton}>
        <Text style={styles.confirmText}>Pedir</Text>
      </TouchableOpacity>
    </CustomModal>
  );
};

export default CloseEventModal;

const styles = StyleSheet.create({
  background: {
    width: "100%",
    height: "100%",
    borderRadius: RADIUS,
  },
  confirmButton: {
    width: "80%",
    height: 46,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.primaryColor,
    borderRadius: RADIUS,
    position: "absolute",
    bottom: 10,
  },
  confirmText: {
    color: "white",
    fontFamily: family.bold,
    fontSize: size.big,
  },
});
