export const isCompatibleType = (cart, shopData) => {
  let result = false;

  const hasLaterShops =
    cart.orders.filter(
      (shop) => shop?.order_lines?.length > 0 && shop.hasLaterOrders
    ).length === 1;

  const hasNormalShops =
    cart.orders.filter(
      (shop) => shop?.order_lines?.length > 0 && !shop.hasLaterOrders
    ).length === 1;
  const hasShops =
    cart.orders.filter((shop) => shop?.order_lines?.length > 0).length > 0;

  const isLaterShop =
    shopData.services.filter((ser) => ser.key === "no_service").length > 0;

  if (!hasShops) {
    result = true;
  } else {
    if (hasNormalShops && isLaterShop) {
      result = false;
    } else if (hasLaterShops && !isLaterShop) {
      result = false;
    } else {
      result = true;
    }
  }
  return result;
};
