import React from "react";
import { StyleSheet, View, ActivityIndicator } from "react-native";

// Components
import Screen from "../../../components/atoms/Screen";
import Header from "./Header";

// Constants
import { colors } from "../../../constants/theme";

const LoadingScreen = ({ navigation, image, loading, name }) => {
  return (
    <Screen hasFooter header={"hidden"} hideActions>
      <Header image={image} navigation={navigation} name={name} loading={loading}/>
      <View style={styles.loading}>
        <ActivityIndicator color={colors.secondary} size="small" />
      </View>
    </Screen>
  );
};

export default LoadingScreen;

const styles = StyleSheet.create({
  loading: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
});
