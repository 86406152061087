import React from "react";
import { StyleSheet, ActivityIndicator, View } from "react-native";

// Constants
import { colors } from "../../../../constants/theme";

const LoadingScreen = () => {
  return (
    <View style={styles.loadingWrapper}>
      <ActivityIndicator size="small" color={colors.primaryColor} />
    </View>
  );
};

export default LoadingScreen;

const styles = StyleSheet.create({
  loadingWrapper: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
});
