import React, { useState, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";

// Components
import ScheduleModal from "../../modals/ScheduleModal";
import ScheduleButton from "./ScheduleButton";
import Divider from "../../../../../components/atoms/Divider";

// Methods
import { canSchedule } from "../../methods/canSchedule";
import { canASAP } from "../../methods/canASAP";

// Redux
import { setDate, setOrderType } from "../../../../../redux/cart/cartActions";

// Utils
import { islaterOrder } from "../../../../../utils/productMethods";

const ScheduleSection = ({
  customStyle,
  shops,
  setDate,
  setOrderType,
  orderType,
  orders,
}) => {
  const [modal, setModal] = useState();
  
  const isLater = islaterOrder(orders);

  const modalHandler = () => {
    if (canSchedule(shops) === true) {
      setModal("Schedule");
    }
  };

  const getShopSchedules = () => {
    let array = ["Para las "];
    let isScheduled = false;
    shops.map((shop) => {
      if (
        shop.delivery !== "ASAP" &&
        shop?.order_lines.length > 0 &&
        isScheduled === false
      ) {
        array.push(`${shop.delivery}`);
        isScheduled = true;
      }
    });
    if (!isScheduled) {
      array = ["Escoge hora"];
    }
    return array;
  };

  useEffect(() => {
    if (!isLater && orderType === "Later") {
      setOrderType("ASAP");
    }
    if (isLater) {
      setOrderType("Later");
    }
    if (!canASAP(shops) && canSchedule(shops)) {
      setOrderType();
    }
  }, []);

  const asapHandler = () => {
    shops.map((shop) => {
      setDate(null, shop.id_shop);
    });
    setOrderType("ASAP");
  };

  const laterHandler = () => {
    setOrderType("Later");
  };

  return (
    <>
      <View style={styles.container}>
        <View style={styles.row}>
          {isLater ? (
            <ScheduleButton
              title={"Precompra"}
              customStyle={customStyle}
              subtitle={"Podrás consumir los productos más tarde"}
              onPress={laterHandler}
              active={orderType === "Later"}
            />
          ) : (
            <>
              {canASAP(shops) && (
                <ScheduleButton
                  title={"Lo antes posible"}
                  customStyle={customStyle}
                  active={orderType === "ASAP"}
                  onPress={asapHandler}
                />
              )}
              <Divider width={10} />
              {canSchedule(shops) && (
                <ScheduleButton
                  title={"Programar pedido"}
                  subtitle={getShopSchedules()}
                  customStyle={customStyle}
                  active={orderType === "Scheduled"}
                  onPress={modalHandler}
                />
              )}
            </>
          )}
        </View>
      </View>
      <ScheduleModal
        visible={modal === "Schedule"}
        setModal={setModal}
        shopList={shops}
        customStyle={customStyle}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    shops: state.cart.orders,
    orderType: state.cart.orderType,
    orders: state.cart.orders,
  };
};
const mapDispatchToProps = {
  setDate,
  setOrderType,
};

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
  },
  container: {
    marginBottom: 10,
    flexDirection: "column",
    paddingHorizontal: 18,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleSection);
