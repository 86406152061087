import React from "react";
import { StyleSheet, Text } from "react-native";

// Constants
import { colors, size, family } from "../../constants/theme";

const Label = ({ label, ...props }) => (
  <Text style={[styles.label, { ...props }]}>{label}</Text>
);

export default Label;

const styles = StyleSheet.create({
  label: {
    fontFamily: family.bold,
    fontSize: size.small,
    color: colors.brown,
  },
});
