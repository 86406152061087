import React from 'react'
import Svg, { Path, G } from "react-native-svg";

const MyPlaceIcon = (props) => {
  return (
    <Svg
      height="512pt"
      viewBox="-68 0 512 512.001"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M343.195 280.602L218.31 485.199c-4.176 6.832-9.09 11.738-14.36 14.871-15.922 9.512-34.96 2.91-45.86-14.933L33.23 280.602C-38.012 161.645 47.355 7.629 188.22 7.629c5.246 0 10.426.215 15.508.637C335.43 19.062 411.633 166.348 343.195 280.602zm0 0"
        fill="#169b62"
      />
      <Path
        d="M343.195 280.602L218.31 485.199c-4.176 6.832-9.09 11.738-14.36 14.871-5.68-3.277-10.828-8.332-14.851-14.933L64.238 280.602C-4.355 166.074 72.215 19.043 203.727 8.266 335.43 19.062 411.633 166.348 343.195 280.602zm0 0"
        fill="#1ab975"
      />
      <Path
        d="M331.59 230.434c-7.047 24.043-20.14 46.125-37.863 63.847-19.461 19.461-43.977 33.14-70.891 39.559a149.608 149.608 0 01-18.45 3.195c-73.702 8.098-143.835-39.883-161.48-113.84-6.023-25.273-5.367-51.742 1.903-76.53 7.046-24.044 20.14-46.114 37.863-63.837 19.46-19.473 43.976-33.152 70.89-39.558 17.106-4.086 34.243-5.028 50.801-3.208 60.977 6.692 114.106 50.844 129.13 113.848 6.023 25.277 5.366 51.735-1.903 76.524zm0 0"
        fill="#1ed688"
      />
      <Path
        d="M331.59 230.434c-7.047 24.043-20.14 46.125-37.863 63.847-19.461 19.461-43.977 33.14-70.891 39.559a149.608 149.608 0 01-18.45 3.195c-60.976-6.683-114.1-50.832-129.128-113.84-6.024-25.273-5.367-51.742 1.902-76.53 7.047-24.044 20.14-46.114 37.863-63.837C134.484 63.355 159 49.676 185.914 43.27a148.926 148.926 0 0118.45-3.208c60.976 6.692 114.105 50.844 129.128 113.848 6.024 25.277 5.367 51.735-1.902 76.524zm0 0"
        fill="#35e298"
      />
      <G fill="#e3a76f">
        <Path d="M150.309 209.918c0 13.875-11.25 25.125-25.125 25.125s-25.121-11.25-25.121-25.125 11.246-25.121 25.12-25.121 25.126 11.246 25.126 25.121zm0 0M276.11 209.922c0 13.875-11.247 25.125-25.122 25.125-13.879 0-25.125-11.25-25.125-25.125S237.11 184.8 250.988 184.8c13.875 0 25.121 11.246 25.121 25.12zm0 0" />
        <Path d="M253.406 110.992V227.82c0 30.38-35.554 55.914-56.636 63.223-3.958 1.367-7.399 2.094-10.004 2.094-8.258 0-24.918-7.309-39.512-19.133-14.598-11.817-27.129-28.152-27.129-46.184V110.992c0-11.91 9.656-21.562 21.563-21.562h90.156c11.91 0 21.562 9.652 21.562 21.562zm0 0" />
      </G>
      <Path
        d="M253.406 110.992V227.82c0 26.285-26.625 48.95-47.453 59.313a20.564 20.564 0 01-18.316.023c-6.555-3.254-13.684-7.73-20.371-13.152-14.602-11.816-27.133-28.149-27.133-46.184V110.992c0-11.91 9.656-21.562 21.562-21.562h70.149c11.906 0 21.562 9.656 21.562 21.562zm0 0"
        fill="#f2bb88"
      />
      <Path
        d="M263.145 162.043h-20.512c-10.988 0-19.895-8.906-19.895-19.895v-36.402c0-10.984 8.907-19.894 19.895-19.894h4.308c10.989 0 19.895 8.91 19.895 19.894v52.606a3.691 3.691 0 01-3.691 3.691zm0 0"
        fill="#ae583e"
      />
      <Path
        d="M254.727 79.488v6.696c0 27.09-54.196 80.218-125.84 79.757h-12.364c-6.949 0-12.59-3.894-12.59-8.691v-54.215c0-15.027 17.7-27.21 39.52-27.21h105.969c.797 0 1.555.12 2.23.34 1.813.581 3.075 1.847 3.075 3.323zm0 0"
        fill="#c0694e"
      />
      <Path
        d="M251.652 76.164c-12.406 28.23-61.125 67.496-122.765 67.102h-12.364c-6.949 0-12.59-3.895-12.59-8.692v-31.539c0-15.027 17.7-27.21 39.52-27.21h105.969c.797 0 1.555.12 2.23.34zm0 0"
        fill="#c97e67"
      />
      <Path d="M250.258 332.715c57.668-24.781 94.894-81.672 94.894-144.067 0-140.144-170.03-209-267.847-111.187-67.032 67.031-59.684 177.094 14.382 234.773 35.356 27.536 82.012 39.579 128.231 30.024 4.102-.852 6.738-4.863 5.89-8.961-.847-4.102-4.863-6.735-8.96-5.89a141.682 141.682 0 01-28.653 2.94c-126.957 0-188.699-153.632-100.168-242.16 77.028-77.023 212.008-41.878 238.082 67.481 16 67.117-18.664 135.969-81.836 163.117a7.576 7.576 0 00-3.972 9.957 7.574 7.574 0 009.957 3.973zm0 0" />
      <Path d="M323.844 57.844C240.44-30.027 91.105-17.246 26.46 92.524c-2.125 3.605-.926 8.253 2.684 10.378s8.257.926 10.382-2.683c31.348-53.23 86.93-85.008 148.692-85.008 128.457 0 206.922 133.543 158.293 242.633a7.584 7.584 0 0013.851 6.176c31.735-71.188 14.532-152.387-36.52-206.176zm0 0M342.906 281.086a7.578 7.578 0 00-10.422 2.52L211.832 481.241c-13.367 21.895-35.234 19.653-47.266-.055L39.742 276.707c-27.426-45.789-32.164-100.312-12.86-150.336a7.584 7.584 0 00-14.148-5.46C-8.476 175.87-2.78 235.226 26.766 284.554L151.625 489.09c17.508 28.683 53.441 32.336 73.148.058l120.653-197.64a7.58 7.58 0 00-2.52-10.422zm0 0" />
      <Path d="M92.64 209.98c0 14.239 9.15 26.38 21.868 30.86 3.789 13.676 13.445 27.215 28.113 39.082 15.125 12.265 33.324 20.816 44.262 20.816 19.719 0 62.601-26.703 72.125-59.039 14.207-3.55 24.75-16.422 24.75-31.707 0-14.547-9.543-26.89-22.688-31.129v-9.148h2.145c6.219 0 11.273-5.055 11.273-11.274v-52.574c0-9.449-4.8-17.812-12.101-22.754v-3.488c0-6.309-5.66-11.242-12.88-11.242H143.595c-26.399 0-47.082 15.273-47.082 34.777v54.18c0 8.027 6.843 14.508 16.183 15.965v6.53c-11.765 4.942-20.054 16.599-20.054 30.145zm20.055 12.133a17.43 17.43 0 01-4.89-12.133c0-4.71 1.86-8.976 4.89-12.128zm148.375-26.508c4.543 3.176 7.524 8.442 7.524 14.387 0 5.946-2.98 11.203-7.524 14.367zm-15.164 32.274c0 30.242-45.187 57.695-59.023 57.695-11.434 0-45.27-19.226-55.824-43.402-2.024-4.621-3.196-9.41-3.196-14.293v-35.246-19.028c1.192-.175 44.297 2.73 87.965-25.757 2.59 12.464 13.66 21.867 26.883 21.867h3.195zm12.781-125.902c1.133 3.343.415 1.265.637 52.574h-16.613c-6.781 0-12.293-5.524-12.293-12.305v-5.226c11.121-9.387 22.441-21.723 28.27-35.043zm-147.011 54.937V103.16c0-6.422 5.34-12.242 13.375-15.844 11.648-5.242 11.832-3.77 122.172-3.77-.145 2.29 1.039 7.22-5.258 17.856-16.942 28.645-62.445 57.04-112.88 57.04-10.831 0-15.483.507-17.41-1.528zm0 0" />
      <Path d="M219.156 202.906c-6.312 2.785-6.254 11.774.059 14.477 5.074 2.21 11.012-1.5 11.012-7.25 0-5.785-5.997-9.5-11.07-7.227zm0 0M210.148 176.094c-4.093.86-6.722 4.87-5.875 8.976.86 4.094 4.875 6.735 8.97 5.875 5.402-1.113 15.687-1.64 20.382 1.313a7.547 7.547 0 004.023 1.176c7.602 0 10.457-9.973 4.043-14.016-8.457-5.32-21.93-5.297-31.543-3.324zm0 0M154.066 202.238c-4.355 0-7.894 3.54-7.894 7.895 0 5.699 5.844 9.453 11 7.262 2.808-1.215 4.781-4.004 4.781-7.262 0-4.324-3.504-7.895-7.887-7.895zm0 0M172.125 185.07c.848-4.105-1.781-8.117-5.875-8.976-9.621-1.977-23.086-1.996-31.543 3.324-3.55 2.234-4.61 6.918-2.387 10.465 2.207 3.492 6.844 4.648 10.453 2.375 4.77-2.969 14.907-2.434 20.395-1.313 4.082.848 8.098-1.78 8.957-5.875zm0 0M203.375 233.79c-4.188 0-7.582 3.394-7.582 7.581s-3.406 7.594-7.594 7.594c-4.191 0-7.597-3.406-7.597-7.594s-3.395-7.582-7.582-7.582c-14.473 0-7.395 30.344 15.18 30.344 22.448 0 29.85-30.344 15.175-30.344zm0 0" />
    </Svg>
  )
}

export default MyPlaceIcon
