import React from "react";
import { Text } from "react-native";
import StyleSheet from "react-native-media-query";

// Constants
import { colors, family } from "../../../../../constants/theme";

const Title = ({ title }) => (
  <Text numberOfLines={2} style={styles.title} dataSet={{ media: ids.title }}>
    {title}
  </Text>
);

export default Title;

const { ids, styles } = StyleSheet.create({
  title: {
    fontFamily: family.bold,
    color: colors.white,
    fontSize: 24,
    "@media (max-height: 700px) or (max-width: 340px)": {
      fontSize: 16,
    },
  },
});
