export const isOnEvent = (orders, fk_event) => {
  let result = false;
  let eventSelected = null;
  
  orders.map((shop) => {
    if (shop.order_lines.length > 0) {
      eventSelected = shop.id_event;
    }
  });

  if (orders.length === 0 || eventSelected === null) {
    result = true;
  } else if (fk_event === eventSelected) {
    result = true;
  } else {
    result = false;
  }
  return result;
};