import React from "react";
import StyleSheet from "react-native-media-query";
import { Text, View } from "react-native";
import { v4 as uuidv4 } from "uuid";

// Constants
import { colors, family } from "../../../../constants/theme";

// Utils
import { getPastOrderVariantText } from "../../../../utils/variantsMethods";

const PastVariantsText = ({ variants = [] }) => {
  if (variants.length === 0) {
    return <View></View>;
  }
  return (
    <Text dataSet={{ media: ids.text }} style={styles.text}>
      {getPastOrderVariantText(variants, false).map((text) => {
        const key = uuidv4();
        return (
          <Text
            key={key}
            dataSet={{ media: ids.text }}
            style={[
              styles.text,
              {
                fontFamily: text.type === "bold" ? family.bold : family.normal,
              },
            ]}
          >
            {text.content}
          </Text>
        );
      })}
    </Text>
  );
};

export default PastVariantsText;

const { ids, styles } = StyleSheet.create({
  text: {
    fontFamily: family.normal,
    color: colors.brown,
    fontSize: 14,
    "@media (max-width: 320px)": {
      fontSize: 12,
    },
  },
});
