import React from "react";
import { TouchableOpacity } from "react-native";
import {
  StyleSheet,
  Text,
  useWindowDimensions,
  Image,
  View,
} from "react-native";

// Components
import PastOrderProductLines from "../pastOrder/PastOrderProductLines";
import Divider from "../../atoms/Divider";
import Title from "../pastOrder/Title";

// Constants
import { colors, family } from "../../../constants/theme";

// Utils
import { getFormattedPrice } from "../../../utils/productMethods";
import { getStatusText } from "../../../screens/orders/PastOrderDetailScreen/Header/methods/getStatusText";

const CompletedOrder = ({ data, navigation }) => {
  const width = useWindowDimensions().width;
  const { status, amount, shop, orderLines } = data;

  const clickHandler = () => {
    navigation.navigate("ProfileStack", {
      screen: "CompletedOrderDetailScreen",
      params: data,
    });
  };

  return (
    <TouchableOpacity style={styles.container} onPress={clickHandler}>
      {width > 320 && (
        <View style={styles.imageContainer}>
          <Image style={styles.image} source={{ uri: shop?.image }} />
        </View>
      )}
      <Divider width={10} />
      <View style={styles.content}>
        <View style={styles.row}>
          <View style={{ flex: 1 }}>
            <Title title={shop?.name} />
          </View>
          <View style={{ alignItems: "flex-end" }}>
            <Text style={styles.price}>
              {status.key === "completed"
                ? getFormattedPrice(amount)
                : getStatusText(status.key)}
            </Text>
          </View>
        </View>
        <View style={styles.row}>
          <PastOrderProductLines lines={orderLines} />
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default CompletedOrder;

const styles = StyleSheet.create({
  container: {
    maxWidth: 400,
    flex: 1,
    maxHeight: 89,
    marginHorizontal: 18,
    backgroundColor: "white",
    borderBottomColor: colors.grey,
    borderBottomWidth: 1,
    flexDirection: "row",
    paddingVertical: 12,
  },
  image: {
    width: 65,
    height: 65,
    resizeMode: "cover",
    borderRadius: 10,
  },
  content: {
    flex: 1,
    justifyContent: "space-between",
    alignItems: "stretch",
    flexDirection: "column",
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flex: 1,
  },
  price: {
    fontFamily: family.normal,
    fontSize: 14,
    color: colors.brown,
  },
});
