import React from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";

// Components
import CustomModal from "../../atoms/CustomModal";
import AuthContainer from "../../organisms/auth/AuthContainer";

// Constants
import { colors } from "../../../constants/theme";

const TicketLoginModal = ({ visible, action, setModal, navigation }) => {
  const authHandler = () => {
    if (action) {
      action();
    } else {
      setModal();
    }
  };

  const handleClose = () => {
    setModal(false);
  };

  const customStyle = {
    backgroundColor: colors.white,
    buttonTextColor: "black",
    textColor: colors.white,
    checkColor: colors.white,
    errorColor: colors.white,
  };

  return (
    <CustomModal
      modalVisible={visible}
      handleModal={handleClose}
      color={"rgba(0,0,0,0.8)"}
    >
      <View style={styles.container}>
        <AuthContainer customStyle={customStyle} onAuth={authHandler} />
      </View>
    </CustomModal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  backButton: {
    width: "100%",
  },
});

const mapDispatchToProps = (state) => {
  return {};
};

export default connect(mapDispatchToProps)(TicketLoginModal);
