import { Platform } from "react-native";
import { validateMerchant } from "./functions/validateMerchant";

export const applePayConfiguration = async (session) => {
  return {
    //onValidateMerchant is required if you're using your own Apple Pay certificate
    onValidateMerchant: async (resolve, reject, validationURL) => {
      try {
        let merch = session;
        // Your server uses the validation URL to request a session from the Apple Pay server.
        // Call resolve(MERCHANTSESSION) or reject() to complete merchant validation.

        merch = await validateMerchant(validationURL);

        if (merch) {
          resolve(merch);
        } else {
          // Complete merchant validation with reject() if any error occurs
          console.log("apple pay error", error);
          reject();
        }
      } catch (error) {
        console.log("error onValidateMerchant", error.message);
      }
    },
  };
};
