import React from "react";
import { StyleSheet, Text, View } from "react-native";

// Componentd
import HtmlNative from "../../../../components/atoms/HtmlNative";

// Constants
import { colors, family } from "../../../../constants/theme";

const Description = ({ description, customStyle }) => {
  const { custom_color_letter_2_actions } = customStyle;
  return description ? (
    <View style={styles.description}>
      <HtmlNative
        value={description}
        style={`p{color: ${
          custom_color_letter_2_actions || colors.brown
        }; font-family: 'Raleway', sans-serif}`}
        styleSheet={styles.descriptionText}
      />
    </View>
  ) : (
    <View></View>
  );
};

export default Description;

const styles = StyleSheet.create({
  description: {
    marginTop: 10,
  },
  descriptionText: {
    fontFamily: family.normal,
    fontSize: 12,
    lineHeight: 15,
  },
});
