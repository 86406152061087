import React from "react";
import StyleSheet from "react-native-media-query";
import { FlatList, Text, useWindowDimensions, View } from "react-native";
import { family } from "../../../constants/theme";
import TicketProductItem from "./TicketProductItem";
import i18n from "../../../i18n/i18n";

const TicketExtraProducts = ({
  data,
  addProduct,
  removeProduct,
  productLines,
}) => {
  const width = useWindowDimensions().width;
  return (
    <View style={styles.container}>
      <Text style={styles.title}>{i18n.t("tickets.more_interest")}</Text>
      <View style={styles.content} dataSet={{ media: ids.content }}>
        {data.map((category) => {
          return (
            <View key={category.gid}>
              {data.length > 1 && (
                <View style={styles.textWrapper}>
                  <Text style={styles.categoryText}>{category.name}</Text>
                </View>
              )}
              {width > 700 ? (
                <FlatList
                  key={"1"}
                  data={category.products}
                  keyExtractor={(item) => item.gid}
                  columnWrapperStyle={{ justifyContent: "space-between" }}
                  renderItem={(item) => (
                    <TicketProductItem
                      data={item.item}
                      onAdd={addProduct}
                      onRemove={removeProduct}
                      productLines={productLines}
                    />
                  )}
                  style={styles.listStyle}
                  showsVerticalScrollIndicator={false}
                  numColumns={2}
                />
              ) : (
                <FlatList
                  key={"2"}
                  data={category.products}
                  keyExtractor={(item) => item.gid}
                  renderItem={(item) => (
                    <TicketProductItem
                      data={item.item}
                      onAdd={addProduct}
                      onRemove={removeProduct}
                      productLines={productLines}
                    />
                  )}
                  style={styles.listStyle}
                  showsVerticalScrollIndicator={false}
                  numColumns={1}
                />
              )}
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default TicketExtraProducts;

const { ids, styles } = StyleSheet.create({
  container: {
    width: "100%",
    height: '100%',
    maxWidth: 700,
  },
  title: {
    fontFamily: family.bold,
    fontSize: 18,
    color: "white",
  },
  textWrapper: {
    marginTop: 20,
  },
  categoryText: {
    fontFamily: family.bold,
    color: "white",
    fontSize: 14,
  },
  listStyle: { width: "100%" },

  content: {
    width: "100%",
    maxWidth: 1400,
    justifyContent: "center",
  },
});
