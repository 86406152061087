import React, { useContext } from "react";
import { Platform } from "react-native";
import { createStackNavigator } from "@react-navigation/stack";
import { connect } from "react-redux";

// Components
import TitleHeader from "../../components/Header/TitleHeader";
import BackHeader from "../../components/Header/BackHeader";

// Screens
import CheckoutLaterProducts from "../../screens/orders/CheckoutLaterProducts";
import MyProductsScreen from "../../screens/orders/MyProductsScreen";
import OrdersHomeScreen from "../../screens/orders/OrdersHomeScreen";
import PastOrderDetailScreen from "../../screens/orders/PastOrderDetailScreen";
import OrdersAuthScreen from "../../screens/orders/OrdersAuthScreen";

import { AuthContext } from "../../contexts/AuthContext";

const Stack = createStackNavigator();

const OrdersStack = () => {
  const { user: firebaseUser } = useContext(AuthContext);
  return (
    <Stack.Navigator
      screenOptions={{
        title: "Gopick",
        animationEnabled: Platform.OS === "ios" ? true : false,
      }}
    >
      <Stack.Screen
        name="OrdersHomeScreen"
        component={!!firebaseUser ? OrdersHomeScreen : OrdersAuthScreen}
        options={{
          headerShown: !!firebaseUser,
          header: () => <TitleHeader title={"Tus pedidos"} />,
        }}
      />
      <Stack.Screen
        name="CheckoutLaterProducts"
        component={CheckoutLaterProducts}
        options={{
          header: (props) => <BackHeader title={"Tu pedido"} {...props} />,
        }}
      />
      <Stack.Screen
        name="MyProductsScreen"
        component={MyProductsScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="PastOrderDetailScreen"
        component={PastOrderDetailScreen}
        options={{ headerShown: false }}
      />
    </Stack.Navigator>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(OrdersStack);
