import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";

// Constants
import { colors, family } from "../../../../constants/theme";

const Title = ({ shop, orders }) => {
  const { shopId, shopName } = shop;

  const getProductsQtyByShop = (orders = [], shopId = null) => {
    let qty = 0;
    orders?.map((shop) => {
      if (shop?.shopId === shopId) {
        shop.orderLines.map((line) => {
          qty += line.quantity;
        });
      }
    });
    return qty;
  };

  const productQty = getProductsQtyByShop(orders, shopId);
  return (
    <View>
      <Text style={styles.title}>
        {productQty} {productQty > 1 ? "productos" : "producto"} de{" "}
        <Text style={styles.titlebold}>{shopName}</Text>
      </Text>
    </View>
  );
};

const mapStateToProps = (state) => {
  return {
    orders: state.laterOrders.orders,
  };
};

export default connect(mapStateToProps)(Title);

const styles = StyleSheet.create({
  title: {
    fontFamily: family.normal,
    fontSize: 12,
    color: colors.brown,
  },
  titlebold: {
    fontFamily: family.bold,
    fontSize: 12,
  },
});
