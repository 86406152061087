import React, { useState, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import { connect } from "react-redux";

// Components
import LocationsModal from "../../../../../components/organisms/modals/LocationsModal";
import Divider from "../../../../../components/atoms/Divider";
import Wrapper from "./Wrapper";
import Title from "./Title";
import ScheduleTag from "./ScheduleTag";
import ClosedTag from "./ClosedTag";

// Methods
import { fetchShopById } from "../../methods/fetchShopById";
import { toProductScreen } from "../../../../../navigation/redirections/toProductScreen";

// Redux
import { addShop, setOrderType, setService } from "../../../../../redux";
import CustomAlert from "../../../../../components/molecules/CustomAlert";

const EventItem = ({ data, navigation, setService, setOrderType, addShop }) => {
  const [selectedService, setSelectedService] = useState();
  const [modal, setModal] = useState();
  const [alertData, setAlertData] = useState()
  const [localization, setLocalization] = useState([]);

  const { image, name, schedule } = data;
  const abierto = schedule.opened || schedule?.scheduled;

  const singleShop = data?.shops[0];

  useEffect(() => {
    setLocalization([]);
  }, [selectedService]);

  const singleShopHandler = async (
    service,
    loc,
    data,
    eventData,
    orderType
  ) => {
    fetchShopById({
      id: data.gid,
      locId: loc?.gid_location,
      service: service,
    }).then((res) => {
      if (res.status === "success") {
        toProductScreen({
          navigation: navigation,
          data: data,
          res: res.data,
          action: () => {
            addShop(data, res.data, eventData, orderType);
            setOrderType(orderType);
          },
        });
      } else {
        setModal("Empty");
      }
    });
  };

  const handlePlaceClick = () => {
    if (data.shops.length === 1) {
      const noService = singleShop?.services.filter(
        (el) => el.key === "no_service"
      );
      const isPrecompras = noService.length > 0;
      if (
        (!isPrecompras && singleShop?.schedule.opened === 1) ||
        (isPrecompras && singleShop?.schedule.opened !== 1)
      ) {
        if (
          singleShop?.services.length === 1 &&
          singleShop?.services[0].levels_locations === "not_show"
        ) {
          let service = singleShop?.services[0];
          let orderType = "ASAP";

          if (isPrecompras) {
            service = noService[0];
            orderType = "Later";
          }
          setService({
            service: service,
            shop: singleShop,
            location: "",
            gid_location: null,
          });
          singleShopHandler(service.key, "", singleShop, data, orderType);
        } else {
          if (isPrecompras) {
            setService({
              service: noService[0],
              shop: singleShop,
              location: "",
              gid_location: null,
            });
            singleShopHandler(
              noService[0].key,
              "",
              singleShop,
              data,
              "Later"
            ).then(() => setLoading(false));
          } else {
            setModal("Locations");
          }
        }
      } else {
        setAlertData({
          title: `${data.name} no cuenta con puntos de venta abiertos`,
        });
        setModal("Alert");
      }
    } else {
      navigation.navigate("EventsStack", {
        screen: "ShopList",
        params: {
          shopList: data.shops.sort((a, b) => {
            if (a?.schedule?.opened === 1) {
              return -1;
            } else {
              return 1;
            }
          }),
          name: data.name,
          style: data?.custom_design || null,
          eventData: data,
          eventId: data.gid,
        },
      });
    }
  };

  return (
    <Wrapper opened={abierto} image={image} onPress={handlePlaceClick}>
      <View style={styles.content}>
        <View style={styles.tagContainer}>{!abierto && <ClosedTag />}</View>
        <View style={styles.infoContainer}>
          <Title title={name} />
          <Divider height={5} />
          {schedule?.scheduled === 1 && <ScheduleTag />}
        </View>
      </View>
      <LocationsModal
        services={singleShop?.services}
        setSelectedService={setSelectedService}
        setModal={setModal}
        visible={modal === "Locations"}
        data={singleShop}
        shopInfo={{
          gid: singleShop?.gid,
          name: singleShop?.name,
        }}
        onNavigate={singleShopHandler}
        eventId={singleShop.gid}
        optionsSel={localization}
        setOptionsSel={setLocalization}
        navigation={navigation}
      />
      <CustomAlert
        setModalVisible={setModal}
        modalVisible={modal === "Alert"}
        alertData={alertData}
      />
    </Wrapper>
  );
};

const styles = StyleSheet.create({
  content: {
    width: "100%",
    height: "100%",
    padding: 24,
    justifyContent: "space-between",
  },
  infoContainer: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  tagContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
});

const mapDispatchToProps = {
  addShop,
  setOrderType,
  setService,
};

export default connect(null, mapDispatchToProps)(EventItem);
