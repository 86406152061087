import React from "react";
import { StyleSheet, View, Text, useWindowDimensions } from "react-native";

// Components
import Animation from "../../../../components/atoms/Animation";
import Button from "./Button";

// Assets
import emptyBag from "../../../../assets/animations/emptyBag.json";

// Constants
import { colors, family } from "../../../../constants/theme";
import Divider from "../../../../components/atoms/Divider";
import { Platform } from "react-native";

const EmptyScreen = ({ navigation }) => {
  const height = useWindowDimensions().height
  const buttonAction = () => {
    navigation.navigate("EventsStack", {
      screen: "LiveScreen",
    });
  };

  return (
    <View style={[styles.screen,{height:height}] }>
      <View style={styles.container}>
        <Text style={styles.text}>No tienes ningún pedido en proceso</Text>
        <Divider height={20} />
        <Button title={"Ver qué me apetece"} onClick={buttonAction} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  screen: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 240,
    backgroundColor: "transparent",
  },
  text: {
    fontSize: 18,
    color: colors.brown,
    fontFamily: family.normal,
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default EmptyScreen;
