import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View, Platform, ScrollView } from "react-native";

// Components
import PlateProduct from "../../../../components/organisms/products/ProductItem/productTypes/PlateProduct";
import CustomButton from "../../../../components/atoms/CustomButton";
import Divider from "../../../../components/atoms/Divider";
import BackLine from "./BackLine";

// Constants
import { colors, family } from "../../../../constants/theme";

const PlateSelectorStep = ({
  data,
  setModal,
  navigation,
  shopData,
  step,
  handleStep,
  choices,
}) => {
  const [selections, setSelections] = useState(0);
  const {
    gid,
    fk_point_sale,
    family,
    exclusive_order,
    amount,
    not_pay_commission,
    name,
    only_legal_age,
    not_pay_service,
  } = data;
  const numOfChoices = choices[step - 1].numOfChoices;
  const showButton = numOfChoices > 1 && selections === numOfChoices;

  const plateHandler = () => {
    if (numOfChoices === 1) {
      handleStep();
    }
  };

  useEffect(() => {
    setSelections(0);
  }, [step]);

  return (
    <View style={styles.container}>
      <BackLine navigation={navigation} title={choices[step - 1].title} />
      <View style={styles.infoWrapper}>
        {numOfChoices > 1 && (
          <Text style={styles.title}>
            ({selections} de {numOfChoices})
          </Text>
        )}
        <Divider height={10} />
        {numOfChoices > 1 && (
          <Text style={styles.text}>Debes elegir {numOfChoices} productos</Text>
        )}
      </View>

      <View style={styles.scrollWrapper}>
        <ScrollView showsVerticalScrollIndicator={false} style={{ height: 1 }}>
          {choices[step - 1].plates.map((product) => (
            <PlateProduct
              key={product.gid}
              setModal={setModal}
              selections={selections}
              navigation={navigation}
              setSelections={setSelections}
              data={{
                ...product,
                gid: `menu${product.gid}`,
                realGid: product.gid,
                variants: product.groupVariants,
              }}
              canBeAdded={selections < numOfChoices}
              shopData={shopData}
              onPress={plateHandler}
              menuData={{
                gid: gid,
                id_point_sale: fk_point_sale,
                typePlate: choices[step - 1].gid,
                amount: amount,
                name: name,
                only_legal_age: only_legal_age,
                not_pay_service: not_pay_service,
                not_pay_commission: not_pay_commission,
                exclusive_order: exclusive_order,
                family: family,
              }}
            />
          ))}
          <View style={{ height: 200 }}></View>
        </ScrollView>
      </View>
      {showButton && (
        <View style={styles.buttonContainer}>
          <CustomButton active onPress={handleStep} title={`Continuar`} />
        </View>
      )}
    </View>
  );
};

export default PlateSelectorStep;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingHorizontal: 18,
  },
  title: {
    fontSize: 14,
    fontFamily: family.bold,
    color: colors.brown,
  },
  text: {
    fontSize: 14,
    fontFamily: family.normal,
    color: colors.brown,
  },
  scrollWrapper: {
    flex: 1,
    width: "100%",
  },
  buttonContainer: {
    position: "absolute",
    bottom: 0,
    height: 64,
    width: "100%",
    paddingBottom: 18,
    paddingHorizontal: Platform.OS === "web" && 18,
  },
  infoWrapper: {
    width: "100%",
  },
});
