import React from "react";
import { ScrollView, StyleSheet, View } from "react-native";

// Components
import Variants from "../../../../components/organisms/products/VariablesTab/Variants";
import CustomButton from "../../../../components/atoms/CustomButton";
import HtmlNative from "../../../../components/atoms/HtmlNative";
import Divider from "../../../../components/atoms/Divider";
import Header from "./Header";

// Constants
import { family } from "../../../../constants/theme";

const DescriptionStep = ({
  data,
  customStyle,
  variantsList,
  setVariants,
  onContinue,
  navigation,
}) => {
  const { image, name, long_description, variants } = data;
  const { custom_color_2, custom_color_letter_2_actions } = customStyle;

  const hasVariants = () => {
    if (variants) {
      if (variants[0]?.name) {
        return true;
      }
    }
    return false;
  };

  return (
    <View style={styles.container}>
      <Header image={image} navigation={navigation} title={name} />
      <View style={styles.scrollWrapper}>
        <ScrollView style={styles.scroll} showsVerticalScrollIndicator={false}>
          {long_description && (
            <View style={styles.description}>
              <HtmlNative
                value={long_description}
                style={
                  "p{color: black; font-family: 'Nunito', sans-serif};font-size:12,line-height:15"
                }
              />
            </View>
          )}
          {hasVariants() === true && (
            <Variants
              data={variants}
              setVariants={setVariants}
              variantsList={variantsList}
              color={custom_color_2}
              textColor={custom_color_letter_2_actions}
            />
          )}
          <Divider height={200} />
        </ScrollView>
      </View>
      <View style={styles.confirmWrapper}>
        <CustomButton title="Continuar" active onPress={onContinue} />
      </View>
    </View>
  );
};

export default DescriptionStep;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  scrollWrapper: {
    flex: 1,
    width: "100%",
  },
  image: {
    height: 180,
    width: "100%",
    resizeMode: "cover",
  },
  scrollContainer: {
    width: "100%",
    height: "100%",
  },
  scroll: {
    flex: 1,
    paddingHorizontal: 18,
  },
  title: {
    fontSize: 14,
    fontFamily: family.bold,
    marginTop: 18,
  },
  description: {
    marginTop: 10,
  },
  confirmWrapper: {
    position: "absolute",
    bottom: 0,
    height: 64,
    width: "100%",
    paddingBottom: 18,
    paddingHorizontal: 18,
  },
});
