import React from "react";
import { StyleSheet, View, Text } from "react-native";

// Components
import CustomModal from "../../../../../components/atoms/CustomModal";
import ProductLine from "./components/ProductLine";
import ProductDetails from "./components/ProductDetails";
import Divider from "../../../../../components/atoms/Divider";

// Constants
import { family } from "../../../../../constants/theme";

const DetailsModal = ({
  visible,
  setModal,
  customStyle,
  product,
  shopName,
}) => {
  const closeModal = () => {
    setModal();
  };
  const { primaryColor, textColor } = customStyle;

  return (
    <CustomModal
      modalVisible={visible}
      handleModal={closeModal}
      canClose={true}
    >
      <View style={styles.container}>
        <Text style={[styles.title, { color: primaryColor }]}>
          Detalle del producto
        </Text>
        <Divider height={10} />
        <Text style={[styles.shop, { color: textColor }]}>{shopName}</Text>
        <ProductLine customStyle={customStyle} product={product} />
        <ProductDetails customStyle={customStyle} product={product} />
      </View>
    </CustomModal>
  );
};

export default DetailsModal;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    maxHeight: 500,
  },
  title: {
    fontFamily: family.bold,
    fontSize: 16,
  },
  shop: {
    fontFamily: family.bold,
    fontSize: 14,
  },
});
