import React from "react";
import { StyleSheet, Text, View } from "react-native";

// Constants
import { colors, family } from "../../../../../constants/theme";

// Utils
import { getIngredientsText } from "../../../../../utils/productMethods";

const IngredientsRemoved = ({ data = [] }) => {
  if (data.length === 0) {
    return <View></View>;
  }
  return (
    <View>
      <Text style={styles.text}>
        {getIngredientsText(data).map((text, index) => (
          <Text
            key={index}
            style={[
              styles.text,
              {
                fontFamily: text.type === "bold" ? family.bold : family.normal,
              },
            ]}
          >
            {text.content}
          </Text>
        ))}
      </Text>
    </View>
  );
};

export default IngredientsRemoved;

const styles = StyleSheet.create({
  text: {
    fontFamily: family.normal,
    fontSize: 12,
    color: colors.brown,
  },
});
