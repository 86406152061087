import React, { useState, useEffect } from "react";
import { View, Platform, StyleSheet } from "react-native";
import { connect } from "react-redux";

// Redux Actions
import { fetchLocation } from "../../redux";

// Components
import Screen from "../../components/atoms/Screen";
import OnboardingStep from "../../components/organisms/onboarding/OnboardingStep";
import StepButton from "../../components/organisms/onboarding/StepButton";
import SkipButton from "../../components/organisms/onboarding/SkipButton";
import stepList from "../../components/organisms/onboarding/stepList";
import CustomStatusBar from "../../components/atoms/CustomStatusBar";

// Constants
import { colors } from "../../constants/theme";

// Utils
import { storeLocalData } from "../../utils/miscelanusUtils";

const OnboardingScreen = ({
  fetchLocation,
  setHeaderType,
  location,
  navigation,
  ...props
}) => {
  const [index, setIndex] = useState(0);
  const [step, setStep] = useState(stepList[0]);
  const [skipLoading, setSkipLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const title = index === stepList.length - 1 ? "Terminar" : "Siguiente";

  const storeData = async () => {
    try {
      storeLocalData("loaded", true);
    } catch (err) {
      console.log("error: ", err);
    }
  };

  useEffect(() => {
    navigation.setParams({ title: "Gopick" });
  }, []);

  const handleStep = async () => {
    if (index < stepList.length - 1) {
      setIndex(index + 1);
      setStep(stepList[index + 1]);
    } else {
      setButtonLoading(true);
      storeData();
      if (location.lat === null && Platform.OS !== "web") {
        await fetchLocation();
      }
      navigation.navigate("App");
    }
  };

  const handleNext = () => {
    if (step.action) {
      step.action();
      handleStep();
    } else {
      handleStep();
    }
  };

  const handleSkip = async () => {
    setSkipLoading(true);
    stepList[1].action();
    stepList[2].action();
    await storeData();
    if (location.lat === null && Platform.OS !== "web") {
      await fetchLocation();
    }
    setSkipLoading(false);
    navigation.navigate("App");
  };

  return (
    <Screen>
      <SkipButton onPress={handleSkip} loading={skipLoading} />
      <CustomStatusBar color={colors.white} />
      <View style={styles.container}>
        <OnboardingStep
          asset={step.asset}
          title={step.title}
          subtitle={step.subtitle}
          name={step.name}
        />
        <StepButton
          onPress={handleNext}
          title={title}
          loading={buttonLoading}
        />
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 18,
    paddingTop: Platform.OS === "android" ? 0 : 18,
  },
});

const mapStateToProps = (state) => {
  return {
    location: state.user.location,
  };
};
const mapDispatchToProps = {
  fetchLocation,
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingScreen);
