import React from "react";
import { Platform } from "react-native";
import { StyleSheet, View, TouchableOpacity } from "react-native";

// Components
import InfoContainer from "./InfoContainer";
import ProductImage from "./ProductImage";
import RemoveProduct from "./RemoveProduct";

const Product = ({
  data,
  justMenu,
  onPress,
  showActions,
  quantity,
  onAction,
  customStyle,
}) => {
  const { image, sold_out, stock } = data;
  const soldOut = stock === 0 || sold_out === 1 ? 1 : 0;

  const showAddButton = soldOut === 1 || justMenu === true ? false : true;

  const clickHandler = () => {
    if (showAddButton) {
      onPress();
    }
  };

  return (
    <TouchableOpacity
      onPress={clickHandler}
      style={[styles.container, { backgroundColor: customStyle?.color1 }]}
    >
      <View style={styles.content}>
        <RemoveProduct
          showActions={showActions}
          quantity={quantity}
          onPress={onAction}
          customStyle={customStyle}
        />
        <ProductImage image={image} soldOut={soldOut} quantity={quantity} />
        <InfoContainer
          data={data}
          customStyle={customStyle}
          showAddButton={showAddButton}
          onAdd={onPress}
        />
      </View>
    </TouchableOpacity>
  );
};

export default Product;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  content: {
    width: "100%",
    flex: 1,
    maxWidth: Platform.OS === "web" ? 400 : null,
    minHeight: 55,
    paddingVertical: 12,
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
  },
});
