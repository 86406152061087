import React from "react";
import { StyleSheet, Image, View } from "react-native";

const Allergens = ({ allergens }) => {
  return allergens ? (
    <View style={styles.tagContainer}>
      {allergens.map((tag) => {
        return (
          <Image
            key={tag.gid}
            style={styles.tag}
            key={tag.key}
            source={{ uri: tag.image }}
          />
        );
      })}
    </View>
  ) : (
    <View></View>
  );
};

export default Allergens;

const styles = StyleSheet.create({
  tagContainer: {
    flexDirection: "row",
    flexWrap: "wrap"
  },
  tag: {
    width: 16,
    height: 16,
    resizeMode: "cover",
    borderRadius: 10,
    marginRight: 3,
  },
});
