import React, { useState, useContext } from "react";
import { StyleSheet, Platform, View } from "react-native";
import { connect } from "react-redux";

// Components
import CustomButton from "../../../components/atoms/CustomButton";
import CustomAlert from "../../../components/molecules/CustomAlert";

// Constants
import { colors } from "../../../constants/theme";

// Methods
import { setFinishedOrder } from "./methods/setFinishedOrder";

import { AuthContext } from "../../../contexts/AuthContext";

const FinishButton = ({ id, onSuccess }) => {
  const { user: firebaseUser } = useContext(AuthContext);
  const [modal, setModal] = useState();
  const [responseAlert, setResponseAlert] = useState();
  const [active, setActive] = useState(true);

  const completeProcess = async () => {
    const token = await firebaseUser.getIdToken(true);
    const response = await setFinishedOrder(id, token);
    if (response.status === "success") {
      onSuccess();
      setActive(false);
      setResponseAlert({
        title: "¡Todo listo!",
        subtitle: "Disfruta de tu pedido",
      });
    } else {
      setResponseAlert({
        title: "No se pudo confirmar la entrega",
        subtitle: response.message,
      });
    }
    setModal("ResponseAlert");
  };

  const alertData = {
    title: "¿Has recibido el pedido?",
    subtitle:
      "Al pulsar en aceptar, confirmas haber recibido el pedido. Cambiará a estado completado",
    actions: [
      {
        title: "Cancelar",
        color: colors.white,
        textColor: colors.primaryColor,
        onPress: () => {},
      },
      {
        title: "Aceptar",
        color: colors.primaryColor,
        textColor: colors.white,
        onPress: completeProcess,
      },
    ],
  };

  const clickHandler = () => {
    setModal("Action");
  };

  if (!active) {
    return <View></View>;
  }
  return (
    <View style={styles.container}>
      <CustomButton active onPress={clickHandler} title={`¿Pedido entregado?`} />
      <CustomAlert
        modalVisible={modal === "Action"}
        setModalVisible={setModal}
        alertData={alertData}
      />
      <CustomAlert
        modalVisible={modal === "ResponseAlert"}
        setModalVisible={setModal}
        alertData={responseAlert}
      />
    </View>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(FinishButton);

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    bottom: Platform.OS === "ios" ? 0 : 160,
    width: "100%",
    height: 100,
    paddingHorizontal: 30,
  },
});
