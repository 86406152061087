import { baseVersion } from "../../../../../config";
import { UrlBaseV2 } from "../../../../constants/urls";

export const fetchOrderDetail = async (id, token) => {
  try {
    const response = await fetch(`${UrlBaseV2}order/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Connection: "keep-alive",
        Authorization: `Bearer ${token}`,
        "X-Client-Version": baseVersion,
      },
      method: "GET",
    });

    const resData = await response.json();

    if (resData.status === "success") {
      return resData;
    } else {
      return { status: "fail", message: error.message };
    }
  } catch (error) {
    console.log("[error fetching order] ", JSON.stringify(error));
    return { status: "fail", message: error.message };
  }
};
