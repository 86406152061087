const getCommissionByElement = (el, commissions, qty = 1) => {
  let result = 0;
  if (el.payService === 0) {
    commissions.map((item) => {
      if (item.amount !== null) {
        if (item.type === "fixed") {
          result += Math.floor(item.amount * qty);
        }
        if (item.type === "percentage") {
          result =
            Math.floor(
              Math.floor(el.amount) * (Math.floor(item.amount) * 0.0001)
            ) * qty;
        }
      }
    });
  }

  return Math.floor(result);
};

export default getCommissionByElement;
