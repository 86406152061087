import React from "react";
import {
  Modal,
  StyleSheet,
  View,
  TouchableOpacity,
  Platform,
} from "react-native";

// Constants
import { colors } from "../../../../constants/theme";

const ModalContainer = ({
  children,
  handleModal,
  modalVisible,
  size = "default",
  width,
  ...props
}) => {
  return (
    <Modal
      animationType="fade"
      transparent={true}
      dismissable={true}
      visible={modalVisible}
      style={styles.modal}
      {...props}
    >
      <View style={styles.centeredView}>
        <TouchableOpacity style={styles.closeContainer} onPress={handleModal} />
        <View style={styles.modalView}>{children}</View>
      </View>
    </Modal>
  );
};

export default ModalContainer;

const styles = StyleSheet.create({
  modal: { flex: 1, height: "100%" },
  centeredView: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  closeContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    backgroundColor: "rgba(0,0,0,0.3)",
    height: "100%",
  },
  modalView: {
    maxHeight: "75%",
    width: "90%",
    maxWidth: 400,
    alignItems: "center",
    justifyContent: "center",
  },
});
