export const SET_CURRENT_AMOUNT = "SET_CURRENT_AMOUNT";
export const SET_PRODUCT_LINES = "SET_PRODUCT_LINES";
export const SET_TICKET_LINES = "SET_TICKET_LINES";
export const SET_SHOP_CHECKOUT_DATA = "SET_SHOP_CHECKOUT_DATA";
export const SET_TICKET_DISCOUNT = "SET_TICKET_DISCOUNT";
export const SET_TICKET_PAYMENT_METHOD = "SET_TICKET_PAYMENT_METHOD";
export const SET_CURRENT_CARD = "SET_CURRENT_CARD";
export const FETCH_TICKET_DETAILS = "FETCH_TICKET_DETAILS";
export const FETCH_SHOP_DETAILS = "FETCH_SHOP_DETAILS";
export const RESET_TICKET_CART = "RESET_TICKET_CART";
