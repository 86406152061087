import React, { useState } from "react";
import { StyleSheet, View, Text } from "react-native";
import { TouchableOpacity } from "react-native";
import { connect } from "react-redux";

// Components
import Divider from "../../../../components/atoms/Divider";
import ServiceItem from "./ServiceItem";
import LocationsModal from "../modals/LocationsModal";

// Contants
import { colors, family } from "../../../../constants/theme";

// Redux
import { setService } from "../../../../redux/laterCart/laterCartActions";

const ServicePicker = ({ service, setService, data, location }) => {
  const [modal, setModal] = useState();
  const pickUpHandler = () => {
    setService({ id: 1 });
  };

  const mySiteHandler = () => {
    setModal("Locations");
  };

  return (
    <View style={styles.container}>
      <View style={styles.picker}>
        <ServiceItem
          service={{ label: "Recoger", id: 1 }}
          selected={service === 1}
          onPress={pickUpHandler}
        />
        <ServiceItem
          service={{ label: "En mi sitio", id: 2 }}
          selected={service === 2}
          onPress={mySiteHandler}
        />
        <LocationsModal
          visible={modal === "Locations"}
          setModal={setModal}
          data={data}
        />
      </View>
      {location && (
        <View style={{height: 30}}>
          <Divider height={10} />
          <TouchableOpacity
            onPress={() => {
              setModal("Locations");
            }}
          >
            <Text style={styles.title}>{location}</Text>
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
};

const mapStateToProp = (state) => {
  return {
    service: state.laterOrders.serviceTypeId,
    location: state.laterOrders.location,
  };
};

const mapDispatchToProps = {
  setService,
};

export default connect(mapStateToProp, mapDispatchToProps)(ServicePicker);

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
  },
  picker: {
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 100,
    borderColor: colors.darkGrey,
    borderWidth: 1,
    flexDirection: "row",
    width: 230,
    height: 45,
    padding: 1,
  },
  title: {
    fontFamily: family.bold,
    fontSize: 14,
    color: colors.secondary,
  },
});
