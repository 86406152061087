import React from "react";
import { Platform } from "react-native";
import { ActivityIndicator } from "react-native";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { colors } from "../../../constants/theme";

import CustomText from "../../atoms/CustomText";

const SkipButton = ({ onPress, loading }) => {
  return (
    <View style={styles.header}>
      {!loading ? (
        <TouchableOpacity onPress={onPress}>
          <CustomText bold color={colors.primaryColor} text="Omitir" />
        </TouchableOpacity>
      ) : (
        <ActivityIndicator size="small" color={colors.primaryColor} />
      )}
    </View>
  );
};

export default SkipButton;

const styles = StyleSheet.create({
  header: {
    justifyContent: "flex-end",
    alignItems: "flex-end",
    width: "100%",
    position: "absolute",
    top: Platform.OS === "android" ? 10 : 36,
    left: -18,
    zIndex: 3,
  },
});
