import React from "react";
import { StyleSheet, View, Picker } from "react-native";

// Components
import TextInput from "../../atoms/TextInput";
import CustomCheckBox from "../../atoms/CustomCheckBox";
import Label from "../../atoms/Label";

// Constants
import { colors, size, family } from "../../../constants/theme";

// Hooks
import useForceRender from "../../../hooks/useForceRender";

const TicketQuestionSelector = ({ data, questionHandler, ticketId }) => {
  const [forcerRender] = useForceRender();
  const getInput = () => {
    const inputHandler = (e) => {
      questionHandler(data.question, ticketId, e);
      forcerRender();
    };

    const multilineInputHandler = (e) => {
      questionHandler(data.question, ticketId, e);
      forcerRender();
    };

    const checkBoxHandler = (e) => {
      questionHandler(data.question, ticketId, e);
      forcerRender();
    };

    switch (data.type) {
      case "textfield": {
        return (
          <TextInput
            value={data.response}
            onChange={inputHandler}
            placeholder={data.question}
          />
        );
      }
      case "select": {
        return (
          <View>
            <Label color={"white"} label={data.question} />
            <Picker
              selectedValue={data.response}
              style={styles.picker}
              onValueChange={(itemValue) => inputHandler(itemValue)}
            >
              {data?.options?.map((el) => (
                <Picker.Item key={el} label={el} value={el} />
              ))}
            </Picker>
          </View>
        );
      }
      case "check": {
        return (
          <CustomCheckBox
            value={data.response}
            onPress={checkBoxHandler}
            textColor="white"
            label={data.question}
          />
        );
      }
      case "textarea": {
        return (
          <View style={styles.multiline}>
            <TextInput
              value={data.response}
              onChange={multilineInputHandler}
              multiline
              placeholder={data.question}
            />
          </View>
        );
      }
    }
  };
  return <View style={styles.container}>{getInput()}</View>;
};

export default TicketQuestionSelector;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    marginBottom: 20,
  },
  picker: {
    width: "100%",
    padding: 10,
    marginTop: 10,
    backgroundColor: "white",
    borderRadius: 10,
    borderWidth: 1,
    borderColor: colors.grey,
    fontSize: size.medium,
    fontFamily: family.normal,
    color: colors.brown,
  },
  multiline: {
    width: "100%",
    height: 100,
  },
});
