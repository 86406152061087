import React from "react";
import { Feather } from "@expo/vector-icons";
import { Text, View } from "react-native";
import StyleSheet from "react-native-media-query";

// Components
import Divider from "../../../../../components/atoms/Divider";

// Constants
import { colors, family } from "../../../../../constants/theme";

// Utils
import getOpeningTime from "../../../../../utils/shop/getOpeningTime";

const TimeLine = ({ schedules, bold = true }) => {
  return (
    <View style={styles.container}>
      <Feather name="clock" size={bold ? 16 : 14} color={colors.white} />
      <Divider width={5} />
      <Text
        style={bold ? styles.boldText : styles.text}
        dataSet={{ media: ids.text }}
      >
        {getOpeningTime(schedules)}
      </Text>
    </View>
  );
};

export default TimeLine;

const { ids, styles } = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    flexShrink: 1,
    flexGrow: 0,
    paddingVertical: 3,
  },
  boldText: {
    fontFamily: family.bold,
    fontSize: 16,
    color: colors.white,
    "@media (max-height: 700px) or (max-width: 340px)": {
      fontSize: 12,
    },
  },
  text: {
    fontFamily: family.normal,
    fontSize: 14,
    color: colors.white,
    "@media (max-height: 700px) or (max-width: 340px)": {
      fontSize: 12,
    },
  },
});
