import React from "react";
import { StyleSheet, View } from "react-native";
import SvgQRCode from "react-native-qrcode-svg";

// Constants
import { colors, family } from "../../../constants/theme";

const QrSection = ({ gid }) => {
  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <SvgQRCode
          value={gid.toString()}
          backgroundColor={"transparent"}
          color={colors.brown}
          size={125}
        />
      </View>
    </View>
  );
};

export default QrSection;

const styles = StyleSheet.create({
  content: {
    width: 125,
    height: 125,
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    fontFamily: family.normal,
    fontSize: 14,
    color: colors.brown,
    textAlign: "center",
    paddingHorizontal: 50,
  },
});
