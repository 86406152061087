import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { Platform } from "react-native";

// Components
import CustomModal from "../../atoms/CustomModal";

// Utils
import WarningModal from "../../organisms/modals/WarningModal";
import AdyenTicketingPayment from "../../../adyen/components/AdyenTicketingPayment";

const PaymentModal = ({ visible, setModal, navigation }) => {
  const [warningText, setWarningText] = useState();
  return (
    <>
      <CustomModal modalVisible={visible} handleModal={setModal}>
        <View style={styles.container}>
          {Platform.OS === "web" && (
            <AdyenTicketingPayment
              modalHandler={setModal}
              navigation={navigation}
              setWarningText={setWarningText}
              visible={visible}
            />
          )}
        </View>
      </CustomModal>
      <WarningModal
        visible={warningText !== undefined}
        setModal={setWarningText}
        text={warningText}
      />
    </>
  );
};

export default PaymentModal;

const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
  webWrapper: {
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
  },
});
