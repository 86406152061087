import React, { useEffect, useState } from "react";
import { ScrollView, useWindowDimensions } from "react-native";
import { connect } from "react-redux";

// Components
import Screen from "../../components/atoms/Screen";
import HtmlNative from "../../components/atoms/HtmlNative";

// Redux Actions
import { fetchTextualPrivacy } from "../../redux/config/configActions";
import { Platform } from "react-native";

const ProfilePrivacyAdviceScreen = ({ privacy, fetchTextualPrivacy }) => {
  const [loading, setLoading] = useState(false);
  const height = useWindowDimensions().height;

  useEffect(() => {
    setLoading(true);
    fetchTextualPrivacy().then(() => setLoading(false));
  }, []);

  return (
    <Screen>
      <ScrollView
        contentContainerStyle={
          Platform.OS === "web"
            ? {
                height: height - 70,
              }
            : { height: "auto" }
        }
        style={{ marginBottom: 10, paddingHorizontal: 18 }}
        showsVerticalScrollIndicator={false}
      >
        <HtmlNative
          value={
            loading
              ? "<p>Cargando...</p>"
              : privacy === null
              ? "<p>Nada que mostrar</p>"
              : privacy
          }
          style={
            "p{color: #424242; font-family: 'Raleway', sans-serif, font-size: 14, line-height:15}"
          }
        />
      </ScrollView>
    </Screen>
  );
};

const mapStateToProps = (state) => {
  return { privacy: state.config.textualPages.privacy };
};

const mapDispatchToProps = {
  fetchTextualPrivacy,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfilePrivacyAdviceScreen);
