import React from "react";
import { Text, View, Image } from "react-native";
import StyleSheet from "react-native-media-query";

// Constants
import { colors, size, family } from "../../../constants/theme";
import Divider from "../../atoms/Divider";
import Title from "../../atoms/Title";
import Animation from "../../atoms/Animation";

const getImage = (key) => {
  switch (key) {
    case 1:
      return (
        <Image
          style={styles.image}
          dataSet={{ media: ids.image }}
          source={require("../../../assets/onboarding/INICIO1.png")}
        />
      );
    case 2:
      return (
        <Image
          style={styles.image}
          dataSet={{ media: ids.image }}
          source={require("../../../assets/onboarding/INICIO2.png")}
        />
      );
    case 3:
      return (
        <Image
          style={styles.image}
          dataSet={{ media: ids.image }}
          source={require("../../../assets/onboarding/INICIO3.png")}
        />
      );

    default:
      break;
  }
};

const OnboardingStep = ({ asset, title, subtitle }) => {
  return (
    <View style={styles.step}>
      <View style={styles.infoContainer}>
        {getImage(asset)}
        <Divider height={30} />
        <Text style={styles.title} dataSet={{ media: ids.title }}>
          {title}
        </Text>
        {subtitle && (
          <>
            <Divider height={10} />
            <Text
              dataSet={{ media: ids.title }}
              style={[styles.title, { color: colors.primaryColor }]}
            >
              {subtitle}
            </Text>
          </>
        )}
      </View>
    </View>
  );
};

export default OnboardingStep;

const { ids, styles } = StyleSheet.create({
  step: {
    width: "100%",
    flex: 1,
    paddingVertical: 18,
  },
  title: {
    fontFamily: family.bold,
    fontSize: 18,
    textAlign: "center",
    color: colors.darkGrey,
    "@media (max-width: 320px)": {
      fontSize: 14,
    },
  },
  infoContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 50,
  },
  image: {
    resizeMode: "contain",
    width: 300,
    marginHorizontal: 50,
    height: 300,
    "@media (max-width: 320px)": {
      width: 200,
      height: 200,
    },
  },
});
