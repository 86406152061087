import React from 'react'
import Svg, { Path } from "react-native-svg";

const OrdersIcon = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
      <Path d="M23.1 7h-3.4v-.5c0-2.8-2.1-5-4.8-5-2.6 0-4.8 2.3-4.8 5V7H6.9c-.6 0-1 .4-1 1v19.5c0 .6.4 1 1 1h16.3c.6 0 1-.4 1-1V8c-.1-.6-.5-1-1.1-1zm-10.9-.5c0-1.6 1.3-3 2.9-3 1.5 0 2.7 1.4 2.7 3V7h-5.6v-.5zm9.9 20H7.9V9h2.3v1.1c0 .6.4 1 1 1s1-.4 1-1V9h5.6v1.1c0 .6.4 1 1 1s1-.4 1-1V9h2.4v17.5z" />
    </Svg>
  )
}

export default OrdersIcon
