import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View } from "react-native";
import { TouchableOpacity, ScrollView } from "react-native";

// Components
import CustomModal from "../../atoms/CustomModal";
import TicketQuestionSelector from "../components/TicketQuestionSelector";
import CustomButton from "../../atoms/CustomButton";
import CustomText from "../../atoms/CustomText";
import Divider from "../../atoms/Divider";

// Constants
import { colors, family, size } from "../../../constants/theme";

// Hooks
import useForceRender from "../../../hooks/useForceRender";

// Utils
import i18n from "../../../i18n/i18n";
import anyEmptyQuestion from "../methods/anyEmptyQuestion";

const TicketQuestionsModal = ({
  visible,
  setModal,
  answerArray,
  setAnswerArray,
  onFinish
}) => {
  const [error, setError] = useState("");
  const [forceRender] = useForceRender();
  const [selectedTicket, setSelectedTicket] = useState();
  const [canContinue, setCanContinue] = useState(false);

  const questionHandler = (question, ticketId, response) => {
    let auxAnswers = answerArray;

    answerArray.map((item, index) => {
      if (item.gid === ticketId) {
        item.questions.map((el, questionIndex) => {
          if (el.question === question) {
            auxAnswers[index].questions[questionIndex].response = response;
          }
        });
      }
    });
    setAnswerArray(auxAnswers);
    forceRender();
  };

  useEffect(() => {
    if (anyEmptyQuestion(answerArray) === true) {
      setCanContinue(false);
    } else {
      setCanContinue(true);
    }
  }, [answerArray]);

  useEffect(() => {
    if (visible === true) {
      setSelectedTicket(answerArray[0]?.gid);
    }
  }, [visible]);

  const buttonHandler = () => {
    let result = anyEmptyQuestion(answerArray);
    if (result === null) {
      onFinish()
    } else {
      if (result !== selectedTicket) {
        setSelectedTicket(result);
        setError();
      } else {
        setError(i18n.t("tickets.questions_remaining"));
      }
    }
  };

  const tagHandler = (index) => {
    setSelectedTicket(index);
  };

  const componentQuestion = (gid) => {
    let array = [];
    if (answerArray.length > 0) {
      answerArray.map((el, index) => {
        if (el.gid === gid) {
          el.questions.map((question) => {
            array.push(
              <TicketQuestionSelector
                key={question.question + index}
                ticketId={el.gid}
                data={question}
                questionHandler={questionHandler}
              />
            );
          });
        }
      });
    }
    return array;
  };

  return (
    <CustomModal
      modalVisible={visible}
      handleModal={() => setModal(false)}
      color={"rgba(0,0,0,0.9)"}
      width={500}
    >
      <View style={styles.column}>
        <View style={styles.titleContainer}>
          <Text style={styles.title}>{i18n.t("tickets.complete_data")}</Text>
        </View>
        <View style={styles.wrapper}>
          <ScrollView
            style={styles.titleListMobile}
            horizontal={true}
            showsHorizontalScrollIndicator={true}
          >
            {answerArray?.map((item) => (
              <TouchableOpacity
                key={item.gid}
                style={[
                  styles.tagContainer,
                  selectedTicket === item.gid && styles.selectedTag,
                ]}
                onPress={() => tagHandler(item.gid)}
              >
                <Text
                  style={[
                    styles.tag,
                    selectedTicket === item.gid && { fontFamily: family.bold },
                  ]}
                >
                  {item.name}
                </Text>
              </TouchableOpacity>
            ))}
          </ScrollView>
          <Divider height={25} />
          <ScrollView
            style={[styles.questionsContainer]}
            showsVerticalScrollIndicator={true}
          >
            {answerArray?.length > 0 && componentQuestion(selectedTicket)}
          </ScrollView>
        </View>
        {error !== "" && <CustomText color="red" text={error} />}
        <View style={{ height: 60, width: "100%" }}>
          <CustomButton
            title={i18n.t("tickets.next")}
            marginTop={20}
            active
            onPress={buttonHandler}
            color={"white"}
            textColor={"black"}
          />
        </View>
      </View>
    </CustomModal>
  );
};

export default TicketQuestionsModal;

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: "column",
    width: "100%",
    maxHeight: 450,
  },
  column: {
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
  tagContainer: {
    marginBottom: 10,
    borderRadius: 10,
    paddingHorizontal: 10,
    paddingVertical: 5,
    textAlign: "center",
    maxWidth: 140,
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  selectedTag: {
    backgroundColor: colors.darkGrey,
  },
  titleList: {
    maxWidth: 100,
    width: "100%",
    marginRight: 10,
    height: "100%",
    flexDirection: "column",
  },
  titleListMobile: {
    width: "100%",
    flexDirection: "row",
    height: 60,
    marginRight: 10,
  },
  tag: {
    fontFamily: family.normal,
    color: "white",
    fontSize: 12,
    textAlign: "center",
  },
  titleContainer: {
    marginBottom: 20,
  },
  title: {
    fontFamily: family.bold,
    textAlign: "center",
    color: "white",
    fontSize: size.normal,
  },
  ticketTitle: {
    fontFamily: family.bold,
    textAlign: "center",
    color: "white",
    fontSize: 18,
  },
  questionsContainer: {
    width: "100%",
    marginBottom: 10,
  },
});
