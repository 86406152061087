import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableOpacity,
} from "react-native";
import { AntDesign } from "@expo/vector-icons";

// Components
import Divider from "../../../../components/atoms/Divider";

// Constants
import { colors, family } from "../../../../constants/theme";

const BackLine = ({ title, navigation }) => {
  const clickHandler = () => {
    navigation.goBack();
  };
  return (
    <View style={styles.container}>
      <TouchableOpacity style={styles.backButton} onPress={clickHandler}>
        <AntDesign name="arrowleft" size={24} color={colors.brown} />
      </TouchableOpacity>
      <Divider width={10} />
      <Text style={styles.text}>{title}</Text>
    </View>
  );
};

export default BackLine;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: Platform.select({ ios: 90, web: 60, android: 60 }),
    justifyContent: "flex-start",
    alignItems: "flex-end",
    flexDirection: "row",
    paddingBottom: 10,
  },
  text: {
    fontFamily: family.bold,
    fontSize: 18,
    color: colors.brown,
  },
});
