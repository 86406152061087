import React, { useState, useRef, useEffect } from "react";
import { Text, Animated, Easing } from "react-native";
import { Feather } from "@expo/vector-icons";
import StyleSheet from "react-native-media-query";

// Components
import ActionButton from "./ActionButton";

// Constants
import { colors, family } from "../../../../../constants/theme";

const RemoveProduct = ({
  quantity,
  onPress,
  buttonSize = 25,
  iconSize = 20,
  customStyle,
  showActions,
}) => {
  const animatedController = useRef(new Animated.Value(0)).current;
  const [visible, setVisible] = useState(showActions);

  let animatedWidth = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 40],
  });

  const closeComponent = () => {
    Animated.timing(animatedController, {
      duration: 300,
      toValue: 0,
      easing: Easing.bezier(0.4, 0.0, 0.2, 1),
      useNativeDriver: false,
    }).start();
    setVisible(false);
  };

  const openComponent = () => {
    setVisible(true);
    Animated.timing(animatedController, {
      duration: 300,
      toValue: 1,
      easing: Easing.bezier(0.4, 0.0, 0.2, 1),
      useNativeDriver: false,
    }).start();
  };

  const toggleListItem = () => {
    if (visible && quantity === 0) {
      closeComponent();
    } else {
      openComponent();
    }
  };

  useEffect(() => {
    if (showActions) {
      toggleListItem();
    }
    if (visible === true && quantity === null) {
      closeComponent();
    }
  }, [quantity]);

  return (
    <Animated.View style={[styles.actions, { width: animatedWidth }]}>
      <Text
        dataSet={{ media: ids.qtyText }}
        style={[styles.qtyText, { color: customStyle?.letter1 }]}
      >
        {quantity}x
      </Text>
      <ActionButton color={colors.lightRed} onPress={onPress} size={buttonSize}>
        <Feather
          name={quantity > 1 ? "minus" : "trash-2"}
          size={quantity > 1 ? iconSize : iconSize - 4}
          color={colors.red}
        />
      </ActionButton>
    </Animated.View>
  );
};

export default RemoveProduct;

const { ids, styles } = StyleSheet.create({
  actions: {
    height: "100%",
    minHeight: 55,
    maxWidth: 40,
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    overflow: "hidden",
  },
  qtyText: {
    fontSize: 14,
    fontFamily: family.bold,
    color: colors.brown,
  },
});
