import React from "react";
import Svg, { Path, Circle } from "react-native-svg";

const EmptyScreen1 = (...props) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 926.632 785.994"
      {...props}
    >
      <Path
        fill="#3f3d56"
        d="M894.203 329.056c-46.34 59.26-133.78 72.83-206.13 52.19a335.51 335.51 0 01-68.1-28.35c-12.54-6.81-24.78-14.28-36.77-22.24-2.95-1.95-5.9-3.93-8.82-5.95q-2.985-2.04-5.95-4.13c-24.76-17.42-48.55-36.53-71.94-55.53-58.39-47.43-118.38-96.32-190.12-118.95-14.78-4.66-33.9-6.84-43.04 5.68-7.93 10.87-3.09 26.26 3.62 37.92 24.89 43.19 70.07 70.46 115.29 91.43 45.22 20.97 93.12 37.96 132.43 68.62 39.31 30.66 69.32 79.39 61.28 128.58-6.93 42.39-41.54 76.83-81.78 91.84-40.24 15-85.12 12.91-126.95 3.17-41.83-9.75-81.54-26.76-121.91-41.42-24.34-8.85-56.16-14.97-72.31 5.28-13.64 17.11-5.8 44.1 10.81 58.34s39.09 19.01 60.54 23.29q99.9 19.95 199.8 39.89c20.43 4.07 41.34 8.33 59.14 19.17 17.8 10.83 32.16 29.75 31.65 50.58-.51 20.53-15.54 38.67-33.96 47.76-18.41 9.08-39.67 10.48-60.18 9.5-76.86-3.68-297.21-125.79-350.06-141.4-33.96-10.02-71.09-24.74-85.41-57.12-17.83-40.31 11.51-89.08 51.56-107.49 40.04-18.42 86.46-14.34 130.1-8.14 43.64 6.2 88.72 14.09 131.2 2.31 42.48-11.78 81.4-50.18 77.21-94.06-3.65-38.18-38.04-66.95-74.23-79.63-28.33-9.93-58.47-12.63-88.23-17.1-8.26-1.23-16.49-2.61-24.64-4.31a307.086 307.086 0 01-60.75-19.5 312.924 312.924 0 01-58.04-33.31 305.265 305.265 0 01-40.32-35.01q-2.835-2.94-5.61-6.01c-1.76-1.96-3.5-3.96-5.2-6-22.12-26.43-38.46-58.98-33.56-92.52 4.89-33.45 30.96-61.3 62-74.68 17.94-7.73 37.29-11.19 56.88-11.69a233.796 233.796 0 0142.78 3.08c65.41 10.46 125.35 42.24 181.96 76.65 56.6 34.41 111.76 72.18 173.29 96.71 61.53 24.54 131.57 34.93 193.99 12.74 62.42-22.18 113.7-82.7 110.65-148.87 41.4 35.65 76.58 80.46 92.16 132.82 15.58 52.36 9.32 112.82-24.33 155.86z"
      ></Path>
      <Circle cx="595.911" cy="253.394" r="7.278" fill="#E22314"></Circle>
      <Circle cx="852.911" cy="157.394" r="11.252" fill="#ff6584"></Circle>
      <Circle cx="114.911" cy="511.394" r="4.157" fill="#ff6584"></Circle>
      <Path
        fill="#ff6584"
        d="M234.243 272.396a44.43 44.43 0 01-1.3 10.7c-8.26-1.23-16.49-2.61-24.64-4.31a307.086 307.086 0 01-60.75-19.5 44.337 44.337 0 0186.69 13.11z"
      ></Path>
      <Circle cx="780.911" cy="230.394" r="4.252" fill="#f0f0f0"></Circle>
      <Circle cx="391.911" cy="698.394" r="4.252" fill="#f0f0f0"></Circle>
      <Circle cx="452.911" cy="757.394" r="4.252" fill="#f0f0f0"></Circle>
      <Circle cx="75.911" cy="589.394" r="4.252" fill="#f0f0f0"></Circle>
      <Circle cx="284.911" cy="510.394" r="4.252" fill="#f0f0f0"></Circle>
      <Circle cx="263.911" cy="88.394" r="4.252" fill="#f0f0f0"></Circle>
      <Circle cx="520.911" cy="421.394" r="4.252" fill="#f0f0f0"></Circle>
      <Circle cx="552.911" cy="495.394" r="4.252" fill="#f0f0f0"></Circle>
      <Path
        fill="none"
        d="M154.843 163.724q9.075-2.738 17.362-5.935c33.96-13.117 53.438-30.911 53.438-48.82s-19.477-35.703-53.438-48.82q-5.037-1.946-10.386-3.724a136.34 136.34 0 01-6.976 107.3z"
      ></Path>
      <Path
        fill="#E22314"
        d="M169.643 101.966a135.44 135.44 0 01-14.8 61.76q-1.935 3.78-4.1 7.43a136.431 136.431 0 01-61.23 54.82 305.27 305.27 0 01-40.32-35.01q-2.835-2.94-5.61-6.01c-1.76-1.96-3.5-3.96-5.2-6-22.12-26.43-38.46-58.98-33.56-92.52 4.89-33.45 30.96-61.3 62-74.68 17.94-7.73 37.29-11.19 56.88-11.69a136.126 136.126 0 0135.33 49.16c1 2.37 1.92 4.77 2.79 7.2a135.756 135.756 0 017.82 45.54z"
      ></Path>
      <Path
        fill="#e4e4e4"
        d="M231.643 108.966c0 20.83-20.34 40.15-57.28 54.42-7.39 2.86-15.3 5.45-23.62 7.77-31.14 8.68-68.2 13.49-107.16 13.8-1.76-1.96-3.5-3.96-5.2-6 .42.01.84.01 1.26.01 42.31 0 82.45-5.36 115.2-15.24 6.05-1.83 11.85-3.81 17.36-5.94 33.96-13.11 53.44-30.91 53.44-48.82s-19.48-35.7-53.44-48.82q-5.04-1.95-10.38-3.72c-.87-2.43-1.79-4.83-2.79-7.2q7.98 2.49 15.33 5.32c36.94 14.27 57.28 33.6 57.28 54.42z"
      ></Path>
      <Circle cx="45.911" cy="30.394" r="4.252" fill="#f0f0f0"></Circle>
      <Circle cx="134.911" cy="57.394" r="4.252" fill="#f0f0f0"></Circle>
      <Circle cx="88.911" cy="100.394" r="41.348" fill="#f0f0f0"></Circle>
      <Path
        fill="#f0f0f0"
        d="M852.513 319.236c-9.28 2.74-29.74 4.76-52.24 6-22.49 1.23-47.16 1.77-71.52 2.29q-63.96 1.38-127.91 2.77-8.79.195-17.64.36c-2.95-1.95-5.9-3.93-8.82-5.95 5.23.03 10.46 0 15.65-.1 22.17-.44 40.94-2.17 62.32-2.99 32.9-1.27 68.42-.27 102.71.24 34.29.52 73.36.33 97.45-2.62zM198.084 634.467L62.163 521.237l132.045 117.765a3.15 3.15 0 103.876-4.535z"
        opacity="0.3"
      ></Path>
      <Circle cx="94.911" cy="201.394" r="4.252" fill="#f0f0f0"></Circle>
      <Path
        fill="#ffb6b6"
        d="M452.017 694.074L469.604 694.073 477.971 626.236 452.014 626.237 452.017 694.074z"
      ></Path>
      <Path
        fill="#2f2e41"
        d="M447.53 688.332h4.951l19.327-7.86 10.358 7.858h.002a22.074 22.074 0 0122.072 22.072v.718l-56.708.002z"
      ></Path>
      <Path
        fill="#ffb6b6"
        d="M320.308 694.074L302.721 694.073 294.354 626.236 320.311 626.237 320.308 694.074z"
      ></Path>
      <Path
        fill="#2f2e41"
        d="M324.794 711.122l-56.709-.002v-.718a22.074 22.074 0 0122.073-22.072l10.36-7.858 19.326 7.86h4.95z"
      ></Path>
      <Path
        fill="#E22314"
        d="M383.325 174.188l-17.808 13.56-47.305 12.528s16.95 118.96 8.95 126.96-.784 8.215-2.392 18.608-8.013 37.83-8.013 37.83h141.188s1.137-17.052-5.823-20.745 6.416-20.929.728-23.81 2.313-27.883 2.313-27.883l17.337-81.85-46.114-36.079-7.013-16.898z"
      ></Path>
      <Circle cx="405.545" cy="274.328" r="6.161" fill="#f2f2f2"></Circle>
      <Path
        fill="#2f2e41"
        d="M316.757 383.674l-5.822 149.921s-25.139 110.73-20.772 113.641 6.216 20.27 6.216 20.27l24.744 2.91s0-11.644 5.823-16.01 59.677-209.599 59.677-209.599l52.54 198.43s1.315 11.169 5.682 11.169 5.822 16.01 5.822 16.01h24.744v-16.01s11.118-3.525 6.752-15.17-24.218-255.562-24.218-255.562z"
      ></Path>
      <Circle cx="401.817" cy="135.415" r="29.839" fill="#ffb6b6"></Circle>
      <Path
        opacity="0.1"
        style={{ isolation: "isolate" }}
        d="M452.85 269.414L458.672 273.781 461.583 253.403 452.85 269.414z"
      ></Path>
      <Path
        fill="#E22314"
        d="M321.003 200.16s-7.278-1.455-11.645 11.644-20.377 62.589-20.377 71.322 99.71 47.267 99.71 47.267l-10.375-36.396-45.669-23.97z"
      ></Path>
      <Circle cx="393.316" cy="307.997" r="17" fill="#ffb6b6"></Circle>
      <Path
        opacity="0.1"
        style={{ isolation: "isolate" }}
        d="M331.316 251.997L332.647 270.026 374.878 292.192 337.316 266.997 331.316 251.997z"
      ></Path>
      <Path
        fill="#f0f0f0"
        d="M432.84 254.567h-.549V239.56a8.686 8.686 0 00-8.686-8.686h-31.797a8.686 8.686 0 00-8.686 8.686v82.337a8.686 8.686 0 008.686 8.686h31.797a8.686 8.686 0 008.686-8.686V265.25h.548z"
      ></Path>
      <Path
        fill="#E22314"
        d="M472.5 229.387s4.367 2.91 7.278 16.01 21.833 62.59 14.555 74.233-9.426 24.073-34.17 6.606l-34.24-31.35 24.744-23.288 10.189 7.277z"
      ></Path>
      <Circle cx="408.095" cy="260.292" r="7.278" fill="#E22314"></Circle>
      <Circle cx="429.163" cy="276.236" r="17" fill="#ffb6b6"></Circle>
      <Path
        opacity="0.1"
        style={{ isolation: "isolate" }}
        d="M330.514 306.41L379.316 327.997 329.158 318.194 330.514 306.41z"
      ></Path>
      <Path
        fill="#2f2e41"
        d="M371.713 141.362l-4.096-15.592c-1.07-4.068-2.147-8.219-1.907-12.418s2.021-8.533 5.56-10.809 8.938-1.737 11.215 1.8c.433-5.648 5.57-9.992 11.022-11.531s11.238-.93 16.897-.67 11.63.078 16.514-2.792a5.112 5.112 0 01-2.289 6.041 4.963 4.963 0 005.995-3.162 12.88 12.88 0 01-3.38 8.845 9.475 9.475 0 0112.245 3.468c2.492 4.32 1.096 9.747-.362 14.517l-7.484 24.483c-1.135-3.722-1.349-7.651-1.956-11.495s-1.687-7.768-4.224-10.718-6.847-4.658-10.451-3.194c-2.023.822-3.565 2.49-5.39 3.686-3.747 2.455-8.513 2.807-12.964 2.308s-8.79-1.767-13.24-2.274c-2.786-.317-5.896-.233-7.999 1.623a10.281 10.281 0 00-2.738 5.468 43.486 43.486 0 00-1.158 12.54"
      ></Path>
    </Svg>
  );
};

export default EmptyScreen1;
