import React from "react";
import { StyleSheet, Text, View } from "react-native";

// Contants
import { colors, family, size } from "../../../../constants/theme";

// Components
import Divider from "../../../atoms/Divider";

const SectionDivider = () => {
  return (
    <View style={styles.mediaDivider}>
      <Divider width="45%" color={colors.brown} />
      <Text style={styles.mediaText}>o</Text>
      <Divider width="45%" color={colors.brown} />
    </View>
  );
};

export default SectionDivider;

const styles = StyleSheet.create({
  mediaDivider: {
    marginVertical: 10,
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  mediaText: {
    fontFamily: family.normal,
    color: colors.brown,
    fontSize: size.small,
    textAlign: "center",
  },
});
