import React from "react";
import { StyleSheet, TouchableOpacity, Text } from "react-native";
import { FontAwesome } from "@expo/vector-icons";

// Components
import Container from "./Container";
import Divider from "../atoms/Divider";

// Constants
import { colors, family } from "../../constants/theme";

const BackHeader = ({ title, navigation, pop, ...props }) => {
  const clickHandler = () => {
    if (pop) {
      navigation.pop();
    } else {
      navigation.goBack(null);
    }
  };

  return (
    <Container {...props}>
      <TouchableOpacity onPress={clickHandler} style={styles.backContainer}>
        <FontAwesome name="chevron-left" size={14} color={colors.brown} />
        <Divider width={5} />
        <Text style={styles.title} numberOfLines={2}>
          {title}
        </Text>
      </TouchableOpacity>
    </Container>
  );
};

export default BackHeader;

const styles = StyleSheet.create({
  backContainer: { flexDirection: "row", alignItems: "center" },
  title: {
    fontFamily: family.bold,
    fontSize: 16,
    textAlign: "left",
    color: colors.brown,
  },
});
