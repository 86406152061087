import React from "react";
import { View, StyleSheet } from "react-native";

// Components
import Wrapper from "./Wrapper";
import Title from "./Title";

const EventItem = ({ data, navigation }) => {
  const { image, name, gid, custom_design } = data;

  const clickHandler = () => {
    navigation.navigate("EventsStack", {
      screen: "EventListScreen",
      params: {
        idEvent: gid,
        pageName: name,
        style: custom_design,
      },
    });
  };

  return (
    <Wrapper image={image} onPress={clickHandler}>
      <View style={styles.content}>
        <Title title={name} />
      </View>
    </Wrapper>
  );
};

const styles = StyleSheet.create({
  content: {
    width: "100%",
    height: "100%",
    padding: 24,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default EventItem;
