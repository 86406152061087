import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View, TouchableOpacity } from "react-native";
import { MaterialIcons } from "@expo/vector-icons";

// Components
import CustomSwitch from "../../../../../../components/atoms/CustomSwitch";
import Divider from "../../../../../../components/atoms/Divider";

// Constants
import { family } from "../../../../../../constants/theme";
import { setDate } from "../../../../../../redux/cart/cartActions";
import { connect } from "react-redux";

const ShopItem = ({ customStyle, shop, onSelect, setDate }) => {
  const [showSlots, setShowSlots] = useState(
    shop?.delivery === "ASAP" ? false : true
  );
  const { textColor } = customStyle;

  const switchHandler = () => {
    setShowSlots(!showSlots);
    if(!showSlots === true){
      onSelect(shop)
    }else{
      setDate(null, shop.id_shop);
    }
  };

  return (
    <View style={styles.container}>
      <Text style={[styles.shop, { color: textColor }]}>{shop.shop_name}</Text>
      <View style={styles.switchContainer}>
        <CustomSwitch
          customStyle={customStyle}
          onChange={switchHandler}
          value={showSlots}
        />
        <Divider width={10} />
        <View style={{ flexDirection: "row" }}>
          <Text style={[styles.text, { color: textColor }]}>
            Programar pedido
          </Text>
          <Divider width={10} />
          {shop?.delivery !== "Lo antes posible" && (
            <>
              <Text style={[styles.text, { color: textColor }]}>
                {shop?.delivery}
              </Text>
              <Divider width={5} />
              <TouchableOpacity onPress={() => onSelect(shop)}>
                <MaterialIcons name="mode-edit" size={14} color={textColor} />
              </TouchableOpacity>
            </>
          )}
        </View>
      </View>
    </View>
  );
};

const mapDispatchToProps = {
  setDate,
};

export default connect(null, mapDispatchToProps)(ShopItem);

const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
  shop: {
    fontFamily: family.bold,
    fontSize: 14,
  },
  text: {
    fontFamily: family.normal,
    fontSize: 12,
  },
  switchContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 10,
  },
});
