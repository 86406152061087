import React from "react";
import { StyleSheet, Text, View } from "react-native";

// Constants
import { family } from "../../../../../../constants/theme";

// Utils
import { getFormattedPrice } from "../../../../../../utils/productMethods";

const TotalLine = ({ amount, customStyle }) => {
  const { textColor } = customStyle;
  const textStyle = [styles.text, { color: textColor }];

  return (
    <View style={styles.container}>
      <Text style={textStyle}>TOTAL</Text>
      <Text style={textStyle}>{getFormattedPrice(amount)}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    justifyContent: "space-between",
    marginBottom: 5,
    flexDirection: "row",
  },
  text: {
    fontFamily: family.bold,
    fontSize: 14,
  },
});

export default TotalLine;
