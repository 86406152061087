import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { family } from "../../../../../constants/theme";

const ShopServiceLine = ({ service, customStyle }) => {
  const { textColor } = customStyle;
  const { key, location, label } = service;
  const textStyle = [styles.text, { color: textColor }];

  return (
    <View>
      {key === "my_site" ? (
        <Text style={textStyle}>Tu pedido se entregará en {location}</Text>
      ) : key === "pick_up" ? (
        <Text style={textStyle}>Pedido para recoger</Text>
      ) : (
        <Text style={textStyle}>{label}</Text>
      )}
    </View>
  );
};

export default ShopServiceLine;

const styles = StyleSheet.create({
  text: {
    fontFamily: family.normal,
    fontSize: 12,
    textAlign: "left",
  },
  column: {
    marginBottom: 5,
  },
});
