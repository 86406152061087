import React from "react";
import { ScrollView } from "react-native";
import { StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";

// Components
import ScheduleItem from "../../../live/ConfirmOrderScreen/modals/ScheduleModal/components/ScheduleItem";

// Constants
import { colors, family } from "../../../../constants/theme";

// Redux
import { setDate } from "../../../../redux/laterCart/laterCartActions";
import { setOrderType } from "../../../../redux/laterCart/laterCartActions";
import Divider from "../../../../components/atoms/Divider";

const ScheduleSlotsContainer = ({ slots, setDate, setOrderType, date }) => {
  const setScheduleHandler = (date) => {
    if (date) {
      setDate(date);
      setOrderType("Scheduled");
    } else {
      setDate(null);
    }
  };

  const customStyle = {
    backgroundColor: colors.white,
    primaryColor: colors.primaryColor,
    textColor: colors.brown,
  };
  return (
    <ScrollView style={styles.container}>
      {slots.map((list) => {
        const title = list?.list[0]?.substring(0, 2);
        return (
          <View style={styles.list}>
            <Text style={styles.title}>
              {title.length > 1 ? title : "0" + title}:
            </Text>
            <Divider width={8} />
            <View style={styles.listContainer}>
              <ScrollView
                key={list.id}
                horizontal
                style={styles.horizontalScroll}
                showsHorizontalScrollIndicator={false}
              >
                {list.list.map((item) => (
                  <ScheduleItem
                    key={item}
                    text={item}
                    onPress={() => setScheduleHandler(item)}
                    selected={date === item}
                    customStyle={customStyle}
                  />
                ))}
                <Divider width={50} />
              </ScrollView>
            </View>
          </View>
        );
      })}
    </ScrollView>
  );
};

const mapStateToProps = (state) => {
  return {
    date: state.laterOrders.date,
  };
};

const mapDispatchToProps = {
  setDate,
  setOrderType,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleSlotsContainer);

const styles = StyleSheet.create({
  container: {
    maxHeight: 300,
    width: "100%",
  },
  list: { flexDirection: "row", alignItems: "center" },
  listContainer: {
    borderLeftWidth: 1,
    paddingLeft: 10,
    width: "100%",
  },
  horizontalScroll: {
    width: "100%",
    marginVertical: 2,
  },
  title: {
    fontFamily: family.normal,
    fontSize: 10,
    color: colors.brown,
  },
});
