import React, { useState, useEffect } from "react";
import { StyleSheet, View } from "react-native";

// Components
import Screen from "../../../components/atoms/Screen";
import EmptyScreen from "../../../components/molecules/EmptyScreen";
import EventList from "./components/EventList";
import LoadingScreen from "./components/LoadingScreen";

// Methods
import { fetchEventsOfType } from "./methods/fetchEventsOfType";

const EventListScreen = ({ navigation, route }) => {
  const [loading, setLoading] = useState(false);
  const [listItems, setListItems] = useState([]);
  const [error, setError] = useState();

  const { idEvent, style } = route.params;

  // Custom Style DEPRECATED
  // const custom_logo = screenStyle?.custom_logo || null;
  // const custom_color_header = screenStyle?.custom_color_header || colors.white;
  // const custom_color_letter_header =
  //   screenStyle?.custom_color_letter_header || colors.primaryColor;
  // const custom_background = screenStyle?.custom_background;
  // const custom_color_icons_header =
  //   screenStyle?.custom_color_letter_header || colors.brown;

  // const customHeaderStyle = {
  //   color: custom_color_header,
  //   textColor: custom_color_letter_header,
  //   customIcon: custom_logo,
  //   iconsColor: custom_color_icons_header,
  // };

  const getData = async () => {
    setLoading(true);
    fetchEventsOfType(idEvent).then((response) => {
      if (response.status === "success") {
        setListItems(response.data);
      } else {
        setError(response.message);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <EmptyScreen id="places" title={error} />;
  }

  if (listItems.length === 0) {
    return <EmptyScreen id="places" title="No hay eventos actualmente" />;
  }

  return (
    <Screen>
      <View style={styles.container}>
        <EventList navigation={navigation} data={listItems} />
      </View>
    </Screen>
  );
};

export default EventListScreen;

const styles = StyleSheet.create({
  container: {
    flex:1,
    alignItems: "center",
  },
});
