const getQuestions = (gid, qId, answerArray) => {
  let array = [];
  answerArray?.map((question) => {
    if (gid === question.id_ticket && qId === question.questionId) {
      question.questions.map((el) => {
        array.push({
          question: el.question,
          response: el.response,
        });
      });
    }
  });
  return array;
};

export default getQuestions;
