import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { StyleSheet, View } from "react-native";
import { ActivityIndicator } from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import { Platform } from "react-native";

// Components
import Screen from "../../../components/atoms/Screen";
import LiveModal from "../../../components/organisms/modals/LiveModal";
import EmptyScreen from "../../../components/molecules/EmptyScreen";
import LocationsModal from "../../../components/organisms/modals/LocationsModal";
import CustomAlert from "../../../components/molecules/CustomAlert";
import CloseEventModal from "../../../components/organisms/modals/CloseEventModal";

// Utils
import { getDistances } from "../../../utils/distanceMethods";
import { getLocalData, storeLocalData } from "../../../utils/miscelanusUtils";

// Redux Actions
import {
  fetchLiveEvents,
  sortLiveItems,
  fetchShopById,
  addShop,
} from "../../../redux";
import { setService } from "../../../redux";
import { getShopInfo } from "../../../redux/shops/shopUtils";
import { getEventInfo } from "../../../redux/events/eventUtils";
import { setCurrentScreen } from "../../../redux";

// Hooks
import useBeforeRender from "../../../hooks/useBeforeRender";

// Constants
import { colors } from "../../../constants/theme";

// Navigation
import { toProductScreen } from "../../../navigation/redirections/toProductScreen";
import { setOrderType } from "../../../redux/cart/cartActions";
import LiveList from "./components/LiveList";
import ToEventsButton from "./components/ToEventsButton";

const LiveScreen = ({
  location,
  navigation,
  fetchLiveEvents,
  liveEvents,
  fetchShopById,
  setService,
  addShop,
  setCurrentScreen,
  setOrderType,
  sortLiveItems,
}) => {
  const [listItems, setListItems] = useState(liveEvents);
  const [closeEvents, setCloseEvents] = useState([]);
  const [redirectedShop, setRedirectedShop] = useState();
  const [redirectedEvent, setRedirectedEvent] = useState();

  const [selectedService, setSelectedService] = useState();
  const [localization, setLocalization] = useState([]);
  const [modal, setModal] = useState();
  const [selectedShop, setSelectedShop] = useState(0);
  const [alertData, setAlertData] = useState({});
  const [loading, setLoading] = useState();

  const [carouselAvailable, setCarouselAvailable] = useState(true);
  useEffect(() => {
    setLocalization([]);
  }, [selectedService]);

  useEffect(() => {
    let isMounted = true;
    sortLiveItems(location);
    setListItems(liveEvents);
    return () => {
      isMounted = false;
    };
  }, [liveEvents]);

  useFocusEffect(
    useCallback(() => {
      setCurrentScreen(0);
      fetchLiveEvents(location);
    }, [])
  );

  useBeforeRender(() => {
    const getPaymentPath = async () => {
      const paymentReturn = await getLocalData("paymentReturn");
      if (paymentReturn === "checkout") {
        const paymentSessionId = await getLocalData("paymentSessionId");
        const paymentRedirectResult = await getLocalData(
          "paymentRedirectResult"
        );
        navigation.navigate("PaymentResult", {
          sessionId: paymentSessionId,
          redirectResult: paymentRedirectResult,
        });
      }
      if (paymentReturn === "success") {
        navigation.navigate("PaymentSuccess");
      }
      if (paymentReturn === "error") {
        navigation.navigate("PaymentError", {
          from: "live",
          errorData: "",
        });
      }
      storeLocalData("paymentSessionId", null);
      storeLocalData("paymentReturn", false);
    };

    const getShopPath = async () => {
      const shopIdUrl = await getLocalData("shopIdUrl");
      storeLocalData("shopIdUrl", "");
      if (shopIdUrl !== "" && shopIdUrl !== null && shopIdUrl !== undefined) {
        setCarouselAvailable(false);
        getShopInfo(shopIdUrl).then((shop) => {
          getEventInfo(shop?.fk_event).then((eventInfo) => {
            setRedirectedEvent(eventInfo);
            if (shop !== false && shop !== null && shop !== undefined) {
              setRedirectedShop(shop);
              const noService = shop?.services.filter(
                (el) => el.key === "no_service"
              );
              const isPrecompras = noService.length > 0;
              let orderType = "ASAP";
              let service = shop.services[0];
              if (isPrecompras) {
                service = noService[0];
                orderType = "Later";
              }
              if (
                (!isPrecompras &&
                  (shop?.schedule?.opened === 1 ||
                    shop?.schedule?.scheduled === 1)) ||
                (isPrecompras &&
                  (shop?.schedule?.opened !== 1 ||
                    shop?.schedule?.scheduled !== 1))
              ) {
                if (
                  shop.services.length === 1 &&
                  shop.services[0].levels_locations === "not_show"
                ) {
                  setService({
                    service: service,
                    shop: shop,
                    location: "",
                    gid_location: null,
                  });

                  singleShopHandler(
                    service.key,
                    "",
                    shop,
                    eventInfo,
                    orderType
                  ).then(() => setLoading(false));
                } else {
                  setLoading(false);
                  if (isPrecompras) {
                    setService({
                      service: noService[0],
                      shop: shop,
                      location: "",
                      gid_location: null,
                    });
                    singleShopHandler(
                      noService[0].key,
                      "",
                      shop,
                      eventInfo,
                      orderType
                    ).then(() => setLoading(false));
                  } else {
                    setModal("Locations");
                  }
                }
              } else {
                setLoading(false);
                setAlertData({
                  title: `${shop?.name}: el establecimiento se encuentra cerrado`,
                });
                setModal("Alert");
              }
            } else {
              setLoading(false);
              setAlertData({
                title: `Tienda no encontrada`,
              });
              setModal("Alert");
            }
            storeLocalData("shopIdUrl", "");
          });
        });
      } else {
        setLoading(false);
      }
    };

    const getEventPath = async () => {
      const eventIdUrl = await getLocalData("eventIdUrl");
      storeLocalData("eventIdUrl", "");
      if (
        eventIdUrl !== "" &&
        eventIdUrl !== null &&
        eventIdUrl !== undefined
      ) {
        setCarouselAvailable(false);
        getEventInfo(eventIdUrl).then((event) => {
          if (event?.gid !== null) {
            setRedirectedEvent(event);
            if (event?.schedule?.opened === 1) {
              redirectEvent(event);
            } else {
              setAlertData({
                title: `${event.name} se encuentra cerrado`,
              });
              setModal("Alert");
            }
          } else {
            setAlertData({
              title: `Evento no encontrado`,
            });
            setModal("Alert");
          }
          storeLocalData("eventIdUrl", "");
        });
      }
    };
    getPaymentPath().then(() => getEventPath().then(getShopPath));
  }, []);

  const reloadHandler = async () => {
    await fetchLiveEvents(location);
  };

  const setCLoseEvents = () => {
    let distances = getDistances({ array: listItems, ref: location });
    if (carouselAvailable === true) {
      setModal("CloseEvent");
      setCloseEvents(
        listItems.filter((item, index) => {
          if (distances[index] < item.popup_distance) {
            return true;
          }
        })
      );
    }
  };

  useEffect(() => {
    if (location.lat) {
      setCLoseEvents();
      fetchLiveEvents(location);
    }
  }, [location]);

  const singleShopHandler = async (
    service,
    loc,
    data,
    eventData,
    orderType
  ) => {
    setCarouselAvailable(false);
    fetchShopById({
      id: data?.gid,
      locId: loc?.gid_location,
      service: service,
    }).then((res) => {
      if (res) {
        toProductScreen({
          navigation: navigation,
          data: data,
          res: res,
          action: () => {
            addShop(data, res, eventData, orderType);
            setOrderType(orderType);
          },
        });
      } else {
        setModal("Empty");
      }
    });
  };

  const redirectEvent = (eventData) => {
    if (eventData?.shops.length === 1) {
      setRedirectedShop(eventData?.shops[0]);
      const noService = eventData.shops[0]?.services.filter(
        (el) => el.key === "no_service"
      );
      const isPrecompras = noService.length > 0;
      let orderType = "ASAP";
      if (isPrecompras) {
        orderType = "Later";
      }
      if (
        (!isPrecompras && eventData.shops[0].schedule.opened === 1) ||
        (isPrecompras && eventData.shops[0].schedule.opened !== 1)
      ) {
        setRedirectedEvent(eventData);
        setSelectedShop(0);
        const currentShop = eventData.shops[0];
        if (
          currentShop.services.length === 1 &&
          currentShop.services[0].levels_locations === "not_show"
        ) {
          setService({
            service: currentShop.services[0],
            shop: currentShop,
            location: "",
            gid_location: null,
          });
          singleShopHandler(
            currentShop.services[0].key,
            "",
            currentShop,
            eventData,
            orderType
          );
        } else {
          if (isPrecompras) {
            setService({
              service: noService[0],
              shop: currentShop,
              location: "",
              gid_location: null,
            });
            singleShopHandler(
              currentShop.services[0].key,
              "",
              currentShop,
              eventData,
              orderType
            ).then(() => setLoading(false));
          } else {
            setModal("Locations");
          }
        }
      } else {
        setAlertData({
          title: `${eventData.name} no cuenta con puntos de venta abiertos`,
        });
        setModal("Alert");
      }
    } else {
      navigation.navigate("EventsStack", {
        screen: "ShopList",
        params: {
          shopList: eventData.shops.sort((a, b) => {
            if (a.schedule.opened === 1) {
              return -1;
            } else {
              return 1;
            }
          }),
          name: eventData.name,
          style: eventData.custom_design || null,
          eventData: eventData,
          eventId: eventData?.gid,
        },
      });
    }
  };
  const handlePlaceClick = (data) => {
    setRedirectedEvent(data);
    setCarouselAvailable(false);
    setModal();
    redirectEvent(data);
  };

  return (
    <Screen maxWidth={null}>
      {loading === true ? (
        <ActivityIndicator size="small" color={colors.primaryColor} />
      ) : listItems.length > 0 ? (
        <View style={styles.scrollContainer}>
          <LiveList list={listItems} navigation={navigation} />
          <ToEventsButton navigation={navigation} />
        </View>
      ) : (
        <EmptyScreen
          title="No hay eventos activos"
          id="live"
          onReload={reloadHandler}
        />
      )}
      <LocationsModal
        services={
          redirectedShop !== null && redirectedShop !== undefined
            ? redirectedShop?.services
            : redirectedEvent?.shops[selectedShop]?.services
        }
        setSelectedService={setSelectedService}
        setModal={setModal}
        visible={modal === "Locations"}
        data={
          redirectedShop !== null && redirectedShop !== undefined
            ? redirectedShop
            : redirectedEvent?.shops[selectedShop]
        }
        shopInfo={
          redirectedShop !== null && redirectedShop !== undefined
            ? { gid: redirectedShop?.gid, name: redirectedShop?.name }
            : {
                gid: redirectedEvent?.shops[selectedShop]?.gid,
                name: redirectedEvent?.shops[selectedShop]?.name,
              }
        }
        onNavigate={singleShopHandler}
        eventId={redirectedEvent?.gid}
        optionsSel={localization}
        setOptionsSel={setLocalization}
        navigation={navigation}
      />
      <CustomAlert
        setModalVisible={setModal}
        modalVisible={modal === "Alert"}
        alertData={alertData}
      />
      {closeEvents.length > 0 &&
        closeEvents[0]?.popup_image !== null &&
        (Platform.OS === "web" ? (
          <CloseEventModal
            data={closeEvents[0]}
            setModal={setModal}
            modal={modal === "CloseEvent"}
            handlePlaceClick={handlePlaceClick}
          />
        ) : (
          closeEvents.length > 0 &&
          closeEvents[0].popup_image !== null &&
          Platform.OS !== "web" && (
            <LiveModal
              handleModal={setModal}
              modalVisible={modal === "CloseEvent"}
              data={closeEvents}
              handlePlaceClick={handlePlaceClick}
            />
          )
        ))}
    </Screen>
  );
};

const styles = StyleSheet.create({
  scrollContainer: {
    flex: 1,
    justifyContent: "space-evenly",
    alignItems: "center",
  },
});
const mapStateToProps = (state) => {
  return {
    location: state.user.location,
    liveEvents: state.events.liveEvents,
    eventTypes: state.events.eventTypes,
  };
};

const mapDispatchToProps = {
  fetchLiveEvents,
  setCurrentScreen,
  sortLiveItems,
  fetchShopById,
  setService,
  setOrderType,
  addShop,
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveScreen);
