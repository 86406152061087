import React from "react";
import { StyleSheet, Text, View } from "react-native";

// Constants
import { colors, family } from "../../../../../constants/theme";

// Utils
import {
  getFormattedPrice,
  getSingleProductCost,
} from "../../../../../utils/productMethods";

const Price = ({ data, color }) => {
  const { amount, variants, plates, quantity } = data;
  return (
    <View style={styles.container}>
      <Text style={[styles.text, { color: color || colors.primaryColor }]}>
        {getFormattedPrice(
          getSingleProductCost(amount, variants, plates) * quantity
        )}
      </Text>
    </View>
  );
};

export default Price;

const styles = StyleSheet.create({
  container: {
    alignItems: "flex-end",
    justifyContent: "center",
    width: 52,
  },
  text: {
    fontFamily: family.normal,
    color: colors.brown,
    fontSize: 12,
  },
});
