export const hasOrderType = (cart) => {
  if (
    cart.orderType !== null &&
    cart.orderType !== undefined &&
    cart.orderType !== ""
  ) {
    return true;
  } else {
    return false;
  }
};
