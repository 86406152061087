import React, { useState, useEffect, useRef } from "react";
import {
  Nunito_400Regular,
  Nunito_800ExtraBold,
  Nunito_300Light,
  Nunito_600SemiBold,
} from "@expo-google-fonts/nunito";
import { NavigationContainer, DefaultTheme } from "@react-navigation/native";
import { PersistGate } from "redux-persist/integration/react";
import { getPathFromState } from "@react-navigation/native";
import { LogBox, Platform } from "react-native";
import { BackHandler } from "react-native";
import { Provider } from "react-redux";
import * as Linking from "expo-linking";
import * as ScreenOrientation from "expo-screen-orientation";
import * as SplashScreen from "expo-splash-screen";
import "react-native-gesture-handler";

// Store
import { store, persistor } from "./src/redux/store";
import AuthProvider from "./src/contexts/AuthContext";

// Expo
import * as Font from "expo-font";

// Components
import CustomStatusBar from "./src/components/atoms/CustomStatusBar";
import AppNavigator from "./src/navigation/MainNavigation";
import TicketNavigator from "./src/navigation/TicketsNavigation";
import CustomAlert from "./src/components/molecules/CustomAlert";

// Navigation
import { config, prefixes } from "./src/navigation/navigationConfig";

// Utils
import { storeLocalData } from "./src/utils/miscelanusUtils";
import { pwaTagMAnager1 } from "./src/constants/tagManager";
import { app, environment } from "./config";
import { onExternalMessage } from "./src/services/firebase";

// Keep the splash screen visible while we fetch resources
SplashScreen.preventAutoHideAsync();

LogBox.ignoreLogs([
  "Warning: Cannot update a component",
  "Warning: Encountered two children with the same key",
  "Can't perform a React state update",
]);

const App = () => {
  const [fontLoaded, setFontLoaded] = useState(false);
  const [modal, setModal] = useState();
  const [modalData, setModalData] = useState();
  const notificationListener = useRef();

  global.__reanimatedWorkletInit = () => {};

  useEffect(() => {
    if (environment === "prod") {
      if (app === "pwa" && Platform.OS === "web") {
        pwaTagMAnager1;
      }
    }
    BackHandler.addEventListener("hardwareBackPress", function () {
      return true;
    });
  }, []);

  async function changeScreenOrientation() {
    await SplashScreen.hideAsync();
    if (Platform.OS !== "web") {
      await ScreenOrientation.lockAsync(
        ScreenOrientation.OrientationLock.PORTRAIT_UP
      );
    }
  }

  useEffect(() => {
    if (Platform.OS === "web") {
      Linking.parseInitialURLAsync().then((res) => {
        let metadata = { token: null, domain: null, table: null };
        let hasMetaData = false;
        if (res.queryParams?.redirectResult) {
          storeLocalData("paymentReturn", "checkout");
          storeLocalData("paymentSessionId", res.queryParams?.sessionId);
          storeLocalData(
            "paymentRedirectResult",
            res.queryParams?.redirectResult
          );
        }
        if (res.queryParams?.token) {
          metadata = { ...metadata, token: res.queryParams?.token };
          hasMetaData = true;
        }
        if (res.queryParams?.domain) {
          metadata = { ...metadata, domain: res.queryParams?.domain };
          hasMetaData = true;
        }
        if (res.queryParams?.table) {
          metadata = { ...metadata, table: res.queryParams?.table };
          hasMetaData = true;
        }
        if (hasMetaData === true) {
          storeLocalData("metaData", metadata);
        }
        if (res.queryParams?.id) {
          storeLocalData("client", res.queryParams?.client);
        }
        if (res.queryParams?.locationId) {
          storeLocalData("locationId", res.queryParams?.locationId);
        }
        if (res.queryParams?.locationName) {
          storeLocalData("locationName", res.queryParams?.locationName);
        }
      });
    }
    if (Platform.OS === "web") {
      const getUrl = async () => {
        const url = await Linking.parseInitialURLAsync();
        if (url.path.includes("shop")) {
          const index = url.path.indexOf("/");
          const shopId = url.path.slice(index + 1, url.path.length);
          storeLocalData("shopIdUrl", parseInt(shopId));
        }
        if (url.path.includes("event")) {
          const index = url.path.indexOf("/");
          const shopId = url.path.slice(index + 1, url.path.length);
          storeLocalData("eventIdUrl", parseInt(shopId));
        }
        if (url.path.includes("orders")) {
          storeLocalData("goToOrders", true);
        }
      };

      getUrl();
    }
    changeScreenOrientation();
  }, []);

  useEffect(() => {
    async function prepare() {
      try {
        // Pre-load fonts, make any API calls you need to do here
        await Font.loadAsync({
          "nunito-bold": Nunito_800ExtraBold,
          "nunito-book": Nunito_400Regular,
          "nunito-light": Nunito_300Light,
          "nunito-semibold": Nunito_600SemiBold,
        });
      } catch (e) {
        console.log(err);
      } finally {
        // Tell the application to render
        setFontLoaded(true);
      }
    }

    prepare();
  }, []);

  const fontLoadHandler = async () => {
    // source: https://docs.expo.dev/versions/latest/sdk/splash-screen/
    if (fontLoaded) {
      await SplashScreen.hideAsync();
    }
  };

  useEffect(() => {
    fontLoadHandler();
  }, [fontLoaded]);

  if (!fontLoaded) {
    return null;
  }

  const MyTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      primary: "white",
    },
    dark: false,
  };

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AuthProvider>
          <CustomStatusBar />
          <NavigationContainer
            theme={MyTheme}
            linking={{
              config: config,
              getPathFromState(state, config) {
                let path = getPathFromState(state, config);
                const index = path.indexOf("?");
                if (index >= 0) {
                  path = path.substr(0, index);
                }
                return path;
              },
              prefixes: prefixes,
            }}
          >
            {app === "tickets" ? <TicketNavigator /> : <AppNavigator />}
            {environment === "dev" && app === "pwa" && Platform.OS === "web" && (
              <noscript>
                <iframe
                  src="https://www.googletagmanager.com/ns.html?id=GTM-MRQV462"
                  height="0"
                  width="0"
                  style="display:none;visibility:hidden"
                ></iframe>
              </noscript>
            )}
            <CustomAlert
              setModalVisible={setModal}
              modalVisible={modal === "Alert"}
              alertData={modalData}
            />
          </NavigationContainer>
        </AuthProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;