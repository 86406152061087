import React from "react";
import { StyleSheet, Text, View, Image, Platform } from "react-native";
import { LinearGradient } from "expo-linear-gradient";

// Constants
import { family } from "../../constants/theme";
import i18n from "../../i18n/i18n";

const NotFound = () => {
  return (
    <View style={styles.heroContainer} hideActions>
      <Image
        style={styles.heroImage}
        source={require("../../assets/concert2.webp")}
      />
      <View style={styles.heroContent}>
        <View style={styles.textContainer}>
          <Text style={styles.heroTitle}>
            {i18n.t("tickets.page_not_found")}
          </Text>
        </View>
      </View>
      <LinearGradient
        colors={["transparent", "rgba(0,0,0,1)"]}
        style={styles.gradient}
        start={{ x: 0, y: 0 }}
        end={{ x: 0, y: 1 }}
      />
    </View>
  );
};

export default NotFound;

const styles = StyleSheet.create({
  heroContainer: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  gradient: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "50%",
    zIndex: 3,
  },
  heroContent: {
    paddingTop: 75,
    width: "100%",
    maxWidth: 1440,
    paddingHorizontal: 40,
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2,
  },
  heroImage: {
    width: "100%",
    height: "100%",
    position: Platform.OS === "web" ? "fixed" : "relative",
    top: 0,
    resizeMode: "cover",
    zIndex: 1,
  },

  textContainer: {
    width: "70%",
    marginBottom: 50,
    alignItems: "center",
  },
  heroTitle: {
    fontSize: 40,
    color: "white",
    fontFamily: family.bold,
    textShadowColor: "rgba(0, 0, 0, 0.75)",
    textShadowOffset: { width: -1, height: 1 },
    textShadowRadius: 5,
    textAlign: "center",
  },
});
