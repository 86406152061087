import { getShopServiceCost, getSingleShopCommission } from "./orderMethods";

export const getProductObjectTickets = (product_lines, shop) => {
  let order_lines = [];
  const today = new Date().getTime() / 1000;
  const shopObject = (lines) => {
    return {
      date: Math.floor(today),
      id_event: shop.fk_event,
      service: "pick_up",
      id_shop: shop.gid,
      order_lines: lines,
    };
  };

  let exclusiveProducts = [];
  let normalProducts = [];

  product_lines.map((product) => {
    if (product.exclusive_order === 1) {
      exclusiveProducts.push(product);
    } else {
      normalProducts.push(product);
    }
  });

  if (normalProducts.length > 0) {
    let lines = [];
    normalProducts.map((product) => {
      let vars = [];
      product.variants.map((group) => {
        let aux = [];
        group.options.map((el) => {
          aux.push({
            gid: el.gid,
            quantity: el.quantity,
          });
        });
        vars.push({
          id_group_variants: group.gid,
          variants: aux,
        });
        aux = [];
      });

      lines.push({
        id_product: product.id_product,
        quantity: product.quantity,
        id_point_sale: product.id_point_sale,
        variants: vars,
      });
      vars = [];
    });
    order_lines.push(shopObject(lines));
  }

  if (exclusiveProducts.length > 0) {
    exclusiveProducts.map((product) => {
      let vars = [];
      product.variants.map((group) => {
        let aux = [];
        group.options.map((el) => {
          aux.push({
            gid: el.gid,
            quantity: el.quantity,
          });
        });
        vars.push({
          id_group_variants: group.gid,
          variants: aux,
        });
        aux = [];
      });

      order_lines.push(
        shopObject([
          {
            id_product: product.id_product,
            quantity: product.quantity,
            id_point_sale: product.id_point_sale,
            variants: vars,
          },
        ])
      );
      vars = [];
    });
  }
  return order_lines;
};

export const getFormattedOrderTickets = (ticketData, user, token, cardId) => {
  const { shop, ticket_lines, discount, commission, product_lines } =
    ticketData;
  const today = new Date().getTime() / 1000;

  let object = {
    id_user: user.gid,
    payment_method: "card",
    id_currency: 1,
    comment: "",
    delivery_address: null,
    orders: [],
  };

  if (cardId) {
    object = { ...object, id_payment_card: cardId.gid };
  } else {
    object = { ...object, jetToken: token };
  }

  const getTicketObject = (i) => {
    return {
      date: Math.floor(today),
      id_event: shop.fk_event,
      service: "pick_up",
      id_shop: shop.gid,
      order_lines_ticket: [ticket_lines[i]],
    };
  };

  const productOrders = getProductObjectTickets(product_lines, shop, discount);
  if (productOrders.length > 0) {
    object.orders = productOrders;
  }

  for (let i = 0; i < ticket_lines.length; i++) {
    const result = getTicketObject(i);
    object.orders.push(result);
  }

  if (commission[0].amount > 0) {
    let auxOrder = object.orders[0];
    auxOrder.order_lines_commission = commission;
    object.orders[0] = auxOrder;
  }
  if (discount[0].discount > 0) {
    let auxOrder = object.orders[0];
    auxOrder.order_lines_discount = discount;
    object.orders[0] = auxOrder;
  }

  return object;
};

export const formattedOrder = (cart, user, discountLines) => {
  let object = {
    id_user: user.gid,
    id_currency: 1,
    comment: cart.comment,
    location: cart.location.location,
    gid_location: cart.location.gid_location,
    payment_method: "card",
    orders: splitOrders(cart),
  };
  if (discountLines.length > 0) {
    let auxOrders = object.orders;
    let indexToInsert = -1;
    let lineToInsert = -1;
    let type = "";
    discountLines.map((el) => {
      auxOrders.map((order, index) => {
        if (
          (el.type === "menu" || el.type === "individual") &&
          order?.order_lines?.length > 0
        ) {
          order?.order_lines.map((line, lineIndex) => {
            if (line?.id_product == el.gid) {
              indexToInsert = index;
              lineToInsert = lineIndex;
              type = "product";
            }
          });
        }
      });
      // Adds discount line to order object
      auxOrders[indexToInsert].order_lines_discount.push({
        id_discount: el.id_discount,
        name: "Descuento",
        amount: el.amount,
      });
      indexToInsert = -1;
    });
    object.orders = auxOrders;
  }

  return object;
};

export const splitOrders = (cart) => {
  const getService = (id) => {
    let result = null;
    cart.services.map((service) => {
      if (service.shopId === id) {
        result = service.key;
      }
    });
    return result;
  };

  const getShopLocation = (id) => {
    let location = "";
    let gid_location = null;
    cart.services.map((service) => {
      if (service.shopId === id) {
        location = service.location;
        gid_location = service.gid_location;
      }
    });
    return [location, gid_location];
  };

  const getServiceLine = (shop, services) => {
    const service = getShopServiceCost(services, shop);
    if (service > 0) {
      return [{ name: "Servicio", amount: parseInt(Math.floor(service)) }];
    } else {
      return [];
    }
  };

  const getCommissionLine = (shop) => {
    const commission = getSingleShopCommission(shop);
    if (commission > 0) {
      return [
        {
          name: "Gastos de gestión",
          amount: parseInt(Math.floor(commission)),
        },
      ];
    } else {
      return [];
    }
  };

  const generateVariantArray = (variants = []) => {
    let vars = [];
    variants.map((group) => {
      let aux = [];
      group.options.map((el) => {
        aux.push({
          gid: el.gid,
          quantity: el.qty,
        });
      });
      vars.push({
        id_group_variants: group.gid,
        variants: aux,
      });
      aux = [];
    });
    return vars;
  };

  const getMenuSelectionArray = (plates = []) => {
    let array = [];

    plates.map((plate) => {
      const separator = plate.gid.indexOf("u");
      const realGid = plate.gid.slice(separator + 1, plate.gid.length);
      for (let i = 0; i < plate.quantity; i++) {
        array.push({
          id_product: realGid,
          quantity: 1,
          variants: generateVariantArray(plate?.variants),
          delete_ingredients: plate.ingredients,
          id_menu_type_plate: plate.id_menu_type_plate,
        });
      }
    });
    return array;
  };

  const getOrderLines = (product_lines) => {
    let normalProducts = [];
    let order_lines = [];

    product_lines.map((product) => {
      const separator = product.gid.indexOf(",");
      const type = product.gid.slice(separator + 1, product.gid.length);

      if (product.exclusive_order !== 1 && type !== "bono") {
        normalProducts.push(product);
      }
    });

    if (normalProducts.length) {
      normalProducts.map((product) => {
        const separator = product.gid.indexOf(",");
        const type = product.gid.slice(separator + 1, product.gid.length);
        const realGid = parseInt(product.gid.slice(0, separator));

        const variants = generateVariantArray(product?.variants);
        const plates = getMenuSelectionArray(product?.plates);
        if (type === "menu") {
          order_lines.push({
            id_product: parseInt(realGid),
            product_type: type,
            quantity: product.quantity,
            id_point_sale: product.id_point_sale,
            menu_selection: plates,
            variants: variants,
            amount: product.totalAmount,
          });
        } else {
          order_lines.push({
            id_product: parseInt(realGid),
            product_type: type,
            quantity: product.quantity,
            id_point_sale: product.id_point_sale,
            variants: variants,
            delete_ingredients: product.ingredients,
            amount: product.totalAmount,
          });
        }
      });
    }

    return order_lines;
  };

  const getExclusiveLines = (orders = []) => {
    let products = [];
    let exclusive_lines = [];

    orders.map((shop) => {
      shop.order_lines.map((line) => {
        const separator = line.gid.indexOf(",");
        const type = line.gid.slice(separator + 1, line.gid.length);
        if (line.exclusive_order === 1 || type === "bono") {
          products.push({
            ...line,
            date: shop.date,
            id_shop: shop.id_shop,
            id_event: shop.id_event,
          });
        }
      });
    });

    if (products.length > 0) {
      products.map((product) => {
        const separator = product.gid.indexOf(",");
        const type = product.gid.slice(separator + 1, product.gid.length);
        const realGid = product.gid.slice(0, separator);

        const plates = getMenuSelectionArray(product?.plates);
        const variants = generateVariantArray(product?.variants);
        if (type === "menu") {
          for (let i = 0; i < product.quantity; i++) {
            const line = {
              id_product: parseInt(realGid),
              quantity: 1,
              id_point_sale: product.id_point_sale,
              menu_selection: plates,
              variants: variants,
              product_type: type,
              amount: product.totalAmount,
            };

            exclusive_lines.push({
              orders: [line],
              date: product.date,
              id_shop: product.id_shop,
              id_event: product.id_event,
            });
          }
        } else {
          for (let i = 0; i < product.quantity; i++) {
            const line = {
              id_product: parseInt(realGid),
              quantity: 1,
              product_type: type,
              id_point_sale: product.id_point_sale,
              variants: variants,
              ingredients: product.ingredients,
              amount: product.totalAmount,
            };

            exclusive_lines.push({
              orders: [line],
              date: product.date,
              id_shop: product.id_shop,
              id_event: product.id_event,
            });
          }
        }
      });
    }
    return exclusive_lines;
  };

  const getOrderObject = (shop) => {
    const date = new Date(shop.date).getTime() / 1000;
    const [location, gid_location] = getShopLocation(shop.id_shop);

    const order_lines = getOrderLines(shop.order_lines);

    const commissionLine = getCommissionLine(shop);
    const serviceLine = getServiceLine(shop, cart.services);

    if (order_lines.length > 0) {
      let object = {
        date: Math.floor(date),
        id_event: shop.id_event,
        service: getService(shop.id_shop),
        id_shop: shop.id_shop,
        location: location,
        gid_location: gid_location,
        order_lines: order_lines,
        order_lines_discount: [],
        order_lines_commission: commissionLine,
        order_lines_service: serviceLine,
      };
      return { result: true, data: object };
    } else {
      return {
        result: false,
        commissionLine: commissionLine,
        serviceLine: serviceLine,
        id_shop: shop.id_shop,
      };
    }
  };

  let orderArray = [];
  let exclusive_lines = getExclusiveLines(cart.orders);

  exclusive_lines.map((line) => {
    const date = new Date(line.date).getTime() / 1000;
    const [location, gid_location] = getShopLocation(line.id_shop);
    orderArray.push({
      date: Math.floor(date),
      id_event: line.id_event,
      service: getService(line.id_shop),

      id_shop: line.id_shop,
      location: location,
      gid_location: gid_location,
      order_lines: line.orders,
      order_lines_commission: [],
      order_lines_discount: [],
      order_lines_service: [],
    });
  });

  cart.orders.map((shop) => {
    if (shop.order_lines.length > 0) {
      let object = getOrderObject(shop);
      if (object.result === true) {
        orderArray.push(object.data);
      } else {
        if (object.commissionLine.length > 0 || object.serviceLine.length > 0) {
          let pushed = false;
          orderArray.map((order) => {
            if (order.id_shop === object.id_shop && pushed === false) {
              order.order_lines_service = object.serviceLine;
              order.order_lines_commission = object.commissionLine;
              pushed = true;
            }
          });
        }
      }
    }
  });

  // Asignamos a todos los orders el tipo de pedido general
  orderArray.forEach((order) => {
    if (cart.orderType === "Later") {
      order.service = "no_service";
    }
    order.order_type = cart.orderType;
  });

  orderArray.forEach((order) => {
    if (
      (order?.order_lines.filter((el) => el.product_type === "bono")).length > 0
    ) {
      order.order_type = "Later";
    }
  });

  return orderArray;
};
