import React from "react";
import { StyleSheet, View } from "react-native";

// Components
import Container from "./Container";
import CartIcon from "./CartIcon";

// deprecated
import LiveLocation from "./LiveLocation";

const LiveHeader = ({ navigation }) => {
  return (
    <Container>
      <View style={styles.content}>
        <LiveLocation />
        <CartIcon navigation={navigation} />
      </View>
    </Container>
  );
};

export default LiveHeader;

const styles = StyleSheet.create({
  content: {
    width: "100%",
    flexDirection: "row",
    height: "100%",
    alignItems: "center",
  },
  infoContainer: {
    flex: 1,
    flexDirection: "row",
  },
});
