import React, { useContext } from "react";
import { StyleSheet, Text, View } from "react-native";

// Components
import CustomModal from "../../atoms/CustomModal";
import CustomButton from "../../atoms/CustomButton";

// Constants
import { family, size } from "../../../constants/theme";
import i18n from "../../../i18n/i18n";
import { connect } from "react-redux";

import { AuthContext } from "../../../contexts/AuthContext";

const TicketLegalModal = ({ visible, setModal, legalAge, hasQuestions }) => {
  const { user: firebaseUser } = useContext(AuthContext);
  return (
    <CustomModal
      modalVisible={visible}
      handleModal={() => setModal(false)}
      color={"rgba(0,0,0,0.8)"}
    >
      <View style={styles.container}>
        <View style={{ marginBottom: 20 }}>
          <Text style={styles.title}>
            {i18n.t("tickets.legal_age.first")} {legalAge}{" "}
            {i18n.t("tickets.legal_age.second")}
          </Text>
        </View>
        <View style={{ width: "100%", flexDirection: "row", marginTop: 10 }}>
          <CustomButton
            active
            onPress={() => {
              setModal();
            }}
            title={i18n.t("tickets.cancel")}
            marginHorizontal={5}
            color={"white"}
            textColor={"black"}
          />
          <CustomButton
            color={"white"}
            textColor={"black"}
            active
            onPress={() => {
              setLegal(true);
              if (firebaseUser) {
                setModal("Login");
              } else if (hasQuestions === true) {
                setModal("Questions");
              } else {
                setModal();
              }
            }}
            title={i18n.t("tickets.accept")}
            marginHorizontal={5}
          />
        </View>
      </View>
    </CustomModal>
  );
};

const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps)(TicketLegalModal);

const styles = StyleSheet.create({
  container: {
    height: 120,
  },
  title: {
    fontFamily: family.bold,
    textAlign: "center",
    color: "white",
    fontSize: size.normal,
  },
});
