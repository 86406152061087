import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { colors, family } from '../../../../constants/theme'

const SectionTitle = ({title}) => {
  return (
    <View style={styles.container}>
      <Text style={styles.text}>{title}</Text>
    </View>
  )
}

export default SectionTitle

const styles = StyleSheet.create({
  text:{
    color: colors.brown,
    fontFamily: family.bold,
    fontSize: 20
  },
  container:{
    marginBottom:10,
    paddingLeft: 18
  }
})