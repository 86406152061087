import React from "react";
import { StyleSheet, Text, View } from "react-native";

// Constants
import { family } from "../../../../constants/theme";

// Methods
import { getStatusText } from "../../../orders/PastOrderDetailScreen/Header/methods/getStatusText";

const Status = ({ status }) => {
  return (
    <View style={styles.container}>
      <Text style={styles.text}>
        {getStatusText(status)}
      </Text>
    </View>
  );
};

export default Status;

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2,
    height: 60,
  },
  text: {
    color: "white",
    fontSize: 16,
    fontFamily: family.normal,
    textAlign: "center",
  },
});
