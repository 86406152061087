import React from "react";
import StyleSheet from "react-native-media-query";
import {
  Text,
  View,
  Image,
  Platform,
  useWindowDimensions,
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";

// Constants
import { family } from "../../../constants/theme";
import i18n from "../../../i18n/i18n";

const ShopHero = ({ title, subtitle, loading, image, logo, imageHeader }) => {
  const height = useWindowDimensions().height;
  return (
    <View style={[styles.heroContainer, { height: height - height * 0.4 }]}>
      <Image
        style={[styles.heroImage, { height: height - height * 0.4 }]}
        source={
          imageHeader
            ? { uri: imageHeader }
            : image
            ? { uri: image }
            : require("../../../assets/concert2.webp")
        }
      />
      <View style={styles.heroContent} dataSet={{ media: ids.heroContent }}>
        {logo !== null && logo !== undefined && (
          <View style={styles.logoWrapper}>
            <Image style={styles.logo} source={{ uri: logo }} />
          </View>
        )}
        <View
          style={styles.textContainer}
          dataSet={{ media: ids.textContainer }}
        >
          {loading ? (
            <Text style={styles.heroTitle} dataSet={{ media: ids.heroTitle }}>
              {i18n.t("tickets.loading")}
            </Text>
          ) : (
            <Text style={styles.heroTitle} dataSet={{ media: ids.heroTitle }}>
              {title || i18n.t("tickets.shop_not_found")}
            </Text>
          )}
        </View>
        <View
          style={styles.textContainer}
          dataSet={{ media: ids.textContainer }}
        >
          <Text
            style={styles.heroDescription}
            dataSet={{ media: ids.heroDescription }}
          >
            {subtitle}
          </Text>
        </View>
      </View>
      <LinearGradient
        colors={["transparent", "rgba(0,0,0,1)"]}
        style={styles.gradient}
        start={{ x: 0, y: 0 }}
        end={{ x: 0, y: 1 }}
      />
    </View>
  );
};

export default ShopHero;

const { ids, styles } = StyleSheet.create({
  heroContainer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    minHeight: 300,
  },
  gradient: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "50%",
    zIndex: 3,
  },
  heroContent: {
    paddingTop: 75,
    width: "100%",
    maxWidth: 1440,
    paddingHorizontal: 40,
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2,
    "@media (max-width: 500px)": {
      paddingHorizontal: 20,
    },
  },
  logoWrapper: {
    width: '100%',
    height:'100%',
    maxWidth: 400,
    maxHeight: 100,
  },
  logo: {
    width: "100%",
    height: "100%",
    resizeMode: "contain",
  },
  heroImage: {
    width: "100%",
    position: Platform.OS === "web" ? "fixed" : "relative",
    top: 0,
    minHeight: 300,
    resizeMode: "cover",
    zIndex: 1,
  },
  textContainer: {
    width: "80%",
    marginBottom: 50,
    alignItems: "center",
    "@media (max-width: 500px)": {
      marginBottom: 20,
      width: "100%",
    },
  },
  heroTitle: {
    fontSize: 60,
    color: "white",
    fontFamily: family.bold,
    textShadowColor: "rgba(0, 0, 0, 0.75)",
    textShadowOffset: { width: -1, height: 1 },
    textShadowRadius: 5,
    textAlign: "center",
    "@media (max-width: 500px)": {
      fontSize: 32,
    },
  },
  heroDescription: {
    fontSize: 32,
    color: "white",
    fontFamily: family.bold,
    textShadowColor: "rgba(0, 0, 0, 0.75)",
    textShadowOffset: { width: -1, height: 1 },
    textShadowRadius: 5,
    textAlign: "center",
    "@media (max-width: 500px)": {
      fontSize: 18,
    },
  },
});
