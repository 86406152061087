import React from "react";
import {
  StyleSheet,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import { FontAwesome } from "@expo/vector-icons";

// Constants
import { colors } from "../../constants/theme";

const IconButton = ({ icon, size, onPress, color, textColor, ...props }) => {
  const width = useWindowDimensions().width;

  const small = () => {
    if (width < 320 || size === "small") {
      return true;
    } else {
      return false;
    }
  };
  return (
    <TouchableOpacity
      style={[
        styles.container,
        { backgroundColor: color || colors.primaryColor },
        small() === true && { width: 18, height: 18 },
      ]}
      onPress={onPress}
      {...props}
    >
      <FontAwesome
        name={icon}
        size={small() === true ? 12 : 16}
        color={textColor || "white"}
      />
    </TouchableOpacity>
  );
};

export default IconButton;

const styles = StyleSheet.create({
  container: {
    width: 26,
    height: 26,
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 1,
    paddingTop: 1,
  },
});
