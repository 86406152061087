import React, { useState, useEffect, useContext } from "react";
import { StyleSheet, View, Text } from "react-native";
import { ActivityIndicator } from "react-native";
import { connect } from "react-redux";

// Components
import Divider from "../../../../components/atoms/Divider";
import ForLaterProduct from "./ForLaterProduct";
import ServicePicker from "./ServicePicker";
import Header from "./Header";

// Constants
import { colors, family } from "../../../../constants/theme";

// Methods
import { fetchPointSalesInfo } from "../methods/fetchPointSalesInfo";

import { AuthContext } from "../../../../contexts/AuthContext";

const ShopItem = ({ data }) => {
  const { user: firebaseUser } = useContext(AuthContext);
  const [psData, setPsData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const { orders = [], gid, fk_event } = data;

  const serviceData = {
    eventId: fk_event,
    shopId: gid,
  };

  const getShopData = async (gid) => {
    const token = await firebaseUser.getIdToken(true);
    const response = await fetchPointSalesInfo(gid, token);
    if (response.status === "success") {
      setPsData(response.data);
    } else {
      setError(response.data);
    }
  };

  useEffect(() => {
    setLoading(true);
    try {
      getShopData(gid).then(() => setLoading(false));
    } catch (error) {
      setError(error.message);
    }
  }, []);

  return (
    <View style={styles.container}>
      <Header data={data} />
      <View style={styles.content}>
        {error ? (
          <>
            <Divider height={20} />
            <Text
              style={styles.title}
            >{`Ocurrió un error obteniendo los datos de esta tienda.\n${error}\nInténdalo más tarde`}</Text>
          </>
        ) : loading ? (
          <>
            <Divider height={20} />
            <ActivityIndicator color={colors.primaryColor} size="small" />
          </>
        ) : (
          <>
            <View style={styles.serviceContainer}>
              <ServicePicker data={serviceData} />
            </View>
            <Divider height={20} />
            {orders?.map((item) => {
              return (
                item.quantityAvailable > 0 && (
                  <View style={styles.categoryWrapper} key={item.gid}>
                    <ForLaterProduct
                      data={item}
                      shop={data}
                      key={item.gid}
                      psData={psData}
                    />
                    <Divider height={12} />
                  </View>
                )
              );
            })}
          </>
        )}
      </View>
    </View>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(ShopItem);

const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
  title: {
    fontFamily: family.bold,
    fontSize: 14,
    color: colors.brown,
    textAlign: "center",
  },
  content: {
    paddingHorizontal: 18,
    paddingVertical: 14,
  },
  categoryWrapper: {
    width: "100%",
  },
  serviceContainer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  categoryName: {
    fontFamily: family.bold,
    color: colors.brown,
    fontSize: 20,
  },
});
