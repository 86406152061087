import React from "react";
import { StyleSheet, View } from "react-native";

// Constants
import { colors, size } from "../../../../constants/theme";

// Components
import CustomButton from "../../../../components/atoms/CustomButton";
import CustomText from "../../../../components/atoms/CustomText";
import Title from "../../../../components/atoms/Title";

const NoOrder = ({ navigation }) => {
  const handleNoOrder = () => {
    navigation.goBack();
  };

  return (
    <View style={styles.emptyWrapper}>
      <View style={styles.emptyContent}>
        <CustomText fontSize={size.big} text="¡Nada en tu carrito!" />
        <Title big title="Comienza tu pedido" color={colors.primaryColor} />
      </View>
      <View style={styles.container}>
        <CustomButton active onPress={handleNoOrder} title={`Volver`} />
      </View>
    </View>
  );
};

export default NoOrder;

const styles = StyleSheet.create({
  emptyWrapper: {
    width: "100%",
    height: "100%",
    justifyContent: "space-evenly",
    alignItems: "center",
    paddingBottom: 20,
  },
  emptyContent: {
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
  },
  container: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: 100,
    paddingHorizontal: 30,
  },
});
