import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Feather } from "@expo/vector-icons";

// Components
import Divider from "../../atoms/Divider";

// Constants
import { colors, family } from "../../../constants/theme";

export const StatusLine = ({ status, scheduled, awaitTime }) => {
  switch (status) {
    case "scheduled":
      return (
        <View style={styles.lineStyle}>
          <Feather name="clock" size={12} color={colors.brown} />
          <Divider width={5} />
          <Text style={styles.textStyle}>
            {scheduled
              ? `Pedido programado para las ${scheduled}`
              : `Pedido programado para más tarde`}
          </Text>
        </View>
      );
    case "waiting":
      return (
        <Text style={styles.textStyle}>{`Tu pedido ha entrado en cocina`}</Text>
      );
    case "processing":
      return (
        <Text style={styles.textStyle}>
          {awaitTime
            ? `El pedido estará preparado para las ${awaitTime} aprox.`
            : `Tu pedido se está preparando`}
        </Text>
      );
    case "await_pickup":
      return (
        <Text
          style={styles.textStyle}
        >{`Tu pedido está listo para recoger`}</Text>
      );
    case "await_shipment":
      return (
        <Text
          style={styles.textStyle}
        >{`Tu pedido está a punto de ser enviado`}</Text>
      );
    case "parcially_delivered":
      return (
        <Text
          style={styles.textStyle}
        >{`Una parte de tu pedido está en camino`}</Text>
      );
    case "shipped":
      return <Text style={styles.textStyle}>{`Tu pedido está en camino`}</Text>;
  }
};

export default StatusLine;

const styles = StyleSheet.create({
  lineStyle: {
    flexDirection: "row",
    alignItems: "center",
  },
  textStyle: {
    fontFamily: family.normal,
    fontSize: 12,
    color: colors.brown,
  },
  accentStyle: {
    fontFamily: family.bold,
    fontSize: 12,
    color: colors.green,
  },
});
