import React, { useState } from "react";
import {
  TouchableOpacity,
  ActivityIndicator,
  View,
  useWindowDimensions,
} from "react-native";

// Constants
import { colors } from "../../constants/theme";

// Components
import Title from "../atoms/Title";
import Animation from "../atoms/Animation";

// Assets
import emptyAnimation from "../../assets/animations/empty.json";

const EmptyScreen = ({ title, id, onReload }) => {
  const [loading, setLoading] = useState(false);
  const width = useWindowDimensions().width;

  const clickHandler = () => {
    setLoading(true);
    onReload().then(() => setLoading(false))
  };

  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        paddingBottom: 80,
        backgroundColor: "transparent",
      }}
    >
      <Animation
        animation={emptyAnimation}
        name={`empty${id}`}
        style={{
          width: width > 400 ? 300 : 250,
          height: 150,
          marginBottom: 40,
          backgroundColor: "transparent",
        }}
      />
      <Title title={title} />
      {onReload && (
        <TouchableOpacity style={{ marginTop: 10 }} onPress={clickHandler}>
          {loading === false ? (
            <Title title={"Recargar"} color={colors.primaryColor} />
          ) : (
            <ActivityIndicator size="small" color={colors.primaryColor} />
          )}
        </TouchableOpacity>
      )}
    </View>
  );
};

export default EmptyScreen;
