import React from "react";
import { StyleSheet, View } from "react-native";

// Components
import Divider from "../../../../atoms/Divider";
import ProductName from "./ProductName";
import IngredientsText from "./IngredientsText";
import VariantsText from "./VariantsText";
import PlatesText from "./PlatesText";

// Constants
import { colors } from "../../../../../constants/theme";

const AddedInfoContainer = ({ data, amount, customStyle }) => {
  const { name, variants, ingredients, plates } = data;
  return (
    <View style={styles.infoContainer}>
      <ProductName
        name={name}
        amount={amount}
        color={customStyle?.title1 || colors.brown}
      />
      <Divider height={5} />
      <VariantsText
        variants={variants}
        color={customStyle?.letter1 || colors.brown}
        separator="newline"
      />
      <IngredientsText
        ingredients={ingredients}
        color={customStyle?.letter1 || colors.brown}
        separator="newline"
      />
      <PlatesText
        plates={plates}
        color={customStyle?.letter1 || colors.brown}
        separator=""
      />
    </View>
  );
};

export default AddedInfoContainer;

const styles = StyleSheet.create({
  infoContainer: {
    width: "100%",
    flex: 1,
    justifyContent: "center",
  },
  footer: {
    width: "100%",
    alignItems: "flex-end",
    flexDirection: "row",
    justifyContent: "space-between",
    height: 20,
  },
});
