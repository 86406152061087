import React from "react";
import { TouchableOpacity, Text } from "react-native";
import StyleSheet from "react-native-media-query";
import { family, RADIUS } from "../../constants/theme";

const Tag = ({ name, onPress, selected, backgroundColor, textColor }) => {
  return (
    <TouchableOpacity
      style={[
        styles.tagContainer,
        { backgroundColor: selected === true ? backgroundColor : 'white' },
        { borderColor:backgroundColor},
      ]}
      onPress={onPress}
      dataSet={{ media: ids.tagContainer }}
    >
      <Text
        style={[
          styles.text,
          selected
            ? { color: 'white' }
            : { color: backgroundColor },
        ]}
        dataSet={{ media: ids.text }}
      >
        {name}
      </Text>
    </TouchableOpacity>
  );
};

export default Tag;

const { ids, styles } = StyleSheet.create({
  tagContainer: {
    height: 36,
    borderRadius: 18,
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: 5,
    "@media (max-width: 320px)": {
      height: 32,
      borderRadius: 16
    },
    borderWidth:2
  },
  text: {
    fontSize: 14,
    paddingHorizontal: 12,
    "@media (max-width: 320px)": {
      fontSize: 12,
    },
    fontFamily: family.bold
  },
});
