import React from "react";
import { StyleSheet, Text, View, Image } from "react-native";
import { TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import { Feather } from "@expo/vector-icons";

// Components
import RemoveProduct from "../../../../components/organisms/products/ProductItem/components/RemoveProduct";
import Tag from "../../../../components/organisms/pastOrder/Tag";
import Divider from "../../../../components/atoms/Divider";
import ActionButton from "./ActionButton";

// Constants
import { colors, family } from "../../../../constants/theme";

// Redux
import { deleteProduct } from "../../../../redux/laterCart/laterCartActions";
import { addProduct } from "../../../../redux/laterCart/laterCartActions";

// Utils
import { getNextOpeningTime } from "../../../../utils/pointSale/getNextOpeningTime";

const ForLaterProduct = ({
  data,
  shop,
  orders,
  psData,
  addProduct,
  deleteProduct,
}) => {
  const {
    name,
    description,
    quantityAvailable,
    product,
    pointSale,
    internalId,
  } = data;

  let selectedProduct = orders
    ?.filter((order) => order.shopId === shop.gid)[0]
    ?.orderLines.filter((line) => line.internalId === internalId)[0];

  if (!selectedProduct) {
    selectedProduct = {
      quantity: 0,
    };
  }

  const currentPs = psData.filter((ps) => ps.gid === pointSale.gid)[0];
  const opened = currentPs.opened;
  const nextTime = opened
    ? { day: null, hour: null }
    : getNextOpeningTime(currentPs?.schedule);

  const tagText = !nextTime?.day
    ? nextTime?.hour
      ? `Disponible a las ${nextTime?.hour}`
      : nextTime?.message === "expired" && `No disponible`
    : `Disponible el ${nextTime?.day} a las ${nextTime?.hour}`;

  const onAdd = () => {
    if (opened && selectedProduct?.quantity < quantityAvailable) {
      addProduct({ ...data, shop: shop });
    }
  };

  const onRemove = () => {
    deleteProduct({ shopId: shop.gid, internalId: internalId });
  };

  return (
    <TouchableOpacity style={styles.container} onPress={onAdd}>
      <RemoveProduct
        showActions={true}
        quantity={selectedProduct?.quantity || 0}
        onPress={onRemove}
      />
      {product?.image && (
        <Image style={styles.image} source={{ uri: product?.image }} />
      )}
      <View style={styles.info}>
        <Text style={styles.title}>{product?.name || name}</Text>
        <Text style={styles.description}>{description}</Text>
        {!opened && (
          <View style={[styles.row, { marginTop: 10 }]}>
            <Tag
              bgColor={colors.lightPrimary}
              textColor={colors.white}
              text={tagText}
            />
          </View>
        )}
      </View>
      {quantityAvailable - selectedProduct?.quantity > 0 && (
        <View style={styles.actionContainer}>
          <View style={styles.row}>
            <Text style={styles.text}>
              {quantityAvailable - selectedProduct?.quantity > 1
                ? "Quedan"
                : "Queda"}
            </Text>
            <Divider width={5} />
            <Text style={[styles.title, { fontSize: 16 }]}>
              {quantityAvailable - selectedProduct?.quantity}
            </Text>
          </View>

          {opened && (
            <ActionButton color={colors.lightenGreen} onPress={onAdd}>
              <Feather name="plus" size={20} color={colors.lightGreen} />
            </ActionButton>
          )}
        </View>
      )}
    </TouchableOpacity>
  );
};

const mapStateToProps = (state) => {
  return {
    orders: state.laterOrders.orders,
  };
};

const mapDispatchToProps = {
  addProduct,
  deleteProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForLaterProduct);

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "row",
    height: 55,
  },
  image: {
    resizeMode: "cover",
    width: 55,
    height: 55,
    borderRadius: 8,
  },
  info: {
    marginLeft: 10,
    flexDirection: "column",
    flex: 1,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontFamily: family.bold,
    fontSize: 14,
    color: colors.brown,
  },
  text: {
    fontFamily: family.normal,
    fontSize: 12,
    color: colors.brown,
  },
  description: {
    fontFamily: family.normal,
    fontSize: 12,
    color: colors.brown,
  },
  actionContainer: {
    flexDirection: "column",
    width: 80,
    justifyContent: "space-between",
    alignItems: "center",
  },
});
