import React from "react";
import { StyleSheet, Text, View } from "react-native";

// Constants
import { colors, family } from "../../constants/theme";
import Container from "./Container";

const TitleHeader = ({ title }) => {
  return (
    <Container>
      <View style={styles.titleWrapper}>
        <Text style={styles.title}>{title}</Text>
      </View>
    </Container>
  );
};

export default TitleHeader;

const styles = StyleSheet.create({
  titleWrapper: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    color: colors.brown,
    fontFamily: family.bold,
    fontSize: 22,
  },
});
