export const LATER_ADD_PRODUCT = "LATER_ADD_PRODUCT";
export const LATER_DELETE_PRODUCT = "LATER_DELETE_PRODUCT";
export const LATER_SET_EVENT = "LATER_SET_EVENT";
export const LATER_INCREMENT_PRODUCT = "LATER_INCREMENT_PRODUCT";

export const LATER_SET_COMMENT = "LATER_SET_COMMENT";
export const LATER_SET_DATE = "LATER_SET_DATE";
export const LATER_SET_SERVICE = "LATER_SET_SERVICE";
export const LATER_SET_ORDER_TYPE = "LATER_SET_ORDER_TYPE";

export const LATER_RESET_CART = "LATER_RESET_CART";
