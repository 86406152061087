import { v4 as uuidv4 } from "uuid";
export const getScheduleArray = (slots = []) => {
  let array = [{ id: uuidv4(), list: [] }];
  slots.map((item, index) => {
    if (index !== 0) {
      const time = item.time.split(":").map(part => part.padStart(2, '0')).join(':');
      if (index === 1) {
        array[0].list.push(time);
      } else {
        let addToIndex = -1;
        array.map((el, arrayIndex) => {
          if (time.split(":")[0] === el.list[0].split(":")[0]) {
            addToIndex = arrayIndex;
          }
        });
        if (addToIndex !== -1) {
          array[addToIndex].list.push(time);
        } else {
          array.push({ id: uuidv4(), list: [time] });
        }
      }
    }
  });
  return array;
};

export const getShopById = (orders, id) => {
  let shopIndex = -1;
  let shop;

  orders.map((orderShop, index) => {
    if (orderShop.id_shop === id) {
      shop = orderShop;
      shopIndex = index;
    }
  });

  return [shop, shopIndex];
};

export const getCommissionArray = (detailShop) => {
  let aux = [];
  if (detailShop.gopick_commission !== null) {
    aux.push({
      name: "Comisión Gopick",
      amount: detailShop.gopick_commission || 0,
      type: detailShop.type_gopick_commission || null,
    });
  }
  if (detailShop.waiter_commission !== null) {
    aux.push({
      name: "Comisión Camarero",
      amount: detailShop.gopick_commission || 0,
      type: detailShop.type_gopick_commission || null,
    });
  }
  return aux;
};

export const hasLaterOrders = (shop) => {
  let result = false
  shop?.services.map((service) => {
    if(service.key === "no_service"){
      result = true;
    }
  })
  return result
};
