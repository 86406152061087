import React, { useState } from "react";
import { StyleSheet, ScrollView, Image } from "react-native";

// Components
import ModalContainer from "./ModalContainer";
import Header from "./Header";
import Description from "./Description";
import Variants from "./Variants";
import Ingredients from "./Ingredients";
import Footer from "./Footer";

const TicketProductVariantsModal = ({ visible, setModal, data, onAdd }) => {
  const [variantsList, setVariantsList] = useState([]);
  const [ingredientsList, setIngredientsList] = useState([]);
  const { name, long_description, variants, ingredients, image } = data;

  const hasVariants = () => {
    if (variants) {
      if (variants[0]?.name) {
        return true;
      }
    }
    return false;
  };

  return (
    <ModalContainer modalVisible={visible} handleModal={() => setModal(false)}>
      <Header title={name} onPress={() => setModal()} />
      <ScrollView style={styles.container}>
        {image !== null && image !== undefined && (
          <Image style={styles.imageWrapper} source={{ uri: image }} />
        )}
        {long_description !== null && long_description !== undefined && (
          <Description description={long_description} />
        )}
        {hasVariants() === true && (
          <Variants
            data={variants}
            setVariants={setVariantsList}
            variantsList={variantsList}
          />
        )}
        {ingredients?.length > 0 && (
          <Ingredients
            data={ingredients}
            setIngredients={setIngredientsList}
            ingredientsList={ingredientsList}
          />
        )}
      </ScrollView>
      <Footer
        modal={visible}
        data={data}
        variantsList={variantsList}
        ingredientsList={ingredientsList}
        onAdd={onAdd}
        setModal={setModal}
      />
    </ModalContainer>
  );
};

export default TicketProductVariantsModal;

const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
  imageWrapper: {
    width: "100%",
    height: 200,
  },
});
