import React from "react";
import { TouchableOpacity } from "react-native";
import { View } from "react-native";
import StyleSheet from "react-native-media-query";

// Components
import CustomIcon from "../../../atoms/CustomIcon";

// Constants
import { RADIUS } from "../../../../constants/theme";

const MediaButton = ({ icon, onPress }) => {
  return (
    <TouchableOpacity
      style={styles.container}
      onPress={onPress}
      dataSet={{ media: ids.container }}
    >
      <View style={styles.iconContainer} dataSet={{ media: ids.iconContainer }}>
        <CustomIcon name={icon} />
      </View>
    </TouchableOpacity>
  );
};

export default MediaButton;

const { ids, styles } = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    height: 46,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: RADIUS,
    paddingHorizontal: 18,
    borderWidth: 0.8,
    borderColor: "black",
    backgroundColor: "white",
    "@media (max-height: 700px) or (max-width: 340px)": {
      height: 32,
    },
  },
  iconContainer: {
    width: 24,
    height: 24,
    marginRight: 6,
    "@media (max-height: 700px) or (max-width: 340px)": {
      width: 18,
      height: 18,
    },
  },
});
