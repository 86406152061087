import React from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";

// Components
import TabBar from "./components/TabBar";

// Stacks
import OrdersStack from "../../navigation/stacks/OrdersStack";
import EventsStack from "../../navigation/stacks/EventsStack";
import ProfileStack from "../../navigation/stacks/ProfileStack";

const Tab = createBottomTabNavigator();

const NavigationTabs = () => {
  return (
    <Tab.Navigator
      tabBar={(props) => <TabBar {...props} />}
      screenOptions={{
        headerShown: false,
      }}
    >
      <Tab.Screen name="EventsStack" component={EventsStack} />
      <Tab.Screen name="OrdersStack" component={OrdersStack} />
      <Tab.Screen name="ProfileStack" component={ProfileStack} />
    </Tab.Navigator>
  );
};

export default NavigationTabs;
