import React, { useState } from "react";
import StyleSheet from "react-native-media-query";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Text, View, TouchableOpacity, ActivityIndicator } from "react-native";
import { connect } from "react-redux";

// Components
import Divider from "../atoms/Divider";

// Constants
import { colors, family } from "../../constants/theme";

// Redux
import { fetchLocation } from "../../redux";

const LiveLocation = ({ location, fetchLocation }) => {
  const [loading, setLoading] = useState(false);
  const clickHandler = async () => {
    setLoading(true);
    await fetchLocation();
    setLoading(false);
  };
  return (
    <View style={styles.container}>
      <TouchableOpacity style={styles.content} onPress={clickHandler}>
        <MaterialCommunityIcons
          name="sign-direction"
          size={20}
          color={colors.secondary}
        />
        <Divider width={12} />
        {loading ? (
          <ActivityIndicator size="small" color={colors.secondary} />
        ) : (
          <Text style={styles.text} dataSet={{ media: ids.text }}>
            {location?.address || "Pulsa para localizar"}
          </Text>
        )}
      </TouchableOpacity>
    </View>
  );
};

const { ids, styles } = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  text: {
    fontFamily: family.bold,
    fontSize: 16,
    color: colors.secondary,
    "@media (max-height: 700px) or (max-width: 340px)": {
      fontSize: 12,
    },
  },
});

const mapStateToProps = (state) => {
  return {
    location: state.user.location,
  };
};

const mapDispatchToProps = {
  fetchLocation,
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveLocation);
