import React from "react";
import { TouchableOpacity } from "react-native";
import { StyleSheet } from "react-native";

const ActionButton = ({ onPress, children, color = "white", size = 25 }) => {
  return (
    <TouchableOpacity
      style={[
        styles.container,
        {
          backgroundColor: color,
          width: size,
          height: size,
          borderRadius: size / 2,
        },
      ]}
      onPress={onPress}
    >
      {children}
    </TouchableOpacity>
  );
};

export default ActionButton;

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
  },
});
