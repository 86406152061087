import React, { useContext, useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import { connect } from "react-redux";

// Components
import Screen from "../../components/atoms/Screen";
import TextInput from "../../components/atoms/TextInput";
import Divider from "../../components/atoms/Divider";
import Title from "../../components/atoms/Title";
import CustomButton from "../../components/atoms/CustomButton";
import TextButton from "../../components/atoms/TextButton";
import ErrorMessage from "../../components/atoms/ErrorMessage";
import WarningModal from "../../components/organisms/modals/WarningModal";

// Redux Actions
import { editUser, deleteCustomer } from "../../redux/user/userActions";

import { AuthContext } from "../../contexts/AuthContext";

// Utils
import { validateMail } from "../../utils/arrayMethods";
import CustomAlert from "../../components/molecules/CustomAlert";
import { colors } from "../../constants/theme";

const ProfilePersonalDataScreen = ({
  navigation,
  user,
  deleteCustomer,
  editUser,
}) => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [error, setError] = useState();
  const [modal, setModal] = useState();

  const { user: firebaseUser } = useContext(AuthContext);

  useEffect(() => {
    setName(user.name);
    setEmail(user.email);
    setPhone(user.phone);
  }, [user]);

  const getObjectValidated = () => {
    let properties = {};
    if (name !== null) {
      properties = { ...properties, name: name };
    }
    if (email !== user.email && email !== null && validateMail(email)) {
      properties = { ...properties, email: email };
    }
    if (phone !== null) {
      properties = { ...properties, phone: phone };
    }
    return properties;
  };

  const handleBack = async () => {
    navigation.goBack();
    const token = await firebaseUser.getIdToken(true);
    editUser({
      token,
      properties: getObjectValidated(),
    });
  };

  const deleteAccount = async () => {
    setError();
    const token = await firebaseUser.getIdToken(true);
    const response = await deleteCustomer(token);
    if (response === "success") {
      setModal("Warning");
    } else {
      setError(response);
    }
  };

  const handleDeleteModal = () => {
    navigation.goBack();
  };

  const deactivateHandler = () => {
    setModal("DeleteAccount");
  };

  const alertData = {
    title: "Desactivar cuenta",
    subtitle:
      "Si continuas con el borrado de cuenta perderás todos tus pedidos hechos y no habrá manera de recuperarlos ni relacionarte con ellos",
    actions: [
      {
        title: "Cancelar",
        disableVisible: false,
        onPress: () => {
          setModal(false);
        },
        textColor: colors.primaryColor,
        color: colors.white,
      },
      {
        title: "Aceptar",
        disableVisible: false,
        onPress: () => {
          setModal(false);
          deleteAccount();
        },
      },
    ],
  };

  return (
    <Screen>
      <View style={styles.container}>
        <View>
          <Title title="Tu cuenta" />
          <Divider height={14} />
          <TextInput placeholder="Nombre" value={name} onChange={setName} />
          <Divider height={14} />
          <TextInput
            placeholder="Email"
            value={firebaseUser.email}
            onChange={setEmail}
          />
          <Divider height={14} />
          <TextInput placeholder="Teléfono" value={phone} onChange={setPhone} />
          <Divider height={14} />
          <TextButton
            icon={"trash"}
            big
            title={"Desactivar cuenta"}
            onPress={deactivateHandler}
          />
          {error && <ErrorMessage error={error} />}
          <Divider height={24} />
        </View>
        <CustomButton active title="Guardar Cambios" onPress={handleBack} />
        <CustomAlert
          modalVisible={modal === "DeleteAccount"}
          setModalVisible={setModal}
          alertData={alertData}
        />
        <WarningModal
          visible={modal === "Warning"}
          setModal={setModal}
          text={"Los datos de tu cuenta han sido borrados satisfactoriamente"}
          action={handleDeleteModal}
        />
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingBottom: 30,
    paddingHorizontal: 18,
    justifyContent: "space-between",
  },
});

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};

const mapDispatchToProps = {
  editUser,
  deleteCustomer,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfilePersonalDataScreen);
