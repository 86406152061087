import React from "react";
import { Platform } from "react-native";
import { Switch } from "react-native";

// Constants
import { colors } from "../../constants/theme";

const CustomSwitch = ({ onChange, value, customStyle }) => {
  const { darkPrimary, backgroundColor, primaryColor } = customStyle;
  return (
    <Switch
      trackColor={{
        true: customStyle ? primaryColor : colors.lightenPrimary,
        false: customStyle ? darkPrimary : colors.grey,
      }}
      thumbColor={
        customStyle
          ? [
              Platform.OS == "ios"
                ? backgroundColor
                : value
                ? primaryColor
                : backgroundColor,
            ]
          : colors.primaryColor
      }
      ios_backgroundColor={customStyle ? backgroundColor : colors.grey}
      value={value}
      onValueChange={onChange}
    />
  );
};

export default CustomSwitch;
