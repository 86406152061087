import React from "react";
import { StyleSheet, View } from "react-native";
import { colors } from "../../../constants/theme";

// Components
import CustomButton from "../../atoms/CustomButton";
import CustomModal from "../../atoms/CustomModal";
import CustomText from "../../atoms/CustomText";

const WarningModal = ({ visible, setModal, text, action }) => {
  const modalHandler = () => {
    if (action) {
      action();
    }
    setModal();
  };

  return (
    <CustomModal
      modalVisible={visible}
      handleModal={() => (action ? {} : setModal())}
    >
      <View style={styles.inputWrapper}>
        <CustomText
          textAlign="center"
          fontSize={18}
          color={colors.brown}
          bold
          text={text}
        />
        <View style={{ height: 60, width: "100%" }}>
          <CustomButton
            title="Aceptar"
            marginTop={20}
            active
            onPress={modalHandler}
          />
        </View>
      </View>
    </CustomModal>
  );
};

export default WarningModal;

const styles = StyleSheet.create({
  inputWrapper: {
    width: "100%",
  },
});
