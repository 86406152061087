import React, { useRef, useState, useEffect, useCallback } from "react";
import StyleSheet from "react-native-media-query";
import { View, Text, useWindowDimensions } from "react-native";
import { SectionList } from "react-native";

// Components
import ProductCategoriesCarousel from "../organisms/products/ProductCategoriesCarousel";
import ProductsSelector from "../organisms/products/ProductItem/ProductsSelector";

// Constants
import { colors, family } from "../../constants/theme";

const ManagedList = ({
  productList,
  catList,
  navigation,
  shopData,
  extraShopData,
}) => {
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [viewableItem, setViewableItem] = useState();
  const height = useWindowDimensions().height;

  // Custom style
  const custom_color_1 = shopData?.custom_design?.custom_color_1 || "#FFFFFF";
  const custom_color_letter_2 =
    shopData.custom_design?.custom_color_letter_2 || colors.brown;

  // Refs to handle categories and products lists
  const productListRef = useRef();
  const catListRef = useRef();
  const viewConfigRef = useRef({ viewAreaCoveragePercentThreshold: 75 });

  // Gets current item on product list
  // and updates categories carousel if necessary
  const catListHandler = (index) => {
    setSelectedCategory(index);
    if (catListRef.current) {
      catListRef?.current?.scrollToIndex({
        index: index,
        animated: true,
        viewPosition: 0,
      });
    }
  };

  const categoriesHandler = (index) => {
    catListHandler(index);
    if (productListRef.current) {
      productListRef.current.scrollToLocation({
        animated: false,
        sectionIndex: index,
        itemIndex: 0,
      });
    }
  };

  // Updates current viewable item in product list by scroll
  useEffect(() => {
    if (viewableItem?.title) {
      let auxIndex = -1;
      catList.map((cat, index) => {
        if (cat.gid === viewableItem.gid) {
          auxIndex = index;
        }
      });
      if (auxIndex !== -1) {
        catListHandler(auxIndex);
      }
    }
  }, [viewableItem]);

  const _onViewableItemsChanged = useCallback(({ viewableItems }) => {
    setViewableItem(viewableItems[0]?.item);
  }, []);

  const _viewabilityConfig = {
    itemVisiblePercentThreshold: 100,
  };

  return (
    <View
      style={[
        styles.productsSelector,
        { backgroundColor: custom_color_1 || "white" },
        { height: height - 150 },
      ]}
    >
      <View style={styles.categoriesWrapper}>
        <ProductCategoriesCarousel
          categoriesList={catList}
          selectedCategory={selectedCategory}
          categoriesHandler={categoriesHandler}
          listRef={catListRef}
          shopData={shopData}
        />
      </View>
      <View style={styles.productListWrapper}>
        <SectionList
          style={{
            width: "100%",
            height: "100%",
            paddingHorizontal: 18,
          }}
          ref={productListRef}
          showsVerticalScrollIndicator={false}
          viewabilityConfig={viewConfigRef.current}
          onViewableItemsChanged={_onViewableItemsChanged}
          viewabilityConfig={_viewabilityConfig}
          sections={productList}
          keyExtractor={(item, index) => item + index}
          renderItem={({ item }) => (
            <ProductsSelector
              key={item.gid}
              data={item}
              shopData={shopData}
              navigation={navigation}
              extraShopData={extraShopData}
            />
          )}
          ListFooterComponent={() => <View style={{ height: 200 }}></View>}
          stickySectionHeadersEnabled={false}
          renderSectionHeader={({ section: { title } }) => (
            <View style={styles.headerContainter}>
              <View
                style={styles.headerContent}
                dataSet={{ media: ids.headerContent }}
              >
                <Text
                  style={[styles.header, { color: custom_color_letter_2 }]}
                  dataSet={{ media: ids.header }}
                >
                  {title}
                </Text>
              </View>
            </View>
          )}
        />
      </View>
    </View>
  );
};

const { ids, styles } = StyleSheet.create({
  productsSelector: {
    width: "100%",
  },
  categoriesWrapper: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  productListWrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  headerContainter: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  headerContent: {
    width: "100%",
    maxWidth: 400,
    height: 40,
    justifyContent: "center",
    paddingLeft: 18,
    alignItems: "flex-start",
    "@media (max-width: 320px)": {
      height: 30,
    },
  },
  header: {
    fontSize: 16,
    fontFamily: family.bold,
    color: colors.brown,
    "@media (max-width: 320px)": {
      fontSize: 12,
    },
  },
});
export default ManagedList;
