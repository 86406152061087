import React from 'react'
import Svg, { Path } from "react-native-svg";

const SearchIcon = (props) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      className="prefix__ionicon"
      viewBox="0 0 512 512"
      {...props}
    >
      <Path
        d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z"
        fill="none"
        strokeMiterlimit={10}
        strokeWidth={32}
      />
      <Path
        fill="none"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={32}
        d="M338.29 338.29L448 448"
      />
    </Svg>
  )
}

export default SearchIcon
