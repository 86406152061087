import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View } from "react-native";

// Components
import { getFormattedPrice } from "../../../../utils/productMethods";
import CustomCheckBox from "../../../atoms/CustomCheckBox";
import RadioButton from "../../../atoms/RadioButton";
import CustomAlert from "../../../molecules/CustomAlert";

// Utils
import {
  variantsCheckHandler,
  variantsRadioHandler,
  getVariantGroupIndex,
  getVariantVariantIndex,
  handleQtyVariant,
} from "../../../../utils/variantsMethods";

// Hooks
import useForceRender from "../../../../hooks/useForceRender";
import { family, size } from "../../../../constants/theme";
import IconButtonTickets from "../../components/IconButtonTickets";
import { containsObject } from "../../../../utils/arrayMethods";

const Variants = ({ data, setVariants, variantsList }) => {
  const [forceRender] = useForceRender();
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertData, setAlertData] = useState({});
  useEffect(() => {
    data.map((variant) => {
      if (variant.type === "radio") {
        variant.options.map((op) => {
          if (op.default === 1) {
            variantsRadioHandler(variant, op, variantsList, setVariants);
            forceRender();
          }
        });
      }
    });
  }, []);

  const qtyHandler = (op, option, variant) => {
    const response = handleQtyVariant(
      op,
      option,
      variant,
      variantsList,
      setVariants
    );

    if (response === false) {
      setAlertData({
        title: `Sólo se puede añadir ${op.max_selected} ${op.name}`,
      });
      setAlertVisible(true);
    }
  };

  const handleRadio = (variant, op) => {
    variantsRadioHandler(variant, op, variantsList, setVariants);
    forceRender();
  };

  const handleCheck = (variant, op) => {
    variantsCheckHandler(variant, op, variantsList, setVariants);
    forceRender();
  };

  const containsVariant = (op) => {
    let result = false;
    variantsList.map((group) => {
      group.options.map((option) => {
        if(option.gid === op.gid){
          result = true
        }
      })
    });
    return result;
  };

  return (
    <View style={styles.varsContainer}>
      {data.map((variant) => {
        return (
          <View key={variant.name} style={{ flex: 1, marginTop: 10 }}>
            <Text style={[styles.title, { marginBottom: 5, color: "white" }]}>
              {variant.name}
            </Text>
            {variant.options.map((op) => {
              if (containsVariant(op)) {
                var groupIndex = getVariantGroupIndex(
                  variant.gid,
                  variantsList
                );
                var variantIndex = getVariantVariantIndex(
                  op.gid,
                  variantsList[groupIndex].options
                );
              }
              return (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    marginTop: 5,
                  }}
                  key={op.name}
                >
                  {variant.type === "radio" ? (
                    <RadioButton
                      selected={containsVariant(op)}
                      label={
                        op.increment > 0
                          ? op.name +
                            " (+" +
                            getFormattedPrice(op.increment) +
                            ")"
                          : op.name
                      }
                      color={"white"}
                      onPress={() => handleRadio(variant, op)}
                    />
                  ) : (
                    <CustomCheckBox
                      textColor={"white"}
                      key={op.gid}
                      label={
                        op.increment > 0
                          ? op.name +
                            " (+" +
                            getFormattedPrice(op.increment) +
                            ")"
                          : op.name
                      }
                      value={containsVariant(op)}
                      onPress={() => handleCheck(variant, op)}
                    />
                  )}
                  {op.allow_quantity === 1 &&
                    JSON.stringify(JSON.stringify(variantsList)).includes(
                      op.name
                    ) && (
                      <View
                        style={{
                          flexDirection: "row",
                          width: 90,
                          marginRight: 80,
                          alignItems: "center",
                        }}
                      >
                        <View style={styles.actions}>
                          <IconButtonTickets
                            icon="minus"
                            onPress={() => qtyHandler(op, "remove", variant)}
                          />
                          <Text style={styles.qtyText}>
                            {variantsList[groupIndex].options[variantIndex].qty}
                          </Text>
                          <IconButtonTickets
                            icon="plus"
                            onPress={() => qtyHandler(op, "add", variant)}
                          />
                        </View>
                      </View>
                    )}
                </View>
              );
            })}
          </View>
        );
      })}
      <CustomAlert
        setModalVisible={setAlertVisible}
        modalVisible={alertVisible}
        alertData={alertData}
      />
    </View>
  );
};

export default Variants;

const styles = StyleSheet.create({
  varsContainer: {
    backgroundColor: "black",
    paddingHorizontal: 18,
    paddingVertical: 20,
  },
  title: {
    fontSize: size.small,
    fontFamily: family.bold,
  },
});
