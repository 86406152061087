import React from "react";
import { View } from "react-native";
import Svg, { Image } from "react-native-svg";
import StyleSheet from "react-native-media-query";

const LogoTicketingVertical = (props) => {
  return (
    <Svg {...props}>
      <Image {...props} href={require("../ticketingVertical.svg")} />
    </Svg>
  );
};

export default LogoTicketingVertical;
