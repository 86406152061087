import React from "react";
import { StyleSheet, View } from "react-native";
import { family, size } from "../../../../constants/theme";
import HtmlNative from "../../../atoms/HtmlNative";

const Description = ({ description }) => {
  return (
    <View style={styles.description}>
      <HtmlNative
        value={description}
        style={"p{color: white; font-family: 'Raleway', sans-serif}"}
        link={`<link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;600&family=Raleway:wght@300&display=swap" rel="stylesheet">`}
      />
    </View>
  );
};

export default Description;

const styles = StyleSheet.create({
  description: {
    backgroundColor: "black",
    paddingHorizontal: 18,
  },
});
