import {hasOrderType } from "./hasOrderType";
import needAnyLocation from "./needAnyLocation";
import needToSchedule from "./needToSchedule";

const needAnythingLeft = (cart) => {
  let result = false;
  const locationNeeded = needAnyLocation(cart.services);
  const scheduleNeeded = needToSchedule(cart.orders);
  const needToSelectOrderType = !hasOrderType(cart);

  if (locationNeeded !== false) {
    result = locationNeeded;
  }
  if (scheduleNeeded !== false) {
    result = scheduleNeeded;
  }
  if (needToSelectOrderType) {
    result = "Debes seleccionar tipo de entrega";
  }
  return result;
};

export default needAnythingLeft;
