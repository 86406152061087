import { baseVersion } from "../../../config";
import { URlManagement } from "../../constants/urls";

export const getEventInfo = async (gid) => {
  try {
    let event = {
      gid: null,
      shops: [],
    };
    const url = `${URlManagement}/event/get/${gid}`;
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Connection: "keep-alive",
         
      },
      method: "GET",
    });

    const resData = await response.json();
    if (resData.message === "success") {
      event = { ...event, ...resData.data };
      return event;
    }
  } catch (err) {
    console.log("error fetching event:", err);
  }
};

export const getEventTypeArray = (data = []) => {
  const grupos = {};
  const resultado = [];

  data.forEach((elemento) => {
    const event_type = elemento.event_type;

    if (event_type) {
      if (grupos[event_type.gid]) {
        grupos[event_type.gid].push(elemento);
      } else {
        grupos[event_type.gid] = [elemento];
      }
    }
  });

  for (const id in grupos) {
    if (grupos.hasOwnProperty(id)) {
      const eventType = grupos[id][0].event_type;
      const events = grupos[id];

      const { name, gid } = eventType;
      resultado.push({ name, gid, events });
    }
  }

  return resultado;
};
