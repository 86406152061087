import React from "react";
import StyleSheet from "react-native-media-query";
import { View } from "react-native";

// Constants
import TicketDetailItem from "../components/TicketDetailItem";

const BuyTicket = ({ data, onAdd, onRemove }) => {
  return (
    <View style={styles.container} dataSet={{ media: ids.container }}>
      {data.map((ticket) => (
        <TicketDetailItem
          key={ticket.gid}
          data={ticket}
          onAdd={onAdd}
          onRemove={onRemove}
        />
      ))}
    </View>
  );
};

export default BuyTicket;

const { ids, styles } = StyleSheet.create({
  container: {
    flexDirection: "column",
    width: "50%",
    "@media (max-width: 700px)": {
      width: "100%",
    },
  },
});
