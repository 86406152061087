import { sortLiveElements } from "../../utils/distanceMethods";
import {
  FETCH_LIVE_EVENTS,
  SORT_LIVE_ITEMS,
  FETCH_EVENT_TYPES,
  FETCH_ALL_EVENTS,
} from "./eventsTypes";
import { getEventTypeArray } from "./eventUtils";

const initialState = {
  liveEvents: [],
  eventTypes: [],
  allEvents: [],
};

const eventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LIVE_EVENTS: {
      return {
        ...state,
        liveEvents: action.payload,
      };
    }
    case SORT_LIVE_ITEMS: {
      const { sortBy, location } = action.payload;
      let newArray = state.liveEvents;
      if (sortBy === "location") {
        newArray = sortLiveElements({ array: state.liveEvents, ref: location });
      } else if (sortBy === "schedule") {
        newArray = state.liveEvents.sort((a, b) => {
          if (a.schedule.opened === 1) {
            return -1;
          } else {
            return 1;
          }
        });
      }
      return {
        ...state,
        liveEvents: newArray,
      };
    }
    case FETCH_EVENT_TYPES: {
      return {
        ...state,
        eventTypes: action.payload,
      };
    }
    case FETCH_ALL_EVENTS: {
      return {
        ...state,
        allEvents: action.payload,
      };
    }
    default:
      return state;
  }
};

export default eventsReducer;
