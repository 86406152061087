import React from "react";
import { TouchableOpacity } from "react-native";
import StyleSheet from "react-native-media-query";

// Components
import EventImage from "./EventImage";

const Wrapper = ({ onPress, image, itemWidth, children }) => {
  return (
    <TouchableOpacity
      activeOpacity={0.7}
      style={[styles.wrapper, { width: itemWidth }]}
      onPress={onPress}
      dataSet={{ media: ids.wrapper }}
    >
      <EventImage image={image} />
      {children}
    </TouchableOpacity>
  );
};
export default Wrapper;

const { ids, styles } = StyleSheet.create({
  wrapper: {
    height: "100%",
    borderRadius: 15,
    "@media (max-height: 700px) or (max-width: 340px)": {
      borderRadius: 10,
    },
  },
});
