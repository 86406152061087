import React from "react";
import { Text, View } from "react-native";
import StyleSheet from "react-native-media-query";
import { family } from "../../../../constants/theme";
import { getFormattedPrice } from "../../../../utils/productMethods";

const TicketLines = ({ ticketLines }) => {
  return (
    <View style={styles.orders}>
      {ticketLines.map((data) => {
        return (
          <View
            key={data.id_ticket}
            style={[styles.line, { marginBottom: 20 }]}
          >
            <View style={styles.qtyContainer}>
              <Text style={styles.text} dataSet={{ media: ids.text }}>
                {data.quantity + "x"}
              </Text>
            </View>
            <View style={styles.content}>
              <Text style={styles.text} dataSet={{ media: ids.text }}>
                {data.name}
              </Text>
              <Text style={styles.text} dataSet={{ media: ids.text }}>
                {getFormattedPrice(data.amount * data.quantity)}
              </Text>
            </View>
          </View>
        );
      })}
    </View>
  );
};

export default TicketLines;

const { ids, styles } = StyleSheet.create({
  orders: {
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  line: {
    flexDirection: "row",
    width: "100%",
  },
  qtyContainer: {
    width: 30,
  },
  content: {
    flexDirection: "row",
    justifyContent: "space-between",
    flex: 1,
    alignItems: "center",
  },
  text: {
    fontFamily: family.normal,
    fontSize: 14,
    color: "white",
    "@media (max-width: 700px)": {
      fontSize: 10,
    },
  },
});
