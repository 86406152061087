import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import { View, StyleSheet, useWindowDimensions } from "react-native";
import { ActivityIndicator } from "react-native";
import { Platform } from "react-native";
import { ScrollView } from "react-native";
import { connect } from "react-redux";
import { useFocusEffect } from "@react-navigation/native";

// Redux Actions
import {
  fetchCompletedOrders,
  updateOrder,
} from "../../redux/orders/ordersActions";
import { setCurrentScreen } from "../../redux";

// Components
import CompletedOrder from "../../components/organisms/completedOrder/CompletedOrder";
import Screen from "../../components/atoms/Screen";
import CustomButton from "../../components/atoms/CustomButton";
import EmptyScreen from "../../components/molecules/EmptyScreen";

// Constants
import { colors } from "../../constants/theme";

// Hooks
import useForceRender from "../../hooks/useForceRender";

import { AuthContext } from "../../contexts/AuthContext";

const CompletedOrdersSCreen = ({
  navigation,
  completedOrders,
  fetchCompletedOrders,
  user,
  setCurrentScreen,
}) => {
  const { user: firebaseUser } = useContext(AuthContext);
  const [limit, setLimit] = useState(1);
  const [listItems, setListItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const height = useWindowDimensions().height;
  const listRef = useRef();
  const [forceRender] = useForceRender();

  const getData = async () => {
    setLoading(true);
    try {
      const token = await firebaseUser.getIdToken(true);
      await fetchCompletedOrders(user.gid, token);
    } catch (err) {
      console.log("error fetching completed orders");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const array = completedOrders.slice(0, 10 * limit);
    setListItems(array);
    forceRender();
  }, [completedOrders, limit]);

  useFocusEffect(
    useCallback(() => {
      setCurrentScreen(3);
    }, [])
  );

  const getOldOrders = async () => {
    getData();
    setLimit(limit + 1);
  };

  const reloadHandler = async () => {
    try {
      const token = await firebaseUser.getIdToken(true);
      await fetchCompletedOrders(user.gid, token);
    } catch (err) {
      console.log("error fetching past orders");
    }
  };

  return (
    <Screen hasFooter header={"logo"} hideActions navigation={navigation}>
      {loading === true ? (
        <View style={styles.loadingWrapper}>
          <ActivityIndicator size="small" color={colors.primaryColor} />
        </View>
      ) : listItems.length > 0 ? (
        <View style={{ height: "100%" }}>
          <ScrollView
            ref={listRef}
            style={{ height: 1 }}
            showsVerticalScrollIndicator={false}
          >
            {listItems.map((order) => (
              <CompletedOrder
                key={order.gid}
                data={order}
                navigation={navigation}
              />
            ))}
            {listItems?.length > limit * 10 && (
              <View style={styles.button}>
                <CustomButton
                  active
                  onPress={getOldOrders}
                  title="Cargar pedidos anteriores"
                />
              </View>
            )}
            <View style={{ height: 200 }} />
          </ScrollView>
        </View>
      ) : (
        <EmptyScreen
          title="No has completado ningún pedido"
          onReload={reloadHandler}
          id="orders"
        />
      )}
    </Screen>
  );
};

const mapDispatchToProps = {
  fetchCompletedOrders,
  updateOrder,
  setCurrentScreen,
};

const mapStateToProps = (state) => {
  return {
    completedOrders: state.orders.completedOrders,
    user: state.user.user,
  };
};

const styles = StyleSheet.create({
  button: {
    width: "100%",
    height: 90,
    paddingHorizontal: 18,
    paddingTop: 20,
    marginBottom: 80,
  },
  loadingWrapper: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompletedOrdersSCreen);
