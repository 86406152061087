import React from "react";
import { StyleSheet, Text, View } from "react-native";

// Components
import TitleRow from "./TitleRow";

// Constants
import { colors, family } from "../../../constants/theme";

// Utils
import { getFormattedDate, unixToHHMM } from "../../../utils/dateMethods";

const InfoSection = ({ orderNumber, deliveryDate, gid, created }) => {
  return (
    <View style={styles.container}>
      <TitleRow>Información del pedido</TitleRow>
      <TitleRow name={"Información del pedido"} />
      <Text style={styles.text}>
        Número de pedido: <Text style={styles.bold}>{orderNumber}</Text>
      </Text>
      <Text style={styles.text}>
        Número de identificación: <Text style={styles.bold}>{gid}</Text>
      </Text>
      {created && (
        <Text style={styles.text}>
          Fecha del pedido:{" "}
          <Text style={styles.bold}>{getFormattedDate(created)}</Text>
        </Text>
      )}
      <Text style={styles.text}>
        Recibir:{" "}
        <Text style={styles.bold}>
          {deliveryDate ? unixToHHMM(deliveryDate) : "Lo antes posible"}
        </Text>
      </Text>
    </View>
  );
};

export default InfoSection;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    justifyContent: "flex-start",
  },
  title: {
    fontFamily: family.bold,
    color: colors.brown,
    fontSize: 14,
  },
  text: {
    fontFamily: family.normal,
    color: colors.brown,
    fontSize: 14,
    paddingBottom: 7,
  },
  bold: {
    fontFamily: family.bold,
    color: colors.brown,
    fontSize: 13,
  },
});
