import React, { useState, useEffect } from "react";
import { Text, ScrollView, View, Platform } from "react-native";
import { Picker } from "@react-native-picker/picker";
import { connect } from "react-redux";
import StyleSheet from "react-native-media-query";
import SelectDropdown from "react-native-select-dropdown";

// Components
import CustomModal from "../../../../../components/atoms/CustomModal";
import CustomButton from "../../../../../components/atoms/CustomButton";

// Constants
import { colors, family } from "../../../../../constants/theme";

// Redux
import { setService } from "../../../../../redux/laterCart/laterCartActions";
import {
  getCharFromIndex,
  getCharIndex,
} from "../../../../../utils/arrayMethods";
import { ActivityIndicator } from "react-native";
import { getServices } from "../../methods/getServices";
import { getLocalizaciones } from "../../methods/getLocalizations";

const LocationsModal = ({ visible, setModal, data, setService }) => {
  const { eventId, shopId } = data;
  let initArray = [];
  const [loading, setLoading] = useState(false);
  const [servicioSel, setServicioSel] = useState({});
  const [localizacionesSel, setLocalizacionesSel] = useState(initArray);
  const [activeButton, setActiveButton] = useState(false);
  const [optionsSel, setOptionsSel] = useState([]);

  const getLocalizations = async (serviceArray) => {
    try {
      const { data, status } = await getLocalizaciones(eventId, shopId);
      if (status === "success") {
        let localizacionesAux = data[0]?.structure;
        let localizacionesSelAux = [];
        let checkServiciosAux = [];

        const servicios = serviceArray.filter((ser) => ser.key === "my_site");

        if (servicios != undefined) {
          servicios.map(function (servicio, indice) {
            servicio.structure = localizacionesAux;
            let aux = {
              servicio: servicio,
              valor: false,
            };
            if (indice == 0) {
              aux.valor = true;
            }
            checkServiciosAux.push(aux);
          });

          if (
            servicios[0]?.structure != undefined &&
            servicios[0]?.structure.length > 0
          ) {
            setLocalizacionesSel([servicios[0]]);
            localizacionesSelAux = [servicios[0]];
          }

          setServicioSel(servicios);
        }
        canOpen(servicios[0], localizacionesSelAux);
      }
    } catch (err) {
      console.log("error fetching localizations:", err);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    const response = await getServices(eventId, shopId);
    getLocalizations(response).then(() => {
      setLoading(false);
    });
  };
  useEffect(() => {
    if (visible === true) {
      fetchData();
    }
  }, [visible]);

  function seleccionarValor(localizacion, indice, valor) {
    let localizacionesSelAux;
    if (valor != -1) {
      localizacionesSelAux = localizacionesSel.slice();
      localizacionesSelAux[indice + 1] = localizacion;
      

      localizacionesSelAux.splice(
        indice + 2,
        localizacionesSelAux.length - indice + 2
      );

      setLocalizacionesSel(localizacionesSelAux);

      let opcionesSelAux = optionsSel.slice();

      opcionesSelAux[indice] = valor;

      opcionesSelAux.splice(indice + 1, opcionesSelAux.length - indice + 1);

      setOptionsSel(opcionesSelAux);
    } else {
      localizacionesSelAux = localizacionesSel.slice();
      let opcionesSelAux = optionsSel.slice();
      localizacionesSelAux[indice + 1] = localizacion;
      opcionesSelAux[indice] = valor;
      localizacionesSelAux.splice(
        indice + 1,
        localizacionesSelAux.length - indice + 1
      );
      opcionesSelAux.splice(indice, opcionesSelAux.length - indice);
      setLocalizacionesSel(localizacionesSelAux);
      setOptionsSel(opcionesSelAux);
    }
    canOpen(servicioSel, localizacionesSelAux);
  }

  function cargarOpciones(localizacion) {
    let arrayOpciones = [
      /*{
      label:localizacion.name,
      value:-1
    }*/
    ];
    switch (localizacion.structure.type) {
      case "numeric":
        for (
          var i = parseInt(localizacion.structure.number_from);
          i <= parseInt(localizacion.structure.number_to);
          i++
        ) {
          arrayOpciones.push({
            label: localizacion.name + " " + i,
            value: i,
          });
        }
        break;
      case "numeric_even_odd":
        for (
          var i = parseInt(localizacion.structure.number_from);
          i <= parseInt(localizacion.structure.number_to);
          i++
        ) {
          if (localizacion.structure.even_odd == "even") {
            if (i % 2 == 0) {
              arrayOpciones.push({
                label: localizacion.name + " " + i,
                value: i,
              });
            }
          } else {
            if (i % 2 != 0) {
              arrayOpciones.push({
                label: localizacion.name + " " + i,
                value: i,
              });
            }
          }
        }
        break;
      case "alphanumeric":
        for (
          var i = parseInt(localizacion.structure.number_from);
          i <= parseInt(localizacion.structure.number_to);
          i++
        ) {
          for (
            var j = getCharIndex(localizacion.structure.letter_from);
            j <= getCharIndex(localizacion.structure.letter_to);
            j++
          ) {
            arrayOpciones.push({
              label:
                localizacion.name +
                " " +
                i +
                " " +
                getCharFromIndex(j, "upper"),
              value: i + " " + getCharFromIndex(j, "upper"),
            });
          }
        }
        break;
      case "alphanumeric_even_odd":
        for (
          var i = parseInt(localizacion.structure.number_from);
          i <= parseInt(localizacion.structure.number_to);
          i++
        ) {
          if (localizacion.structure.even_odd == "even") {
            if (i % 2 == 0) {
              for (
                var j = getCharIndex(localizacion.structure.letter_from);
                j <= getCharIndex(localizacion.structure.letter_to);
                j++
              ) {
                arrayOpciones.push({
                  label:
                    localizacion.name +
                    " " +
                    i +
                    " " +
                    getCharFromIndex(j, "upper"),
                  value: i + " " + getCharFromIndex(j, "upper"),
                });
              }
            }
          } else {
            if (i % 2 != 0) {
              for (
                var j = getCharIndex(localizacion.structure.letter_from);
                j <= getCharIndex(localizacion.structure.letter_to);
                j++
              ) {
                arrayOpciones.push({
                  label:
                    localizacion.name +
                    " " +
                    i +
                    " " +
                    getCharFromIndex(j, "upper"),
                  value: i + " " + getCharFromIndex(j, "upper"),
                });
              }
            }
          }
        }
        break;
      case "alphabetical":
        for (
          var i = getCharIndex(localizacion.structure.letter_from);
          i <= getCharIndex(localizacion.structure.letter_to);
          i++
        ) {
          arrayOpciones.push({
            label: localizacion.name + " " + getCharFromIndex(j, "upper"),
            value: getCharFromIndex(j, "upper"),
          });
        }
        break;
      case "writing":
        localizacion.structure.writing.map(function (opcion, indice) {
          arrayOpciones.push({
            label: localizacion.name + " " + opcion,
            value: opcion,
          });
        });
        break;
    }
    return arrayOpciones;
  }
  function pintarSelect(localizacion, indice) {
    let arrayHijos = [];
    if (localizacion.childs != undefined) {
      localizacion.childs.map(function (hijo, indice) {
        hijo.arrayOpciones = cargarOpciones(hijo);
        arrayHijos.push(hijo);
      });
    }
    if (localizacion.structure != undefined) {
      if (localizacion.structure.length > 0) {
        localizacion.structure.map(function (hijo, indice) {
          hijo.arrayOpciones = cargarOpciones(hijo);
          arrayHijos.push(hijo);
        });
      }
    }
    if (arrayHijos.length > 0) {
      let arrayLoc = [];
      if (Platform.OS !== "ios") {
        arrayHijos[0].arrayOpciones.unshift({
          label: "Seleccionar localización",
          value: -1,
        });
      }

      arrayHijos.map(function (hijo, indice) {
        let color = colors.brown;
        if (servicioSel.gid_active != undefined) {
          if (servicioSel.gid_active.indexOf(parseInt(hijo.gid)) == -1) {
            color = "#D7D7D7";
          }
        }
        return hijo.arrayOpciones.map(function (opcion, indice) {
          arrayLoc.push(opcion);
        });
      });
      return (
        <View style={styles.pickerContainer}>
          {Platform.OS === "ios" ? (
            <SelectDropdown
              data={arrayLoc}
              onSelect={function (itemValue, itemIndex) {
                let limiteZona = 0;
                let zonaSel = -1;
                arrayHijos.map(function (hijo, indice) {
                  limiteZona = limiteZona + hijo.arrayOpciones.length;
                  if (zonaSel == -1 && limiteZona > itemIndex) {
                    zonaSel = indice;
                  }
                });
                seleccionarValor(arrayHijos[zonaSel], indice, itemValue);
              }}
              defaultValue={optionsSel[indice] || -1}
              defaultButtonText={"Elige localización"}
              renderCustomizedButtonChild={(selectedItem, index) => {
                return (
                  <View style={styles.dropdown4BtnStyle}>
                    <Text style={styles.dropdown4BtnTxtStyle}>
                      {selectedItem ? selectedItem.label : "Elige localización"}
                    </Text>
                  </View>
                );
              }}
              renderCustomizedRowChild={(item, index) => {
                return (
                  <View style={styles.dropdown4BtnStyle}>
                    <Text style={styles.dropdown4BtnTxtStyle}>
                      {item.label}
                    </Text>
                  </View>
                );
              }}
            />
          ) : (
            <Picker
              style={styles.picker}
              mode={"dropdown"}
              selectedValue={optionsSel[indice] || -1}
              onValueChange={function (itemValue, itemIndex) {
                let limiteZona = 0;
                let zonaSel = -1;
                arrayHijos.map(function (hijo, indice) {
                  limiteZona = limiteZona + hijo.arrayOpciones.length;
                  if (zonaSel == -1 && limiteZona > itemIndex) {
                    zonaSel = indice;
                  }
                });
                seleccionarValor(arrayHijos[zonaSel], indice, itemValue);
              }}
              dataSet={{ media: ids.picker }}
              itemStyle={styles.pickerItem}
            >
              {arrayHijos.map(function (hijo, indice) {
                let color = colors.brown;
                if (servicioSel.gid_active != undefined) {
                  if (
                    servicioSel.gid_active.indexOf(parseInt(hijo.gid)) == -1
                  ) {
                    color = "#D7D7D7";
                  }
                }

                return hijo.arrayOpciones.map(function (opcion, indice) {
                  return (
                    <Picker.Item
                      key={optionsSel.value}
                      color={color}
                      label={opcion.label.toString()}
                      value={opcion.value}
                      fontFamily={family.normal}
                    />
                  );
                });
              })}
            </Picker>
          )}
        </View>
      );
    }
  }
  function canOpen(servicioSel, localizacionesSel) {
    let puedoAbrirTienda = true;
    if (
      servicioSel.levels_locations == "All" ||
      servicioSel.levels_locations == "all"
    ) {
      if (localizacionesSel[localizacionesSel.length - 1] != undefined) {
        if (
          localizacionesSel[localizacionesSel.length - 1].childs == undefined &&
          localizacionesSel[localizacionesSel.length - 1].type_amount ==
            undefined
        ) {
          puedoAbrirTienda = true;
        } else {
          puedoAbrirTienda = false;
        }
      }
    } else {
      if (
        parseFloat(servicioSel.levels_locations) >= localizacionesSel.length
      ) {
        puedoAbrirTienda = false;
      }
    }
    setActiveButton(puedoAbrirTienda);
  }
  function finishHandler() {
    let opcionesSelAux = [];
    let auxText = "";

    localizacionesSel.map(function (localizacion, indice) {
      if (indice != 0) {
        let aux = {
          gid_location: localizacion.gid,
          select: optionsSel[indice - 1],
        };
        if (Platform.OS === "ios") {
          auxText +=
            localizacion.name + " " + optionsSel[indice - 1]?.value + " ";
        } else {
          auxText += localizacion.name + " " + optionsSel[indice - 1] + " ";
        }
        opcionesSelAux.push(aux);
      }
    });
    if (opcionesSelAux.length > 0) {
      setService({
        id: 2,
        locationId: opcionesSelAux[opcionesSelAux.length - 1].gid_location,
        location: auxText,
      });
    } else {
      setService({
        id: 2,
        location: auxText,
      });
    }

    setModal(false);
  }
  return (
    <CustomModal modalVisible={visible} handleModal={() => setModal(false)}>
      <View style={styles.container}>
        {loading === true ? (
          <View style={styles.loadingWrapper}>
            <ActivityIndicator size="small" color={colors.primaryColor} />
          </View>
        ) : (
          <ScrollView>
            {localizacionesSel != undefined && localizacionesSel.length > 0 && (
              <Text style={styles.siteTitle} dataSet={{ media: ids.siteTitle }}>
                Elige el sitio
              </Text>
            )}
            <View style={styles.siteContainer}>
              {localizacionesSel != undefined && localizacionesSel.length > 0
                ? localizacionesSel.map(function (localizacion, indice) {
                    if (
                      servicioSel[0].levels_locations == "all" ||
                      servicioSel[0].levels_locations == "All" ||
                      parseFloat(servicioSel[0].levels_locations) > indice
                    ) {
                      return pintarSelect(localizacion, indice);
                    }
                  })
                : null}
            </View>
          </ScrollView>
        )}

        <View
          style={styles.buttonWrapper}
          dataSet={{ media: ids.buttonWrapper }}
        >
          <CustomButton
            marginTop={10}
            title="Aceptar"
            active={activeButton}
            onPress={finishHandler}
          />
        </View>
      </View>
    </CustomModal>
  );
};

const { ids, styles } = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  topTitle: {
    padding: 10,
    fontWeight: "bold",
    color: colors.brown,
    fontSize: 18,
    width: "100%",
    textAlign: "center",
    "@media (max-width:370px)": {
      padding: 5,
      fontSize: 12,
    },
  },
  siteTitle: {
    padding: 10,
    fontWeight: "bold",
    color: "#E22314",
    fontSize: 18,
    width: "100%",
    textAlign: "center",
    "@media (max-width:370px)": {
      padding: 5,
      fontSize: 12,
    },
  },
  servicesContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginVertical: 5,
    "@media (max-width:370px)": {
      marginVertical: 2,
    },
  },
  siteContainer: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
  },
  pickerItem: {
    borderRadius: 5,
    fontFamily: family.normal,
    color: colors.brown,
  },
  delivery: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  buttonWrapper: {
    height: 60,
    width: "100%",
    "@media (max-width:370px)": {
      marginVertical: 2,
      height: 40,
    },
  },
  pickerContainer: {
    borderRadius: 8,
    borderColor: "gray",
    marginBottom: 20,
  },
  picker: {
    height: 50,
    width: 200,
    "@media (max-width:370px)": {
      height: 30,
    },
  },
  dropdown4BtnStyle: {
    width: "100%",
    height: 30,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
  },
  dropdown4BtnTxtStyle: {
    color: colors.brown,
    textAlign: "center",
    fontFamily: family.normal,
    fontSize: 12,
  },
  loadingWrapper: {
    height: 50,
    justifyContent: "center",
    alignItems: "center",
  },
});

const mapDispatchToProps = {
  setService,
};

export default connect(null, mapDispatchToProps)(LocationsModal);
