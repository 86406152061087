import React from "react";
import { FontAwesome } from "@expo/vector-icons";
import { StyleSheet, View, TouchableOpacity } from "react-native";

// Constants
import { colors } from "../../constants/theme";

const CloseButtonModal = ({ onPress, size = 14, color = colors.brown }) => {
  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <FontAwesome name="close" size={size} color={color} />
    </TouchableOpacity>
  );
};

export default CloseButtonModal;

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    right: 20,
    top: 20,
    zIndex: 10,
  },
});
