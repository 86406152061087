import React, { useState } from "react";
import { connect } from "react-redux";

// Components
import Screen from "../../../components/atoms/Screen";
import CheckoutContainer from "./containers/CheckoutContainer";
import NoOrder from "./containers/NoOrder";

// Utils
import { hasOrders } from "../../../utils/orderMethods";

// Redux Actions
import { purgueCart } from "../../../redux/cart/cartActions";

const ConfirmOrderScreen = ({ orders, navigation }) => {
  const [screenColor, setScreenColor] = useState();

  return (
    <Screen screenColor={screenColor}>
      {!hasOrders(orders) ? (
        <NoOrder navigation={navigation} />
      ) : (
        <CheckoutContainer
          navigation={navigation}
          setScreenColor={setScreenColor}
        />
      )}
    </Screen>
  );
};

const mapDispatchToProps = {
  purgueCart,
};

const mapStateToProps = (state) => {
  return { orders: state.cart.orders };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmOrderScreen);
