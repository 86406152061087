import React, { useState } from "react";
import { StyleSheet, View } from "react-native";

// Components
import CustomButton from "../../atoms/CustomButton";
import CustomModal from "../../atoms/CustomModal";
import TextInput from "../../atoms/TextInput";
import CustomText from "../../atoms/CustomText";
import { URlManagement } from "../../../constants/urls";
import i18n from "../../../i18n/i18n";
import { getLocalData } from "../../../utils/miscelanusUtils";
import { connect } from "react-redux";
import { baseVersion } from "../../../../config";

const TicketDiscountModal = ({
  visible,
  setModal,
  discountError,
  setPromotion,
  setDiscountError,
  userGid,
}) => {
  const [value, setValue] = useState("");

  const getDiscount = async () => {
    let gid = userGid;
    if (gid === null || gid === undefined) {
      setModal("LogIn")
    }
    try {
      const url = `${URlManagement}/discount/getOfUser/${gid}?code=${value}`;
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Connection: "keep-alive",
          "X-Client-Version": baseVersion
        },
        method: "GET",
      });

      const resData = await response.json();
      if (resData.message === "success") {
        return resData.data;
      } else {
        setDiscountError(i18n.t("tickets.code_invalid"));
        return false;
      }
    } catch (err) {
      console.log("error fetching event:", err);
    }
  };

  const handlePromotion = async () => {
    const response = await getDiscount();
    setPromotion(response);
  };

  const handleModal = () => {
    setDiscountError();
    setModal();
  };
  return (
    <CustomModal
      modalVisible={visible}
      handleModal={handleModal}
      color={"rgba(0,0,0,0.8)"}
    >
      <View style={styles.inputWrapper}>
        <TextInput
          value={value}
          onChange={setValue}
          placeholder={i18n.t("tickets.write_code")}
        />
        <View style={styles.errorWrapper}>
          {discountError && <CustomText color="red" text={discountError} />}
        </View>
        <View style={{ height: 60, width: "100%" }}>
          <CustomButton
            color={"white"}
            textColor={"black"}
            title="Validar"
            marginTop={20}
            active
            onPress={handlePromotion}
          />
        </View>
      </View>
    </CustomModal>
  );
};

const styles = StyleSheet.create({
  inputWrapper: {
    width: "100%",
  },
  errorWrapper: {
    height: 15,
    marginTop: 5,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
});

const mapStateToProps = (state) => {
  return {
    userGid: state.user.user.gid,
  };
};
export default connect(mapStateToProps)(TicketDiscountModal);
