const needToSchedule = (orders) => {
  let result = false;
  orders.map((shop) => {
    if (
      shop.only_scheduled === true &&
      shop.delivery === "Lo antes posible"
    ) {
      result = `Debes programar pedido para la tienda ${shop.shop_name}`;
    }
  });
  return result;
};

export default needToSchedule